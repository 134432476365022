export class LegalStatusesDTO {
  id!: number;
  title: string = "";
  description: string = "";
  parent_id: string = "";
  is_active: string = "";

  parent: { id: number; title: string } = { id: undefined, title: "" };

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
export class LegalStatusesCategoryDTO {
  id!: number;
  title: string = "";
  constructor(data = {}) {
    Object.assign(this, data);
  }
}
