import { FC, useEffect, useRef, useState } from "react";
import { DropdownMain, SingleSelectFieldMain } from "./style";
import { useSelector } from "react-redux";
import FormErrorMessage from "../form-error-message";
import useComponentVisible from "hooks/click-outside";

interface Props {
  options: any[];
  onSelect: (item: any) => void;
  value: any;
  property?: string;
  searchable?: boolean;
  closeOnSelect?: boolean;
  setShow?: Function;
  label: string;
  error?: any;
  helperText?: string;
}

const SingleSelectField: FC<Props> = ({
  options,
  onSelect,
  value,
  property = "title",
  searchable = true,
  error,
  helperText = "Select",
  label,
}) => {
  const [searchedOptions, setSearchedOptions] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const searchRef = useRef(null);
  const {
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown,
    ref: dropdownRef,
  } = useComponentVisible();

  const handleSelect = (item: any) => {
    onSelect(item?.id);
    setShowDropdown(false);
  };

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearch(value);

    const filteredOptions = options.filter((board) =>
      board?.[property]?.toLowerCase()?.includes(value.toLowerCase())
    );
    setSearchedOptions(filteredOptions);
  };

  const getOptions = (): any[] => {
    return search === "" ? options : searchedOptions;
  };

  useEffect(() => {
    if (searchable && searchRef?.current && options.length) {
      (searchRef as any).current.focus();
    }
  }, [options]);

  const getValue = (): string => {
    const item = options.find((item) => item.id == value);
    return item ? item[property] : "";
  };

  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <SingleSelectFieldMain ref={dropdownRef}>
      <div className="input-field">
        <label>{label}</label>
        <div className="field-wrap">
          <div className="field" onClick={toggleShowDropdown}>
            <input
              type="text"
              className="cd"
              value={getValue()}
              placeholder={helperText}
              readOnly
            />
          </div>
          {!showDropdown ? <FormErrorMessage error={error} /> : ""}
        </div>
      </div>
      {showDropdown ? (
        <DropdownMain>
          <ul className="p-custom-scrollbar-8">
            {searchable && options.length ? (
              <div className="search-section">
                <input
                  type="search"
                  placeholder="Search"
                  onChange={handleSearchChange}
                  ref={searchRef}
                />
              </div>
            ) : (
              ""
            )}
            {getOptions().map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => handleSelect(item)}
                className={`${value == item.id ? "selected" : ""}`}
              >
                <div className="item-text">
                  <span className="text">{item?.[property]}</span>
                </div>
              </li>
            ))}
            {!getOptions().length && !isLoading && (
              <span className="no-options-found">No option found</span>
            )}
          </ul>
        </DropdownMain>
      ) : (
        ""
      )}
    </SingleSelectFieldMain>
  );
};

export default SingleSelectField;
