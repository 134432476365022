export class EnrolledStudentsDTO {
  total_enrolled_female_students: number = 0;
  total_enrolled_male_students: number = 0;
  total_enrolled_students: number = 0;
  total_active_enrolled_students: {
    total_count: number;
    female_count: number;
    male_count: number;
  } = {
    total_count: 0,
    female_count: 0,
    male_count: 0,
  };
  total_active_not_enrolled_students: {
    total_count: number;
    female_count: number;
    male_count: number;
  } = {
    total_count: 0,
    female_count: 0,
    male_count: 0,
  };

  constructor(data: Partial<EnrolledStudentsDTO> = {}) {
    Object.assign(this, data);
  }
}
