// import { siteRoutes } from "utils/helpers/enums/routes.enum";

// const getPathToSetRoute = (path: string, isModule: boolean = false): string => {
//   const splittedPath: string[] = path.split("/");
//   const pagePath: string = splittedPath[splittedPath.length - 1];
//   let pathToReturn: string = "";
//   if (isModule) {
//     const modulePath: string = splittedPath[splittedPath.length - 2];
//     pathToReturn = `${modulePath}/${pagePath}`;
//   } else {
//     pathToReturn = pagePath;
//   }
//   return pathToReturn;
// };

export const religions: any[] = [
  { title: "Islam" },
  { title: "Jewism" },
  { title: "Christianity" },
  { title: "Hinduism" },
  { title: "Budhism" },
];
