export class CourseAnomaliesDTO {
  course_enrollemts_not_assigned: number = 0;
  course_sections_not_assigned: number = 0;
  course_teachers_not_assigned: number = 0;
  duplicate_template_courses: number = 0;
  enrollments_not_belongsto_pos: number = 0;

  constructor(data: Partial<CourseAnomaliesDTO> = {}) {
    Object.assign(this, data);
  }
}

export class AcademicDashboardGradebookDataDTO {
  gradebooks_receipt_acknowledged: number;
  gradebooks_submitted: number;
  gradebooks_submitted_and_verified: number;
  gradebooks_submitted_not_verified: number;
  zero_marks_in_submitted_gradebooks: number;
  zero_marks_in_verified_gradebooks: number;

  constructor(data: Partial<AcademicDashboardGradebookDataDTO> = {}) {
    Object.assign(this, data);
  }
}
