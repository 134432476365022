import { ACADEMICS_APIS } from "libs/apis/academics.api";

const useMyApps = () => {
  const getExamInchargeProgrames = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function,
    setSessionData: Function,
    setCampusData: Function
  ) => {
    const response = await ACADEMICS_APIS.getExamInchargeProgrames(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.records?.data || [];
      const sessionsData = response?.response?.sessions || [];
      const CampusData = response?.response?.campus_id || [];
      if (data) {
        setData(data);
        setSessionData(sessionsData);
        setCampusData(CampusData);
      }
    }
  };

  const getExamInchargeSection = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ACADEMICS_APIS.getExamInchargeSection(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.records?.data || [];
      if (data) {
        setData(data);
      }
    }
  };
  return { getExamInchargeProgrames, getExamInchargeSection };
};

export default useMyApps;
