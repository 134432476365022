import { FC, Fragment, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { ResultApproveUpdateDTO } from "utils/helpers/models/finance/update-result-status-approve-unapprove.dto";
import useSystemAdministration from "containers/private/system-administration/useHooks";
import useAcademics from "containers/private/academics/useHooks";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

interface pageProps {
  setOpen: Function;
  verified_status: number;
  gradebook_id: number;
  course_id: number;
}

const UpdateResultApproveStatusModal: FC<pageProps> = ({
  setOpen,
  verified_status,
  gradebook_id,
  course_id,
}) => {
  const { hasAccess } = useStore();
  const [formData, setFormData] = useState<ResultApproveUpdateDTO>(
    new ResultApproveUpdateDTO({ gradebook_id, course_id })
  );
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<ResultApproveUpdateDTO>();

  const { updateGradeBookResultApproveStatus } = useAcademics();
  const handleChange = (event: any) => {
    const {
      target: { name, value },
    } = event;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    const dto = new ResultApproveUpdateDTO();
    for (let key in formData) {
      if (key !== "userId") {
        formData[key] = dto[key];
        setValue(key as keyof ResultApproveUpdateDTO, formData[key]);
      }
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: ResultApproveUpdateDTO, status: number) => {
    if (!gradebook_id || !course_id) {
      console.error("Gradebook ID or Course ID is missing.");
      return;
    }
    const updatedFormData: Partial<ResultApproveUpdateDTO> = {
      ...data,
      gradebook_id: gradebook_id,
      course_id: course_id,
    };
    updateGradeBookResultApproveStatus(updatedFormData, status);
  };

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>
                Add Remarks & {verified_status == 0 ? "Approve" : "Reject"}
              </span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label htmlFor="">Remarks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("remarks", { required: true })}
                      value={formData?.remarks}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.remarks} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={resetForm}
                >
                  Reset
                </button>
                {verified_status == 0 ? (
                  <Fragment>
                    {hasAccess(sitePermissions.approveGradeBookResult) && (
                      <button
                        className="lg-rounded-btn"
                        type="button"
                        onClick={() =>
                          handleSubmit((data) => onSubmit(data, 1))()
                        }
                      >
                        Approve & Submit
                      </button>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {hasAccess(sitePermissions.UnApproveGradeBookResult) && (
                      <button
                        className="lg-rounded-btn red"
                        type="button"
                        onClick={() =>
                          handleSubmit((data) => onSubmit(data, 0))()
                        }
                      >
                        Reject & Submit
                      </button>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default UpdateResultApproveStatusModal;
