import { FC, Fragment, useEffect, useState } from "react";
import {
  TeachersTitlesListingSection,
  TeachersTitlesListingMain,
  TeachersTitlesListingTop,
  Filters,
  FilterHeader,
  FilterSection,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import { TeachersTitleDTO } from "utils/helpers/models/academics/teachers-title.dto";
import useAcademics from "../../useHooks";
import { VerifiedGradeBookDTO } from "utils/helpers/models/academics/verified-gradebook.dto";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";

const VerifiedGradebookListing: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: "" },
    {
      title: "Verified Gradebooks",
      path: siteRoutes.academicsVerifiedGradebookListing,
    },
  ];
  const navigate = useNavigate();
  const [data, setData] = useState<VerifiedGradeBookDTO[]>([]);
  const [session, setSession] = useState<any>([]);
  const [campuses, setCampuses] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [programs, setPrograms] = useState<any>([]);
  const [sections, setSections] = useState<any>([]);
  const { getTeachersTitles, deleteTeachersTitle } = useAcademics();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Campus",
    "Department",
    "Course",
    "Course Code",
    "Teachers",
    "Status",
    "Action",
  ];
  const [filters, setFilters] = useState<any>({
    session: "",
    campuses: "",
    departments: "",
    programs: "",
    sections: "",
  });

  const getAllVerifiedGradeBooks = (page: number = 1) => {
    const filterOptions = { ...filters };

    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }

    const queryParams = {
      per_page: pagination.per_page,
      page,
      ...filterOptions,
    };

    getVerifiedGradeBooks((data: any) => {
      const studentsWithExpandState = data?.map((student: any) => ({
        ...student,
        isExpanded: false,
      }));
      setData(studentsWithExpandState);
    }, queryParams);
  };
  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleApplyFilters = () => {
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };
      getAllVerifiedGradeBooks();
      return updatedFilters;
    });
  };

  const { handleSearchChange, handleTableSearch } = useUtils();

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
  };

  const goToAwardList = (id: number) => {
    navigate(`${siteRoutes.academicsAwardList}?gradebook_id=${id}`);
  };
  const { getVerifiedGradeBooks, getAcademicSessions, getAcademicSections } =
    useAcademics();

  const { getSpecificOrgStructures, getPrograms } = useOrganization();

  useEffect(() => {
    getAllVerifiedGradeBooks();
    getAcademicSessions(setSession);
    getPrograms(setPrograms);
    getAcademicSections(setSections);
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
  }, []);

  return (
    <TeachersTitlesListingMain>
      <TeachersTitlesListingTop>
        <div className="left">
          <span className="page-heading">Verified Gradebooks</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </TeachersTitlesListingTop>
      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Select Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="session" onChange={handleFilterChange}>
                      <option value="">Select Session</option>
                      {session.map((item: any, index: number) => (
                        <option key={index} value={item.id}>
                          {item?.session?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="campus" onChange={handleFilterChange}>
                      <option value="">Select Campus</option>
                      {campuses.map((item: any, index: number) => (
                        <option key={index} value={item.id}>
                          {item?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="department" onChange={handleFilterChange}>
                      <option value="">Select Department</option>
                      {departments.map((item: any, index: number) => (
                        <option key={index} value={item.id}>
                          {item?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="program" onChange={handleFilterChange}>
                      <option value="">Select Programs</option>
                      {programs.map((item: any, index: number) => (
                        <option key={index} value={item.id}>
                          {item?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Section</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="section" onChange={handleFilterChange}>
                      <option value="-1">Select Section</option>
                      {sections.map((item: any, index: number) => (
                        <option key={index} value={item.id}>
                          {item?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  // onClick={handleResetFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={handleApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <TeachersTitlesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" value={search} />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((title, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">
                        {title?.course?.campus?.title}
                      </div>
                    </td>
                    <td>{title?.course?.template_course?.department?.title}</td>
                    <td>{title?.course?.c_title}</td>
                    <td>{title?.course?.c_code}</td>
                    <td>
                      {
                        title?.course?.course_teacher_allocations[0]
                          ?.teacher_details?.teacher_firstname
                      }{" "}
                      {
                        title?.course?.course_teacher_allocations[0]
                          ?.teacher_details?.teacher_lastname
                      }
                    </td>
                    <td>
                      {title?.submitted_status === 1 ? (
                        <span className="status-tile green">Submitted</span>
                      ) : (
                        <span className="status-tile red">Not Submitted</span>
                      )}
                    </td>
                    <td>
                      <div className="table-action-icons">
                        <div className="table-action-button">
                          <button className="unApproved-btn">UnApproved</button>
                        </div>
                        <div
                          className="table-action-button"
                          onClick={() => goToAwardList(title?.id)}
                        >
                          <button className="award-btn">Award List</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data?.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </TeachersTitlesListingSection>
    </TeachersTitlesListingMain>
  );
};

export default VerifiedGradebookListing;
