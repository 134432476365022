import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicExamTypeListingMain,
  AcademicExamTypeListingSection,
  AcademicExamTypeListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAcademics from "containers/private/academics/useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { errorToaster } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import useMyApps from "../../useHooks";

interface dataInterFace {
  id: number;
  title: string;
  submitted_courses: string;
  verified_courses: string;
}

const ExamInchargePrograms: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "My Apps / ", path: "" },
    {
      title: "Exam Incharge Programs Listing",
      path: siteRoutes.examInchargePrograms,
    },
  ];
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { getAcademicSessions } = useAcademics();
  const { getExamInchargeProgrames } = useMyApps();

  const { createQuery } = useUtils();

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [sessions, setSessionData] = useState<any[]>([]);
  const [campus_id, setCampusData] = useState<any[]>([]);
  const [data, setData] = useState<dataInterFace[]>([]);
  const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>(
    []
  );
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Course Title",
    "Submitted Courses",
    "Verified Courses",
    "Actions",
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [filters, setFilters] = useState<any>({
    session_id: "",
  });
  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };
  const onClearFilters = async () => {
    const clearedFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
    setFilters(clearedFilters);
    getExamInchargeProgramsList(pagination.page, search, pagination.per_page);
  };

  const onSubmitFilters = () => {
    getExamInchargeProgramsList(pagination.page, search, pagination.per_page);
  };

  const goToViewSections = (program_id: number, index: number) => {
    const session = sessions[index];

    if (!session) {
      errorToaster("Session not found at the provided index");
      return;
    }
    const params = createQuery({
      session_id: session?.id || filters?.session_id,
      campus_id,
      program_id,
    });
    navigate(siteRoutes.examInchargeSections + params);
  };
  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const getExamInchargeProgramsList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      search,
      per_page,
      session_id: filters?.session_id,
    };
    getExamInchargeProgrames(
      setData,
      queryParams,
      setPagination,
      setSessionData,
      setCampusData
    );
  };

  useEffect(() => {
    getAcademicSessions(setAcademicSession);
    getExamInchargeProgramsList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AcademicExamTypeListingMain>
      <AcademicExamTypeListingTop>
        <div className="left">
          <span className="page-heading">Exam Incharge Programs</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcademicExamTypeListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="campus-select">Select Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      id="session_id"
                      name="session_id"
                      value={filters.session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {academicSession?.map((item: any, i: number) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.type} {item?.year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={onClearFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onSubmitFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <AcademicExamTypeListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => (
                <tr>
                  <td>
                    <div className="mw-150">{item?.title}</div>
                  </td>
                  <td>
                    <div className="status-tile">{item?.submitted_courses}</div>
                  </td>
                  <td>
                    <div className="status-tile">{item?.verified_courses}</div>
                  </td>
                  <td>
                    <div className="table-action-icons">
                      {/* {hasAccess(
                        sitePermissions.examInchargeSections
                      ) && ( */}
                      <div
                        className="table-action-button"
                        onClick={() => goToViewSections(item?.id, index)}
                      >
                        <button className="black">View Sections</button>
                      </div>
                      {/* )} */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !data?.length} />
            <Pagination
              onPageChange={(page: any) => console.log(page)}
              {...pagination}
            />
          </Fragment>
        </div>
      </AcademicExamTypeListingSection>
    </AcademicExamTypeListingMain>
  );
};

export default ExamInchargePrograms;
