import { FC, Fragment, useState, useEffect } from "react";
import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  StudyPlanCourseListingMain,
  StudyPlanCourseListingSection,
  StudyPlanCourseListingTop,
  StudyPlanCourseListingStatsSection,
} from "./style";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { CoursesListingSection } from "containers/private/e-portal/short-courses/listing/style";
import useStudentPortal from "../../useHooks";
import { MyPlanOfStudyDTO } from "utils/helpers/models/user/plan-of-study.dto";

const StudentStudyPlan: FC = () => {
  const breadcrumbLinks = [
    { title: "Student Dashboard / ", path: "" },
    {
      title: " Planof Study",
      path: siteRoutes.studentPlanOfStudy,
    },
  ];
  const columns1: string[] = [
    "",
    "Course Code",
    "Course ",
    "Faculty",
    "Credit Hours(Theory -Lab)",
  ];
  const columns2: string[] = [
    "",
    "Course Code",
    "Course ",
    "",
    "Credit Hours(Theory -Lab)",
  ];

  const { getMyPlanOfStudy } = useStudentPortal();

  const [planOfStudy, setPlanOfStudy] = useState<MyPlanOfStudyDTO[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Course Code",
    "Course",
    "Faculty",
    "Credit Hours(Theory -Lab)",
  ];

  const getMyAllPlanOfStudy = () => {
    getMyPlanOfStudy(setPlanOfStudy);
  };
  useEffect(() => {
    getMyAllPlanOfStudy();
  }, []);
  return (
    <StudyPlanCourseListingMain>
      <StudyPlanCourseListingTop>
        <div className="left">
          <span className="page-heading">Plan of Study</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn">Download PDF</button>
          </div>
        </div>
      </StudyPlanCourseListingTop>
      <StudyPlanCourseListingStatsSection className="content-radius-shadow">
        <div className="table-heading">Plan of study</div>
        <div className="stats">
          <div className="sats-item">
            <div className="stats-title">Total Plan of Study Credit Hours</div>
            <div className="stats-value">36</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Total Fulfilled Credit Hours</div>
            <div className="stats-value">24</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Remaining Credit Hours </div>
            <div className="stats-value">12</div>
          </div>
        </div>
      </StudyPlanCourseListingStatsSection>

      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="table-heading">Semester 1</div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns1.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1, 1].map((course, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="green-dot">...</div>
                    </td>
                    <td>ARIN-4567</td>
                    <td>Advanced Theory of Computation</td>
                    <td>
                      <div className="mw-150">Dr. Wareesha Sharif</div>
                    </td>
                    <td>
                      <div className="mw-150">3 (3-0)</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </StudyPlanCourseListingSection>
      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="table-heading">Semester 2</div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns1.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1, 1].map((course, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="green-dot">...</div>
                    </td>
                    <td>ARIN-4567</td>
                    <td>Advanced Theory of Computation</td>
                    <td>
                      <div className="mw-150">Dr. Wareesha Sharif</div>
                    </td>
                    <td>
                      <div className="mw-150">3 (3-0)</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </StudyPlanCourseListingSection>
      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="table-heading">Semester 3</div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns1.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1, 1].map((course, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="gray-dot">...</div>
                    </td>
                    <td>ARIN-4567</td>
                    <td>Advanced Theory of Computation</td>
                    <td>
                      <div className="mw-150">Dr. Wareesha Sharif</div>
                    </td>
                    <td>
                      <div className="mw-150">3 (3-0)</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </StudyPlanCourseListingSection>
      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="table-heading">Semester 4</div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns2.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1, 1].map((course, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="gray-dot">...</div>
                    </td>
                    <td>ARIN-4567</td>
                    <td>Advanced Theory of Computation</td>
                    <td></td>
                    <td>
                      <div className="mw-150">3 (3-0)</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Fragment></Fragment>
      </StudyPlanCourseListingSection>
    </StudyPlanCourseListingMain>
  );
};

export default StudentStudyPlan;
