export class LegalAdviserDTO {
  id!: number;
  name: string = "";
  designation: string = "";
  cnic: string = "";
  license_no: number;
  email: string = "";
  mobile: string = "";
  address: "";
  is_active: number;
  commencement_date: string = "";
  ending_date: string = "";
  category: string = "";
  type_id: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
export class LegalAdviserTypeDTO {
  id!: number;
  title: string = "";
  description: string = "";

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
