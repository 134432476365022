import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicSessionListingSection,
  AcademicSessionListingTop,
  AcademicSessionListingMain,
} from "./style";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  MinusRedSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import useAcademics from "../../useHooks";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

interface AcademicSessionListingProps {}

const AcademicSessionListing: FC<AcademicSessionListingProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: "" },
    { title: "Academic Sessions", path: siteRoutes.academicSessionListing },
  ];
  const columns: string[] = [
    "",
    "Session",
    "Year",
    "Semester Type",
    "Enrollment Close Date",
    "Session Start Date",
    "Status",
    "Action",
  ];
  const navigate = useNavigate();
  const { getAcademicSessions, deleteAcademicSession } = useAcademics();
  const [data, setData] = useState<AcademicSessionDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch, getDateFromDateTime } =
    useUtils();
  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deleteAcademicSession(id, setData, queryParams, setPagination);
    }
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAcademicSessions(page + 1, search, per_page);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };
  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.createAcademicSession}?id=${id}`);
  };
  const goToManageAcademicSession = (session_id: number) => {
    navigate(
      `${siteRoutes.academicSessionManagementListing}?session_id=${session_id}`
    );
  };
  const goToCourseGroupsListing = () => {
    navigate(siteRoutes.academicCourseGroupListing);
  };
  const goToCreateAcademicSession = () => {
    navigate(siteRoutes.createAcademicSession);
  };
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  );
  const [isTableOpened, setIsTableOpened] = useState(false);
  const toggleDropdown = (index: number) => {
    setOpenedDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsTableOpened((prevState) => !prevState);
  };
  const getAllAcademicSessions = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getAcademicSessions(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAllAcademicSessions(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AcademicSessionListingMain>
      <AcademicSessionListingTop>
        <div className="left">
          <span className="page-heading">Academic Sessions</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createAcademicSession) && (
            <div className="create-org-btn" onClick={goToCreateAcademicSession}>
              <button className="lg-rounded-btn">+ Add Academic Session</button>
            </div>
          )}
        </div>
      </AcademicSessionListingTop>
      <AcademicSessionListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllAcademicSessions
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllAcademicSessions
                )
              }
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 6 ? `extra-head extra-head-${index - 6}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((session, index) => {
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${
                        (session as any).isExpanded && "opened"
                      }`}
                    >
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{(session as any).isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>{session?.session?.title ?? "-"}</td>
                      <td>{session?.session?.year ?? "-"}</td>
                      <td>{session.type ?? "-"}</td>
                      <td>{session.enrol_end_date ?? "-"}</td>
                      <td>
                        {getDateFromDateTime(session?.session?.start_date) ??
                          "-"}
                      </td>
                      <td>
                        {session?.is_active === 1 ? (
                          <span className="status-tile">Active</span>
                        ) : (
                          <span className="status-tile red">Inactive</span>
                        )}
                      </td>
                      <td className="extra-col extra-col-1">
                        <div className="action-menu">
                          <div
                            className="menu-icon cp"
                            onClick={() => toggleDropdown(index)}
                          >
                            <TabPrimaryActionMenu className="icon" />
                          </div>
                          {/* modal */}
                          {openedDropdownIndex === index && (
                            <div className="table-menu-dropdown">
                              <div className="particular-menu cp">
                                <span className="title">Edit</span>
                              </div>
                              <div
                                className="particular-menu cp"
                                onClick={() =>
                                  goToManageAcademicSession(session.id)
                                }
                              >
                                <span className="title">
                                  Management Academic Session
                                </span>
                              </div>

                              <div className="particular-menu cp">
                                <span className="title">
                                  Faculty Worked CSV
                                </span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title"> Course Group</span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title"> Course Anomalies</span>
                              </div>
                              <div className="particular-menu cp">
                                <span className="title">Delete Session</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {(session as any).isExpanded && (
                      <tr>
                        <td colSpan={9}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <div className="table-action-buttons">
                                <div
                                  className="action-icon cp"
                                  onClick={() => goToEdit(session.id)}
                                >
                                  <EditTableSvg />
                                </div>
                                <div
                                  className="action-icon cp"
                                  onClick={() => handleDelete(session.id)}
                                >
                                  <DeleteTableSvg />
                                </div>
                                {hasAccess(
                                  sitePermissions.academicSessionManagementListing
                                ) && (
                                  <div
                                    className="table-action-button"
                                    onClick={() =>
                                      goToManageAcademicSession(session.id)
                                    }
                                  >
                                    <button className="green">
                                      Management Academic Session
                                    </button>
                                  </div>
                                )}
                                <div className="table-action-button black">
                                  <button>Faculty Workload CSV</button>
                                </div>
                                {hasAccess(
                                  sitePermissions.academicCourseGroupListing
                                ) && (
                                  <div
                                    className="table-action-button purple"
                                    onClick={goToCourseGroupsListing}
                                  >
                                    <button className="purple">
                                      Course Groups
                                    </button>
                                  </div>
                                )}
                                <div className="table-action-button">
                                  <button className="red">
                                    Course Anomalies
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </AcademicSessionListingSection>
    </AcademicSessionListingMain>
  );
};

export default AcademicSessionListing;
