import { FC, useCallback, useMemo } from "react";
import { LeftSection, PaginationMain, PerPageField, RecordStats } from "./style";
import ReactPaginate from "react-paginate";

interface PaginationProps {
    onPageChange: (pageInfo: { selected: number, per_page: number }) => void;
    totalRecords: number;
    page: number;
    per_page: number;
}

const Pagination: FC<PaginationProps> = ({ onPageChange, page, totalRecords, per_page }) => {
    const perPageOptions: number[] = [10, 30, 50, 100];

    const handlePerPageChange = (event: any) => {
        const { target: { value } } = event;
        if (+value != per_page) {
            onPageChange({ selected: 0, per_page: +value });
        }
    }

    const handlePageChange = (pageInfo: { selected: number }) => {
        onPageChange({ ...pageInfo, per_page });
    }
    
        return (
        <PaginationMain>
            <LeftSection>
                <PerPageField>
                    <div className="input-field">
                        <div className="field-wrap">
                            <div className="field">
                                <select name="per_page" onChange={handlePerPageChange}>
                                    {perPageOptions.map((item, index) => {
                                        return <option key={index}>{item}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </PerPageField>
                <RecordStats>
                    <span>{totalRecords < page * per_page ? totalRecords : page * per_page} of {totalRecords}</span>
                </RecordStats>
            </LeftSection>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageChange}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(totalRecords / per_page)}
                previousLabel="<"
                renderOnZeroPageCount={null}
                forcePage={page - 1}
            />
        </PaginationMain>
    )
}

export default Pagination;