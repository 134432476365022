import { FC, useEffect, useState } from "react";
import { AddAllocateTeacherMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";

interface AddClearanceProps {
  setOpen: Function;
}

const ApplyClearance: FC<AddClearanceProps> = ({ setOpen }) => {
  return (
    <AddAllocateTeacherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Why Do You Need Clearance ?</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
           <div>
            <label htmlFor="">Comment</label>
            <div className="text-area">
                <textarea name="" id="" cols={30} rows={10}></textarea>
            </div>
           </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddAllocateTeacherMain>
  );
};

export default ApplyClearance;
