import { FC, useEffect, useState } from "react";
import { AddParticularToChallanMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import MultiselectDropdown from "components/particles/forms/multiselect-dropdown";
import useComponentVisible from "hooks/click-outside";
import { VoucherParticularDTO } from "utils/helpers/models/finance/voucher-particular.dto";
import { AccSlotInstallmentParticular } from "utils/helpers/models/finance/acc-slot-installment-particular.dto";
import useFinance from "containers/private/finance/useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import MultiselectField from "components/particles/forms/multiselect-field";

interface AddParticularToChallanProps {
  setOpen: Function;
}

const AddParticularToSlot: FC<AddParticularToChallanProps> = ({ setOpen }) => {
  const {
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown,
    ref: dropdownRef,
  } = useComponentVisible(false);
  const { getQueryParams } = useUtils();
  const { slot_id } = getQueryParams();
  const [voucherParticulars, setVoucherParticulars] = useState<
    VoucherParticularDTO[]
  >([]);

  const [isSectionDropdownOpen, setIsSectionDropdownOpen] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<AccSlotInstallmentParticular>({
    ...new AccSlotInstallmentParticular(),
  });
  const { createAccSlotInstallmentParticular, getVoucherParticulars } =
    useFinance();

  const onSubmit = () => {
    if (!formData.particular_id) {
      warningToaster("Select atleast one voucher particular");
    } else {
      createAccSlotInstallmentParticular(
        {
          ...formData,
          slot_id: slot_id,
          particular_id: formData.particular_id.join(","),
        },
        setOpen
      );
    }
  };

  const onSelectSections = (value: number[]) => {
    formData.particular_id = value;
    setFormData({ ...formData });
  };

  useEffect(() => {
    getVoucherParticulars(setVoucherParticulars);
  }, []);

  return (
    <AddParticularToChallanMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8" expand={showDropdown}>
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span> Add Particulars to Installment slots</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <MultiselectField
              options={voucherParticulars}
              onSelect={onSelectSections}
              value={formData.particular_id}
              searchable
              label="Voucher Particulars"
              helperText="-- select --"
              onOpen={() => setIsSectionDropdownOpen(true)}
              onClose={() => setIsSectionDropdownOpen(false)}
            />

            {!isSectionDropdownOpen ? (
              <div className="action-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn gray"
                    type="reset"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>

                  <button
                    className="lg-rounded-btn"
                    type="button"
                    onClick={onSubmit}
                  >
                    Save & Close
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ marginBottom: "120px" }}></div>
            )}
          </form>
        </ContentWrapper>
      </Container>
    </AddParticularToChallanMain>
  );
};

export default AddParticularToSlot;
