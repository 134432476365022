import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  AddProgramTableSvg,
  DarkEyeSvg,
  DownloadChallanSvg,
  ExcelSvg,
  GreenDownLodadSvg,
  MannageRoomSvg,
  PdfSvg,
  StopActionSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  GradeBooksListingMain,
  GradeBooksListingSection,
  GradeBooksListingTop,
  Filters,
  FilterSection,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import useTestingServices from "../../testing-services/useHooks";
import {
  confirmationPopup,
  warningToaster,
} from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import useAdmissions from "containers/private/admissions/useHooks";
import useAcademics from "../useHooks";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import ManageEnrollmentModal from "./components/manage-enrollmets";
import GradingSchema from "../manage-academic-sessions/components/grading-schema";

const GradeBooksListing: FC = ({}) => {
  const breadcrumbLinks = [
    { title: "Academics  /", path: "" },
    { title: "Academic Sessions /", path: siteRoutes.academicSessionListing },
    {
      title: "Manage Academic Sessions / ",
      path: siteRoutes.academicSessionManagementListing,
    },
    { title: "Grade Book", path: siteRoutes.academicGradeBookListing },
  ];
  const navigate = useNavigate();
  const { deleteTestSchedule } = useTestingServices();
  const { downloadBlankGradeBook } = useAcademics();
  const { uploadGradeBook } = useAcademics();
  const {
    handleSearchChange,
    handleTableSearch,
    getQueryParams,
    isFileOfExcelType,
    createQuery,
  } = useUtils();
  const { hasAccess } = useStore();
  const { course_id } = getQueryParams();
  const { getAdmissionSessions } = useAdmissions();
  const {
    getGradeBookList,
    downloadGradeBookPDF,
    downloadGradesBookCSVFile,
    updateGradeBookSubmittedStatus,
    gradeBookResultVerifyStatus,
  } = useAcademics();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openEnrollmentModal, setOpenEnrollmentModal] =
    useState<boolean>(false);
  const [openGradingSchemeModal, setOpenGradingSchemeModal] =
    useState<boolean>(false);
  const [gradeBookTitle, setGradeBookTitle] = useState<string>("");
  const [data, setData] = useState<any>({});

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [sessions, setSessions] = useState<any[]>([]);

  const [filters, setFilters] = useState<any>({
    test_id: "",
    session_id: "",
    test_center_id: "",
    batch: "",
  });
  const {
    gradebook_version,
    submitted_status,
    verified_status,
    approved_status,
    receipt_acknowledged_status,
    submitted_by,
  } = data;
  const columns: string[] = [
    "Grade Book Version",
    "Submitted",
    "Verified",
    "Approved",
    "Acknowledged",
    "Submitted by",
    "Action",
  ];

  const goToGradeBooksDetailsPage = (gradebook_id: number) => {
    const query = createQuery({ verified_status, gradebook_id, course_id });
    navigate(siteRoutes?.academicGradeBookDetailsListing + query);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleUploadFile = (event: any) => {
    const {
      files: [file],
    } = event.target;
    event.target.value = "";
    if (isFileOfExcelType(file)) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("course_id", course_id);
      uploadGradeBook(formData);
    } else {
      warningToaster("File must be of excel type");
    }
  };

  const updateGradeBookSubmittionStatus = (
    gradebook_id: number,
    status: number
  ) => {
    updateGradeBookSubmittedStatus(
      gradebook_id,
      status,
      setData,
      course_id,
      setGradeBookTitle
    );
  };

  const updateGradeBookResultVerifyStatus = (
    gradebook_id: number,
    status: number
  ) => {
    gradeBookResultVerifyStatus(
      gradebook_id,
      status,
      setData,
      course_id,
      setGradeBookTitle
    );
  };

  const downloadGradeBookPDFFile = () => {
    downloadGradeBookPDF(course_id);
  };
  const downloadGradesBookCSV = () => {
    downloadGradesBookCSVFile(course_id);
  };

  const handleOpenModal = (toOpen: string) => {
    if (toOpen === "enrollment") {
      setOpenEnrollmentModal(true);
    }
  };

  const handleDownloadBlankGradebook = async () => {
    const result = await confirmationPopup(
      warningMessages.fileDownloadConfirmation
    );
    if (result.isConfirmed) {
      downloadBlankGradeBook(course_id);
    }
  };

  const handleDropdownClick = async (event: React.MouseEvent) => {
    await event.stopPropagation();
  };

  const handleMenuIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!course_id) {
      navigate(siteRoutes?.academicSessionManagementListing);
      return;
    }
    getGradeBookList(setData, course_id, setGradeBookTitle);
    getAdmissionSessions(setSessions);
  }, []);

  return (
    <GradeBooksListingMain>
      <GradeBooksListingTop>
        <div className="left">
          <span className="page-heading">Grade Book’s</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        {(Object.keys(data).length === 0 ||
          (Object.keys(data).length > 0 && verified_status === 0)) && (
          <div className="right">
            <div className="create-org-btn">
              <button
                className="lg-rounded-btn"
                onClick={handleDownloadBlankGradebook}
              >
                Blank Grade Book
              </button>
            </div>
            <div className="create-org-btn upload">
              <label htmlFor="file" className="lg-rounded-btn black cp">
                Upload Grade Book
              </label>
              <input
                type="file"
                className="d-none"
                id="file"
                onChange={handleUploadFile}
              />
            </div>
          </div>
        )}
      </GradeBooksListingTop>

      <FilterSection className="content-radius-shadow">
        <Filters>
          <div className="filter-fields">
            <div className="input-field">
              <label>Session</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    name="session"
                    value={filters.session}
                    onChange={handleFilterChange}
                  >
                    <option value="">-- Select --</option>
                    {sessions?.map((session, i) => {
                      return (
                        <option key={i} value={session?.id}>
                          {session?.type ?? ""} {session?.year ?? ""}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={() => setOpenGradingSchemeModal(true)}
                >
                  View Course Grading Scheme
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={() => handleOpenModal("enrollment")}
                >
                  Manage Enrollments
                </button>
              </div>
            </div>
          </div>
          <div className="grade-book-note">
            Note: You can update Course Total Marks OR Enrollment Statuses only
            before submitting Grade Book. If you want to edit mentioned things
            after submission, then you have to un submit Grade Book if its not
            verified and then resubmit it after updating mentioned things.
          </div>
          <div className="grade-book-note">
            Once Grade Book is Submitted and Verified, nothing can be reverted
            or updated.
          </div>
        </Filters>
      </FilterSection>
      <GradeBooksListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          {gradeBookTitle && (
            <div className="grade-book-title">
              Grade Book Title:{" "}
              <span dangerouslySetInnerHTML={{ __html: gradeBookTitle }}></span>
            </div>
          )}
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).length !== 0 ? (
                <tr>
                  <td>
                    <span className="status-tile">
                      {gradebook_version ?? "--"}
                    </span>
                  </td>

                  <td>
                    <span
                      className={`status-tile ${
                        submitted_status === 1 ? "green" : "red"
                      }`}
                    >
                      {submitted_status === 1 ? "Yes" : "No"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`status-tile ${
                        verified_status === 1 ? "green" : "red"
                      }`}
                    >
                      {verified_status === 1 ? "Yes" : "No"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`status-tile ${
                        approved_status === 0 ? "red" : "green"
                      } `}
                    >
                      {approved_status === 1 ? "Yes" : "No"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`status-tile ${
                        receipt_acknowledged_status === 1 ? "green" : "red"
                      } `}
                    >
                      {receipt_acknowledged_status === 1 ? "Yes" : "No"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`status-tile ${
                        submitted_by === 0 ? "red" : "green"
                      } `}
                    >
                      {submitted_by ?? "--"}
                    </span>
                  </td>

                  <td>
                    <div className="action-menu">
                      <div
                        className="menu-icon cp"
                        onClick={handleMenuIconClick}
                      >
                        <TabPrimaryActionMenu className="icon" />
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                <td colSpan={12}>
                  <DataNotFound show={!isLoading} />
                </td>
              )}
            </tbody>
          </table>
        </div>

        {isDropdownOpen && (
          <Fragment>
            {Object.keys(data).length != 0 && (
              <div className="menu-dropdown" onClick={handleDropdownClick}>
                <div className="content-radius-shadow" ref={dropdownRef}>
                  {hasAccess(sitePermissions.gradeBookViewChange) && (
                    <div
                      className="action-button"
                      onClick={() => goToGradeBooksDetailsPage(data?.id)}
                    >
                      <div className="action-icon">
                        <DarkEyeSvg className="icon" />
                      </div>
                      <span className="title">View Changes</span>
                    </div>
                  )}
                  {hasAccess(sitePermissions.gradeBookViewDetails) && (
                    <div className="action-button">
                      <div className="action-icon">
                        <MannageRoomSvg className="icon" />
                      </div>
                      <span className="title">View Details</span>
                    </div>
                  )}
                  {hasAccess(sitePermissions.gradeBookDownloadCSV) && (
                    <div
                      className="action-button"
                      onClick={downloadGradesBookCSV}
                    >
                      <div className="action-icon">
                        <DownloadChallanSvg className="icon" />
                      </div>
                      <span className="title">Download CSV</span>
                    </div>
                  )}
                  {hasAccess(sitePermissions.gradeBookDownloadAwardList) && (
                    <div
                      className="action-button"
                      onClick={downloadGradeBookPDFFile}
                    >
                      <div className="action-icon">
                        <GreenDownLodadSvg className="icon" />
                      </div>
                      <span className="title">Download Award List</span>
                    </div>
                  )}
                  {submitted_status == 0 ? (
                    <Fragment>
                      {hasAccess(sitePermissions.submitGradeBookResult) && (
                        <div
                          className="action-button"
                          onClick={() =>
                            updateGradeBookSubmittionStatus(data?.id, 1)
                          }
                        >
                          <div className="action-icon">
                            <AddProgramTableSvg className="icon" />
                          </div>
                          <span className="title">Submit Result</span>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {verified_status === 0 && (
                        <Fragment>
                          {hasAccess(
                            sitePermissions.unSubmitGradeBookResult
                          ) && (
                            <div
                              className="action-button"
                              onClick={() =>
                                updateGradeBookSubmittionStatus(data?.id, 0)
                              }
                            >
                              <div className="action-icon">
                                <StopActionSvg className="icon" />
                              </div>
                              <span className="title">Un Submit Result</span>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                  {verified_status == 1 ? (
                    <Fragment>
                      {hasAccess(sitePermissions.UnVerifyGradeBookResult) && (
                        <div
                          className="action-button"
                          onClick={() =>
                            updateGradeBookResultVerifyStatus(data?.id, 0)
                          }
                        >
                          <div className="action-icon">
                            <StopActionSvg className="icon" />
                          </div>
                          <span className="title">Un Verify Result</span>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {hasAccess(sitePermissions.verifyGradeBookResult) && (
                        <div
                          className="action-button"
                          onClick={() =>
                            updateGradeBookResultVerifyStatus(data?.id, 1)
                          }
                        >
                          <div className="action-icon">
                            <GreenDownLodadSvg className="icon" />
                          </div>
                          <span className="title">Verify Result</span>
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </GradeBooksListingSection>
      <Fragment>
        {openGradingSchemeModal && (
          <GradingSchema
            setOpen={setOpenGradingSchemeModal}
            course_id={course_id}
          />
        )}
        {openEnrollmentModal && (
          <ManageEnrollmentModal
            setOpen={setOpenEnrollmentModal}
            course_id={course_id}
            actionToDo="delete"
          />
        )}
      </Fragment>
    </GradeBooksListingMain>
  );
};

export default GradeBooksListing;
