import { FC, Fragment, useEffect, useState } from "react";
import {
  MBLFilters,
  MBLFilterSection,
  MBLFilterHeader,
  MBLListingSection,
  MBLMain,
  MBLTopSection,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AddGrayTableSvg,
  BlackSettingTableSvg,
  BlackTableViewSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  GreenIncognitoTableSvg,
  MetallicGrayUploadTableSvg,
  PdfSvg,
  SearchFieldSvg,
  YellowDownloadTableSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import UploadBankScroll from "./components/upload-bank-scroll";
import useUtils from "hooks/useUtils";
import useFinance from "../../useHooks";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import { MasterBookDTO } from "utils/helpers/models/finance/master-book.dto";
import CreateInstallment from "./components/create-installment";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import ViewMasterBookParticulars from "./components/view-particulars";
import UploadUpdateMasterBook from "./components/update-upload-master-book";

interface MasterBookListingProps {}

const MasterBookListing: FC<MasterBookListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Finance /", path: "" },
    { title: "Master Book List", path: siteRoutes.masterBookListing },
  ];
  const { createQuery } = useUtils();
  const { hasAccess } = useStore();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const columns: string[] = [
    "",
    "CNIC",
    "Name",
    "Father Name",
    "Session",
    "Installment #",
    "App #",
    "Challan #",
    "Amount",
    "Status",
    "Paid Bank",
    "Due Date",
    "Paid on",
    "Remarks",
    "Action",
  ];

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [transIdToShowParticular, setTransIdToShowParticular] = useState(0);
  const [showParticularDialog, setShowParticularDialog] =
    useState<boolean>(false);
  const [masterIdToCreateInstallment, setMasterIdToCreateInstallment] =
    useState<number>(0);
  const [masterIdToUpdateMasterBook, setMasterIdToUpdateMasterBook] =
    useState<any>({});
  const [dataForUpload, setDataForUpload] = useState<any>({});
  const [openInstallmentDialog, setOpenInstallmentDialog] =
    useState<boolean>(false);
  const [openUpdateUploadModal, setOpenUpdateUploadModal] =
    useState<boolean>(false);
  const [uploadBankScrollDialog, setOpenUploadBankScrollDialog] =
    useState<boolean>(false);
  const [data, setData] = useState<MasterBookDTO[]>([]);
  const {
    getMasterBooks,
    downloadMasterBookVoucher,
    deleteMasterBookInstallment,
  } = useFinance();
  const { handleSearchChange, handleTableSearch, getYears } = useUtils();

  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const [filters, setFilters] = useState<any>({
    voucher_issue_date_from: "",
    voucher_issue_date_to: "",
    cnic: "",
    app: "",
    challan_no: "",
    year: "",
    semester: "",
    status: "",
    challan_file: "",
    remarks: "",
    is_installment: "",
    verification_bank: "",
    verification_method: "",
    verified_date_from: "",
    verified_date_to: "",
    total_amount: "",
    voucher_type_code: "",
    particular_remarks: "",
    application: "",
    particular_head: "",
  });

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllMasterBooks(pagination.page, search, pagination.per_page);
  };
  const onApplyFilters = () => {
    getAllMasterBooks(pagination.page, search, pagination.per_page);
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleOpenInstallmentDialog = (master_id: number) => {
    setMasterIdToCreateInstallment(master_id);
    setOpenInstallmentDialog(true);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };

  const goToShowTransactionDetail = (transaction: any) => {
    const { id: transaction_id, app_no, challan_no } = transaction;

    const query = createQuery({ transaction_id, app_no, challan_no });
    navigate(siteRoutes.showMasterBookTransactionDetail + query);
  };
  const goToTransactionDetails = (transaction: any) => {
    const {
      id: transaction_id,
      app_no,
      account_transactions: { challan_no },
    } = transaction;

    const query = createQuery({ transaction_id, app_no, challan_no });
    navigate(siteRoutes.masterBookTransactionDetail + query);
  };

  const handleUploadMasterBook = (book: any) => {
    const { id, trans_id, status } = book;
    setOpenUpdateUploadModal(true);
    setDataForUpload(book);
  };

  const openUploadBankScrollDialog = () => {
    setOpenUploadBankScrollDialog(true);
  };

  const handleDownloadVoucher = async (challan_no: string) => {
    const result = await confirmationPopup(
      warningMessages.fileDownloadConfirmation
    );
    if (result.isConfirmed) {
      downloadMasterBookVoucher(challan_no);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllMasterBooks(page + 1, search, per_page);
  };
  const getAllMasterBooks = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) filtersToSend[key] = filters[key];
    }
    const queryParams = {
      per_page,
      page,
      search,
      ...filtersToSend,
    };
    getMasterBooks(setData, queryParams, setPagination);
  };

  const handleDeleteInstallment = async (master_id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const filtersToSend: any = {};
      for (let key in filters) {
        if (filters[key]) filtersToSend[key] = filters[key];
      }
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
        ...filtersToSend,
      };
      deleteMasterBookInstallment(
        master_id,
        setData,
        queryParams,
        setPagination
      );
    }
  };
  const handleDeleteMasterBookById = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const filtersToSend: any = {};
      for (let key in filters) {
        if (filters[key]) filtersToSend[key] = filters[key];
      }
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
        ...filtersToSend,
      };
      deleteMasterBookInstallment(id, setData, queryParams, setPagination);
    }
  };

  const handleViewParticulars = (trans_id: number) => {
    setTransIdToShowParticular(trans_id);
    setShowParticularDialog(true);
  };

  useEffect(() => {
    if (!openInstallmentDialog || !openUpdateUploadModal) {
      getAllMasterBooks(pagination.page, search, pagination.per_page);
    }
  }, [!openInstallmentDialog, !openUpdateUploadModal]);

  return (
    <MBLMain>
      <MBLTopSection>
        <div className="left">
          <span className="page-heading">Master Book List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.masterBookListing) && (
            <div className="create-fine-slot-btn">
              <button
                className="lg-rounded-btn"
                onClick={openUploadBankScrollDialog}
              >
                + Upload Bulk Verification Data
              </button>
            </div>
          )}
        </div>
      </MBLTopSection>
      <MBLFilterSection className="content-radius-shadow">
        <MBLFilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </MBLFilterHeader>
        {showFilterDropdown && (
          <MBLFilters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Voucher Issue Date (From)</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="voucher_issue_date_from"
                      value={filters.voucher_issue_date_from}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Voucher Issue Date (To)</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="voucher_issue_date_to"
                      value={filters.voucher_issue_date_to}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="">App</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="app"
                      value={filters.app}
                      onChange={handleFilterChange}
                      placeholder="App name"
                    />
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="">Year</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="year"
                      value={filters.year}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select year</option>
                      {getYears(2000).map((year, index) => {
                        return (
                          <option value={year} key={index}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Semester</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="semester"
                      value={filters.semester}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- select --</option>
                      {Array.from({ length: 10 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="">Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="status"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      <option value="1">Active</option>
                      <option value="0">Not Active</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="">Remarks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="remarks"
                      value={filters.remarks}
                      onChange={handleFilterChange}
                      placeholder="Remarks"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Is Installment?</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="is_installment"
                      value={filters.is_installment}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Verification Bank?</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="verification_bank"
                      value={filters.verification_bank}
                      onChange={handleFilterChange}
                      placeholder="Bank Name"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Verification Method?</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="verification_method"
                      value={filters.verification_method}
                      onChange={handleFilterChange}
                      placeholder="Verification Method"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Verified Date (From)?</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="verified_date_from"
                      value={filters.verified_date_from}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Verified Date (To)?</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      name="verified_date_to"
                      value={filters.verified_date_to}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Total Amount</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      name="total_amount"
                      value={filters.total_amount}
                      onChange={handleFilterChange}
                      placeholder="Total Amount"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="input-field">
                <label htmlFor="">Particular Head</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="particular_head"
                      value={filters.particular_head}
                      onChange={handleFilterChange}
                      disabled
                    >
                      <option value="">-- Select --</option>
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <option key={index} value={index + 1}>
                            particular {index + 1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="input-field">
                <label htmlFor="">Voucher Type Code</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="voucher_type_code"
                      value={filters.voucher_type_code}
                      onChange={handleFilterChange}
                      placeholder="Voucher Type Code"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Particular Remarks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="particular_remarks"
                      value={filters.particular_remarks}
                      onChange={handleFilterChange}
                      placeholder="Particular Remarks"
                    />
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="">CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      name="cnic"
                      value={filters.cnic}
                      onChange={handleFilterChange}
                      placeholder="cnic"
                    />
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label htmlFor="">Challan #</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="challan_no"
                      value={filters.challan_no}
                      onChange={handleFilterChange}
                      placeholder="challan no"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </MBLFilters>
        )}
      </MBLFilterSection>

      <MBLListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllMasterBooks
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllMasterBooks)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 8 ? `extra-head extra-head-${index - 6}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((book, index) => {
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${
                        (book as any).isExpanded && "opened"
                      }`}
                    >
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{(book as any).isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{book?.cnic ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">{book?.name ?? "-"}</div>{" "}
                      </td>
                      <td>
                        <div className="mw-150">{book?.father_name ?? "-"}</div>
                      </td>
                      <td>-</td>
                      <td>{book?.installment_no ?? "-"}</td>
                      <td>
                        <div className="m2-150">{book?.app_no ?? "-"}</div>{" "}
                      </td>
                      <td>{book?.challan_no ?? "-"}</td>
                      <td>{book?.total_amount ?? "-"}</td>
                      <td className="extra-col extra-col-1">--</td>
                      <td className="extra-col extra-col-2">
                        {book?.remarks === "" ? "-" : book?.remarks}
                      </td>
                      <td className="extra-col extra-col-5">-</td>

                      <td className="extra-col extra-col-6">
                        <div className="table-action-icons">
                          {hasAccess(
                            sitePermissions.showMasterBookTransactionDetail
                          ) ? (
                            <div
                              className="action-icon view"
                              onClick={() => goToShowTransactionDetail(book)}
                            >
                              <BlackTableViewSvg />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="action-icon">
                            <AddGrayTableSvg />
                          </div>
                          <div
                            className="action-icon"
                            onClick={() => goToTransactionDetails(book)}
                          >
                            <BlackSettingTableSvg />
                          </div>
                          <div className="action-icon">
                            <YellowDownloadTableSvg />
                          </div>
                          <div className="action-icon">
                            <MetallicGrayUploadTableSvg />
                          </div>
                          <div className="action-icon">
                            <GreenIncognitoTableSvg />
                          </div>
                          <div
                            className="action-icon"
                            onClick={() => handleDeleteInstallment(book?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </td>
                    </tr>
                    {(book as any).isExpanded && (
                      <tr className="expanded-row">
                        <td></td>
                        <td colSpan={7}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <span className="title">Status</span>
                              {book?.status === "Pending" ? (
                                <span className="status-tile yellow">
                                  {book?.status}
                                </span>
                              ) : (
                                <span className="status-tile green">
                                  {book?.status}
                                </span>
                              )}
                              <span className="info"></span>
                            </div>
                            <div className="particular-info particular-info-1">
                              <span className="title">Paid Bank</span>
                              <span className="info">
                                {book?.verification_bank ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Due Date</span>
                              <span className="info">
                                {book?.due_date === "" ? "-" : book?.due_date}
                              </span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Paid On</span>
                              <span className="info">
                                {book?.collection_date === ""
                                  ? "-"
                                  : book?.collection_date}
                              </span>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">Remarks</span>
                              <span className="info">
                                {book?.remarks === "" ? "-" : book?.remarks}
                              </span>
                            </div>
                            <div className="particular-info particular-info-5">
                              <span className="title">Particulars</span>
                              <span className="info">
                                <div
                                  className="table-action-icon"
                                  onClick={() =>
                                    handleViewParticulars(book.trans_id)
                                  }
                                >
                                  <BlackTableViewSvg />
                                </div>
                              </span>
                            </div>

                            <div className="particular-info particular-info-6">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-buttons">
                                  {book?.status !== "Pending" ? (
                                    <Fragment>
                                      <div className="table-action-button">
                                        <button className="green">
                                          Update to Pending
                                        </button>
                                      </div>
                                      <div className="table-action-button">
                                        <button className="green">
                                          Download Paid Voucher
                                        </button>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {!book.is_installment ? (
                                        <div className="table-action-button">
                                          <button
                                            className="green"
                                            onClick={() =>
                                              handleOpenInstallmentDialog(
                                                book.id
                                              )
                                            }
                                          >
                                            Create installements
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="table-action-button">
                                          <button
                                            className="red"
                                            onClick={() =>
                                              handleDeleteInstallment(book.id)
                                            }
                                          >
                                            Remove All Installments
                                          </button>
                                        </div>
                                      )}
                                      <div className="table-action-button">
                                        <button
                                          onClick={() =>
                                            goToShowTransactionDetail(book)
                                          }
                                        >
                                          Manage Details
                                        </button>
                                      </div>
                                      <div className="table-action-button">
                                        <button
                                          onClick={() =>
                                            handleDownloadVoucher(
                                              book.challan_no
                                            )
                                          }
                                        >
                                          Download Voucher
                                        </button>
                                      </div>
                                      <div className="table-action-button">
                                        <button
                                          className="red"
                                          onClick={() =>
                                            handleUploadMasterBook(book)
                                          }
                                        >
                                          Upload/Update
                                        </button>
                                      </div>
                                      {hasAccess(
                                        sitePermissions.masterBookListingDelete
                                      ) && (
                                        <div
                                          className="table-action-icon"
                                          onClick={() =>
                                            handleDeleteMasterBookById(book?.id)
                                          }
                                        >
                                          <DeleteTableSvg />
                                        </div>
                                      )}
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </MBLListingSection>
      <Fragment>
        {openUpdateUploadModal && (
          <UploadUpdateMasterBook
            setOpen={setOpenUpdateUploadModal}
            book={dataForUpload}
          />
        )}
        {uploadBankScrollDialog && (
          <UploadBankScroll setOpen={setOpenUploadBankScrollDialog} />
        )}
        {openInstallmentDialog ? (
          <CreateInstallment
            master_id={masterIdToCreateInstallment}
            setOpen={setOpenInstallmentDialog}
          />
        ) : (
          ""
        )}
        {showParticularDialog ? (
          <ViewMasterBookParticulars
            setOpen={setShowParticularDialog}
            trans_id={transIdToShowParticular}
          />
        ) : (
          ""
        )}
      </Fragment>
    </MBLMain>
  );
};

export default MasterBookListing;
