import { FC, useEffect, useRef, useState } from "react";
import { Main, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { InstallmentDTO } from "utils/helpers/models/finance/installment.dto";
import useFinance from "containers/private/finance/useHooks";
import { UploadUpdateMasterBookDTO } from "utils/helpers/models/finance/upload-update-master-book.dto";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { errorToaster } from "utils/helpers/common/alert-service";

interface Props {
  setOpen: Function;
  book: any;
}

const UploadUpdateMasterBook: FC<Props> = ({ setOpen, book }) => {
  const { status, trans_id, id, remarks, collection_date, upload_file_path } =
    book;

  const FileUploadRef = useRef(null);
  const { getQueryParams } = useUtils();
  const { updateUploadMasterBook } = useFinance();
  const [formData, setFormData] = useState<UploadUpdateMasterBookDTO>(
    new UploadUpdateMasterBookDTO()
  );
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<UploadUpdateMasterBookDTO>({
    defaultValues: formData,
    mode: "onChange",
  });

  const handleFileUpload = (event: any) => {
    const {
      files: [file],
    } = event.target;
    event.target.value = "";
    setValue("challan_file", file);
    formData.challan_file = file;
    setFormData({ ...formData });
  };
  const onSubmit = () => {
    const form_data = new FormData();
    for (let key in formData) {
      form_data.append(key, formData[key]);
    }
    if (id && trans_id) {
      updateUploadMasterBook(form_data, setOpen);
    } else {
      errorToaster("master_id or transaction id required");
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    trigger(name);
  };

  useEffect(() => {
    setValue("master_id", id);
    setValue("transaction_id", trans_id);
    setValue("status", status);
    setValue("remarks", remarks);
    setValue("collection_date", collection_date);
    setValue("challan_file", upload_file_path);
  }, []);

  return (
    <Main>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Update/Upload Master Book</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label htmlFor="">Remarks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("remarks", { required: true })}
                      value={formData.remarks}
                      onChange={handleChange}
                      placeholder="Type Title Here"
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors.remarks} />
              </div>

              <div className="input-field">
                <label>Attachment</label>
                <div className="upload-container">
                  <div className="upload-section">
                    <label htmlFor="file-upload" className="upload-label">
                      Upload Challan
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      {...register("challan_file", { required: false })}
                      onChange={handleFileUpload}
                      className="file-input"
                      ref={FileUploadRef}
                    />
                  </div>
                  <div
                    className="file-details-section"
                    onClick={() => FileUploadRef.current?.click()}
                    id="file-details"
                  >
                    <p className="file-placeholder">
                      {formData.challan_file?.name}
                    </p>
                  </div>
                </div>
                <FormErrorMessage error={errors.challan_file} />
              </div>

              <div className="input-field">
                <label>Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("status", { required: true })}
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      <option>Paid</option>
                      <option>Pending</option>
                    </select>
                  </div>
                </div>
                <FormErrorMessage error={errors.status} />
              </div>

              <div className="input-field">
                <label htmlFor="">Collection Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      {...register("collection_date", { required: true })}
                      value={formData.collection_date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors.collection_date} />
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </Main>
  );
};

export default UploadUpdateMasterBook;
