import { FC, Fragment, useEffect, useState } from "react";
import {
  AcademicFinancialVouchersSection,
  AcademicFinancialVouchersMain,
  AcademicFinancialVouchersTop,
  FilterSection,
  FilterHeader,
  Filters,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import useUtils from "hooks/useUtils";
import useAcademics from "../useHooks";
import useAdmissions from "containers/private/admissions/useHooks";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import useOrganization from "containers/private/organization/useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import { errorToaster } from "utils/helpers/common/alert-service";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";

const SemesterFeeVoucherListing: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics  / ", path: "" },
    {
      title: "Semester Fee Vouchers",
      path: siteRoutes.semesterFeeVoucherListing,
    },
  ];
  const columns: string[] = ["Batch ID"];

  const { getPrograms } = useOrganization();
  const { getAllFeeVouchersList, getAcademicSessions } = useAcademics();

  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const [data, setData] = useState<any>({});

  const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>(
    []
  );
  const [search, setSearch] = useState<string>("");
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [filters, setFilters] = useState<any>({
    session_id: "",
    fee_due_date: "",
    program_id: "",
  });

  const onApplyFilters = () => {
    getAllFeeVouchersListing();
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    filters[name] = value;
    setFilters({ ...filters });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
  };

  const getAllFeeVouchersListing = () => {
    const queryParams = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    const { session_id, fee_due_date, program_id } = filters;
    const validations = [
      { condition: !session_id, message: "Session required" },
      { condition: !fee_due_date, message: "Fee due date required" },
      { condition: !program_id, message: "Program required" },
    ];
    for (const validation of validations) {
      if (validation.condition) {
        errorToaster(validation.message);
        return;
      }
    }
    getAllFeeVouchersList(queryParams, resetFilters);
  };

  useEffect(() => {
    getPrograms(setPrograms);
    getAcademicSessions(setAcademicSession);
  }, []);

  return (
    <AcademicFinancialVouchersMain>
      <AcademicFinancialVouchersTop>
        <div className="left">
          <span className="page-heading">Semester Fee Voucher</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </AcademicFinancialVouchersTop>

      <FilterSection className="content-radius-shadow">
        {/* <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader> */}
        {/* {openFilterDropdown && ( */}
        <Filters>
          <div className="filter-fields">
            <div className="input-field">
              <label>Session</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    name="session_id"
                    value={filters.session_id}
                    onChange={handleFilterChange}
                  >
                    <option value="">-- Select --</option>
                    {academicSession?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.type ?? ""} {item?.year ?? ""}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="input-field">
              <label>Fee Due Date</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="date"
                    name="fee_due_date"
                    onChange={handleFilterChange}
                    value={filters.fee_due_date}
                  />
                </div>
              </div>
            </div>
            <div className="input-field">
              <label htmlFor="">Program</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    name="program_id"
                    value={filters.program_id}
                    onChange={handleFilterChange}
                  >
                    <option value="">-- Select --</option>
                    {programs.map((program, index) => {
                      return (
                        <option value={program?.id} key={index}>
                          {program?.title ?? "-"}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-buttons">
            <div className="buttons">
              <button className="lg-rounded-btn gray" onClick={resetFilters}>
                Reset
              </button>
              <button className="lg-rounded-btn" onClick={onApplyFilters}>
                Submit
              </button>
            </div>
          </div>
        </Filters>
        {/* )} */}
      </FilterSection>

      {/* <AcademicFinancialVouchersSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllFeeVouchersListing
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllFeeVouchersListing
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).length != 0 ? (
                <tr>
                  <td>{data?.batch_id ?? "-"}</td>
                </tr>
              ) : (
                <Fragment>
                  <DataNotFound show={true} />
                </Fragment>
              )}
            </tbody>
          </table>
        </div>
      </AcademicFinancialVouchersSection> */}
    </AcademicFinancialVouchersMain>
  );
};

export default SemesterFeeVoucherListing;
