import { FC, Fragment, useState, useEffect } from "react";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  TestCenterListingMain,
  TestCenterListingSection,
  TestCenterListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useTestingServices from "../useHooks";
import useUtils from "hooks/useUtils";
import useAdmission from "../../admissions/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { StudentsMarksSheetsDTO } from "utils/helpers/models/testing-service/students-marks-sheet-dto";

interface Props {}

const PublishTestResults: FC<Props> = ({}) => {
  const breadcrumbLinks = [
    { title: "Testing Services /", path: "" },
    {
      title: "Students Mark Sheet",
      path: siteRoutes.testingServicespublishresult,
    },
  ];

  const columns: string[] = [
    "Apply date",
    "Candidate  Name",
    "Father Name",
    "CNIC",
    "Gender",
    "PHD",
    "Total Marks",
    "Obtained Marks",
    "Is Publish",
  ];

  const gender: any[] = [
    { title: "Male" },
    { title: "Female" },
    { title: "Others" },
  ];

  const challanStatuses: any[] = [
    { id: 1, title: "Verified" },
    { id: 2, title: "Pending" },
    { id: 3, title: "Rejected" },
  ];
  const obtMarksFilter: any[] = [
    { title: ">=", value: "gteq" },
    { title: "<=", value: "lteq" },
  ];
  const [filters, setFilters] = useState<any>({
    session_id: "",
    batch: "",
    test_id: "",
    gender_name: "",
    verify_status: "",
    ob_marks: "",
    operator: "",
  });

  const {
    getBatch,
    getPublishResult,
    publishFilteredResults,
    updateMarkdSheetResultForIspublish,
  } = useTestingServices();

  const [data, setData] = useState<StudentsMarksSheetsDTO[]>([]);
  const [sessions, setSessions] = useState<any[]>([]);
  const [batch, setBatch] = useState<any[]>([]);
  const [tests, setTests] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const { getDateFromDateTime } = useUtils();

  const { getAdmissionSessions, getAdmissionEntryTests } = useAdmission();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllPublishResults(page + 1, search, per_page, filters);
  };

  const onSubmitFilters = () => {
    getAllPublishResults(1, search, pagination.per_page, filters);
  };

  const onClearFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllPublishResults(1, search, pagination.per_page, filters);
  };

  const handlePuslishResult = async () => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      setData([...data.map((i) => ({ ...i, is_publish: 1 }))]);
      publishFilteredResults({ ...filters, publish_res: 1 });
    }
  };

  const handleUpdateResult = async (
    result: StudentsMarksSheetsDTO,
    index: number
  ) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      data[index] = result;
      setData([...data]);
    }

    const dataToSend = {
      challan_no: data[index]?.challan_no,
      is_publish: data[index]?.is_publish,
    };
    console.log(dataToSend);
    updateMarkdSheetResultForIspublish(dataToSend);
  };

  const getAllPublishResults = (
    page: number,
    search: string,
    per_page: number,
    filters: any = {}
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
      ...filters,
    };
    getPublishResult(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllPublishResults(pagination.page, search, pagination.per_page, filters);
    getAdmissionSessions(setSessions);
    getBatch(setBatch);
    getAdmissionEntryTests(setTests);
  }, []);

  return (
    <TestCenterListingMain>
      <TestCenterListingTop>
        <div className="left">
          <span className="page-heading">Students Mark Sheet</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </TestCenterListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.session_id}
                      onChange={handleFilterChange}
                      name="session_id"
                    >
                      <option value="">Select Session</option>
                      {sessions.map((session, index) => {
                        return (
                          <option value={session.id} key={index}>
                            {session.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Test Batch</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.batch}
                      onChange={handleFilterChange}
                      name="batch"
                    >
                      <option value="">Select Test Batch</option>
                      {batch.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Test Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.test_id}
                      onChange={handleFilterChange}
                      name="test_id"
                    >
                      <option value="">Select name</option>
                      {tests.map((test, index) => {
                        return (
                          <option value={test.id} key={index}>
                            {test.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Gender</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.gender_name}
                      onChange={handleFilterChange}
                      name="gender_name"
                    >
                      <option value="">Select Gender</option>
                      {gender.map((item, index) => {
                        return (
                          <option value={item.title} key={index}>
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Challan Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.verify_status}
                      onChange={handleFilterChange}
                      name="verify_status"
                    >
                      <option value="">Select Status</option>
                      {challanStatuses.map((status, index) => (
                        <option key={index} value={status.id}>
                          {status.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Obtained Marks Filter</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.operator}
                      onChange={handleFilterChange}
                      name="operator"
                    >
                      <option value="">None</option>
                      {obtMarksFilter.map((filter, index) => (
                        <option key={index} value={filter.id}>
                          {filter.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Obtained marks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="ob_marks"
                      value={filters.ob_marks}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="apply-filter-btn">
                <button
                  className="lg-rounded-btn gray"
                  onClick={onClearFilters}
                >
                  Reset
                </button>
              </div>
              <div className="buttons">
                <button className="lg-rounded-btn" onClick={onSubmitFilters}>
                  Apply Filter
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={handlePuslishResult}
                >
                  Publish Result for filter record
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <TestCenterListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              // onKeyUp={handleSearch}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div className="mw-150">
                          {getDateFromDateTime(item?.apply_date)}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.full_name ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.father_name ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.cnic ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.gender_name ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {" "}
                          {item?.is_phd === 1 ? item?.phd_program : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.total_marks ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.ob_marks ?? "-"}</div>
                      </td>
                      <td>
                        <div className="switch-field">
                          <label className="switch-field">
                            <input
                              type="checkbox"
                              checked={item?.is_publish === 1}
                              readOnly
                              onChange={({ target: { checked } }) =>
                                handleUpdateResult(
                                  { ...item, is_publish: checked ? 1 : 0 },
                                  index
                                )
                              }
                            />
                            <span className="slider"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </TestCenterListingSection>
    </TestCenterListingMain>
  );
};

export default PublishTestResults;
