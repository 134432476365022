import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreateAcademicExamSection,
  CreateAcademicExamMain,
  CreateAcademicExamTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import useAcademics from "../../useHooks";
import { ExamDTO } from "utils/helpers/models/academics/exams.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import useOrganization from "containers/private/organization/useHooks";
import { ExamTypeDTO } from "utils/helpers/models/academics/exam-types.dto";
import isSameMinute from "date-fns/fp/isSameMinute";
import SingleSelectField from "components/particles/forms/single-select-field";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import { CourseTypeDTO } from "utils/helpers/models/academics/course-type.dto";

export const CreateAcademicExam: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Exams /", path: siteRoutes.academicExamTypeListing },
    { title: "Add Exam", path: siteRoutes.createAcademicExam },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ExamDTO>();
  const {
    createExams,
    updateExamsByID,
    getExamsByID,
    getAcademicSessions,
    getExamType,
    getSessionAllocatedCourses,
    getTemplateCourses,
    getCourseTypes,
  } = useAcademics();

  const { getSpecificOrgStructures } = useOrganization();

  const [academicSessions, setAcademicSessions] = useState<
    AcademicSessionDTO[]
  >([]);

  const [templateCourses, setTemplateCourses] = useState<TemplateCourseDTO[]>(
    []
  );
  const [courses, setCourses] = useState<CourseTypeDTO[]>([]);
  const [examType, setExamType] = useState<ExamTypeDTO[]>([]);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<ExamDTO>(new ExamDTO());

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };
  const onSelectCustom = (value: number, name: string) => {
    handleChange({ target: { name, value } });
  };
  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new ExamDTO()[key as keyof ExamDTO];
      setValue(key as keyof ExamDTO, (formData as any)[key]);
    }

    setFormData({ ...formData });
  };

  const onSubmit = (data: ExamDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateExamsByID(params?.id, formData);
    } else {
      createExams(formData, addMore, resetForm);
    }
  };
  useEffect(() => {
    if (params?.id) {
      getExamsByID(params?.id, formData, setValue, setFormData);
    }
    // getTemplateCourses(setTemplateCourses);
    getCourseTypes(setCourses);
    getExamType(setExamType);
    getAcademicSessions(setAcademicSessions);
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
  }, []);

  return (
    <CreateAcademicExamMain>
      <CreateAcademicExamTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Exam
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateAcademicExamTop>

      <CreateAcademicExamSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Start Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("start_date", { required: true })}
                  value={formData.start_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.start_date} />
            </div>
          </div>
          <div className="input-field">
            <label>End Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("end_date", { required: true })}
                  value={formData.end_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.end_date} />
            </div>
          </div>
          <div className="input-field">
            <label>Exam Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Exam Title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
          <div className="input-field ">
            <label>Academic Session</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("academic_session_id", { required: true })}
                  value={formData.academic_session_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {academicSessions?.map((session: any, index: number) => {
                    return (
                      <option value={session.id} key={index}>
                        {session.type} {session?.year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors?.academic_session_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Select campus</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("campus_id", { required: true })}
                  value={formData.campus_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {campuses.map((campus, index) => {
                    return (
                      <option value={campus.id} key={index}>
                        {campus.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors?.campus_id} />
            </div>
          </div>

          <SingleSelectField
            options={examType}
            value={formData.exam_type_id}
            onSelect={(program) => onSelectCustom(program, "exam_type_id")}
            label="Select Exam Type"
            helperText="-- Select --"
            searchable={true}
            closeOnSelect={true}
            error={errors.exam_type_id}
          />
          <SingleSelectField
            options={courses}
            value={formData.course_type_id}
            onSelect={(program) => onSelectCustom(program, "course_type_id")}
            label="Select Course"
            helperText="-- Select --"
            searchable={true}
            closeOnSelect={true}
            error={errors.course_type_id}
          />
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="reset"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateAcademicExamSection>
    </CreateAcademicExamMain>
  );
};

export default CreateAcademicExam;
