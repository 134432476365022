import { FC, useEffect, useState } from "react";
import {
  CreateLegalStatusTop,
  CreateLegalStatusSection,
  CreateLegalStatusMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useLegalMatters from "../../useHook";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import {
  LegalStatusesCategoryDTO,
  LegalStatusesDTO,
} from "utils/helpers/models/legal-matters/legal-statuses.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
const CreateLegalStatus: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    { title: "Legal Status Listing / ", path: siteRoutes.legalStatusList },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Status`,
      path: siteRoutes.createLegalStatus,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalStatusesDTO>();
  const {
    createLegalStatuses,
    updateLegalStatuses,
    getLegalStatusesByID,
    getLegalStatusCategory,
  } = useLegalMatters();
  const [legalStatusCat, setLegalStatusCat] = useState<
    LegalStatusesCategoryDTO[]
  >([]);
  const [formData, setFormData] = useState<LegalStatusesDTO>(
    new LegalStatusesDTO()
  );

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalStatusesDTO()[
        key as keyof LegalStatusesDTO
      ];
      setValue(key as keyof LegalStatusesDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: LegalStatusesDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateLegalStatuses(params?.id, data);
    } else {
      createLegalStatuses(data, addMore, resetForm);
    }
  };

  useEffect(() => {
    getLegalStatusCategory(setLegalStatusCat);
    if (params?.id) {
      getLegalStatusesByID(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreateLegalStatusMain>
      <CreateLegalStatusTop>
        <div className="heading">
          <span className="page-heading">Add Legal Status</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateLegalStatusTop>

      <CreateLegalStatusSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData?.title}
                  onChange={handleChange}
                  placeholder="title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
          <div className="input-field">
            <label>Description</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("description", { required: true })}
                  value={formData?.description}
                  onChange={handleChange}
                  placeholder="descryption"
                />
              </div>
              <FormErrorMessage error={errors.description} />
            </div>
          </div>
          <div className="input-field">
            <label>Main Category</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("parent_id", { required: true })}
                  value={formData?.parent_id}
                  onChange={handleChange}
                >
                  <option value="">Select Main Category</option>
                  {legalStatusCat?.map((i, x) => (
                    <option key={x} value={i.id}>
                      {i?.title}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.parent_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Is Active</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_active", { required: true })}
                  value={formData?.is_active}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_active} />
            </div>
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateLegalStatusSection>
    </CreateLegalStatusMain>
  );
};

export default CreateLegalStatus;
