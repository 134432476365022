import CreateLegalAdvisors from "containers/private/legal-matters/legal-advisors/create";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";
import { AdviserRatesDTO } from "utils/helpers/models/legal-matters/adviser-rates.dto";
import { LegalFrameWorksDocumentDTO } from "utils/helpers/models/legal-matters/framework-documents.dto";
import {
  LegalAdviserDTO,
  LegalAdviserTypeDTO,
} from "utils/helpers/models/legal-matters/legal-adviser.dto";
import { LegalCaseNatureDTO } from "utils/helpers/models/legal-matters/legal-case-nature.dto";
import { LegalCasesDTO } from "utils/helpers/models/legal-matters/legal-cases.dto";
import { LegalCourtsDTO } from "utils/helpers/models/legal-matters/legal-courts-dto";
import { LegalDocsTypeDTO } from "utils/helpers/models/legal-matters/legal-frameworks-document-types.dto";
import { LegalOrganizationDTO } from "utils/helpers/models/legal-matters/legal-orgainizations.dto";
import { LegalStatusesDTO } from "utils/helpers/models/legal-matters/legal-statuses.dto";
import { MatterTypeDTO } from "utils/helpers/models/legal-matters/matter-type.dto";

export const LEGAL_MATTERS_API = {
  getLegalFrameworkDocumentsList: (params: LegalFrameWorksDocumentDTO) =>
    getRequest("/admin/legalmatters/legal_documents", params),
  deleteLegalFrameworkDocumentsById: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_documents/${id}`),
  createLegalMatterFrameworkDocument: (body: LegalFrameWorksDocumentDTO) =>
    postRequest("/admin/legalmatters/legal_documents", body),
  updateLegalMatterFrameworkDocument: (
    id: number,
    body: LegalFrameWorksDocumentDTO
  ) => putRequest(`/admin/legalmatters/legal_documents/${id}`, body),
  getLegalMatterFrameworkDocumentById: (id: number) =>
    getRequest(`/admin/legalmatters/legal_documents/${id}`),
  getLegalMatterTypesList: (params: MatterTypeDTO) =>
    getRequest("/admin/legalmatters/legal_matter_types", params),
  getLegalmatterTypeById: (id: number) =>
    getRequest(`/admin/legalmatters/legal_matter_types/${id}`),
  updateLegalmatterTypeById: (id: number, params: MatterTypeDTO) =>
    putRequest(`/admin/legalmatters/legal_matter_types/${id}`, params),
  deleteLegalmatterTypeById: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_matter_types/${id}`),
  createLegalMatterType: (params: MatterTypeDTO) =>
    postRequest(`/admin/legalmatters/legal_matter_types/`, params),
  getLegalDocTypeList: (params: any) =>
    getRequest(`/admin/legalmatters/legal_doc_types`, params),
  deleteLegalDocTypeByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_doc_types/${id}`),
  createLegalMatterFrameworkDocumentType: (params: LegalDocsTypeDTO) =>
    postRequest(`/admin/legalmatters/legal_doc_types`, params),
  updateLegalMatterFrameworkDocumentType: (
    id: number,
    params: LegalDocsTypeDTO
  ) => putRequest(`/admin/legalmatters/legal_doc_types/${id}`, params),
  getLegalMatterFrameworkDocumentTypeByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_doc_types/${id}`),

  createLegalOrganization: (params: LegalOrganizationDTO) =>
    postRequest(`/admin/legalmatters/legal_doc_organizations`, params),
  updateLegalOrganization: (id: number, params: LegalOrganizationDTO) =>
    putRequest(`/admin/legalmatters/legal_doc_organizations/${id}`, params),
  getLegalOrganizationByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_doc_organizations/${id}`),
  getLegalOrganization: (params: any) =>
    getRequest(`/admin/legalmatters/legal_doc_organizations`, params),
  deleteLegalOrganizationByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_doc_organizations/${id}`),

  createLegalStatuses: (params: LegalStatusesDTO) =>
    postRequest(`/admin/legalmatters/legal_case_status`, params),
  updateLegalStatuses: (id: number, params: LegalStatusesDTO) =>
    putRequest(`/admin/legalmatters/legal_case_status/${id}`, params),
  getLegalStatusesByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_case_status/${id}`),
  getLegalStatuses: (params: any) =>
    getRequest(`/admin/legalmatters/legal_case_status`, params),
  deleteLegalStatusesByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_case_status/${id}`),

  createLegalCourts: (params: LegalCourtsDTO) =>
    postRequest(`/admin/legalmatters/legal_courts`, params),
  updateLegalCourts: (id: number, params: LegalCourtsDTO) =>
    putRequest(`/admin/legalmatters/legal_courts/${id}`, params),
  getLegalCourtsByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_courts/${id}`),
  getLegalCourts: (params: any) =>
    getRequest(`/admin/legalmatters/legal_courts`, params),
  deleteLegalCourtsByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_courts/${id}`),

  createLegalAdvisor: (params: LegalAdviserDTO) =>
    postRequest(`/admin/legalmatters/legal_advisors`, params),
  updateLegalAdviserByID: (id: number, params: LegalAdviserDTO) =>
    putRequest(`/admin/legalmatters/legal_advisors/${id}`, params),
  getLegalAdviserByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_advisors/${id}`),
  getLegalAdviser: (params: any) =>
    getRequest(`/admin/legalmatters/legal_advisors`, params),
  deleteLegalAdviserByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_advisors/${id}`),

  createLegalAdvisorType: (params: LegalAdviserTypeDTO) =>
    postRequest(`/admin/legalmatters/legal_advisors_types`, params),
  updateLegalAdviserTypeByID: (id: number, params: LegalAdviserTypeDTO) =>
    putRequest(`/admin/legalmatters/legal_advisors_types/${id}`, params),
  getLegalAdviserTypeByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_advisors_types/${id}`),
  getLegalAdviserType: (params: any) =>
    getRequest(`/admin/legalmatters/legal_advisors_types`, params),
  deleteLegalAdviserTypeByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_advisors_types/${id}`),

  createLegalCase: (params: LegalCasesDTO) =>
    postRequest(`/admin/legalmatters/legal_cases`, params),
  updateLegalCaseByID: (id: number, params: LegalCasesDTO) =>
    putRequest(`/admin/legalmatters/legal_cases/${id}`, params),
  getLegalCaseByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_cases/${id}`),
  getLegalCase: (params: any) =>
    getRequest(`/admin/legalmatters/legal_cases`, params),
  deleteLegalCaseByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_cases/${id}`),

  createLegalCaseNature: (params: LegalCaseNatureDTO) =>
    postRequest(`/admin/legalmatters/legal_case_nature`, params),
  updateLegalCaseNatureByID: (id: number, params: LegalCaseNatureDTO) =>
    putRequest(`/admin/legalmatters/legal_case_nature/${id}`, params),
  getLegalCaseNatureByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_case_nature/${id}`),
  getLegalCaseNature: (params: any) =>
    getRequest(`/admin/legalmatters/legal_case_nature`, params),
  deleteLegalCaseNatureByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_case_nature/${id}`),

  createLegalStatusCategory: (params: LegalCaseNatureDTO) =>
    postRequest(`/admin/legalmatters/legal_status_categories`, params),
  updateLegalStatusCategoryByID: (id: number, params: LegalCaseNatureDTO) =>
    putRequest(`/admin/legalmatters/legal_status_categories/${id}`, params),
  getLegalStatusCategoryById: (id: number) =>
    getRequest(`/admin/legalmatters/legal_status_categories/${id}`),
  getLegalStatusCategory: (params: any) =>
    getRequest(`/admin/legalmatters/legal_status_categories`, params),
  deleteLegalStatusCategoryByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_status_categories/${id}`),

  createAdviserRates: (params: AdviserRatesDTO) =>
    postRequest(`/admin/legalmatters/legal_rates`, params),
  updateAdviserRatesByID: (id: number, params: AdviserRatesDTO) =>
    putRequest(`/admin/legalmatters/legal_rates/${id}`, params),
  getAdviserRatesByID: (id: number) =>
    getRequest(`/admin/legalmatters/legal_rates/${id}`),
  getAdviserRates: (params: any) =>
    getRequest(`/admin/legalmatters/legal_rates`, params),
  deleteAdviserRatesByID: (id: number) =>
    deleteRequest(`/admin/legalmatters/legal_rates/${id}`),
};
