import { FC, Fragment, useEffect, useState } from "react";
import { Main, Container, ContentWrapper } from "./style";
import {
  CloseMediumSvg,
  AddGrayTableSvg,
  DeleteTableSvg,
  DownloadChallanSvg,
} from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import { errorToaster } from "utils/helpers/common/alert-service";
import useAdmissions from "containers/private/admissions/useHooks";
import { InstallamentManagementDTO } from "utils/helpers/models/admissions/installament-management.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useFinance from "containers/private/finance/useHooks";

interface ManageInstallmentsProps {
  setOpen: (open: boolean) => void;
  offer_letter: string;
}

interface challanInterface {
  challan_no: string;
  due_date: string;
  expiry_date: string;
  status: string;
  amount: number;
}

const ManageInstallments: FC<ManageInstallmentsProps> = ({
  setOpen,
  offer_letter,
}) => {
  const challanColumn = [
    "Challan No",
    "Amount",
    "Due Date",
    "Exp Date",
    "Status",
    "Action",
  ];
  const columns = ["Title", "Due Date", "Exp Date", "Amount", "Action"];

  const {
    getFeeVoucherDetails,
    downloadFeeChallan,
    createInstallmentsByAdmin,
  } = useAdmissions();

  const { downloadMasterBookVoucher } = useFinance();

  const [installmentsSlots, setInstallmentSlots] = useState<any[]>([]);
  const [voucherDetails, setVoucheDetails] = useState({
    total_amount: 0,
    discount_amount: 0,
    title: "",
  });
  const [isChallan, setIsChallan] = useState<challanInterface[]>([
    { challan_no: "", due_date: "", expiry_date: "", status: "", amount: null },
  ]);
  const [voucherData, setVoucherData] = useState([]);
  const [formData, setFormData] = useState<InstallamentManagementDTO>(
    new InstallamentManagementDTO()
  );
  const [isCreateInstallments, setIsCreateInstallments] = useState(false);
  const totalFee = voucherDetails?.discount_amount;
  const [installments, setInstallments] = useState([
    {
      id: 1,
      title: "Installment 1",
      due_date: "",
      exp_date: "",
      amount: totalFee,
    },
    { id: 2, title: "Installment 2", due_date: "", exp_date: "", amount: 0 },
  ]);
  const { handleSubmit } = useForm<InstallamentManagementDTO>({
    defaultValues: formData,
  });

  const getRemainingFee = () =>
    totalFee - installments.reduce((sum, inst) => sum + inst.amount, 0);
  const updateInstallments = (index: number, amount: number) => {
    const updatedInstallments = [...installments];
    let remaining = totalFee;
    updatedInstallments[index].amount = amount;
    for (let i = 0; i <= index; i++) {
      remaining -= updatedInstallments[i].amount;
    }
    if (remaining < 0) {
      errorToaster(`Installments cannot exceed the total fee ${totalFee}`);
      return;
    }
    for (let i = index + 1; i < updatedInstallments.length; i++) {
      updatedInstallments[i].amount = 0;
    }
    if (index + 1 < updatedInstallments.length) {
      updatedInstallments[index + 1].amount = remaining;
    }
    setInstallments(updatedInstallments);
  };

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const amount = Number(event.target.value) || 0;
    updateInstallments(index, amount);
  };

  const addInstallment = () => {
    const maxInstallments = installmentsSlots?.length || 0;
    if (installments.length >= maxInstallments) {
      errorToaster(`You can only add ${maxInstallments} installments.`);
      return;
    }

    const remainingFee = getRemainingFee();
    if (remainingFee <= 0) {
      errorToaster("No remaining fee to allocate.");
      return;
    }
    setInstallments([
      ...installments,
      {
        id: installments.length + 1,
        title: `Installment ${installments.length + 1}`,
        due_date: "",
        exp_date: "",
        amount: remainingFee,
      },
    ]);
  };

  const removeInstallment = (index: number) => {
    if (index !== 0 && index !== 1) {
      const updatedInstallments = installments.filter((_, i) => i !== index);
      if (index > 0) {
        updatedInstallments[index - 1].amount += installments[index].amount;
      }
      setInstallments(updatedInstallments);
    }
  };

  const downlaodFeeChallan = (item: string) => {
    downloadFeeChallan(item);
  };
  const downlaodInstallmentFeeChallan = (challan_no: string) => {
    downloadMasterBookVoucher(challan_no);
  };

  const getFeeVocherData = () => {
    getFeeVoucherDetails(
      offer_letter,
      setVoucherData,
      setVoucheDetails,
      setInstallmentSlots,
      setIsChallan
    );
  };

  const onSubmit = () => {
    const isFeeRemaining = getRemainingFee();
    if (isFeeRemaining) {
      errorToaster(`Pls adjust remaining fee ${isFeeRemaining}`);
      return;
    }
    const form_data = new FormData();
    form_data.append("offer_letter", offer_letter);
    form_data.append("installments", JSON.stringify(installments));
    const entries = Array.from(form_data.entries());
    entries.forEach(([key, value]) => {
      if (key === "installments" && typeof value === "string") {
        try {
          const parsedInstallments = JSON.parse(value);
          const data = {
            offer_letter,
            installments: parsedInstallments,
          };
          createInstallmentsByAdmin(
            offer_letter,
            data,
            getFeeVocherData,
            setIsCreateInstallments
          );
        } catch (error) {
          console.error("Failed to parse installments:", error);
        }
      } else {
        console.log("asd", key, value);
      }
    });
  };
  useEffect(() => {
    if (voucherData) {
      setInstallments((prevInstallments) => {
        const updatedInstallments = prevInstallments.map((installment) => {
          const matchingSlot = installmentsSlots.find(
            (slot) => slot.slot_no === installment.id
          );

          if (matchingSlot) {
            return {
              ...installment,
              due_date: matchingSlot.due_date,
              exp_date: matchingSlot.exp_date || installment.exp_date,
              id: matchingSlot.id,
            };
          }
          return installment;
        });
        return updatedInstallments;
      });
    }
  }, [voucherData, installmentsSlots]);

  useEffect(() => {
    if (voucherDetails && totalFee !== 0) {
      setInstallments((prevInstallments) => {
        const updatedInstallments = [...prevInstallments];
        updatedInstallments[0] = {
          ...updatedInstallments[0],
          amount: totalFee,
        };
        return updatedInstallments;
      });
    }
  }, [voucherDetails, totalFee]);

  useEffect(() => {
    getFeeVocherData();
  }, []);

  return (
    <Main>
      <Container>
        <ContentWrapper>
          <div className="header">
            <div className="heading">
              <span className="title">
                {isChallan?.length > 0
                  ? "Dowload Fee Challan"
                  : "Create Installments"}
              </span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <div className="total-fee">
            Total Fee : <span className="amount-span"> {totalFee} </span>
          </div>
          <div className="table-top">
            {isChallan?.length > 0 ? (
              <div className="data-table">
                <table className="bottom-bordered-cells">
                  <thead>
                    <tr>
                      {challanColumn.map((column, index) => (
                        <Fragment key={index}>
                          <th>{column}</th>
                        </Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {isChallan.map((challan, index) => (
                      <tr key={index}>
                        <td>{challan?.challan_no ?? "-"}</td>
                        <td>{challan?.amount ?? "-"}</td>
                        <td>{challan?.due_date ?? "-"}</td>
                        <td>{challan?.expiry_date ?? "-"}</td>
                        <td>
                          <div
                            className={`status-tile ${
                              challan?.status === "Pending" ? "red" : ""
                            }`}
                          >
                            {challan?.status ?? "-"}
                          </div>
                        </td>
                        <td>
                          <div
                            onClick={() =>
                              downlaodInstallmentFeeChallan(challan?.challan_no)
                            }
                          >
                            <DownloadChallanSvg />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="table-modal-close-button">
                  <button
                    className="lg-rounded-btn gray"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="total-fee-title">
                  {isCreateInstallments && getRemainingFee() > 0 ? (
                    <span>
                      <span className="amount-span">-</span> Remaining Amount:{" "}
                      <span className="amount-span"> {getRemainingFee()}</span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {!isCreateInstallments && (
                  <Fragment>
                    <button
                      className="buttons"
                      onClick={() => downlaodFeeChallan(offer_letter)}
                    >
                      Download Fee Challan
                    </button>
                    <button
                      className="buttons button-primary"
                      onClick={() => setIsCreateInstallments(true)}
                    >
                      Create Installments
                    </button>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>

          {isCreateInstallments && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="data-table">
                <table className="bottom-bordered-cells">
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <Fragment key={index}>
                          <th>{column}</th>
                        </Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {installments.map((installment, index) => (
                      <tr key={index}>
                        <td>{installment.title}</td>
                        <td>
                          <div className="input-field">
                            <div className="field-wrap">
                              <div className="field">
                                <input
                                  type="date"
                                  value={installment.due_date}
                                  onChange={(e) => {
                                    const updatedInstallments = [
                                      ...installments,
                                    ];
                                    updatedInstallments[index].due_date =
                                      e.target.value;
                                    setInstallments(updatedInstallments);
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-field">
                            <div className="field-wrap">
                              <div className="field">
                                <input
                                  className=""
                                  type="date"
                                  value={installment.exp_date}
                                  onChange={(e) => {
                                    const updatedInstallments = [
                                      ...installments,
                                    ];
                                    updatedInstallments[index].exp_date =
                                      e.target.value;
                                    setInstallments(updatedInstallments);
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="input-field">
                            <div className="field-wrap">
                              <div className="field">
                                <input
                                  type="number"
                                  min={0}
                                  value={installment.amount}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="table-action-buttons">
                            {index === installments.length - 1 &&
                              getRemainingFee() > 0 && (
                                <button
                                  type="button"
                                  className="buttons"
                                  onClick={addInstallment}
                                >
                                  <AddGrayTableSvg />
                                </button>
                              )}
                            {index >= 2 && (
                              <button
                                type="button"
                                className="buttons"
                                onClick={() => removeInstallment(index)}
                              >
                                <DeleteTableSvg />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="action-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn gray"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <button className="lg-rounded-btn" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          )}
        </ContentWrapper>
      </Container>
    </Main>
  );
};

export default ManageInstallments;
