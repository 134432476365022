import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreateEmployeesForm,
  CreateEmployeesMain,
  CreateEmployeesTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { EmployeesDTO } from "utils/helpers/models/hr-management/employees.dto";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import useAcademics from "containers/private/academics/useHooks";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useAdmissions from "containers/private/admissions/useHooks";
import useHRManagement from "../../useHook";
import InputMask from "react-input-mask";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { UserManagementDTO } from "utils/helpers/models/system-administration/user-management.dto";
import useSystemAdministration from "containers/private/system-administration/useHooks";
import SingleSelectField from "components/particles/forms/single-select-field";
import useCareers from "containers/private/careers/useHooks";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";

export const EmployeesCreate: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Hr Management / ", path: siteRoutes.academicSessionListing },
    {
      title: `Employees Listing / `,
      path: siteRoutes.employeesListing,
    },
    {
      title: `Employee ${params.id ? "Update" : "Create"}`,
      path: siteRoutes.employeesCreate,
    },
  ];

  const {
    getTeachersTitles,
    createEmployees,
    updateEmployeesById,
    getEmployeeById,
  } = useHRManagement();

  const {
    getSpecificStructure,
    getCitiesAdmin,
    getRolesByAdmin,
    getCountriesAdmin,
  } = useOrganization();
  const { getUsers } = useSystemAdministration();
  const { getJobDesignations } = useCareers();

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<EmployeesDTO>();

  const [cities, setCities] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [users, setUsers] = useState<UserManagementDTO[]>([]);
  const [teacherTitles, setTeachersTitles] = useState<any[]>([]);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<EmployeesDTO>(new EmployeesDTO());
  // const [roles, setRoles] = useState<any[]>([]);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    const newFormData = new EmployeesDTO();
    for (let key in newFormData) {
      setValue(
        key as keyof EmployeesDTO,
        newFormData[key as keyof EmployeesDTO] || ""
      );
    }
    setFormData(newFormData);
  };

  const onSubmit = (formData: EmployeesDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateEmployeesById(params?.id, formData);
    } else {
      createEmployees(formData, addMore, resetForm);
    }
  };

  const onSelectCustom = (value: number, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSelectSingleSelect = (value: number | string, name: any) => {
    setValue(name, value);
    trigger(name);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    getUsers(setUsers);
    getCitiesAdmin(setCities);
    // getRolesByAdmin(setRoles);
    getCountriesAdmin(setCountries);
    getJobDesignations(setDesignations);
    getTeachersTitles(setTeachersTitles);
    if (params?.id) {
      getEmployeeById(params?.id, formData, setValue, setFormData);
    }
    getSpecificStructure(setCampuses, {
      per_page: "All",
      type: OrgStructureTypes.campus,
    });
    getSpecificStructure(setDepartments, {
      per_page: "All",
      type: OrgStructureTypes.department,
    });

    {
      register("user_id", { required: true });
    }

    {
      register("designation_id", { required: true });
    }

    {
      register("city_id", { required: true });
    }
    {
      register("domicile_id", { required: true });
    }
    {
      register("department_id", { required: true });
    }
    {
      register("nationality_id", { required: true });
    }
  }, []);

  return (
    <CreateEmployeesMain>
      <CreateEmployeesTop>
        <div className="left">
          <span className="page-heading">
            Employee {params?.id ? "Update" : "Add"}
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateEmployeesTop>

      <CreateEmployeesForm
        className="content-radius-shadow"
        onSubmit={handleSubmit((data: EmployeesDTO) => onSubmit(data))}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("title_id", { required: true })}
                  value={formData.title_id}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    -- Select --
                  </option>
                  {teacherTitles.map((titles, index) => {
                    return (
                      <option value={titles.id} key={index}>
                        {titles.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.title_id} />
            </div>
          </div>

          <div className="input-field">
            <label>First Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("name", { required: true })}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="first name"
                />
              </div>
              <FormErrorMessage error={errors.name} />
            </div>
          </div>

          <div className="input-field">
            <label>User Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("user_name", { required: true })}
                  value={formData.user_name}
                  onChange={handleChange}
                  placeholder="user name"
                />
              </div>
              <FormErrorMessage error={errors.user_name} />
            </div>
          </div>

          <div className="input-field">
            <label>Phone</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("phone_no", { required: true })}
                  value={formData.phone_no}
                  onChange={handleChange}
                  placeholder="phone no"
                />
              </div>
              <FormErrorMessage error={errors.phone_no} />
            </div>
          </div>

          <div className="input-field">
            <label>CNIC</label>
            <div className="field-wrap">
              <div className="field">
                <InputMask
                  mask="99999-9999999-9"
                  {...register("cnic", { required: true })}
                  value={formData.cnic}
                  onChange={handleChange}
                  placeholder="cnic"
                >
                  {(inputProps) => <input type="text" {...inputProps} />}
                </InputMask>
              </div>
              <FormErrorMessage error={errors.cnic} />
            </div>
          </div>

          <div className="input-field">
            <label>DOB</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("dob", { required: true })}
                  value={formData.dob}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.dob} />
            </div>
          </div>

          <div className="input-field">
            <label>Job Status Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("job_status_date", { required: true })}
                  value={formData.job_status_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.job_status_date} />
            </div>
          </div>

          <div className="input-field">
            <label>Joining Notify Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("joining_notify_date", { required: true })}
                  value={formData.joining_notify_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.joining_notify_date} />
            </div>
          </div>

          <div className="input-field">
            <label>Joining Letter Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("joining_letter_no", { required: false })}
                  value={formData.joining_letter_no}
                  onChange={handleChange}
                  placeholder="letter number"
                />
              </div>
              <FormErrorMessage error={errors.joining_letter_no} />
            </div>
          </div>

          <div className="input-field">
            <label>App Letter Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("app_letter_no", { required: false })}
                  value={formData.app_letter_no}
                  onChange={handleChange}
                  placeholder="app letter number"
                />
              </div>
              <FormErrorMessage error={errors.app_letter_no} />
            </div>
          </div>

          <div className="input-field">
            <label>Experience</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("experience", { required: false })}
                  value={formData.experience}
                  onChange={handleChange}
                  placeholder="experience"
                />
              </div>
              <FormErrorMessage error={errors.experience} />
            </div>
          </div>

          <div className="input-field">
            <label>Mobile Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("mobile_number", { required: true })}
                  value={formData.mobile_number}
                  onChange={handleChange}
                  placeholder="0000-000000-0"
                />
              </div>
              <FormErrorMessage error={errors.mobile_number} />
            </div>
          </div>

          <div className="input-field">
            <label>Passport Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("passport_number", { required: false })}
                  value={formData.passport_number}
                  onChange={handleChange}
                  placeholder="passport number"
                />
              </div>
              <FormErrorMessage error={errors.passport_number} />
            </div>
          </div>

          <SingleSelectField
            options={cities}
            value={formData.city_id}
            onSelect={(value) => onSelectSingleSelect(value, "city_id")}
            helperText="-- Select --"
            label="Select City"
            error={errors.city_id}
            searchable={true}
          />

          {/* <div className="input-field">
            <label>Select User</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("user_id", { required: true })}
                  value={formData.user_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {users?.map((user, index) => {
                    return (
                      <option value={user.id} key={index}>
                        {user?.first_name ?? "-"} {user?.last_name ?? "-"} --{" "}
                        {user?.cnic ?? "-"}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.user_id} />
            </div>
          </div> */}

          <SingleSelectField
            options={users}
            value={formData?.user_id}
            onSelect={(value) => onSelectSingleSelect(value, "user_id")}
            property="name"
            helperText="-- Select --"
            label="Select User"
            error={errors.user_id}
            searchable={true}
          />

          {/* <div className="input-field">
            <label>Role</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("designation_id", { required: true })}
                  value={formData.designation_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {roles?.map((role: any, index: number) => {
                    return (
                      <option value={role.id} key={index}>
                        {role?.name ?? "-"}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.designation_id} />
            </div>
          </div> */}

          <SingleSelectField
            options={designations}
            value={formData.designation_id}
            onSelect={(value) => onSelectSingleSelect(value, "designation_id")}
            helperText="-- Select --"
            label="Desigination"
            error={errors.designation_id}
            searchable={true}
          />

          <SingleSelectField
            options={cities}
            value={formData.domicile_id}
            onSelect={(value) => onSelectSingleSelect(value, "domicile_id")}
            helperText="-- Select --"
            label="Select Domicile City"
            error={errors.domicile_id}
            searchable={true}
          />

          <div className="input-field">
            <label>Select Religion</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("religion", { required: true })}
                  value={formData.religion}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option>Islam</option>
                  <option>Christianity</option>
                  <option>Judism</option>
                  <option>Jewism</option>
                  <option>Hindu</option>
                  <option>Others</option>
                </select>
              </div>
              <FormErrorMessage error={errors.religion} />
            </div>
          </div>

          <div className="input-field">
            <label>Gender</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("gender", { required: true })}
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">Male</option>
                  <option value="0">Female</option>
                </select>
              </div>
              <FormErrorMessage error={errors.gender} />
            </div>
          </div>

          <SingleSelectField
            options={countries}
            value={formData?.nationality_id}
            onSelect={(value) => onSelectSingleSelect(value, "nationality_id")}
            property="name"
            helperText="-- Select --"
            label="Select Nationality"
            error={errors.nationality_id}
            searchable={true}
          />

          <div className="input-field">
            <label>Campus</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("campus_id", { required: true })}
                  value={formData.campus_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {campuses?.map((item: any, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.campus_id} />
            </div>
          </div>

          <SingleSelectField
            options={departments}
            value={formData.department_id}
            onSelect={(value) => onSelectSingleSelect(value, "department_id")}
            helperText="-- Select --"
            label="Select Department"
            error={errors.department_id}
            searchable={true}
          />

          <div className="input-field">
            <label>Blood Group</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("blood_group", { required: false })}
                  value={formData.blood_group}
                  onChange={handleChange}
                  placeholder="blood group"
                />
              </div>
              <FormErrorMessage error={errors.blood_group} />
            </div>
          </div>

          <div className="input-field">
            <label>Personel Email</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="email"
                  {...register("email", { required: true })}
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="email"
                />
              </div>
              <FormErrorMessage error={errors.email} />
            </div>
          </div>

          <div className="input-field">
            <label>Present Address</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("present_address", { required: true })}
                  value={formData.present_address}
                  onChange={handleChange}
                  placeholder="address"
                />
              </div>
              <FormErrorMessage error={errors.present_address} />
            </div>
          </div>

          <div className="input-field">
            <label>Permanent Address</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("permanent_address", { required: true })}
                  value={formData.permanent_address}
                  onChange={handleChange}
                  placeholder="permanent address"
                />
              </div>
              <FormErrorMessage error={errors.permanent_address} />
            </div>
          </div>

          <div className="input-field">
            <label>Kin Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("nok_name", { required: false })}
                  value={formData.nok_name}
                  onChange={handleChange}
                  placeholder="nok name"
                />
              </div>
              <FormErrorMessage error={errors.nok_name} />
            </div>
          </div>

          <div className="input-field">
            <label>Account Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("acc_number", { required: false })}
                  value={formData.acc_number}
                  onChange={handleChange}
                  placeholder="account number"
                />
              </div>
              <FormErrorMessage error={errors.acc_number} />
            </div>
          </div>

          <div className="input-field">
            <label>Kin Mobile Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("nok_mobile_number", { required: false })}
                  value={formData.nok_mobile_number}
                  onChange={handleChange}
                  placeholder="nok mobile number"
                />
              </div>
              <FormErrorMessage error={errors.nok_mobile_number} />
            </div>
          </div>

          <div className="input-field">
            <label>Kin Relationship</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("nok_relation", { required: false })}
                  value={formData.nok_relation}
                  onChange={handleChange}
                  placeholder="enter relation ship with kin"
                />
              </div>
              <FormErrorMessage error={errors.nok_relation} />
            </div>
          </div>

          <div className="input-field">
            <label>
              Is HOD
              <span className="status-tile red">( head of department )</span>
            </label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_hod", { required: false })}
                  value={formData.is_hod}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_hod} />
            </div>
          </div>

          <div className="input-field">
            <label>Is Teacher</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_teaching", { required: false })}
                  value={formData.is_teaching}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_teaching} />
            </div>
          </div>

          <div className="input-field">
            <label>Job Status</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("job_status", { required: false })}
                  value={formData.job_status}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">Active</option>
                  <option value="0">Not Active</option>
                </select>
              </div>
              <FormErrorMessage error={errors.job_status} />
            </div>
          </div>

          <div className="input-field">
            <label>DOR</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("dor", { required: false })}
                  value={formData.dor}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.dor} />
            </div>
          </div>

          <div className="input-field">
            <label>Salary</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("salary", { required: false })}
                  value={formData.salary}
                  onChange={handleChange}
                  placeholder="salary"
                />
              </div>
              <FormErrorMessage error={errors.salary} />
            </div>
          </div>

          <div className="input-field">
            <label>job Type</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("job_type", { required: false })}
                  value={formData.job_type}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="Regular">Regular</option>
                  <option value="Contract">Contract Base</option>
                </select>
              </div>
              <FormErrorMessage error={errors.job_type} />
            </div>
          </div>

          <div className="input-field">
            <label>DOA</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("doa", { required: false })}
                  value={formData.doa}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.doa} />
            </div>
          </div>

          <div className="input-field">
            <label>Emp Number</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("emp_number", { required: false })}
                  value={formData.emp_number}
                  onChange={handleChange}
                  placeholder="emp number"
                />
              </div>
              <FormErrorMessage error={errors.emp_number} />
            </div>
          </div>

          <div className="input-field">
            <label>Cast</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("cast", { required: false })}
                  value={formData.cast}
                  onChange={handleChange}
                  placeholder="cast"
                />
              </div>
              <FormErrorMessage error={errors.cast} />
            </div>
          </div>

          <div className="input-field">
            <label>Remarks</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("remarks", { required: false })}
                  value={formData.remarks}
                  onChange={handleChange}
                  placeholder="remarks"
                />
              </div>
              <FormErrorMessage error={errors.remarks} />
            </div>
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" onClick={resetForm}>
              Reset
            </button>
            {!params?.id && (
              <button
                className="lg-rounded-btn spring"
                type="submit"
                onClick={handleSubmit((data: EmployeesDTO) =>
                  onSubmit(data, true)
                )}
              >
                Save & Add more
              </button>
            )}
            <button
              className="lg-rounded-btn"
              onClick={handleSubmit((data: EmployeesDTO) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Save"} & Exit
            </button>
          </div>
        </div>
      </CreateEmployeesForm>
    </CreateEmployeesMain>
  );
};

export default EmployeesCreate;
