import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";

export const DSSYSTEM_APIS = {
  getOrgDataForAdminDashboard: () =>
    getRequest("/admin/dashboards/organization"),

  getAdmissionDashboard: (params: any) =>
    getRequest("/admin/dashboards/admissions", params),
  getFilterDataForAdmDashboard: () =>
    getRequest(`/admin/admission_session/years`),
  getCourseAnomalies: (params: any) =>
    getRequest(`admin/dashboard/academic/course/anomalies`, params),
  getEnrolledStudents: (params: any) =>
    getRequest(`admin/dashboard/academic/enrolled/students`, params),
  getAcademicDashboardGradebookData: (params: any) =>
    getRequest(`admin/dashboard/academic/gradebooks-data`, params),
  getNotEnrolledStudents: (params: any) =>
    getRequest(`admin/dashboard/academic/not/enrolled/students`, params),
  getStudentAnomalies: (params: any) =>
    getRequest(`admin/dashboard/academic/student/anomalies`, params),
  getAcademicsData: (params: any) =>
    getRequest(`admin/dashboard/academic/academic-data`, params),
  getEnrolledCoursesAndTeachers: (params: any) =>
    getRequest(`admin/dashboard/academic/enrolled/courses-teachers`, params),
  getFinanceDashboard: () => getRequest(`admin/dashboard/finance`),
  getReportsList: (params: any) => getRequest(`admin/all_reports`, params),
  getReportById: (id: number) => getRequest(`admin/all_reports/${id}`),
  deleteReportsById: (id: number) => deleteRequest(`admin/all_reports/${id}`),
  createReports: (params: any) => postRequest(`admin/all_reports`, params),
  updateReports: (id: number, params: any) =>
    putRequest(`admin/all_reports/${id}`, params),
  downloadReportsWithUrlAndSessionID: (params: any) => getRequest(params),
  downloadReportsWithUrl: (url: string) => getRequest(`${url}`),
  getExecutiveSummaryFeeIncomeDashboard: (params: any) =>
    getRequest(`admin/dashboard/finance/exective-summary`, params),
  getSemesterFeeIncomeDashboard: (params: any) =>
    getRequest(`admin/dashboard/finance/semester-fee`, params),
  getSemesterFeeInstallments: (params: any) =>
    getRequest(`admin/dashboard/finance/semester-fee-installments`, params),
  getYearWiseSummaryFee: (params: any) =>
    getRequest(`admin/dashboard/finance/year-wise-summary`, params),
};
