import styled from "styled-components";

export const FinanceDashboardMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const FDTopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FDContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

export const HeadlineCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* display: flex; */
  gap: 1em;
`;

export const HeadlineCard = styled.div`
  /* width: 24%; */
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background: var(--white-color);
  border-radius: 20px;
  padding: 1em;

  .header {
    width: 100%;
    display: flex;
    gap: 0.8em;
    align-items: center;

    .title {
      color: var(--black-text);
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .lower-part {
    width: 100%;
    display: flex;
    gap: 1em;
    align-items: flex-end;

    .stats {
      display: flex;
      flex-direction: column;
      .total {
        font-size: 14px;
        font-weight: 600;
        color: var(--black-text);
      }

      .percentage {
        font-size: 14px;
        font-weight: 400;
        color: var(--primary);
      }
    }
  }
`;

export const MainStatCards = styled.div`
  width: 100%;
  display: flex;
  gap: 1em;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const MainStatCard = styled.div`
  width: 50%;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 1em;

  @media screen and (max-width: 950px) {
    width: 100%;
  }

  .header-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    .title {
      span {
        font-size: 32px;
        font-weight: 500;
        color: var(--black-text);

        @media screen and (max-width: 1280px) {
          font-size: 24px;
        }
      }
    }

    .description {
      color: var(--black-text);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const LinkSection = styled.div`
  padding: 1em;
  color: var(--black-text);

  .fee-container-title {
    font-size: var(--font-32);
    border-bottom: 1px solid var(--light-gray);
  }
  .link-card-container {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .link-card-body {
    display: flex;
    flex-direction: column;
  }
  .link-card {
    padding: 1em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    width: 33%;

    &:hover {
      cursor: pointer;
      color: var(--lightgray-medium);
      opacity: 90%;
    }

    @media (min-width: 1300px) {
      width: 32%;
    }

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }

  .card-title {
    font-size: var(--font-24);
    border-bottom: 1px solid var(--light-gray);
  }
  .card-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }

  .border-b {
    width: 100%;
    padding: 1px;
    margin: 0.5rem 0;
    background-color: var(--light-gray);
  }

  .link-title {
    font-size: 16px;
    font-weight: 700;
    margin-top: 0.5rem;
    color: var(--black-text);
  }
  .total-amount {
    color: var(--black-text);
    font-weight: 500;
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;
export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ $isFilterOpen?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightgray-extralight);
  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    width: 20px;
    height: 28px;
    transition: all 0.1s ease-in-out;

    transform: ${({ $isFilterOpen }) =>
      $isFilterOpen ? "rotate(0deg)" : "rotate(180deg)"};
    path {
      fill: var(--lightgray-medium);
    }

    @media screen and (max-width: 480px) {
      width: 18px;
      height: 24px;
    }
  }

  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const FSListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  .income-management-filters {
    padding: 0 !important;
  }
  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }
  table {
    border: 1px solid var(--lightgray-extralight);
    border-collapse: collapse;
    border-radius: 5px;
  }

  table td {
    .mw-150 {
      min-width: 150px;
    }
  }
  .tabs-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    @media (max-width: 870px) {
      flex-wrap: wrap;
    }
    .tab-btn {
      background-color: var(--lightgray-extralight);
      padding: 14px;
      border-radius: 5px;
      &.active {
        background-color: var(--primary);
        color: var(--white-text);
      }
    }
  }
  .table-status {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .d-flex {
      display: flex;
      gap: 1rem;
      justify-content: space-between;

      .status-tile {
        white-space: nowrap;
      }
    }
  }
  .table-heading {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: var(--black-text);
    font-weight: 700;
    margin-block: 20px;
  }
`;

export const FSListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;
