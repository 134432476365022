import { FC, Fragment, useEffect, useState } from "react";
import {
  LinkGradeTemplateMain,
  Container,
  ContentWrapper,
  AdvertisementListingSection,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import { LetterGradeDTO } from "utils/helpers/models/academics/letter-grade.dto";
import {
  confirmationPopup,
  errorToaster,
} from "utils/helpers/common/alert-service";

interface LinkGradeTemplateProps {
  setOpen: Function;
  templateTitle: string;
  template_id: number;
}

const LinkGradeTemplate: FC<LinkGradeTemplateProps> = ({
  setOpen,
  templateTitle,
  template_id,
}) => {
  const columns: string[] = ["Letter Grades", "Grade Point"];

  const { getLetterGrades, createGradingSchemeTemplateRecord } = useAcademics();

  const [letterGrades, setLetterGrades] = useState<LetterGradeDTO[]>([]);
  const [selectedStudents, setSelectedLetterGrades] = useState<number[]>([]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    getLetterGrades(setLetterGrades);
  }, []);

  const handleSelectStudent = (id: number) => {
    setSelectedLetterGrades((prev) =>
      prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id]
    );
  };

  const areAllStudentsSelected = () =>
    letterGrades.length > 0 &&
    selectedStudents?.length === letterGrades?.length;

  const handleSelectAllStudents = () => {
    if (areAllStudentsSelected()) {
      setSelectedLetterGrades([]);
    } else {
      setSelectedLetterGrades(letterGrades?.map((item) => item?.id));
    }
  };

  const onSubmit = () => {
    if (selectedStudents?.length < 1) {
      errorToaster("pls select atleast one record");
    } else {
      let body = {};

      body = {
        template_id: template_id,
        letter_grade_ids: selectedStudents?.join(", "),
      };
      createGradingSchemeTemplateRecord(body, setOpen);
    }
  };

  return (
    <LinkGradeTemplateMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Link Grades to {templateTitle ?? "-"}</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <AdvertisementListingSection>
            <div className="data-table">
              <table className="bottom-bordered-cells">
                <thead>
                  <tr>
                    <th>
                      <div className="table-checkbox-field">
                        <input
                          type="checkbox"
                          checked={areAllStudentsSelected()}
                          onChange={handleSelectAllStudents}
                        />
                      </div>
                    </th>
                    {columns.map((column: string, index: number) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {letterGrades?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="table-checkbox-field">
                          <input
                            type="checkbox"
                            checked={selectedStudents.includes(item?.id)}
                            onChange={() => handleSelectStudent(item?.id)}
                          />
                        </div>
                      </td>
                      <td>{item.letter_grade}</td>
                      <td>{item.grade_point}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="action-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn black"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>

                  <button
                    onClick={onSubmit}
                    className="lg-rounded-btn"
                    type="submit"
                  >
                    Save & Close
                  </button>
                </div>
              </div>
            </div>
            <Fragment></Fragment>
          </AdvertisementListingSection>
        </ContentWrapper>
      </Container>
    </LinkGradeTemplateMain>
  );
};

export default LinkGradeTemplate;
