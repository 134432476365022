import useUtils from "hooks/useUtils";
import { ORGANIZATION_APIS } from "libs/apis/organization.api";
import { TIMETABLE_API } from "libs/apis/time-table.api";

import { useNavigate } from "react-router-dom";
import {
  errorToaster,
  successToaster,
} from "utils/helpers/common/alert-service";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { PreferedRoomToCourseListDTO } from "utils/helpers/models/time-table/add-room-to-course-list.dto";
import { PreferedRoomToSectionListDTO } from "utils/helpers/models/time-table/add-room-to-section-list.dto";
import { DefaultCreditHoursDTO } from "utils/helpers/models/time-table/default-credit-hours.dto";
import { TimeTablePlanerDTO } from "utils/helpers/models/time-table/time-table-planer.dto";
import { TimeTablePlannerDashboardDTO } from "utils/helpers/models/time-table/time-table-planner-dashboard.dto";
import { TimeTableDTO } from "utils/helpers/models/time-table/time-table.dto";

const useTimeTable = () => {
  const { downloadFileWithUrl } = useUtils();
  const navigate = useNavigate();

  const createTimeTable = async (
    body: TimeTableDTO,
    addMore: boolean = false,
    resetForm: Function
  ) => {
    const response = await TIMETABLE_API.createTimeTable(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.timeTableListing);
      }
    }
  };

  const getTimeTableById = async (
    id: number,
    setFormData: Function,
    formData: TimeTableDTO,
    setValue: Function
  ) => {
    const response = await TIMETABLE_API.getTimeTableById(id);
    const data = response?.response;
    for (let key in formData) {
      data[key] = data[key];
      setValue(key, data[key]);
    }
    setFormData({ ...formData });
  };

  const getTimeTablePlannerById = async (
    id: number,
    setFormData: Function,
    formData: TimeTablePlanerDTO,
    setValue: Function
  ) => {
    try {
      const response = await TIMETABLE_API.getTimeTablePlannerById(id);
      const data = response?.response;
      const updatedFormData = { ...formData };
      Object.keys(formData).forEach((key) => {
        if (data[key] !== undefined) {
          updatedFormData[key] = data[key];
          setValue(key, data[key]);
        } else {
          console.warn(`Key "${key}" not found in API response`);
        }
      });

      setFormData(updatedFormData);
    } catch (error) {
      console.error("Failed to fetch timetable planner data:", error);
    }
  };

  const getTimeTablePlannerDashboardById = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await TIMETABLE_API.getTimeTablePlannerDashboardById(
      queryParams
    );
    const data = response?.response?.data;
    if (data) {
      const {
        total: totalRecords,
        current_page: page,
        per_page,
      } = response?.response;
      if (setPagination) {
        setPagination({ per_page, totalRecords, page });
      }
      // setData(
      //   Array.isArray(data)
      //     ? data.map((i) => new TimeTablePlannerDashboardDTO(i))
      //     : []
      // );
      setData(data);
    } else {
      setData(response?.response);
      // setData(
      //   Array.isArray(response?.response)
      //     ? response?.response.map((i) => new TimeTablePlannerDashboardDTO(i))
      //     : []
      // );
    }
  };

  const createTimeTablePlanner = async (
    body: TimeTablePlanerDTO<string>,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await TIMETABLE_API.createTimeTablePlanner(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };

  const updateTimeTableById = async (id: number, body: TimeTableDTO) => {
    const response = await TIMETABLE_API.updateTimeTableById(body, id);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.timeTableListing);
    }
  };

  const updateTimeTablePlannerById = async (
    id: number,
    body: TimeTablePlanerDTO
  ) => {
    const response = await TIMETABLE_API.updateTimeTablePlannerById(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.timeTablePlannerListing);
    }
  };

  const deleteTimeTableById = async (
    id: number,
    setData?: Function,
    pagination?: any,
    setPagination?: Function
  ) => {
    const response = await TIMETABLE_API.deleteTimeTableById(id);
    if (response) {
      successToaster(response?.message);
      getTimeTableList(setData, pagination, setPagination);
    } else {
      errorToaster(response?.message);
    }
  };
  const deleteTimeTablePlannerById = async (
    id: number,
    setData?: Function,
    pagination?: any,
    setPagination?: Function
  ) => {
    const response = await TIMETABLE_API.deleteTimeTablePlannerById(id);
    if (response) {
      successToaster(response?.message);
      getTimeTableList(setData, pagination, setPagination);
    } else {
      errorToaster(response?.message);
    }
  };

  const getTimeTableList = async (
    setData: Function,
    queryParams: any = { per_page: "All", page: 1 },
    setPagination?: Function
  ) => {
    const response = await TIMETABLE_API.getTimeTableList(queryParams);
    const data = response?.response?.data;
    if (data) {
      const {
        total: totalRecords,
        current_page: page,
        per_page,
      } = response?.response;
      if (setPagination) {
        setPagination({ per_page, totalRecords, page });
      }
      setData(Array.isArray(data) ? data.map((i) => new TimeTableDTO(i)) : []);
    } else {
      setData(
        Array.isArray(response?.response)
          ? response?.response.map((i) => new TimeTableDTO(i))
          : []
      );
    }
  };

  const getOrganizationById = async (
    id: number,
    getValues: Function,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ORGANIZATION_APIS.getOrganizationById(id);
    const { data } = response;
    if (getValues && setValue && setFormData) {
      for (let key in getValues()) {
        if (key === "district" || key === "city") {
          setValue(key, data[key]?.id ?? data[key]);
        } else {
          setValue(key, response?.data[key]);
        }
      }
      setValue("password", Math.random());
      setFormData({ ...getValues() });
    }
  };

  const downloadOrganizationsExcelFile = async () => {
    const response = await ORGANIZATION_APIS.downloadOrganizationsExcelFile();
    if (response?.url) {
      downloadFileWithUrl(response?.url);
    } else {
      errorToaster(response?.message);
    }
  };

  const getTimeTablePlanner = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await TIMETABLE_API.getTimeTablePlanner(queryParams);
    const { status } = response;
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data.map((i) => new TimeTablePlanerDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response.map((i) => new TimeTablePlanerDTO(i))
            : []
        );
      }
    }
  };
  const getDefaultCreditHoursList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await TIMETABLE_API.getDefaultCreditHoursList(queryParams);
    const { status } = response;
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data.map((i) => new DefaultCreditHoursDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response.map((i) => new DefaultCreditHoursDTO(i))
            : []
        );
      }
    }
  };

  const updateDefaultCreditHoursList = async (
    id: number,
    body: DefaultCreditHoursDTO
  ) => {
    const response = await TIMETABLE_API.updateDefaultCreditHoursList(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.defaultCreditHoursListing);
    }
  };

  const getRoomToPlannerCourseList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await TIMETABLE_API.getRoomToPlannerCourseList(
      queryParams
    );
    const { status } = response;
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data.map((i) => new PreferedRoomToCourseListDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response.map((i) => new PreferedRoomToCourseListDTO(i))
            : []
        );
      }
    }
  };
  const getRoomToPlannerSectionList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await TIMETABLE_API.getRoomToPlannerSectionList(
      queryParams
    );
    const { status } = response;
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data.map((i) => new PreferedRoomToSectionListDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response.map((i) => new PreferedRoomToSectionListDTO(i))
            : []
        );
      }
    }
  };

  const deleteRoomToCourseListById = async (
    id: number,
    setData?: Function,
    pagination?: any,
    setPagination?: Function
  ) => {
    const response = await TIMETABLE_API.deleteRoomToCourseListById(id);
    if (response) {
      successToaster(response?.message);
      getRoomToPlannerCourseList(setData, pagination, setPagination);
    } else {
      errorToaster(response?.message);
    }
  };
  const deleteRoomToSectionListById = async (
    id: number,
    setData?: Function,
    pagination?: any,
    setPagination?: Function
  ) => {
    const response = await TIMETABLE_API.deleteRoomToSectionListById(id);
    if (response) {
      successToaster(response?.message);
      getRoomToPlannerSectionList(setData, pagination, setPagination);
    } else {
      errorToaster(response?.message);
    }
  };

  const createRoomToCourseAllocation = async (
    body: PreferedRoomToCourseListDTO<string>,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await TIMETABLE_API.createRoomToCourseAllocation(body);
    console.log("ass", response);
    if (response) {
      successToaster(response?.message);
      if (addMore) {
        resetForm();
      } else {
        navigate(-1);
      }
    } else {
      errorToaster(response?.errors);
    }
  };
  const createRoomToSectionAllocation = async (
    body: PreferedRoomToSectionListDTO<string>,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await TIMETABLE_API.createRoomToSectionAllocation(body);
    if (response) {
      successToaster(response?.message);
      if (addMore) {
        resetForm();
      } else {
        navigate(-1);
      }
    } else {
      errorToaster(response?.errors);
    }
  };

  return {
    deleteTimeTableById,
    deleteTimeTablePlannerById,
    getOrganizationById,
    getTimeTablePlannerDashboardById,
    downloadOrganizationsExcelFile,
    updateTimeTableById,
    updateTimeTablePlannerById,
    createTimeTable,
    createTimeTablePlanner,
    getTimeTableList,
    getTimeTableById,
    getTimeTablePlannerById,
    getTimeTablePlanner,
    getDefaultCreditHoursList,
    updateDefaultCreditHoursList,
    getRoomToPlannerCourseList,
    getRoomToPlannerSectionList,
    deleteRoomToCourseListById,
    deleteRoomToSectionListById,
    createRoomToCourseAllocation,
    createRoomToSectionAllocation,
  };
};

export default useTimeTable;
