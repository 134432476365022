export class MatterTypeDTO {
  id!: number;
  title: string = "";
  description: string = "";
  parent_id: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
