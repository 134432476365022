import { FC, Fragment, useState, useEffect } from "react";
import {
  CallTableSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicFeeTypeListingMain,
  AcademicFeeTypeListingSection,
  AcademicFeeTypeListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useAdmissions from "containers/private/admissions/useHooks";
import useAcademics from "../../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { FeeTypesDTO } from "utils/helpers/models/academics/academic-session.dto";

const AcademicFeeTypeListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Fee Types", path: siteRoutes.academicFeeTypeListing },
  ];
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const columns: string[] = ["Fee Type Title", "Status", "Action"];

  const navigate = useNavigate();
  const { getFeeTypes, deleteFeeTypes } = useAcademics();
  const [search, setSearch] = useState("");
  const [data, setData] = useState<FeeTypesDTO[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deleteFeeTypes(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllFeeTypes(1, search, pagination.per_page);
  };

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.createAcademicFeeType}?id=${id}`);
  };

  const goToCreateFeeType = () => {
    navigate(siteRoutes.createAcademicFeeType);
  };
  const getAllFeeTypes = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getFeeTypes(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllFeeTypes(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AcademicFeeTypeListingMain>
      <AcademicFeeTypeListingTop>
        <div className="left">
          <span className="page-heading">Fee Types</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="add-new-button">
            <button className="lg-rounded-btn" onClick={goToCreateFeeType}>
              + Add Fee Type
            </button>
          </div>
        </div>
      </AcademicFeeTypeListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Enter Name" />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Apply Filters</button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AcademicFeeTypeListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => (
                <Fragment key={index}>
                  <tr>
                    <td>{item?.title ?? "-"}</td>
                    <td>
                      {item?.is_active == 0 ? (
                        <span className="status-tile red">De-Active</span>
                      ) : (
                        <span className="status-tile green">Active</span>
                      )}
                    </td>
                    <td>
                      <div className="table-action-icons">
                        <div
                          className="action-icon"
                          onClick={() => goToEdit(item?.id)}
                        >
                          <EditTableSvg />
                        </div>

                        <div
                          className="action-icon"
                          onClick={() => handleDelete(item?.id)}
                        >
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !data.length} />
            <Pagination onPageChange={onPageChange} {...pagination} />
          </Fragment>
        </div>
      </AcademicFeeTypeListingSection>
    </AcademicFeeTypeListingMain>
  );
};

export default AcademicFeeTypeListing;
