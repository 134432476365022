export class StudentListingDTO {
  id!: number;
  isExpanded: boolean = false;
  bank_type: string = "";
  app_number: string = "";
  admission_date: string = "";
  allergy_to_food: string = "";
  bank_account_no: string = "";
  allergy_to_medicine: string = "";
  basar_title_approval_letter: string = "";
  campus: {
    id: number;
    title: string;
  } = {
    id: 0,
    title: "",
  };
  campus_id: number;
  class_section: string = "";
  clearance_download_date: string = "";
  co_supervised_by: string = "";
  commencement_date: string = "";
  completion_date: string = "";
  comprehensive_exam: string = "";
  comprehensive_exam_date: string = "";
  credit_hours_exempted: string = "";
  degree_title: string = "";
  degree_verification: string = "";
  department_id: string = "";
  faculty_id: string = "";
  fee_percent: string = "";
  fee_plan: { id: number; title: string } = { id: 0, title: "" };
  fee_quota: { id: number; title: string } = { id: 0, title: "" };
  fee_remarks: string = "";
  group_id: number;
  hereditary_disease: string = "";
  is_active: number;
  is_verify_bank_account: number;
  koha_id: number;
  koha_images: string = "";
  lms_id: number;
  merit: string = "";
  migration_double_fee: string = "";
  mother_cell_number: string = "";
  mother_cnic: string = "";
  mother_email_address: string = "";
  mother_name: string = "";
  mother_office_address: string = "";
  mother_office_number: string = "";
  mother_residence: string = "";
  organizations_id: number;
  password: string = "";
  pos: {
    id: number;
    grade_template_id: number;
    degree_title: string;
    title: string;
    total_semesters: number;
  } = {
    id: 0,
    grade_template_id: 0,
    degree_title: "",
    title: "",
    total_semesters: 0,
  };
  pos_id: number = 0;
  previous_degree_institution: string = "";
  program: {
    id: number;
    cat_id: number;
    cat_type_id: number;
    code: string;
    course_repeat_fee: number;
    title: string;
    years: number;
  } = {
    id: 0,
    cat_id: 0,
    cat_type_id: 0,
    code: "",
    course_repeat_fee: 0,
    title: "",
    years: 0,
  };
  program_id: number;
  section_id: number;
  session_id: number;
  user_id: number;
  std_status_id: number;
  specialization_id: number;
  program_semesters: string = "";
  readmission_session1: string = "";
  readmission_session2: string = "";
  readmission_skip_grades: string = "";
  reg_number: string = "";
  register_status: string = "";
  registration_number: string = "";
  result_declaration_date: string = "";
  result_issued_date: string = "";
  std_status: {
    id: number;
    for_department: number;
    title: string;
    slug: string;
  } = {
    id: 0,
    for_department: 0,
    title: "",
    slug: "",
  };
  user: {
    id: number;
    organizations_id: number;
    address: string;
    city: string;
    cnic: string;
    cnic_image: string;
    country: string;
    email: string;
    first_name: string;
    gender: string;
    last_name: string;
    name: string;
    passport_no: string;
    phone_no: string;
    profile_image: string;
    status: number;
    users_meta: {
      blood_group: string;
      cnic_back_image: string;
      date_of_birth: string;
      disability: string;
      domicile: string;
      domicile_certificate: string;
      father_name: string;
      religion: string;
    };
  } = {
    id: 0,
    organizations_id: 0,
    address: "",
    city: "",
    cnic: "",
    cnic_image: "",
    country: "",
    email: "",
    first_name: "",
    gender: "",
    last_name: "",
    name: "",
    passport_no: "",
    phone_no: "",
    profile_image: "",
    status: 0,
    users_meta: {
      blood_group: "",
      cnic_back_image: "",
      date_of_birth: "",
      disability: "",
      domicile: "",
      domicile_certificate: "",
      father_name: "",
      religion: "",
    },
  };

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class UpdateFeePlanQuotaDTO {
  fee_quota_id: number;
  fee_plan_id: number;
  constructor(data = {}) {
    Object.assign(this, data);
  }
}
