import { FC, Fragment, useEffect, useState } from "react";
import {
  AddProgramTableSvg,
  CancelRedSvg,
  DarkEyeSvg,
  DownloadChallanSvg,
  ExcelSvg,
  GreenDownLodadSvg,
  MannageRoomSvg,
  PdfSvg,
  SearchFieldSvg,
  StopActionSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  GradeBooksListingMain,
  GradeBooksListingSection,
  GradeBooksListingTop,
  LogsSection,
  TopCardSection,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TestScheduleDTO } from "utils/helpers/models/testing-service/test-schedule.dto";
import useUtils from "hooks/useUtils";
import useTestingServices from "../../testing-services/useHooks";
import {
  confirmationPopup,
  errorToaster,
  warningToaster,
} from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";
import useAdmissions from "containers/private/admissions/useHooks";
import useAcademics from "../useHooks";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import UpdateResultApproveStatusModal from "./components/add-remarks-approve-result";

const GradeBooksDetailsListing: FC = ({}) => {
  const breadcrumbLinks = [
    { title: "Academics  / ", path: "" },
    { title: "Academic Sessions / ", path: siteRoutes.academicSessionListing },
    {
      title: "Manage Academic Sessions /",
      path: siteRoutes.academicSessionListing,
    },
    { title: "Grade Book’s /", path: siteRoutes.academicGradeBookListing },
    {
      title: "Grade Book Details",
      path: siteRoutes.academicGradeBookDetailsListing,
    },
  ];

  const navigate = useNavigate();

  const {
    handleSearchChange,
    handleTableSearch,
    getQueryParams,
    isFileOfExcelType,
  } = useUtils();

  const params = getQueryParams();
  const { getAdmissionSessions } = useAdmissions();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [data, setData] = useState<any>({});
  const [search, setSearch] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isOpenApproveResultModal, setIsOPenApproveResultModal] =
    useState<boolean>(false);
  const [sessions, setSessions] = useState<any[]>([]);
  const [logsPagination, setLogsPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [filters, setFilters] = useState<any>({
    test_id: "",
    session_id: "",
    test_center_id: "",
    batch: "",
  });

  const columns: string[] = [
    "Registration #",
    "Student Name",
    "Sessional",
    "Mid Term",
    "Final Term",
    "Total Marks",
    "Percentage",
    "Grade",
  ];

  const goToEditTestSchedule = (id: number) => {};

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    // getAllAcademicSessions(page + 1, search, per_page);
  };
  const onLogsPageChange = (pageInfo: {
    selected: number;
    per_page: number;
  }) => {
    const { selected: page, per_page } = pageInfo;
    setLogsPagination({ ...logsPagination, page: page + 1, per_page });
    // getAllAcademicSessions(page + 1, search, per_page);
  };

  const openApproveResultModal = () => {
    // if (!params?.gradebook_id) {
    //   errorToaster("gradebook id requierd");
    // } else {
    // }
    setIsOPenApproveResultModal(true);
  };

  const getGradeBooksDetailsList = async (e: any, setSearch: Function) => {};

  useEffect(() => {
    getAdmissionSessions(setSessions);
  }, []);

  return (
    <GradeBooksListingMain>
      <GradeBooksListingTop>
        <div className="left">
          <span className="page-heading">Grade Book’s Details </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </GradeBooksListingTop>
      <TopCardSection className="content-radius-shadow">
        <div className="container-title">BS Computer Science CS.250</div>

        <div className="stats">
          <div className="stats-item">
            <div className="stats-title">Campus</div>
            <div className="stats-value">Main Campus</div>
          </div>
          <div className="stats-item">
            <div className="stats-title">Department</div>
            <div className="stats-value">CS</div>
          </div>
          <div className="stats-item">
            <div className="stats-title">Course Code</div>
            <div className="stats-value">CS.250</div>
          </div>
          <div className="">
            <div className="stats-title">Teacher</div>
            <div className="stats-value">Gulam Muhammad</div>
          </div>
          <div className="">
            <div className="stats-title">Status</div>
            <div className="stats-value">Active</div>
          </div>
        </div>
      </TopCardSection>

      <GradeBooksListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => getGradeBooksDetailsList(e, setSearch)}
              onKeyUp={(e) => getGradeBooksDetailsList(e, setSearch)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="mw-150">S23BTMGT3M04054</div>
                </td>
                <td>
                  <div className="mw-150">Faizan Rasheed</div>
                </td>
                <td>
                  <div>20</div>
                </td>
                <td>
                  <div>30</div>
                </td>
                <td>
                  <div>50</div>
                </td>
                <td>
                  <div>100</div>
                </td>
                <td>
                  <div>100%</div>
                </td>
                <td>
                  <div>A+</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {isDropdownOpen && (
          <Fragment>
            {Object.keys(data).length != 0 && (
              <div className="menu-dropdown">
                <div
                  className="action-button"
                  onClick={() => goToEditTestSchedule(data?.id)}
                >
                  <div className="action-icon">
                    <DarkEyeSvg className="icon" />
                  </div>
                  <span className="title">View Changes</span>
                </div>

                <div className="action-button">
                  <div className="action-icon">
                    <MannageRoomSvg className="icon" />
                  </div>
                  <span className="title">View Details</span>
                </div>
                <div className="action-button">
                  <div className="action-icon">
                    <DownloadChallanSvg className="icon" />
                  </div>
                  <span className="title">Download CSV</span>
                </div>
                <div className="action-button">
                  <div className="action-icon">
                    <GreenDownLodadSvg className="icon" />
                  </div>
                  <span className="title">Download Award List</span>
                </div>
                <div className="action-button">
                  <div className="action-icon">
                    <AddProgramTableSvg className="icon" />
                  </div>
                  <span className="title">Submit Result</span>
                </div>
                <div className="action-button">
                  <div className="action-icon">
                    <StopActionSvg className="icon" />
                  </div>
                  <span className="title">Un Submit Result</span>
                </div>
                <div className="action-button">
                  <div className="action-icon">
                    <GreenDownLodadSvg className="icon" />
                  </div>
                  <span className="title">Verify Result</span>
                </div>
              </div>
            )}
          </Fragment>
        )}

        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
          <DataNotFound show={!isLoading && !data} />
        </Fragment>
      </GradeBooksListingSection>
      <LogsSection className="content-radius-shadow">
        <div className="container-title">Grade Book Logs</div>
        <div className="stats">
          <div className="stats-item">
            <div className="stats-title  mw-150">Submitted By</div>
            <div className="stats-value">Faizan Rasheed</div>
          </div>
          <div className="stats-item ">
            <div className="stats-title mw-150">Submitted At</div>
            <div className="stats-value">19-02-2025 11:53 am</div>
          </div>
          <div className="stats-item">
            <div className="stats-title mw-150">Grade Book Version</div>
            <div className="stats-value">CS.250</div>
          </div>
          <div className="stats-item">
            <button onClick={openApproveResultModal} className="lg-rounded-btn">
              + Approve / Un Approve Result
            </button>
          </div>
        </div>

        <div className="content-radius-shadow">
          <div className="list-header">
            <div className="table-data-export-buttons">
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>

              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            </div>
            <div className="table-search-field">
              <span className="search-icon">
                <SearchFieldSvg className="icon" />
              </span>
              <input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => getGradeBooksDetailsList(e, setSearch)}
                onKeyUp={(e) => getGradeBooksDetailsList(e, setSearch)}
              />
            </div>
          </div>
          <div className="data-table">
            <table className="bottom-bordered-cells">
              <thead>
                <tr>
                  <th>Remarks</th>
                  <th>Remarks By</th>
                  <th>Remarks At</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>Result Published</div>
                  </td>
                  <td>
                    <div>Faizan</div>
                  </td>
                  <td>
                    <div>19-02-2025</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Fragment>
            <Pagination {...logsPagination} onPageChange={onLogsPageChange} />
            <DataNotFound show={!isLoading && !data} />
          </Fragment>
        </div>
      </LogsSection>
      <Fragment>
        {isOpenApproveResultModal && (
          <UpdateResultApproveStatusModal
            course_id={params?.course_id}
            gradebook_id={params?.gradebook_id}
            verified_status={params?.verified_status}
            setOpen={setIsOPenApproveResultModal}
          />
        )}
      </Fragment>
    </GradeBooksListingMain>
  );
};

export default GradeBooksDetailsListing;
