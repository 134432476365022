export class PreferedRoomToCourseListDTO<
  MultiSelectType extends number[] | string = number[]
> {
  id!: number;
  tc_id: number;
  planner_id: number;
  tc_title: string = "";
  template_course: { tc_title: string } | null = null;
  room: { room_name: string } | null = null;
  room_id: MultiSelectType;

  constructor(data: Partial<PreferedRoomToCourseListDTO> = {}) {
    Object.assign(this, data);
  }
}
