import { FC, Fragment, useEffect, useState } from "react";
import {
  TeacherLisitngSection,
  TeacherLisitngTop,
  TeacherListingMain,
} from "./style";
import {
  CallTableSvg,
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  MinusRedSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useHRManagement from "../../useHook";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";
import SingleDetailDialog from "components/particles/dialogs/single-detail-dialog";

interface TeahersProps {}

const TeachersListing: FC<TeahersProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Hr management / ", path: "" },
    { title: "Teachers Listing", path: siteRoutes.teachersListing },
  ];

  const columns: string[] = [
    "",
    "CNIC",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email",
    "Campus",
    "Is Active",
    "Is Employee",
  ];
  const navigate = useNavigate();
  const { hasAccess } = useStore();

  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getTeachersLisiting, deleteTeacherById, updateTeacherById } =
    useHRManagement();

  const [data, setData] = useState<TeacherDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const [singleDetail, setSingleDetail] = useState({ value: "", label: "" });
  const [showSingleDetailDialog, setShowSingleDetailDialog] =
    useState<boolean>(false);
  const [isTableOpened, setIsTableOpened] = useState(false);
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deleteTeacherById(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllTeachersList(page + 1, search, per_page);
  };
  const getAllTeachersList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getTeachersLisiting(setData, queryParams, setPagination);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.teachersCreate}?id=${id}`);
  };

  const goToCreateTeachers = () => {
    navigate(siteRoutes.teachersCreate);
  };
  const handleShowSingleDetailDialog = (label: string, value: string) => {
    setSingleDetail({ label, value });
    setShowSingleDetailDialog(true);
  };

  const updateTeacherIndexById = async (teacher: TeacherDTO, index: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const { id } = teacher;
      data[index] = teacher;
      setData([...data]);
      updateTeacherById(id, teacher);
    }
  };

  useEffect(() => {
    getAllTeachersList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <TeacherListingMain>
      <TeacherLisitngTop>
        <div className="left">
          <span className="page-heading">Teachers Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.teachersCreate) && (
            <div className="create-org-btn" onClick={goToCreateTeachers}>
              <button className="lg-rounded-btn">+ Add Teacher</button>
            </div>
          )}
        </div>
      </TeacherLisitngTop>
      <TeacherLisitngSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllTeachersList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllTeachersList)
              }
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 8 ? `extra-head extra-head-${index - 8}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((teacher, index) => {
                const { is_active, is_employee, is_on_leave, is_visiting } =
                  teacher;
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${
                        (teacher as any).isExpanded && "opened"
                      }`}
                    >
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{(teacher as any).isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>{teacher?.cnic ?? "-"}</td>
                      <td>
                        {teacher?.teacher_title?.title}{" "}
                        {teacher?.teacher_firstname ?? "-"}
                      </td>
                      <td>{teacher?.teacher_middlename ?? "-"}</td>
                      <td>{teacher?.teacher_lastname ?? "-"}</td>
                      <td>{teacher?.personal_email ?? "-"}</td>
                      <td>{teacher?.campus?.title ?? "-"}</td>
                      <td>
                        <label className="switch-field">
                          <input
                            type="checkbox"
                            checked={is_active == 1}
                            onChange={({ target: { checked } }) =>
                              updateTeacherIndexById(
                                {
                                  ...teacher,
                                  is_active: checked ? 1 : 0,
                                },
                                index
                              )
                            }
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch-field">
                          <input
                            type="checkbox"
                            checked={is_employee == 1}
                            onChange={({ target: { checked } }) =>
                              updateTeacherIndexById(
                                {
                                  ...teacher,
                                  is_employee: checked ? 1 : 0,
                                },
                                index
                              )
                            }
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>

                    {(teacher as any).isExpanded && (
                      <tr>
                        <td colSpan={12}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info">
                              <div className="expanded-content particular-info-1">
                                <div className="particular-info particular-info-3">
                                  <span className="title">Bank Name</span>
                                  <span className="info">
                                    {teacher?.bank_name ?? "-"}
                                  </span>
                                </div>
                                <div className="particular-info particular-info-4">
                                  <span className="title">Account Title</span>
                                  <span className="info">
                                    {teacher?.bank_account_title ?? "-"}
                                  </span>
                                </div>
                                <div className="particular-info particular-info-5">
                                  <span className="title">Account Number</span>
                                  <span className="info">
                                    {teacher?.bank_account ?? "-"}
                                  </span>
                                </div>
                                <div className="particular-info particular-info-5">
                                  <span className="title">NTN</span>
                                  <span className="info">
                                    {teacher?.ntn ?? "-"}
                                  </span>
                                </div>
                                <div className="particular-info particular-info-5">
                                  <span className="title">User Name</span>
                                  <span className="info">
                                    {teacher?.user_name ?? "-"}
                                  </span>
                                </div>
                                <div className="particular-info particular-info-1">
                                  <span className="title">Is On Leave</span>
                                  <span className="info">
                                    <label className="switch-field">
                                      <input
                                        type="checkbox"
                                        checked={is_on_leave == 1}
                                        onChange={({ target: { checked } }) =>
                                          updateTeacherIndexById(
                                            {
                                              ...teacher,
                                              is_on_leave: checked ? 1 : 0,
                                            },
                                            index
                                          )
                                        }
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </span>
                                </div>
                                <div className="particular-info particular-info-2">
                                  <span className="title">Is Visiting</span>
                                  <span className="info">
                                    <label className="switch-field">
                                      <input
                                        type="checkbox"
                                        checked={is_visiting == 1}
                                        onChange={({ target: { checked } }) =>
                                          updateTeacherIndexById(
                                            {
                                              ...teacher,
                                              is_visiting: checked ? 1 : 0,
                                            },
                                            index
                                          )
                                        }
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </span>
                                </div>
                                <div className="particular-info particular-info-5">
                                  <span className="title">Actions</span>
                                  <span className="info">
                                    <div className="table-action-buttons">
                                      <div
                                        className="action-icon"
                                        onClick={() =>
                                          handleShowSingleDetailDialog(
                                            "Phone",
                                            teacher?.phone
                                          )
                                        }
                                      >
                                        <CallTableSvg />
                                      </div>
                                      {hasAccess(
                                        sitePermissions.teachersUpdate
                                      ) && (
                                        <div
                                          className="action-icon cp"
                                          onClick={() => goToEdit(teacher.id)}
                                        >
                                          <EditTableSvg />
                                        </div>
                                      )}
                                      {hasAccess(
                                        sitePermissions.teachersDelete
                                      ) && (
                                        <div
                                          className="action-icon cp"
                                          onClick={() =>
                                            handleDelete(teacher.id)
                                          }
                                        >
                                          <DeleteTableSvg />
                                        </div>
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {showSingleDetailDialog ? (
            <SingleDetailDialog
              {...singleDetail}
              setOpen={setShowSingleDetailDialog}
            />
          ) : (
            ""
          )}
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </TeacherLisitngSection>
    </TeacherListingMain>
  );
};

export default TeachersListing;
