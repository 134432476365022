import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";
import { TestingCenterRoomDTO } from "utils/helpers/models/testing-service/testing-center-room.dto";
import { SeatingPlanDTO } from "utils/helpers/models/testing-service/seating-plan.dto";
import { SeatingPlanRoomDTO } from "utils/helpers/models/testing-service/seating-plan-room.dto";
import { UploadResultDTO } from "utils/helpers/models/testing-service/upload-result.dto";
import { AddStudentsForTestDTO } from "utils/helpers/models/testing-service/add-students-for-test.dto";
import { TestScheduleDTO } from "utils/helpers/models/testing-service/test-schedule.dto";

export const TESTING_SERVICES_APIs = {
  createTestingCenter: (body: TestingCenterDTO) =>
    postRequest(`admin/test/centers`, body),
  updateTestingCenter: (id: number, body: any) =>
    putRequest(`admin/test/centers/${id}`, body),
  getTestingCenters: (params: any) => getRequest(`admin/test/centers`, params),
  getTestingCenterById: (id: number) => getRequest(`admin/test/centers/${id}`),
  deleteTestingCenterById: (id: number) =>
    deleteRequest(`admin/test/centers/${id}`),
  createTestingCenterRoom: (
    body: TestingCenterRoomDTO,
    test_center_id: string
  ) => postRequest(`admin/test/center/rooms`, { ...body, test_center_id }),
  updateTestingCenterRoom: (id: number, body: any) =>
    putRequest(`admin/test/center/rooms/${id}`, body),
  getTestingCenterRooms: (params: any) =>
    getRequest(`admin/test/center/rooms`, params),
  getTestingCenterRoomById: (id: number) =>
    getRequest(`admin/test/center/rooms/${id}`),
  deleteTestingCenterRoom: (id: number) =>
    deleteRequest(`admin/test/center/rooms/${id}`),
  createTestSchedule: (body: TestScheduleDTO<string>) =>
    postRequest(`admin/test/schedule`, body),
  updateTestSchedule: <T extends string | number[] = string>(
    id: number,
    body: TestScheduleDTO<T>
  ) => putRequest(`admin/test/schedule/${id}`, body),
  getTestSchedules: (params: any) => getRequest(`/admin/test/schedule`, params),
  getTestScheduleById: (id: number) => getRequest(`admin/test/schedule/${id}`),
  deleteTestSchedule: (id: number) =>
    deleteRequest(`admin/test/schedule/${id}`),

  createSeatingPlan: (body: SeatingPlanDTO, testScheduleId: number) =>
    postRequest(`admin/seating/plan`, body),
  updateSeatingPlan: (id: number, body: any) =>
    putRequest(`admin/seating/plan/${id}`, body),
  getSeatingPlans: (params: any) => getRequest(`/admin/seating/plan`, params),
  getSeatingPlanById: (id: number) => getRequest(`admin/seating/plan/${id}`),
  deleteSeatingPlan: (id: number) => deleteRequest(`admin/seating/plan/${id}`),
  removeApplicantSeatingPlan: (id: number) =>
    deleteRequest(`admin/applicants/seating/plan/${id}`),
  generateSeatingPlan: (body: any) =>
    postRequest(`admin/generate/seating/plan`, body),
  publishSeatingPlan: (body: any) =>
    getRequest(`admin/publish/seating/plan`, body),
  unPublishSeatingPlan: (body: any) =>
    getRequest(`admin/unpublish/seating/plan`, body),
  publishFilteredResults: (params: any) =>
    getRequest(`/admin/publish/result`, params),
  getCenterWiseSummary: (params: any) =>
    getRequest(`/admin/center/wise/summary`, params),
  getApplicants: (body: AddStudentsForTestDTO) =>
    getRequest(`/admin/test/applications`, body),
  addStudentInBulkForTest: (params: any) =>
    postRequest(`/admin/test/applications`, params),
  getBatch: (params: any) => getRequest(`/admin/batch/dropdown`, params),
  getPublishResult: (params: any) =>
    getRequest(`/admin/publish/result`, params),
  updateMarkdSheetResultForIspublish: (body: any) =>
    postRequest(`/admin/update/publish/result`, body),
  uploadResult: (body: UploadResultDTO) =>
    postRequest(`admin/test/services/upload/result`, body),
  downloadResultSampleFile: () =>
    getRequest(`admin/download/result/sample/file`),
  createSeatingPlanRoom: (body: SeatingPlanRoomDTO, testScheduleId: number) =>
    postRequest(`admin/seating_plan_rooms`, body),
  getSeatingPlanRooms: (params: any) =>
    getRequest(`/admin/seating_plan_rooms`, params),
  getSeatingPlanList: (params: any) =>
    getRequest(`/admin/applicants/seatings/plan/list`, params),
  getSeatingPlanRoomById: (id: number) =>
    getRequest(`admin/seating_plan_rooms/${id}`),
  deleteSeatingPlanRoom: (id: number) =>
    deleteRequest(`admin/seating_plan_rooms/${id}`),
  updateSeatingPlanRoom: (id: number, body: any) =>
    putRequest(`admin/seating_plan_rooms/${id}`, body),

  getDashboard: () => getRequest(`/admin/testing/dashboard`),
  getDashboardContent: (params: any) =>
    getRequest(`/admin/testing/get_dashboard_content`, params),
  getAddmisionApplicants: (params: any) =>
    getRequest(`admin/admission_applicants`, params),

  downloadResultCard: (body: any) =>
    getRequest(`admin/test/application/admin_result_card`, body),
  downloadTestCenterSlips: (schedule_id: number) =>
    getRequest(`admin/tests/schedule/test_center_slips`, { schedule_id }),
  downloadSchedule: (schedule_id: number) =>
    getRequest(`admin/tests/schedule/download_pdf`, { schedule_id }),
  additionalInformation: (id: number, body: any) =>
    putRequest(`admin/test/applications/${id}`, body),
};
