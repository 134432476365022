export class LoginFormDTO {
    email: string = ''
    password: string = ''
    username: string = ''
    rememberMe: boolean = false;
    login_type: string = 'email';

    constructor(data = {}){
        Object.assign(this,data)
    }
}