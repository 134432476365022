export class InstallamentManagementDTO {
  id: number = 0;
  amount: number = 0;
  due_date: string = "";
  exp_date: string = "";
  title: string = "";
  offer_letter: string = "";

  // constructor(data: Partial<InstallamentManagementDTO> = {}) {
  //   Object.assign(this, data);
  // }
}
