import { FC, useEffect, useState } from "react";
import {
  CreateLegalOrganizationForm,
  CreateLegalOrganizationMain,
  CreateLegalOrganizationTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { LegalOrganizationDTO } from "utils/helpers/models/legal-matters/legal-orgainizations.dto";
import useLegalMatters from "../../useHook";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";

const CreateLegalOrganization: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Legal Oraganization Listing / ",
      path: siteRoutes.legalOrganizationList,
    },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Document Organizations`,
      path: siteRoutes.createLegalOrganization,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalOrganizationDTO>();
  const {
    createLegalOrganization,
    updateLegalOrganization,
    getLegalOrganizationByID,
  } = useLegalMatters();

  const [formData, setFormData] = useState<LegalOrganizationDTO>(
    new LegalOrganizationDTO()
  );

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalOrganizationDTO()[
        key as keyof LegalOrganizationDTO
      ];
      setValue(key as keyof LegalOrganizationDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: LegalOrganizationDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateLegalOrganization(params?.id, data);
    } else {
      createLegalOrganization(data, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getLegalOrganizationByID(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreateLegalOrganizationMain>
      <CreateLegalOrganizationTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Legal Document Organizations
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateLegalOrganizationTop>

      <CreateLegalOrganizationForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="">Organization Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("name", { required: true })}
                  value={formData?.name}
                  onChange={handleChange}
                  placeholder="organization name"
                />
              </div>
              <FormErrorMessage error={errors.name} />
            </div>
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateLegalOrganizationForm>
    </CreateLegalOrganizationMain>
  );
};

export default CreateLegalOrganization;
