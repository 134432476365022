import AcademicsDashboard from "containers/private/desicion-support-system/academics-dashboard";
import AdmissionDashboard from "containers/private/desicion-support-system/admission-dashboard";
import FeeIncomeDashboard from "containers/private/desicion-support-system/fee-income-dashboard";
import ReportsCreate from "containers/private/desicion-support-system/reports/create";
import ReportsListing from "containers/private/desicion-support-system/reports/listing";
import TestingDashboard from "containers/private/desicion-support-system/testing-services-dashboard";
import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const DSSystemRoutes: FC<any> = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.feeIncomeDashboard)}
        Component={FeeIncomeDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.testingServicesDashboard)}
        Component={TestingDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionDashboard)}
        Component={AdmissionDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.academicsDashboard)}
        Component={AcademicsDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.resportsListing)}
        Component={ReportsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.resportsCreate)}
        Component={ReportsCreate}
      />
    </Routes>
  );
};

export default DSSystemRoutes;
