import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";

import { AcademicSectionDTO } from "utils/helpers/models/academics/academic-section.dto";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { AcademicStatusDTO } from "utils/helpers/models/academics/academic-status.dto";
import { uploadProfileImageDTO } from "utils/helpers/models/academics/card-managements-list.dto";
import { CoReqDTO } from "utils/helpers/models/academics/co-req-dto";
import { CoursePoolBodyDTO } from "utils/helpers/models/academics/course-pool-body.dto";
import { CoursePoolHeaderDTO } from "utils/helpers/models/academics/course-pool-header.dto";
import { CourseTypeDTO } from "utils/helpers/models/academics/course-type.dto";
import { EnrollCourseTeacherDTO } from "utils/helpers/models/academics/enroll-course-teacher.dto";
import { EquivalentCoursesDTO } from "utils/helpers/models/academics/equivalent-courses.dto";
import { ExamTypeDTO } from "utils/helpers/models/academics/exam-types.dto";
import { FeePlanDTO } from "utils/helpers/models/academics/fee-plan.dto";
import { GradeTemplateDTO } from "utils/helpers/models/academics/grade-template.dto";
import { LetterGradeDTO } from "utils/helpers/models/academics/letter-grade.dto";
import { LinkVoucherTemplateDTO } from "utils/helpers/models/academics/link-voucher-template-to-fee-plan.dto";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { PlanOfStudyStudentDTO } from "utils/helpers/models/academics/plan-of-study-student";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { PreReqDTO } from "utils/helpers/models/academics/pre-req-dto";
import { ProSpececilizationDTO } from "utils/helpers/models/academics/program-specialization.dto";
import { SessionCourseClassDTO } from "utils/helpers/models/academics/session-coures-class.dto";
import { SessionEnrollmentDTO } from "utils/helpers/models/academics/session-enrollment.dto";
import { StudentSpecializationDTO } from "utils/helpers/models/academics/student-specialization.dto";
import { StudentStatusDTO } from "utils/helpers/models/academics/student-status.dto";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";

export const ACADEMICS_APIS = {
  createGradeSyncTemplate: (body: any) =>
    postRequest(`admin/grading/scheme/template/sync`, body),
  createLetterGrade: (body: LetterGradeDTO) =>
    postRequest(`admin/letter/grade`, body),
  updateLetterGrade: (id: number, body: LetterGradeDTO) =>
    putRequest(`admin/letter/grade/${id}`, body),
  getLetterGradeById: (id: number) => getRequest(`admin/letter/grade/${id}`),
  getLetterGrades: (params: any) => getRequest(`admin/letter/grade`, params),
  getGradingSchemeTemplateRecord: (params: any) =>
    getRequest(`admin/grading/scheme/template/records`, params),
  getViewGradingSchemeData: (course_id: number) =>
    getRequest(`admin/academic/gradebook/view/grading_scheme`, { course_id }),
  deleteGradingSchemeTemplateList: (record_ids: string) =>
    postRequest(`admin/grading/scheme/template/records/delete`, { record_ids }),
  updatePercentageBoundary: (id: number, percentage_boundary: number) =>
    postRequest(`admin/grading/scheme/template/record/update/${id}`, {
      percentage_boundary,
    }),
  createGradingSchemeTemplateRecord: (body: any) =>
    postRequest(`admin/grading/scheme/template/records`, body),
  deleteLetterGradeById: (id: number) =>
    deleteRequest(`admin/letter/grade/${id}`),
  createPlanOfStudyCourse: (body: PlanOfStudyCourseDTO) =>
    postRequest(`admin/academic/plan_of_study_courses`, body),
  updatePlanOfStudyCourse: (id: number, body: PlanOfStudyCourseDTO) =>
    putRequest(`admin/academic/plan_of_study_courses/${id}`, body),
  getPlanOfStudyCourses: (params: any) =>
    getRequest(`admin/academic/plan_of_study_courses`, params),
  getPlanOfStudyCourseById: (id: number) =>
    getRequest(`admin/academic/plan_of_study_courses/${id}`),
  deletePlanOfStudyCourse: (id: number) =>
    deleteRequest(`admin/academic/plan_of_study_courses/${id}`),
  createPoolCourseHeader: (body: CoursePoolHeaderDTO) =>
    postRequest(`admin/academic/pool_header`, body),
  updatePoolCourseHeader: (id: number, body: CoursePoolHeaderDTO) =>
    putRequest(`admin/academic/pool_header/${id}`, body),
  getPoolCourseHeaders: (params: any) =>
    getRequest(`admin/academic/pool_header`, params),
  getPoolCourseHeaderById: (id: number) =>
    getRequest(`admin/academic/pool_header/${id}`),
  deletePoolCourseHeader: (id: number) =>
    deleteRequest(`admin/academic/pool_header/${id}`),
  createPoolCourseBody: (body: CoursePoolBodyDTO) =>
    postRequest(`admin/academic/pool_body`, body),
  updatePoolCourseBody: (id: number, body: CoursePoolBodyDTO) =>
    putRequest(`admin/academic/pool_body/${id}`, body),
  getPoolCourseBodies: (params: any) =>
    postRequest(`admin/academic/pool_body_listing`, params),
  getPoolCourseBodyById: (id: number) =>
    getRequest(`admin/academic/pool_body/${id}`),
  deletePoolCourseBody: (id: number) =>
    deleteRequest(`admin/academic/pool_body/${id}`),
  createCourseType: (body: CourseTypeDTO) =>
    postRequest(`admin/academic/course_type`, body),
  updateCourseType: (id: number, body: CourseTypeDTO) =>
    putRequest(`admin/academic/course_type/${id}`, body),
  getCourseTypes: (params: any) =>
    getRequest(`admin/academic/course_type`, params),
  getCourseTypeById: (id: number) =>
    getRequest(`admin/academic/course_type/${id}`),
  deleteCourseType: (id: number) =>
    deleteRequest(`admin/academic/course_type/${id}`),
  createTeachersTitle: (body: CourseTypeDTO) =>
    postRequest(`admin/teachers/titles`, body),
  updateTeachersTitle: (id: number, body: CourseTypeDTO) =>
    putRequest(`admin/teachers/titles/${id}`, body),
  getTeachersTitles: (params: any) =>
    getRequest(`admin/teachers/titles`, params),
  getTeachersTitleById: (id: number) =>
    getRequest(`admin/teachers/titles/${id}`),
  deleteTeachersTitle: (id: number) =>
    deleteRequest(`admin/teachers/titles/${id}`),
  createTemplateCourse: (body: TemplateCourseDTO) =>
    postRequest(`admin/template/courses`, body),
  updateTemplateCourse: (id: number, body: TemplateCourseDTO) =>
    putRequest(`admin/template/courses/${id}`, body),
  getTemplateCourses: (params: any) =>
    getRequest(`admin/template/courses`, params),
  getTemplateCourseById: (id: number) =>
    getRequest(`admin/template/courses/${id}`),
  deleteTemplateCourse: (id: number) =>
    deleteRequest(`admin/template/courses/${id}`),
  createExamIncharge: (body: any) =>
    postRequest(`admin/academic/exam_incharge`, body),
  updateExamIncharge: (id: number, body: any) =>
    putRequest(`admin/academic/exam_incharge/${id}`, body),
  getManageExamIncharge: (params: any) =>
    getRequest(`admin/academic/exam_programs`, params),
  getExamIncharges: (
    params: any // update on behalf of sir asif instructions
  ) => getRequest(`admin/academic/exam_incharge`, params),
  getExamInchargeById: (id: number) =>
    getRequest(`admin/academic/exam_incharge/${id}`),
  deleteExamIncharge: (id: number) =>
    deleteRequest(`admin/academic/exam_incharge/${id}`),
  createGradeTemplate: (body: GradeTemplateDTO) =>
    postRequest(`admin/grading/scheme/template`, body),
  updateGradeTemplate: (id: number, body: GradeTemplateDTO) =>
    putRequest(`admin/grading/scheme/template/${id}`, body),

  getExamInchargeProgrames: (queryParams: any) =>
    getRequest(`admin/academic/exam_incharge_programs`, queryParams),
  getExamInchargeSection: (queryParams: any) =>
    getRequest(`admin/academic/exam_incharge_sections`, queryParams),

  getGradeTemplates: (params: any) =>
    getRequest(`admin/grading/scheme/template`, params),
  getGradeTemplateById: (id: number) =>
    getRequest(`admin/grading/scheme/template/${id}`),
  deleteGradeTemplate: (id: number) =>
    deleteRequest(`admin/grading/scheme/template/${id}`),
  createPreReqCourse: (params: any) =>
    postRequest(`admin/template_courses/add_pre_req_courses`, params),
  addCoReqCourseToTemplateCourse: (body: CoReqDTO) =>
    postRequest(`admin/template_courses/add_co_req_courses`, body),
  addPreReqCourseToTemplateCourse: (body: PreReqDTO) =>
    postRequest(`admin/template_courses/add_pre_req_courses`, body),
  addEquivalentCoursesToTemplateCourse: (body: EquivalentCoursesDTO) =>
    postRequest(`admin/template_courses/add_equivalent_courses`, body),
  GetCoRequsiteCourses: (id: number, params: any) =>
    getRequest(`admin/template_courses/get_co_req_courses/${id}`, params),
  getEquvalentCourses: (id: number, params: any) =>
    getRequest(`admin/template_courses/get_equivalent_courses/${id}`, params),
  deleteCoRequsiteListItemById: (id: number) =>
    getRequest(`admin/template_courses/delete_co_req_courses/${id}`),
  deletePreRequsiteListItemById: (id: number) =>
    getRequest(`admin/template_courses/delete_pre_req_courses/${id}`),
  deleteEqvaleentCourseItemByID: (id: number) =>
    getRequest(`admin/template_courses/delete_equivalent_courses/${id}`),
  getPreReqList: (id: any) =>
    getRequest(`admin/template_courses/get_pre_req_courses/${id}`),
  getEquivalentList: (params: any) =>
    getRequest(`https://dummyjson.com/todos`, params), // get Request for pre getEquivalentList course list
  createAcademicStatus: (body: AcademicStatusDTO) =>
    postRequest(`admin/academic/status`, body),
  updateAcademicStatus: (id: number, body: AcademicStatusDTO) =>
    putRequest(`admin/academic/status/${id}`, body),
  getAcademicStatuses: (params: any) =>
    getRequest(`admin/academic/status`, params),
  getAcademicStatusById: (id: number) =>
    getRequest(`admin/academic/status/${id}`),
  deleteAcademicStatus: (id: number) =>
    deleteRequest(`admin/academic/status/${id}`),
  createStudentStatus: (body: StudentStatusDTO) =>
    postRequest(`admin/student/status`, body),
  updateStudentStatus: (id: number, body: StudentStatusDTO) =>
    putRequest(`admin/student/status/${id}`, body),
  getStudentStatuses: (params: any) =>
    getRequest(`admin/student/status`, params),
  getStudentStatusById: (id: number) =>
    getRequest(`admin/student/status/${id}`),
  deleteStudentStatus: (id: number) =>
    deleteRequest(`aadmin/student/status/${id}`),
  createAcademicSection: (body: AcademicSectionDTO) =>
    postRequest(`admin/academic/sections`, body),
  updateAcademicSection: (id: number, body: AcademicSectionDTO) =>
    putRequest(`admin/academic/sections/${id}`, body),
  getAcademicSections: (params: any) =>
    getRequest(`admin/academic/sections`, params),
  getAcademicSectionById: (id: number) =>
    getRequest(`admin/academic/sections/${id}`),
  deleteAcademicSection: (id: number) =>
    deleteRequest(`admin/academic/sections/${id}`),
  createStudentSpecialization: (body: StudentSpecializationDTO) =>
    postRequest(`admin/academic/specializations`, body),
  updateStudentSpecialization: (id: number, body: StudentSpecializationDTO) =>
    putRequest(`admin/academic/specializations/${id}`, body),
  getStudentSpecializations: (params: any) =>
    getRequest(`admin/academic/specializations`, params),
  getStudentSpecializationById: (id: number) =>
    getRequest(`admin/academic/specializations/${id}`),
  deleteStudentSpecialization: (id: number) =>
    deleteRequest(`admin/academic/specializations/${id}`),
  createAcademicSession: (body: AcademicSessionDTO) =>
    postRequest(`admin/academic/academic_sessions`, body),
  updateAcademicSession: (id: number, body: AcademicSessionDTO) =>
    putRequest(`admin/academic/academic_sessions/${id}`, body),
  getAcademicSessions: (params: any) =>
    getRequest(`admin/academic/academic_sessions`, params),
  getAcademicSessionById: (id: number) =>
    getRequest(`admin/academic/academic_sessions/${id}`),
  deleteAcademicSession: (id: number) =>
    deleteRequest(`admin/academic/academic_sessions/${id}`),
  createPlanOfStudy: (body: PlanOfStudyDTO) =>
    postRequest(`admin/academic/plan_of_study`, body),
  updatePlanOfStudy: (id: number, body: PlanOfStudyDTO) =>
    putRequest(`admin/academic/plan_of_study/${id}`, body),
  getPlanOfStudies: (params: any) =>
    getRequest(`admin/academic/plan_of_study`, params),
  getPlanOfStudyById: (id: number) =>
    getRequest(`admin/academic/plan_of_study/${id}`),
  deletePlanOfStudy: (id: number) =>
    deleteRequest(`admin/academic/plan_of_study/${id}`),
  getSessionAllocatedCourses: (params: any) =>
    getRequest(`admin/allocation/courses`, params),
  deleteSessionAllocatedCourses: (id: number) =>
    deleteRequest(`admin/allocation/courses/${id}`),
  getTeachers: (params: any) => getRequest(`admin/teachers`, params),
  addSessionCourse: (body: any) =>
    postRequest(`admin/academic/add_session_courses`, body),
  addCourseTeacher: (body: any) =>
    postRequest(`admin/academic/add_course_teacher`, body),
  addCourseRoom: (body: any) =>
    postRequest(`admin/academic/add_course_room`, body),
  addCourseSection: (body: any) =>
    postRequest(`admin/academic/add_course_section`, body),
  getRooms: (params: any) => getRequest(`admin/rooms`, params),
  getAcademicStudents: (params: any) => getRequest(`admin/students`, params),
  getStudentsForAddToPOS: (params: any) =>
    getRequest(`admin/academic/plan_of_study_students`, params),
  uploadGradeBook: (body: FormData) =>
    postRequest(`admin/academic/gradebooks`, body),
  getGradeBookList: (course_id: number) =>
    getRequest(`admin/academic/gradebook/list`, { course_id }),
  updateGradeBookSubmittedStatus: (gradebook_id: number) =>
    postRequest(`admin/academic/gradebook/submit_gradebook`, {
      gradebook_id,
    }),
  updateGradeBookUnSubmittedStatus: (gradebook_id: number) =>
    postRequest(`/admin/academic/gradebook/unsubmit_gradebook`, {
      gradebook_id,
    }),
  updateGradeBookResultVerifyStatus: (gradebook_id: number) =>
    postRequest(`/admin/academic/gradebook/verify_gradebook`, {
      gradebook_id,
    }),
  updateGradeBookResultUnVerifyStatus: (gradebook_id: number) =>
    postRequest(`/admin/academic/gradebook/unverify_gradebook`, {
      gradebook_id,
    }),
  updateGradeBookResultApproveStatus: (body: any) =>
    postRequest(`/admin/academic/gradebook/approved_gradebook`, {
      body,
    }),
  updateGradeBookResultUnApproveStatus: (body: any) =>
    postRequest(`/admin/academic/gradebook/unapproved_gradebook`, {
      body,
    }),
  downloadBlankGradeBook: (params: any) =>
    getRequest(`admin/academic/gradebook/blank_gradebook`, params),
  downloadGradeBookPDF: (course_id: number) =>
    getRequest(`admin/academic/gradebook/download_award_list/${course_id}`),
  downloadStudentsCard: (student_ids: any) =>
    getRequest(`admin/students/download/card`, { student_ids }),
  downloadGradesBookCSVFile: (course_id: number) =>
    getRequest(`admin/academic/gradebook/download/csv/${course_id}`),
  getManageEnrollmentsData: (course_id: number) =>
    getRequest(`admin/academic/enrolled-students`, { course_id }),
  deleteManageEnrollementsMultipleData: (body: any) =>
    postRequest(`admin/students/enrollment/delete`, body),
  handleAddEnrollmentsInBulk: (body: any) =>
    postRequest(`admin/students/enrollment`, body),
  enrollCourseTeacher: (body: EnrollCourseTeacherDTO) =>
    postRequest(`admin/academic/teacher_pool_courses`, body),
  getProgramSpecialization: (params: any) =>
    getRequest(`admin/academic/specializations_session`, params),
  createProgramSpecialization: (body: any) =>
    postRequest(`admin/academic/specializations_session`, body),
  updateProgramSpecialization: (id: number, body: ProSpececilizationDTO) =>
    putRequest(`admin/academic/specializations_session/${id}`, body),
  deleteProgramSpecialization: (id: number) =>
    deleteRequest(`admin/academic/specializations_session/${id}`),
  getProgramSpecializationById: (id: number) =>
    getRequest(`admin/academic/specializations_session/${id}`),
  getResultTemplateHeader: (params: any) =>
    getRequest(`admin/academic/result_setting_template_header`, params),
  deleteResultTemplateHeader: (id: number) =>
    deleteRequest(`admin/academic/result_setting_template_header/${id}`),
  createResultTemplateHeader: (body: any) =>
    postRequest(`/admin/academic/result_setting_template_header`, body),
  updateResultTemplateHeader: (id: number, body: any) =>
    putRequest(`admin/academic/result_setting_template_header/${id}`, body),
  getResultTemplateHeaderById: (id: number) =>
    getRequest(`admin/academic/result_setting_template_header/${id}`),
  getResultTemplate: (params: any) =>
    getRequest(`admin/academic/result_setting_template`, params),
  deleteResultTemplate: (id: number) =>
    deleteRequest(`admin/academic/result_setting_template_header/${id}`),
  createResultTemplate: (body: any) =>
    postRequest(`admin/academic/result_setting_template`, body),
  updateResultTemplate: (id: number, body: any) =>
    putRequest(`admin/academic/result_setting_template/${id}`, body),
  getResultTemplateById: (id: number) =>
    getRequest(`admin/academic/result_setting_template/${id}`),
  getFeeTypesForAcadSessions: () => getRequest(`admin/academic/fee_type`),
  createFeeTypes: (body: any) => postRequest(`admin/academic/fee_type`, body),
  getFeeTypes: (params: any) => getRequest(`admin/academic/fee_type`, params),
  deleteFeeTypes: (id: number) =>
    deleteRequest(`admin/academic/fee_type/${id}`),
  updateFeeTypes: (id: number, body: any) =>
    putRequest(`admin/academic/fee_type/${id}`, body),
  getFeeTypesById: (id: number) => getRequest(`admin/academic/fee_type/${id}`),
  createPlanOfStudyStudent: (body: PlanOfStudyStudentDTO) =>
    postRequest(`admin/academic/plan_of_study_students`, body),
  getPlanOfStudyStudents: (params: any) =>
    getRequest(`admin/academic/plan_of_study_students`, params),
  getPlanOfStudyStudentById: (id: number) =>
    getRequest(`admin/academic/plan_of_study_students/${id}`),
  deletePlanOfStudyStudent: (id: number) =>
    deleteRequest(`admin/academic/plan_of_study_students/${id}`),
  getStudentsSpecificData: (params: any) =>
    getRequest(`admin/students/list/specific_data`, params),
  deleteAcademicSessionSection: (id: number) =>
    deleteRequest(`admin/academic/delete_course_section/${id}`),
  deleteAcademicSessionTeacher: (id: number) =>
    deleteRequest(`admin/academic/delete_course_teacher/${id}`),
  enrollStudentInSession: (body: SessionEnrollmentDTO<string>) =>
    postRequest(`admin/students/enrollment`, body),
  updateSessionCourseClass: (body: SessionCourseClassDTO, courseId: number) =>
    putRequest(`admin/allocation/courses/update-class/${courseId}`, body),
  getVirtualCourses: (params: any) =>
    getRequest(`admin/academic/virtual_courses`, params),
  getEnrollmentsLists: (params: any) =>
    getRequest(`admin/students/enrollments/list`, params),
  downloadEnrollmentCSV: (id: number) =>
    getRequest(`admin/academic/enrollments/download/csv/${id}`),
  getStudnetsListForCardManagement: (params: any) =>
    getRequest(`admin/students/cards/list`, params),
  createVirtualCourse: (body: any) =>
    postRequest(`admin/academic/virtual_courses`, body),
  deleteVirtualCourse: (id: number) =>
    deleteRequest(`admin/academic/virtual_courses/${id}`),
  studentsBulkEnrollmentByFile: (body: FormData) =>
    postRequest("admin/students/enrollment/bulk", body),
  getAcademicFeePlans: (params: any) =>
    getRequest(`admin/academic/fee_plan`, params),
  createAcademicFeePlan: (body: FeePlanDTO) =>
    postRequest(`admin/academic/fee_plan`, body),
  updateAcademicFeePlan: (id: number, body: FeePlanDTO) =>
    putRequest(`admin/academic/fee_plan/${id}`, body),
  getAcademicFeePlanById: (id: number) =>
    getRequest(`admin/academic/fee_plan/${id}`),
  deleteAcademicFeePlan: (id: number) =>
    deleteRequest(`admin/academic/fee_plan/${id}`),
  getLinkedVoucherTemplates: (params: any) =>
    getRequest(
      `admin/academic/fee_plan/details/${params?.fee_plan_id}`,
      params
    ),
  getAllFeeVouchersList: (params: any) =>
    postRequest(`admin/academic/semester/bulk_fee_voucher`, params),
  linkVoucherTemplateToFeePlan: (body: LinkVoucherTemplateDTO) =>
    postRequest(`admin/academic/fee_plan/link_feetype_store`, body),
  getVerifiedGradeBooks: (params: any) =>
    getRequest(`admin/academic/gradebook/verified/list`, params),
  getAwardList: (params: any) =>
    getRequest(`admin/academic/gradebook/gradebook_records`, params),
  updateManageProgrameFee: (body: any) =>
    postRequest(`admin/academic/students_fee_plan/update`, body),

  getConsolidatedResultsList: (params: any) =>
    getRequest(`admin/academic/consolidated-result/list`, params),
  getConsolidatedCoursesSectionList: (params: any) =>
    getRequest(`admin/academic/consolidated-result/sections/list`, params),
  getSubmittedCoursesList: (params: any) =>
    getRequest(
      `admin/academic/consolidated-result/submitted-courses/list`,
      params
    ),
  downloadConsolidatedResult: (section_id: number) =>
    getRequest(`admin/academic/consolidated-result/download`, { section_id }),
  updateStudentAvatar: (body: uploadProfileImageDTO, stduentID: number) =>
    postRequest(`admin/user/update/profile_image/${stduentID}`, body),

  getPaidVouchersList: (params: any) =>
    getRequest(`/admin/finance/vouchers/paid/list`, params),

  createExamTypes: (body: ExamTypeDTO) =>
    postRequest(`admin/academic/exam_types`, body),
  updateExamTypes: (id: number, body: ExamTypeDTO) =>
    putRequest(`admin/academic/exam_types/${id}`, body),
  getExamTypeByID: (id: number) =>
    getRequest(`admin/academic/exam_types/${id}`),
  getExamType: (params: any) =>
    getRequest(`/admin/academic/exam_types`, params),
  deleteExamTypeByID: (id: number) =>
    deleteRequest(`/admin/academic/exam_types/${id}`),

  createExams: (body: ExamTypeDTO) =>
    postRequest(`/admin/academic/exams`, body),
  updateExamsByID: (id: number, body: ExamTypeDTO) =>
    putRequest(`/admin/academic/exams/${id}`, body),
  getExamsByID: (id: number) => getRequest(`/admin/academic/exams/${id}`),
  getExams: (params: any) => getRequest(`/admin/academic/exams`, params),
  deleteExamByID: (id: number) => deleteRequest(`/admin/academic/exams/${id}`),
};
