import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  AddExamInchargeMain,
  Container,
  ContentWrapper,
  AddExamInchargeSection,
  Form,
} from "./style";
import {
  CloseMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  SmallUploadSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { useForm } from "react-hook-form";
import { ExamInchargeDTO } from "utils/helpers/models/academics/exam-incharge.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import MultiselectField from "components/particles/forms/multiselect-field";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import useHRManagement from "containers/private/hr-management/useHook";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";
import useAcademics from "containers/private/academics/useHooks";

interface AddExamInchargeProps {
  setOpen: any;
  id?: number;
  program_id: number | string;
}

const AddExamIncharge: FC<AddExamInchargeProps> = ({ setOpen, program_id }) => {
  const { getTeachersLisiting } = useHRManagement();
  const { getSpecificOrgStructures } = useOrganization();
  const { createExamIncharge } = useAcademics();

  const [teachers, setTeachers] = useState<TeacherDTO[]>([]);
  const [campus, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<ExamInchargeDTO>(
    new ExamInchargeDTO()
  );
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const columns: string[] = [
    "Username",
    "Name",
    "CNIC",
    "Designation",
    "Department",
    "Campus",
  ];
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<ExamInchargeDTO>();

  const resetForm = () => {
    for (let key in formData) {
      setValue(key as keyof ExamInchargeDTO, "");
    }
    setFormData({
      ...new ExamInchargeDTO(),
    });
  };

  const onSelect = (value: number[], name: keyof ExamInchargeDTO) => {
    (formData as any)[name] = value;
    setValue(name as keyof ExamInchargeDTO, formData[name]);
    trigger([name]);
    setFormData({ ...formData });
  };

  const handleChange = (event: any): void => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (data: any, addMore: boolean) => {
    const body: ExamInchargeDTO<string> = {
      ...formData,
      program_id: program_id,
      teacher_ids: Array.isArray(formData.teacher_ids)
        ? formData.teacher_ids.join(", ")
        : "",
    };
    createExamIncharge(body, setOpen, addMore, resetForm);
  };

  useEffect(() => {
    register("teacher_ids", { required: true });
    getSpecificOrgStructures(setCampuses, {
      per_page: "All",
      type: OrgStructureTypes.campus,
    });
    getTeachersLisiting(setTeachers);
  }, []);

  return (
    <AddExamInchargeMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add Exam In charge</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>

          <Form>
            <div className="common-fields">
              <div className="input-field">
                <label>Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("campus_id", { required: true })}
                      value={formData.campus_id}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      {campus?.map((item: any, index: number) => (
                        <option key={index} value={item?.id}>
                          {item?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.campus_id} />
                </div>
              </div>

              <MultiselectField
                options={teachers}
                onSelect={(item) => onSelect(item, "teacher_ids")}
                value={formData.teacher_ids}
                property="user_name"
                searchable
                label="Teachers"
                helperText="-- Select --"
                error={errors?.teacher_ids}
              />

              <div className="input-field">
                <label htmlFor="campus-select">Semesters Allowed</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("semester_allowed", { required: true })}
                      value={formData?.semester_allowed}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(
                        (item: any, index: number) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.semester_allowed} />
                </div>
              </div>

              <div className="radio-field">
                <label htmlFor="no">Can Verify?</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="radio"
                      id="can_verify_yes"
                      {...register("can_verify", { required: true })}
                      value={1}
                      onChange={handleChange}
                      checked={formData?.can_verify == 1}
                    />
                    <label htmlFor="can_verify_yes">Yes</label>
                  </div>

                  <div className="field">
                    <input
                      type="radio"
                      id="can_verify_no"
                      {...register("can_verify", { required: true })}
                      value={0}
                      onChange={handleChange}
                      checked={formData.can_verify == 0}
                    />
                    <label htmlFor="can_verify_no">No</label>
                  </div>
                </div>
                <FormErrorMessage error={errors.can_verify} />
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button
                  className="lg-rounded-btn red"
                  type="button"
                  onClick={resetForm}
                >
                  Reset
                </button>

                <button
                  className="lg-rounded-btn"
                  onClick={handleSubmit((data) => onSubmit(data, true))}
                >
                  Save & Add More
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={handleSubmit((data) => onSubmit(data, false))}
                >
                  Save & Exit
                </button>
              </div>
            </div>
          </Form>
        </ContentWrapper>
      </Container>
    </AddExamInchargeMain>
  );
};

export default AddExamIncharge;
function getSpecificOrgStructures(
  setCampuses: Dispatch<SetStateAction<OrgStructureDTO[]>>,
  arg1: { per_page: string; type: OrgStructureTypes }
) {
  throw new Error("Function not implemented.");
}
