import { FC, Fragment, useState, useEffect } from "react";
import {
  CallTableSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  TableBlackRightArrowSvg,
  TableDownloadPrimarySvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  LegalCasesListingMain,
  LegalCasesListingSection,
  LegalCasesListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAdmissions from "../../../admissions/useHooks";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { LegalAdviserDTO } from "utils/helpers/models/legal-matters/legal-adviser.dto";
import useLegalMatters from "../../useHook";

interface AdmissionStudentListingProps {}

const LegalAdvisorListing: FC<AdmissionStudentListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Legal Advisors ",
      path: siteRoutes.legalAdvisorListing,
    },
  ];
  const columns: string[] = [
    "Name",
    "CNIC",
    "Type",
    "Category",
    "License No.",
    "Mobile",
    "Email",
    "Adrress",
    "Is Active",

    "Action",
  ];

  const { hasAccess } = useUtils();

  const navigate = useNavigate();

  const { handleSearchChange, handleTableSearch, createQuery } = useUtils();
  const { getLegalAdviser, deleteLegalAdviserByID } = useLegalMatters();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<LegalAdviserDTO[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const handleEdit = (id: number) => {
    const params = createQuery({ id });
    navigate(siteRoutes.createLegalAdvisor + params);
  };
  const goToCreateLegalAdvisors = () => {
    navigate(siteRoutes.createLegalAdvisor);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deleteLegalAdviserByID(id, setData, queryParams, setPagination);
    }
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllLegalAdviserList(page + 1, search, per_page);
  };

  const getAllLegalAdviserList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getLegalAdviser(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAllLegalAdviserList(pagination.page, search, pagination.per_page);
  }, []);
  return (
    <LegalCasesListingMain>
      <LegalCasesListingTop>
        <div className="left">
          <span className="page-heading">Legal Advisors</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            {hasAccess(sitePermissions.createLegalAdvisor) && (
              <button
                className="lg-rounded-btn"
                onClick={goToCreateLegalAdvisors}
              >
                + Add New
              </button>
            )}
          </div>
        </div>
      </LegalCasesListingTop>

      <LegalCasesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllLegalAdviserList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllLegalAdviserList
                )
              }
              placeholder="Search"
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-100">{item?.name ?? "-"}</div>{" "}
                    </td>

                    <td>
                      <div className="mw-100">{item?.cnic ?? "-"}</div>{" "}
                    </td>
                    <td>{item?.type_id ?? "-"} </td>
                    <td>{item?.category ?? "-"} </td>
                    <td>{item?.license_no ?? "-"} </td>
                    <td>{item?.mobile ?? "-"} </td>
                    <td>
                      <div className="mw-150">{item?.email ?? "-"} </div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.address ?? "-"} </div>
                    </td>

                    <td>
                      <span
                        className={`status-tile ${
                          item?.is_active === 1 ? "green" : "red"
                        }`}
                      >
                        {item?.is_active === 1 ? "Yes" : "No"}
                      </span>
                    </td>

                    <td>
                      <div className="table-action-icons">
                        <div
                          className="action-icons"
                          onClick={() => handleEdit(item?.id)}
                        >
                          <EditTableSvg />
                        </div>
                        <div
                          className="action-icons"
                          onClick={() => handleDelete(item?.id)}
                        >
                          <DeleteTableSvg />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </LegalCasesListingSection>
    </LegalCasesListingMain>
  );
};

export default LegalAdvisorListing;
