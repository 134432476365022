import styled from "styled-components";

export const GradeBooksListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const GradeBooksListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  .grade-book-title {
    font-size: 1rem;
    font-weight: bold;
    span {
      font-weight: 500;
      color: var(--light-medium-crimson);
    }
  }

  table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }
    .mw-100 {
      min-width: 100px;
    }
  }

  .menu-dropdown {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    align-items: end;
    gap: 1rem;

    .content-radius-shadow {
      padding: 1rem 0;
      width: 300px;
      border-radius: 0.5rem;
      box-shadow: 5px 4px 14px 10px #0000001a;
    }
    .action-button {
      display: flex;
      align-items: center;
      gap: 0.5em;
      padding: 0.3rem 1rem;
      color: var(--black-text);

      .action-icon {
        display: flex;
        align-items: start;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const GradeBooksListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;

    .create-org-btn {
      &.upload {
        margin-top: 0.8em;
      }
      label {
        padding: 0.9em 1.5em;
      }
    }
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
  .grade-book-note {
    color: #ff4b4a;
    font-size: 16px;
  }
`;
