import { FC, useEffect, useState } from "react";
import {
  CreateAdvisorRatesForm,
  CreateAdvisorRatesMain,
  CreateAdvisorRatesTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AdviserRatesDTO } from "utils/helpers/models/legal-matters/adviser-rates.dto";
import { useForm } from "react-hook-form";
import useLegalMatters from "../../useHook";
import FormErrorMessage from "components/particles/forms/form-error-message";

const CreateAdvisorRates: FC = () => {
  const { getQueryParams, getYears } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Advisors Rates /",
      path: siteRoutes.advisorRatesListing,
    },
    {
      title: `${params?.id ? "Update" : "Add"} Advisors Rates`,
      path: siteRoutes.createAdvisorRates,
    },
  ];
  const { getAdviserRatesByID, createAdviserRates, updateAdviserRatesByID } =
    useLegalMatters();
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<AdviserRatesDTO>();

  const [formData, setFormData] = useState<AdviserRatesDTO>(
    new AdviserRatesDTO()
  );

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new AdviserRatesDTO()[
        key as keyof AdviserRatesDTO
      ];
      setValue(key as keyof AdviserRatesDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: AdviserRatesDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateAdviserRatesByID(params?.id, formData);
    } else {
      createAdviserRates(formData, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getAdviserRatesByID(params?.id, formData, setValue, setFormData);
    }
  }, []);
  return (
    <CreateAdvisorRatesMain>
      <CreateAdvisorRatesTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Advisors Rates
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateAdvisorRatesTop>

      <CreateAdvisorRatesForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="">Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Rate</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("rate", { required: true })}
                  value={formData.rate}
                  onChange={handleChange}
                  placeholder="1000K"
                />
              </div>
              <FormErrorMessage error={errors.rate} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Year</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("year", { required: true })}
                  value={formData.year}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {getYears(2000)?.map((year, index) => {
                    return (
                      <option value={year} key={index}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.year} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Category</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("legal_advisor_category", { required: true })}
                  value={formData.legal_advisor_category}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <FormErrorMessage error={errors.legal_advisor_category} />
            </div>
          </div>

          <div className="radio-field">
            <label htmlFor="no">Is Active</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="status_yes"
                  {...register("is_active", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_active == 1}
                />
                <label htmlFor="status_yes">Yes</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="status_no"
                  {...register("is_active", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_active == 0}
                />
                <label htmlFor="status_no">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_active} />
          </div>
          <div className="input-field">
            <label htmlFor="">Descryption</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("description", { required: false })}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="descryption"
                />
              </div>
              <FormErrorMessage error={errors.description} />
            </div>
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="reset"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateAdvisorRatesForm>
    </CreateAdvisorRatesMain>
  );
};

export default CreateAdvisorRates;
