import { FC, Fragment, useEffect, useState } from "react";
import {
  ConsolidatedResultsListingSection,
  ConsolidatedResultsListingTop,
  ConsolidatedResultsListingMain,
  FilterSection,
  FilterHeader,
  Filters,
} from "./style";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useUtils from "hooks/useUtils";
import useAcademics from "../../useHooks";
import { ConsolidatedResultDTO } from "utils/helpers/models/academics/consolidated-result.dto";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import useOrganization from "containers/private/organization/useHooks";

interface ConsolidatedResultsListingProps {}

const ConsolidatedResultsListing: FC<
  ConsolidatedResultsListingProps
> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics/ ", path: siteRoutes.academicSessionListing },
    {
      title: "Consolidated Results",
      path: siteRoutes.consolidatedResultsListing,
    },
  ];

  const navigate = useNavigate();
  const columns: string[] = [
    "Program",
    "Campus",
    "Submitted Courses",
    "Verified Courses",
    "Action",
  ];
  const { createQuery } = useUtils();
  const { hasAccess } = useStore();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getConsolidatedResultsList, getAcademicSessions } = useAcademics();
  const { getPrograms } = useOrganization();
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [data, setData] = useState<ConsolidatedResultDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const [academicSessions, setAcademicSessions] = useState<
    AcademicSessionDTO[]
  >([]);
  const [filters, setFilters] = useState<any>({
    academic_session_id: "",
    program_id: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllConsolidatedResultList(pagination.page, search, pagination.per_page);
  };

  const goToSectionCourses = (program_id: number, program_title: string) => {
    const params = createQuery({
      program_id,
      program_title: encodeURIComponent(program_title),
    });
    navigate(siteRoutes.sectionCoursesListing + params);
  };
  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };
  const onApplyFilters = () => {
    getAllConsolidatedResultList(pagination.page, search, pagination.per_page);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllConsolidatedResultList(page + 1, search, per_page);
  };
  const getAllConsolidatedResultList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
      ...Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      ),
    };
    getConsolidatedResultsList(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getPrograms(setPrograms);
    getAcademicSessions(setAcademicSessions);
    getAllConsolidatedResultList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <ConsolidatedResultsListingMain>
      <ConsolidatedResultsListingTop>
        <div className="left">
          <span className="page-heading">Consolidated Results</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </ConsolidatedResultsListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {showFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Select Academic Section</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="academic_session_id"
                      value={filters.academic_session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {academicSessions?.map((session, index) => {
                        return (
                          <option value={session.id} key={index}>
                            {session?.type ?? "-"}-{session?.year ?? "-"}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="program_id"
                      value={filters.program_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {programs?.map((level, index) => {
                        return (
                          <option value={level.id} key={index}>
                            {level.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <ConsolidatedResultsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllConsolidatedResultList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllConsolidatedResultList
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td>
                        <div className="mw-150">
                          {item?.program_title ?? "-"}
                        </div>
                      </td>
                      <td>{item?.campus_title ?? "-"}</td>
                      <td>{item?.submitted_courses_count ?? "-"}</td>
                      <td>{item?.verified_courses_count ?? "-"}</td>
                      <td>
                        <div className="table-action-buttons">
                          {hasAccess(sitePermissions.sectionCoursesListing) && (
                            <div className="table-action-button">
                              <button
                                onClick={() =>
                                  goToSectionCourses(
                                    item?.program_id,
                                    item.program_title
                                  )
                                }
                              >
                                View Sections
                              </button>
                            </div>
                          )}
                          {hasAccess(
                            sitePermissions.academicsConsolidatedResultsViewPostition
                          ) && (
                            <div className="table-action-button">
                              <button className="black">
                                {/* onClick={goToViewPostions} */}
                                View Positions
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </ConsolidatedResultsListingSection>
    </ConsolidatedResultsListingMain>
  );
};

export default ConsolidatedResultsListing;
