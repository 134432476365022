import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  LibraryBooksListingMain,
  LibraryBooksListingSection,
  LibraryBooksListingTop,
  FilterSection,
  FilterHeader,
  Filters,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useTestingServices from "containers/private/testing-services/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";
import { TestingCenterRoomDTO } from "utils/helpers/models/testing-service/testing-center-room.dto";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";

const GenerateCourseChallan: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard / ", path: "" },
    {
      title: "Generate Course(s) Repeat Challan",
      path: siteRoutes.generateCourseChallan,
    },
  ];
  const columns: string[] = [
    "Course Code",
    "Course Name",
    "Credit Hour",
    "Amount.",
    "Status",
  
  ];
  const navigate = useNavigate();
  const {
    getTestingCenterRooms,
    deleteTestingCenterRoom,
    getTestingCenterById,
    updateTestingCenter,
  } = useTestingServices();
  const [data, setData] = useState<TestingCenterRoomDTO[]>([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [center, setCenter] = useState<TestingCenterDTO>(
    new TestingCenterDTO()
  );
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();

  useEffect(() => {
    // getAllTestingCenterRooms(pagination.page, search);
    // getTestingCenterById(params?.id, center, setCenter);
  }, []);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      // deleteTestingCenterRoom(id, setData, queryParams, setPagination);
    }
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllTestingCenterRooms(page + 1, search);
  };
  const getAllTestingCenterRooms = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    // getTestingCenterRooms(setData, queryParams, setPagination);
  };

  return (
    <LibraryBooksListingMain>
      <LibraryBooksListingTop>
        <div className="left">
          <span className="page-heading">
            Generate Course(s) Repeat Challan
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </LibraryBooksListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Apply For Course Repeat Challan</span>
        
        </FilterHeader>
     
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <div className="field-wrap">
                  <div className="field">
                    <select name="" id="">
                      <option value="">Select Exam Fee Type</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="note">
              <span>Note:</span> Please Fill Course(s) Information Carefully.
              Once You Will Genrate Challan For Selected Courses, It Will Then
              Not Allow You Update.
            </div>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Course 1</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="" id="">
                      <option value="">Select Courses</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Course 2</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="" id="">
                      <option value="">Select Courses</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Course 3</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="" id="">
                      <option value="">Select Courses</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Course 4</label>
                <div className="field-wrap">
                  <div className="field">
                    <select name="" id="">
                      <option value="">Select Courses</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="total">
            Total <span>RS 0</span>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Apply</button>
              </div>
            </div>
          </Filters>
     
      </FilterSection>

      <LibraryBooksListingSection
        isTableOverflowing={false}
        className="content-radius-shadow"
      >
        
          <div className="table-heading">Applied Curse Repeat Application List</div>
         
       
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1, 1, 1].map((room, index) => {
                return (
                  <tr>
                    <td>
                      <div className="mw-150">
                        Transaction & Proceedings Of The American Philogical
                        Association
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">Khayr al-Din al-Zirikll</div>
                    </td>
                    <td>Serat Chair</td>
                    <td>324.213 K234T</td>
                    <td><span className="status-tile">Available</span></td>
                  
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {/* <DataNotFound show={!isLoading && !data.length} /> */}
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </LibraryBooksListingSection>
    </LibraryBooksListingMain>
  );
};

export default GenerateCourseChallan;
