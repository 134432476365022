import { FC, useEffect, useState } from "react";
import {
  CreateFrameworkDocumentTypeForm,
  CreateFrameworkDocumentTypeMain,
  CreateFrameworkDocumentTypeTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { LegalDocsTypeDTO } from "utils/helpers/models/legal-matters/legal-frameworks-document-types.dto";
import useLegalMatters from "../../useHook";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";

const CreateFrameworkDocumentType: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Legal Framework Documents Types /",
      path: siteRoutes.frameworkDocumentTypeList,
    },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Framework Documents Types`,
      path: siteRoutes.createFrameworkDocumentType,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalDocsTypeDTO>();
  const {
    createLegalMatterFrameworkDocumentType,
    updateLegalMatterFrameworkDocumentType,
    getLegalMatterFrameworkDocumentTypeByID,
  } = useLegalMatters();

  const [formData, setFormData] = useState<LegalDocsTypeDTO>(
    new LegalDocsTypeDTO()
  );
  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalDocsTypeDTO()[
        key as keyof LegalDocsTypeDTO
      ];
      setValue(key as keyof LegalDocsTypeDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = () => {
    if (params?.id) {
      updateLegalMatterFrameworkDocumentType(params?.id, formData);
    } else {
      createLegalMatterFrameworkDocumentType(formData, false, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getLegalMatterFrameworkDocumentTypeByID(
        params?.id,
        formData,
        setValue,
        setFormData
      );
    }
  }, []);

  return (
    <CreateFrameworkDocumentTypeMain>
      <CreateFrameworkDocumentTypeTop>
        <div className="heading">
          <span className="page-heading">
            Add Legal Framework Documents Types
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateFrameworkDocumentTypeTop>

      <CreateFrameworkDocumentTypeForm
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="">Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("name", { required: true })}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="doc type"
                />
              </div>
              <FormErrorMessage error={errors.name} />
            </div>
          </div>
          <div className="input-field">
            <label htmlFor="">Organization</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("legal_organizations_id", { required: true })}
                  value={formData.legal_organizations_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">1</option>
                </select>
              </div>
            </div>
            <FormErrorMessage error={errors.legal_organizations_id} />
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button">
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </CreateFrameworkDocumentTypeForm>
    </CreateFrameworkDocumentTypeMain>
  );
};

export default CreateFrameworkDocumentType;
