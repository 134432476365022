import { FC, Fragment, useEffect, useState } from "react";
import {
  RoomsListingMain,
  RoomsListingSection,
  RoomsListingTop,
} from "./style";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import Pagination from "components/particles/table/pagination";
import dummyRoom from "../../../../../assets/images/common/others/avatar-8.png";
import useOrganization from "../../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { AddRoomDto } from "utils/helpers/models/organization/add-rooms.dto";

interface AssetsListingProps {}

const RoomsListing: FC<AssetsListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Infrastructure & Asset Management /", path: "" },
    { title: "Buildings /", path: siteRoutes.buildingListing },
    { title: "Rooms", path: siteRoutes.roomsListing },
  ];
  const columns: string[] = [
    "Image",
    "Title",
    "Number",
    "Type",
    "Length",
    "Width",
    "Floors",
    "Action",
  ];
  const navigate = useNavigate();

  const { getRoomsList, deleteBuildingRoomById } = useOrganization();
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<AddRoomDto[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const goToCreateRooms = () => {
    navigate(siteRoutes.createRooms);
  };

  const handleDeleteRoom = async (id: number) => {
    const result = await confirmationPopup();

    if (result?.isConfirmed) {
      deleteBuildingRoomById(id, setData, pagination, setPagination);
      getRoomsList(setData, "All", setPagination);
    }
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getBuildingRoomsList(page + 1, search, per_page);
  };

  const goToUpdateBuildingRoomById = (id: number) => {
    navigate(`${siteRoutes.createRooms}?id=${id}`);
  };

  const getBuildingRoomsList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getRoomsList(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getBuildingRoomsList(pagination.page, search, pagination.per_page);
  }, [pagination.page, search]);

  return (
    <RoomsListingMain>
      <RoomsListingTop>
        <div className="left">
          <span className="page-heading">Rooms</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createBuilding) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={goToCreateRooms}>
                + Add Rooms
              </button>
            </div>
          )}
        </div>
      </RoomsListingTop>
      <RoomsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadBuildingListPdf) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}
            {hasAccess(sitePermissions.downloadBuildingListExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getBuildingRoomsList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getBuildingRoomsList)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="room-image">
                        <img src={item?.room_image ?? dummyRoom} alt="" />
                      </div>
                    </td>
                    <td>{item?.room_name}</td>
                    <td>{item?.room_number}</td>
                    <td>{item?.room_type?.room_type_title}</td>
                    <td>{item?.length} square feet</td>
                    <td>{item?.width} square feet</td>

                    <td>{item?.floor ?? "-"}</td>
                    <td>
                      <div className="table-action-icons">
                        {hasAccess(sitePermissions.editBuilding) && (
                          <div
                            className="action-icon"
                            onClick={() => goToUpdateBuildingRoomById(item?.id)}
                          >
                            <EditTableSvg />
                          </div>
                        )}

                        {hasAccess(sitePermissions.deleteBuilding) && (
                          <div
                            className="action-icon"
                            onClick={() => handleDeleteRoom(item.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </RoomsListingSection>
    </RoomsListingMain>
  );
};

export default RoomsListing;
