import { FC, Fragment, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { ResultApproveUpdateDTO } from "utils/helpers/models/finance/update-result-status-approve-unapprove.dto";
import useSystemAdministration from "containers/private/system-administration/useHooks";
import useAcademics from "containers/private/academics/useHooks";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useDSSystem from "containers/private/desicion-support-system/useHook";
import { SelectSessionDTO } from "utils/helpers/models/ds-system/reports.dto";
import useUtils from "hooks/useUtils";

interface pageProps {
  setOpen: Function;
  url: any;
}

const SelectSessionModal: FC<pageProps> = ({ setOpen, url }) => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { getAcademicSessions } = useAcademics();

  const { downloadReportsWithUrlAndSessionID } = useDSSystem();

  const [session, setSession] = useState<any>([]);
  const [formData, setFormData] = useState<SelectSessionDTO[]>([]);
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<SelectSessionDTO>();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
    trigger(name);
  };

  const onSubmit = (data: SelectSessionDTO) => {
    downloadReportsWithUrlAndSessionID(data?.session_id, url);
  };

  useEffect(() => {
    getAcademicSessions(setSession);
  }, []);

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Select Session</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label htmlFor="">Select Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("session_id", { required: true })}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      {session.map((item: any, index: number) => (
                        <option key={index} value={item?.id}>
                          {item?.session?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.session_id} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <Fragment>
                  <button className="lg-rounded-btn">Submit</button>
                </Fragment>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default SelectSessionModal;
