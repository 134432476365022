import { FC, Fragment, useState, useEffect } from "react";
import {
  CallTableSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  TableBlackRightArrowSvg,
  TableDownloadPrimarySvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  LegalStatusListingMain,
  LegalStatusListingSection,
  LegalStatusListingTop,
} from "./style";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useLegalMatters from "../../useHook";
import { LegalStatusesDTO } from "utils/helpers/models/legal-matters/legal-statuses.dto";

const LegalStatusListing: FC = ({}) => {
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Legal Statuses ",
      path: siteRoutes.legalStatusList,
    },
  ];
  const columns: string[] = ["Title", "Description", "Main Category", "Action"];

  const navigate = useNavigate();
  const goToCreateLegalStatus = () => {
    navigate(siteRoutes.createLegalStatus);
  };
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const { getLegalStatuses, deleteLegalStatusesByID } = useLegalMatters();
  const { handleSearchChange, handleTableSearch, createQuery } = useUtils();

  const [data, setData] = useState<LegalStatusesDTO[]>([]);
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const goToEditLegalStatuses = (id: number) => {
    const params = createQuery({ id });
    navigate(siteRoutes.createLegalStatus + params);
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllStatusesList(page + 1, search, per_page);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deleteLegalStatusesByID(id, setData, queryParams, setPagination);
    }
  };

  const getAllStatusesList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getLegalStatuses(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAllStatusesList(pagination.page, search, pagination.per_page);
  }, []);
  return (
    <LegalStatusListingMain>
      <LegalStatusListingTop>
        <div className="left">
          <span className="page-heading">Legal Statuses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn" onClick={goToCreateLegalStatus}>
              + Add New
            </button>
          </div>
        </div>
      </LegalStatusListingTop>
      <LegalStatusListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllStatusesList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllStatusesList)
              }
              placeholder="Search"
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Fragment>
                {data?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.title ?? "-"}</td>
                      <td>{item?.description ?? "-"}</td>
                      <td>{item?.parent?.title ?? "-"}</td>
                      <td>
                        <div className="table-action-icons">
                          <div
                            className="action-icons"
                            onClick={() => goToEditLegalStatuses(item?.id)}
                          >
                            <EditTableSvg />
                          </div>
                          <div
                            className="action-icons"
                            onClick={() => handleDelete(item?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </LegalStatusListingSection>
    </LegalStatusListingMain>
  );
};

export default LegalStatusListing;
