import { FC, Fragment, useEffect, useState } from "react";
import {
  AdmissionCampaignListingMain,
  AdmissionCampaignListingSection,
  AdmissionCampaignListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  ProgramActionSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { AdmissionCampaignDTO } from "utils/helpers/models/admissions/admission-campaign.dto";
import ApplyClearance from "./components/clearance-apply";

const MyClearanceListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Student Dashboard /", path: "" },
    {
      title: "My Clarance ",
      path: siteRoutes.myClearanceListing,
    },
  ];
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Clearance Department",
    "Clearance Type",
    "Reason",
    "Status",
    "Remarks By Depaetment",
   
  ];
  const { hasAccess } = useStore();

 
  const { handleSearchChange, handleTableSearch } = useUtils();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const navigate = useNavigate();
  const [data, setData] = useState<AdmissionCampaignDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const { getDateFromDateTime, createQuery } = useUtils();

  const goToCreateAdmissionCampaign = () => {
    navigate(siteRoutes.createAdmissionCampaign);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };

    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAdmissionCampaigns(page + 1, search, per_page);
  };
  const getAllAdmissionCampaigns = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
    };

  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const openClearanceModal = () => {
    setOpenModal(true);
  }

 

  return (
    <AdmissionCampaignListingMain>
      <AdmissionCampaignListingTop>
        <div className="left">
          <span className="page-heading">My Clarance</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
         
            <div className="create-org-btn">
              <button
                className="lg-rounded-btn"
               onClick={openClearanceModal}
              >
            Apply for Clearance
              </button>
            </div>
     
        </div>
      </AdmissionCampaignListingTop>

      <AdmissionCampaignListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadAdmissionCampaignPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadAdmissionCampaignExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllAdmissionCampaigns
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllAdmissionCampaigns
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1,1,1,1].map((campaign, index) => {
                return (
                  <tr key={index}>
                    <td>Finance Department</td>
                    <td>Fee clearance</td>
                    <td>Here</td>
                    <td><span className="status-tile">Pending</span></td>
                    <td>Here</td>
                  
                   
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
       
          <Pagination onPageChange={onPageChange} {...pagination} />
          {openModal && (
            <ApplyClearance
              setOpen={setOpenModal}
             
            />
          )}
        </Fragment>
      </AdmissionCampaignListingSection>
    </AdmissionCampaignListingMain>
  );
};

export default MyClearanceListing;
