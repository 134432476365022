import { FC, Fragment, useEffect, useState } from "react";
import {
  DownArrowLightgrayMediumSvg,
  DownloadTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicPaidVouchersListingMain,
  AcademicPaidVouchersListingSection,
  AcademicPaidVouchersListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import useAcademics from "../useHooks";
import { PaidVouchersDTO } from "utils/helpers/models/academics/pai-vochers.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";

interface filtersInterFace {
  session_id: number;
  cnic: string;
  reg_number: string;
  challan_no: string;
  status: string;
}

const AcademicPaidVouchersListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Paid Vouchers", path: siteRoutes.academicPaidVouchersListing },
  ];

  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getAcademicSessions, getPaidVouchersList, downloadEnrollmentCSV } =
    useAcademics();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [search, setSearch] = useState<string>("");
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [data, setData] = useState<PaidVouchersDTO[]>([]);
  const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>(
    []
  );
  const [filters, setFilters] = useState<filtersInterFace>({
    session_id: undefined,
    cnic: "",
    reg_number: "",
    challan_no: "",
    status: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "RegNo",
    "Name",
    "CNIC",
    "Program",
    "Challan No",
    "Amount",
    "Verified at",
    "Action",
  ];
  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllPaidVoucherList(page + 1, search, per_page);
  };
  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };
  const onApplyFilters = () => {
    getAllPaidVoucherList(pagination.page, search, pagination.per_page);
  };
  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllPaidVoucherList(pagination.page, search, pagination.per_page);
  };
  const downloadEnrollmentDataCSV = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      downloadEnrollmentCSV(id);
    }
  };
  const getAllPaidVoucherList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    const queryParams = {
      page,
      per_page,
      search,
      ...filteredFilters,
    };
    getPaidVouchersList(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAcademicSessions(setAcademicSession);
    getAllPaidVoucherList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AcademicPaidVouchersListingMain>
      <AcademicPaidVouchersListingTop>
        <div className="left">
          <span className="page-heading">Paid Vouchers</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcademicPaidVouchersListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {showFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_id"
                      value={filters.session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {academicSession?.map((item: any, i: number) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.type} {item?.year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="cnic"
                      value={filters.cnic}
                      onChange={handleFilterChange}
                      placeholder="cnic"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Reg No</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="reg_number"
                      value={filters.reg_number}
                      onChange={handleFilterChange}
                      placeholder="reg no"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Challan No</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      name="challan_no"
                      value={filters.challan_no}
                      onChange={handleFilterChange}
                      placeholder="challan no"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Reg No</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="status"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      <option value="Paid">Paid</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AcademicPaidVouchersListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllPaidVoucherList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllPaidVoucherList)
              }
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <div className="mw-150">{item?.reg_number ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.name ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.cnic ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.program_title ?? "-"}</div>
                    </td>
                    <td>{item?.challan_no ?? "-"}</td>
                    <td>
                      {" "}
                      {new Intl.NumberFormat().format(
                        Number(item?.amount ?? "-")
                      )}
                    </td>
                    <td>
                      <div className="mw-150">{item?.verified_at ?? "-"}</div>
                    </td>
                    <td>
                      <div className="table-action-icons">
                        <div className="action-icon">
                          <EditTableSvg />
                        </div>
                        {/* <button
                          className="action-icon"
                          onClick={() => downloadEnrollmentDataCSV(item?.id)}
                        >
                          <DownloadTableSvg />
                        </button> */}
                        <div className="table-action-button">
                          <button className="green">Copy & Create New</button>
                        </div>
                        <div className="table-action-button">
                          <button className="black"> Manage Fee</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </AcademicPaidVouchersListingSection>
    </AcademicPaidVouchersListingMain>
  );
};

export default AcademicPaidVouchersListing;
