import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  ExamInchargeMain,
  ExamInchargeSection,
  ExamInchargeTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import AddParticulars from "./components/add-exam-incharge";
import AddExamIncharge from "./components/add-exam-incharge";
import useAcademics from "../useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import { useNavigate } from "react-router-dom";
import { ExamInchargeDTO } from "utils/helpers/models/academics/exam-incharge.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";

const AcademicExamIncharge: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "manage Exam Incharge / ",
      path: siteRoutes.academicManageExamIncharge,
    },
    {
      title: "Exam Incharge",
      path: siteRoutes.academicExamIncharge,
    },
  ];
  const columns: string[] = [
    "In charge Username",
    "Campus",
    "Semesters Allowed",
    "Can Verify?",
    "Action",
  ];

  const navigate = useNavigate();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { hasAccess } = useStore();
  const { getExamIncharges, deleteExamIncharge, updateExamIncharge } =
    useAcademics();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [examIncharge, setExamIncharge] = useState<ExamInchargeDTO[]>([]);
  const [openAddExamIncharge, setOpenAddExamIncharge] = useState(false);

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    // getAllTestingCenterRooms(page + 1, search, per_page);
  };

  const goToAddExamIncharge = () => {
    setOpenAddExamIncharge(true);
  };

  const getAllExamInchrge = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
      program_id: params?.program_id,
    };
    getExamIncharges(setExamIncharge, queryParams, setPagination);
  };

  const handleDeleteExamIncharge = async (
    id: number,
    additionalData: { username: string }
  ) => {
    const response = await confirmationPopup(
      `Are you want to delete this <b  style="color:red;"> ${additionalData?.username} </b> teacher`
    );
    const queryParams = {
      per_page: pagination?.per_page,
      page: pagination?.page,
      search,
      program_id: params?.program_id,
    };
    if (response.isConfirmed) {
      deleteExamIncharge(id, setExamIncharge, queryParams, setPagination);
    }
  };

  const handleChangeExamIncharge = async (
    id: number,
    index: number,
    field: string,
    additionalData: { username: string },
    value: any,
    program_id: number = params?.program_id
  ) => {
    const response = await confirmationPopup(
      `Are You Want to Update for <b style="color:red;">${
        field ?? ""
      }</b> field of <b style="color:red;">${
        additionalData?.username ?? ""
      }</b> ?`
    );
    if (!response.isConfirmed) return;
    setExamIncharge((prevList: any) =>
      prevList.map((item: any, i: number) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
    setExamIncharge((updatedList) => {
      const updatedRow = updatedList[index];
      updateExamIncharge(id, { ...updatedRow, program_id });
      return updatedList;
    });
  };

  useEffect(() => {
    if (params?.program_id) {
      getAllExamInchrge(pagination?.page, search, pagination?.per_page);
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (!openAddExamIncharge) {
      if (params?.program_id) {
        getAllExamInchrge(pagination?.page, search, pagination?.per_page);
      }
    }
  }, [openAddExamIncharge]);

  return (
    <ExamInchargeMain>
      <ExamInchargeTop>
        <div className="left">
          <span className="page-heading">Exam Incharge</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.academicExamInchargeCreate) && (
            <div className="add-new-button" onClick={goToAddExamIncharge}>
              <button className="lg-rounded-btn">+ Add Exam Incharge</button>
            </div>
          )}
        </div>
      </ExamInchargeTop>

      <ExamInchargeSection className="content-radius-shadow">
        {/* <div className="table-info-section">
          <div className="left-section">
            <div className="heading">
              <span>BS (Hons() Agriculture (M))</span>
            </div>
          </div>
        </div> */}
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {examIncharge?.map((item: any, index: number) => {
                const { teachers, can_verify } = item;

                return (
                  <Fragment key={index}>
                    <tr>
                      <td>
                        <div className="mw-150">
                          {teachers?.teacher_title?.title ?? "-"}{" "}
                          {teachers?.teacher_firstname ?? "-"}{" "}
                          {teachers?.teacher_lastname}
                        </div>
                      </td>
                      <td>
                        <div className="table-field">
                          <select
                            onChange={(e) =>
                              handleChangeExamIncharge(
                                item?.id,
                                index,
                                "campus",
                                {
                                  username: `${
                                    teachers?.teacher_firstname ?? "-"
                                  } ${teachers?.teacher_lastname ?? ""}`,
                                },
                                e.target.value
                              )
                            }
                            value={item?.campus?.id}
                          >
                            <option value="" disabled>
                              -- Select --
                            </option>
                            <option value={item?.campus?.id}>
                              {item?.campus?.title}
                            </option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="table-field">
                          <select
                            onChange={(e) =>
                              handleChangeExamIncharge(
                                item?.id,
                                index,
                                "semester_allowed",
                                {
                                  username: `${
                                    teachers?.teacher_title?.title ?? ""
                                  } ${teachers?.teacher_firstname ?? "-"} ${
                                    teachers?.teacher_lastname ?? ""
                                  }`,
                                },
                                e.target.value
                              )
                            }
                            value={item?.semester_allowed}
                          >
                            <option value="">MultiSelect</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="table-field">
                          <select
                            onChange={(e) =>
                              handleChangeExamIncharge(
                                item?.id,
                                index,
                                "can_verify",
                                {
                                  username: `${
                                    teachers?.teacher_title?.title ?? ""
                                  } ${teachers?.teacher_firstname ?? "-"} ${
                                    teachers?.teacher_lastname ?? ""
                                  }`,
                                },
                                e.target.value
                              )
                            }
                            value={can_verify}
                          >
                            <option value="" disabled>
                              -- Select --
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="table-action-icons">
                          <div
                            className="action-icon"
                            onClick={() =>
                              handleDeleteExamIncharge(item?.id, {
                                username: `${
                                  teachers?.teacher_title?.title ?? ""
                                } ${teachers?.teacher_firstname ?? "-"} ${
                                  teachers?.teacher_lastname ?? ""
                                }`,
                              })
                            }
                          >
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !examIncharge.length} />
            <Pagination onPageChange={onPageChange} {...pagination} />
            {openAddExamIncharge && (
              <AddExamIncharge
                program_id={params?.program_id}
                setOpen={setOpenAddExamIncharge}
              />
            )}
          </Fragment>
        </div>
      </ExamInchargeSection>
    </ExamInchargeMain>
  );
};

export default AcademicExamIncharge;
