export class PaidVouchersDTO {
  id!: number;
  acc_transaction_id: number;
  amount: number;
  challan_no: string = "";
  cnic: string = "";
  name: string = "";
  program_id: number;
  program_title: string = "";
  reg_number: string = "";
  status: string = "";
  student_id: number;
  user_id: number;
  verified_at: string = "";
  voucher_no: string = "";
  constructor(data: Partial<PaidVouchersDTO> = {}) {
    Object.assign(this, data);
  }
}
