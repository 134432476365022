import { FC, Fragment, useEffect, useState } from "react";
import { Main, Container, ContentWrapper, ListingSection } from "./style";
import {
  CloseMediumSvg,
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import useStore from "hooks/useStore";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { confirmationPopup } from "utils/helpers/common/alert-service";

interface pageProps {
  setOpen: Function;
  course_id: number;
  actionToDo: string; // pls use delete / add only      '''''' pls dont remove this commit
}

interface dataInterFace {
  student_id: number;
  name: string;
  cnic: string;
  reg_number: string;
}

const ManageEnrollmentModal: FC<pageProps> = ({
  setOpen,
  course_id,
  actionToDo,
}) => {
  const column = ["Cnic", " Name", "Reg Number"];
  const {
    getManageEnrollmentsData,
    deleteManageEnrollementsMultipleData,
    handleAddEnrollmentsInBulk,
  } = useAcademics();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [data, setData] = useState<dataInterFace[]>([]);

  // Handle individual row selection
  const handleCourseSelection = (courseId: number) => {
    setSelectedCourses((prevSelectedCourses) => {
      if (prevSelectedCourses.includes(courseId)) {
        return prevSelectedCourses.filter((id) => id !== courseId);
      } else {
        return [...prevSelectedCourses, courseId];
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAll = () => {
    setIsSelectedAll((prevState) => {
      const newIsSelectedAll = !prevState;
      const newSelectedCourses = newIsSelectedAll
        ? data.map((item) => item.student_id)
        : [];
      setSelectedCourses(newSelectedCourses);
      return newIsSelectedAll;
    });
  };

  const handleDeleteMultipleRecords = async () => {
    const response = await confirmationPopup(
      "Are You Want To Delete Enrollments"
    );
    if (response.isConfirmed) {
      const record_ids: string = selectedCourses?.join(", ");
      const body = {
        enrollment_ids: record_ids,
      };
      deleteManageEnrollementsMultipleData(
        body,
        setData,
        course_id,
        "manage-enrollments",
        setSelectedCourses,
        setIsSelectedAll
      );
    }
  };

  const handleAddStudentsInBulk = async () => {
    const response = await confirmationPopup("Are You Want To Add Enrollments");
    if (response.isConfirmed) {
      const record_ids: string = selectedCourses?.join(", ");
      const body = {
        course_ids: course_id,
        student_ids: record_ids,
        enrollment_status: 5,
      };
      handleAddEnrollmentsInBulk(body, setData, course_id, setSelectedCourses);
    }
  };

  useEffect(() => {
    getManageEnrollmentsData(setData, course_id);
  }, [course_id]);
  useEffect(() => {
    if (data?.length === selectedCourses?.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  }, [selectedCourses, data]);

  return (
    <Main>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Manage Enrollments</span>
              {selectedCourses?.length > 0 && (
                <Fragment>
                  <button
                    className="lg-rounded-btn red"
                    onClick={handleDeleteMultipleRecords}
                  >
                    Delete Selected ( {selectedCourses?.length} ) Enrollments
                  </button>
                  {actionToDo === "add" && (
                    <button
                      className="lg-rounded-btn"
                      onClick={handleAddStudentsInBulk}
                    >
                      Add Selected ( {selectedCourses?.length} ) Enrollments
                    </button>
                  )}
                </Fragment>
              )}
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>

          <ListingSection>
            <div className="content-radius-shadow">
              <div className="data-table">
                <table className="bottom-bordered-cells">
                  <thead>
                    <tr>
                      <td>
                        <div className="row-action-cell">
                          <div className="table-checkbox-field">
                            <input
                              type="checkbox"
                              checked={isSelectedAll}
                              onChange={handleSelectAll}
                            />
                          </div>
                        </div>
                      </td>
                      {column?.map((i, x) => (
                        <th key={x}>{i}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="row-action-cell">
                            <div className="table-checkbox-field">
                              <input
                                type="checkbox"
                                checked={selectedCourses?.includes(
                                  item?.student_id
                                )}
                                onChange={() =>
                                  handleCourseSelection(item?.student_id)
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <td>{item?.cnic ?? "-"}</td>
                        <td>{item?.name ?? "-"}</td>
                        <td>{item?.reg_number ?? "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="button-botom">
                  <button
                    className="lg-rounded-btn gray"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  {selectedCourses?.length > 0 && data?.length > 5 && (
                    <Fragment>
                      <button
                        className="lg-rounded-btn red"
                        onClick={handleDeleteMultipleRecords}
                      >
                        Delete Selected ( {selectedCourses?.length} )
                        Enrollments
                      </button>
                      {actionToDo === "add" && (
                        <button
                          className="lg-rounded-btn"
                          onClick={handleAddStudentsInBulk}
                        >
                          Add Selected ( {selectedCourses?.length} ) Enrollments
                        </button>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>

              <Fragment>
                <DataNotFound show={!isLoading && !data?.length} />
              </Fragment>
            </div>
          </ListingSection>
        </ContentWrapper>
      </Container>
    </Main>
  );
};

export default ManageEnrollmentModal;
