import styled from "styled-components";

export const ResetMain = styled.div`
  display: flex;
  align-items: center;
  overflow-y: auto;
  padding: 2em 1em;
  gap: 1em;

  @media screen and (max-width: 490px) {
    width: 100%;
    padding: unset;
  }

  .sideImg {
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover;
    float: right;
  }

  .sideImg img {
    width: 100%;
    height: 100%;
    border-radius: 1em;
  }

  @media screen and (min-width: 1280px) {
    display: grid;
    grid-auto-flow: column;
    gap: 8em;
    height: 100vh;
  }
  @media screen and (max-width: 1279px) {
    .sideImg {
      display: none;
    }
    height: 100vh;
  }
`;

export const ResetForm = styled.form`
  width: max(45%, 480px);
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: auto;
  border-radius: 12px !important;

  @media screen and (min-width: 1279px) {
    height: -webkit-fill-available;
    /* display: inline-grid; */
  }

  @media screen and (max-width: 1279px) {
    height: auto;
  }
  @media screen and (max-width: 1279px) {
    border-radius: 12px !important;
    margin: auto;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    padding: 2em 1em;
    gap: 1em;
  }
  @media screen and (max-width: 490px) {
    margin: 0 1em;
    width: 100%;
  }
  .error-text {
    font-weight: 400;
    font-size: 16px;
    color: var(--black-text);
  }
  .error-box {
    background-color: var(--primary-extralight);
    padding: 10px 20px;
    margin-top: 10px;
  }

  .radio-input {
    padding-left: 5px;
  }
  .radio {
    width: 18px;
    height: 18px;
    background-color: var(--primary);
    margin-top: 5px;
  }
  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .heading {
      font-size: 18px;
      font-weight: 400;
      color: var(--primary);

      @media screen and (max-width: 610px) {
        font-size: 20px;
      }
    }
    .sub-heading {
      font-size: 16px;
      font-weight: 400;
      color: var(--lightgray-light);

      @media screen and (max-width: 610px) {
        font-size: 12px;
      }
    }
  }

  .fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .field-wrap {
    justify-content: center;

    .field {
      @media screen and (max-width: 490px) {
        height: 45px;
      }
    }
  }
  .bottom-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width: 610px) {
      font-size: 12px;
    }

    .remember-me {
      display: flex;
      gap: 0.5em;
      align-items: center;
      padding-left: 0.5em;

      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        accent-color: var(--primary);
      }

      label {
        color: var(--black-text);
      }
    }

    span {
      color: var(--black-text);
    }
  }

  .submit-button {
    width: 100%;
    padding-top: 1em;

    button {
      width: 100%;
      height: 50px;
      color: white;
      font-size: 20px;
      background: var(--primary);
      border-radius: 5px;
      font-weight: 600;

      @media screen and (max-width: 610px) {
        font-size: 16px;
      }

      @media screen and (max-width: 490px) {
        height: 45px;
      }

      .loader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .password-icon {
    width: 14px;
    height: 13px;
  }

  .bottom-text {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--black-text);

    @media screen and (max-width: 610px) {
      font-size: 12px;
    }
  }
  .link-text {
    font-weight: 400;
    font-size: 16px;
    color: var(--black-text);
  }
`;

export const SiteLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1.5em;
  img {
    width: 300px;
    max-width: 23rem;
    margin: 0 auto;
    height: auto;
    max-height: 10rem;
  }
  @media screen and (max-width: 430px) {
    max-height: 8rem;
    margin: auto;
    overflow: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: inherit;
  }
`;
