export class SyncGradeTemplateGradeTemplateDTO {
  id!: number;
  template_id: number;
  credit_hours: number;
  session_id: number;
  course_code: string = "";
  common_course: string = "";
  faculty: number[];
  program_level: number[];

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
