import { FC, useEffect, useState } from "react";
import {
  CreateFrameworkForm,
  CreateFrameworkMain,
  CreateFrameworkTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";
import { LegalFrameWorksDocumentDTO } from "utils/helpers/models/legal-matters/framework-documents.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { errorToaster } from "utils/helpers/common/alert-service";
import useLegalMatters from "../../useHook";
import { MatterTypeDTO } from "utils/helpers/models/legal-matters/matter-type.dto";
import { LegalDocsTypeDTO } from "utils/helpers/models/legal-matters/legal-frameworks-document-types.dto";

const CreateFramework: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Legal Framework Documents /",
      path: siteRoutes.legalFrameworkList,
    },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Framework Documents`,
      path: siteRoutes.createLegalFramework,
    },
  ];

  const {
    getLegalDocTypeList,
    createLegalMatterFrameworkDocument,
    updateLegalMatterFrameworkDocument,
    getLegalMatterFrameworkDocumentById,
  } = useLegalMatters();
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalFrameWorksDocumentDTO>();

  const [formData, setFormData] = useState<LegalFrameWorksDocumentDTO>(
    new LegalFrameWorksDocumentDTO()
  );
  const [fileName, setFileName] = useState<string>("No File Chosen");
  const [docType, setDocType] = useState<LegalDocsTypeDTO[]>([]);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalFrameWorksDocumentDTO()[
        key as keyof LegalFrameWorksDocumentDTO
      ];
      setValue(key as keyof LegalFrameWorksDocumentDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
    setFileName("No File Chosen");
  };

  const handleUploadFile = (event: any) => {
    const {
      files: [file],
    } = event.target;
    event.target.value = "";
    setFileName(file.name);
    setFormData((prev) => ({ ...prev, file_path: URL.createObjectURL(file) }));
    // setFormData({ ...formData, file_path: file });
    setValue("file_path", file);
    trigger("file_path");
  };

  const onSubmit = (
    data: LegalFrameWorksDocumentDTO,
    addMore: boolean = false
  ) => {
    const form_data = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        form_data.append(key, data[key]);
      }
    });

    // const form_data = new FormData();
    // for (let key in data) {
    //   if (data[key] == null) {
    //     form_data.append(key, "");
    //   } else {
    //     form_data.append(key, data[key]);
    //   }
    // }

    if (!formData?.file_path) {
      return errorToaster("File is required");
    }

    if (params?.id) {
      updateLegalMatterFrameworkDocument(params?.id, formData);
    } else {
      createLegalMatterFrameworkDocument(form_data, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getLegalMatterFrameworkDocumentById(
        params?.id,
        formData,
        setValue,
        setFormData
      );
    }
    getLegalDocTypeList(setDocType, { per_page: "All" });
    register("file_path", { required: true });
  }, [register]);

  return (
    <CreateFrameworkMain>
      <CreateFrameworkTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "update" : "Add"} Legal Framework Document
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateFrameworkTop>

      <CreateFrameworkForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="">Name / Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Version</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("version", { required: true })}
                  value={formData.version}
                  onChange={handleChange}
                  placeholder="Version"
                />
              </div>
              <FormErrorMessage error={errors.version} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Type</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("type_id", { required: true })}
                  value={formData?.type_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {docType?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.name ?? "-"}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.type_id} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Superior Law</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("superior_law", { required: true })}
                  value={formData?.superior_law}
                  onChange={handleChange}
                  placeholder="Superior Law"
                />
              </div>
              <FormErrorMessage error={errors.superior_law} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("date", { required: true })}
                  value={formData?.date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.date} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Validity From</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("validity_from", { required: true })}
                  value={formData?.validity_from}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.validity_from} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="">Validity To</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("validity_to", { required: true })}
                  value={formData?.validity_to}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.validity_to} />
            </div>
          </div>

          {/* <div className="input-field">
            <label>Text File</label>
            <div className="upload-container">
              <div className="upload-section">
                <label htmlFor="file-upload" className="upload-label">
                  Choose a file
                </label>
                <input
                  type="file"
                  id="file-upload"
                  className="file-input"
                  onChange={handleUploadFile}
                />
              </div>

              <div className="file-details-section" id="file-upload">
                <p className="file-placeholder">{fileName ?? " "}</p>
              </div>
            </div>
            <FormErrorMessage error={errors.file_path} />
          </div> */}
        </div>
        <div className="common-fields">
          <div className="upload-images">
            <div className="image-field light">
              <label className="login-image">
                {formData?.file_path ? (
                  <img src={formData?.file_path} alt="" />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload File</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  id="file-upload"
                  className="file-input"
                  onChange={handleUploadFile}
                />
              </label>
            </div>
            <div>
              <label htmlFor="login_page_image">Select File</label>
            </div>
            <FormErrorMessage error={errors.file_path} />
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="reset"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateFrameworkForm>
    </CreateFrameworkMain>
  );
};

export default CreateFramework;
