export class CardManagementListDTO {
  id!: number;
  user_id: number;
  reg_number: string = "";
  phone_no: string = "";
  user: {
    name: string;
    cnic: string;
    phone_no: string;
    first_name: string;
    profile_image: string;
    users_meta: { father_name: string };
  } = {
    cnic: "",
    name: "",
    phone_no: "",
    first_name: "",
    profile_image: "",
    users_meta: { father_name: "" },
  };

  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export class uploadProfileImageDTO {
  profile_image: string = "";
  constructor(data = {}) {
    Object.assign(this, data);
  }
}
