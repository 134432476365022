import { FC, Fragment, useState, useEffect } from "react";
import {
  DeleteTableSvg,
  DownloadTableSvg,
  DownTableArrowSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  StudyPlanCourseListingMain,
  StudyPlanCourseListingSection,
  StudyPlanCourseListingTop,
  StudyPlanCourseListingStatsSection,
} from "./style";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { CoursesListingSection } from "containers/private/e-portal/short-courses/listing/style";

const MyFeeVoucher: FC = () => {
  const breadcrumbLinks = [
    { title: "Student Dashboard / ", path: "" },
    {
      title: " My Fee Vouchers",
      path: siteRoutes.myFeeVoucher,
    },
   
  ];
  const columns1: string[] = [
    "Title",
    "Challan # ",
    "Amount",
    "Due Date",
    "Status",
    "Remarks",
    "Action",

   
  ];
 
  const [openAddCourseModal, setopenAddCourseModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [planOfStudy, setPlanOfStudy] = useState<PlanOfStudyDTO>(new PlanOfStudyDTO());
  
  const [data, setData] = useState<PlanOfStudyCourseDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
        pos_id: params?.pos_id,
      };
    
    }
  };

  const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllPlanOfStudyCourses(page + 1, search, per_page);
  };
  const getAllPlanOfStudyCourses = (page: number, search: string, per_page: number) => {
    const { pos_id = null} = params;
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      pos_id
    };
   
  };


  const goToCreateCourse = () => {
    setopenAddCourseModal(true);
  };

  useEffect(() => {
    if(!openAddCourseModal){
      getAllPlanOfStudyCourses(pagination.page, search, pagination.per_page);
     
    }
  }, [openAddCourseModal]);

  const handleSequenceChange = (event: any, index: number) => {
    const { value } = event.target;
    data[index].semester_seq_number = value;
    setData([...data]);
   
  }

  const handleTranscriptChange = (course: PlanOfStudyCourseDTO, index: number) => {
    data[index] = course;
    setData([...data]);
  }

  return (
    <StudyPlanCourseListingMain>
      <StudyPlanCourseListingTop>
        <div className="left">
          <span className="page-heading">My Fee Vouchers</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
       

      </StudyPlanCourseListingTop>
     

      <StudyPlanCourseListingSection className="content-radius-shadow">

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns1.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1,1,1,1,].map((course, index) => {
                return <tr key={index}>
                   
                  <td>
                  ARIN-4567
                  </td>
                  <td>
                  Advanced Theory of Computation
                  </td>
                  <td>
                    <div className="mw-150">120</div>
                  </td>
                  <td>
                    <div className="mw-150">120</div>
                  </td>
                  <td>
                    <span className="status-tile">Pending</span>
                  </td>
                  

                  <td>
                    <div className="mw-150">A</div>
                  </td>       
                  <td>
                    <div className="table-action-icons">
                        <div className="action-icons">
                         <TabPrimaryActionMenu/>
                        </div>
                    </div>
                  </td>
                 
                </tr>
              })}
   
             
            </tbody>
          </table>
        </div>

    
      </StudyPlanCourseListingSection>
   
    
    </StudyPlanCourseListingMain>
  );
};

export default MyFeeVoucher;
