import styled from "styled-components";

export const MeritListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const MeritListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }
  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }
  .mert-list-button {
    display: flex;
    background-color: var(--medium-red);
    padding: 0.5em 1em;
    border-radius: 0.5em;
    color: var(--white-color);
    @media screen and (max-width: 500px) {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }

  table {
    tr {
      td {
        @media screen and (min-width: 2200px) {
          border-bottom: 1px solid var(--lightgray-extralight);
        }
      }
    }
    td {
      &.extra-col {
        display: none;
        ${() => {
          let str = "";
          for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
            str += `&.extra-col-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
          }
          return str;
        }}
        &-10 {
          @media screen and (min-width: 2200px) {
            display: table-cell;
          }
        }
      }

      .rounded-expand-button {
        @media screen and (min-width: 1700px) {
          display: none;
        }
      }
    }

    tr.expanded-row {
      @media screen and (min-width: 2780px) {
        display: none;
      }
      .expanded-content {
        .particular-info {
          ${() => {
            let str = "";
            for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
              str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: none;
          }
        }`;
            }
            return str;
          }}
        }
      }
    }
    thead th {
      &.extra-head {
        display: none;
        ${() => {
          let str = "";
          for (let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
            str += `&-${item}{
          @media screen and (min-width: ${1500 + item * 80}px){
            display: table-cell;
          }
        }`;
          }
          return str;
        }}

        &-10 {
          @media screen and (min-width: 2200px) {
            display: table-cell;
          }
        }
      }
    }
  }

  .action-button {
    button {
      padding: 0.4em 0.5em;
      color: var(--white-constant);
      background: var(--lightgreen-medium);
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;

      &.black {
        color: var(--white-color);
        background: var(--black-text);
      }
      &.voilet {
        background: #718ebf;
      }
      &.orange {
        background: var(--medium-orange);
      }
      &.yellow {
        background: #ffbb38;
      }
    }
  }
`;
export const StatsListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lightgray-extralight);

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }
  .stats-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
  }

  table td {
    .mw-150 {
      min-width: 150px;
    }
    .mw-100 {
      min-width: 100px;
    }

    .expanded-content {
      display: flex;
      flex-direction: column;
      gap: 0.7em;

      .particular-info {
        display: flex;
        gap: 1em;
        .title {
          min-width: 170px;
          color: var(--black-text);
          font-size: 15px;
          font-weight: 400;
        }

        .info {
          font-size: 15px;
          font-weight: 400;
          color: var(--lightgray-medium);

          .table-action-icons {
            .action-icon {
              &.right-arrow svg rect {
                fill: var(--spring-green);
              }

              svg {
                rect {
                  rx: 7;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MeritListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 670px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 670px) {
      width: 100%;
    }
    .download-buttons {
      display: flex;
      gap: 0.5em;

      @media screen and (max-width: 670px) {
        width: 100%;
        flex-direction: column;
      }

      .particular-button {
        @media screen and (max-width: 670px) {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
      .lg-rounded-btn {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ $showFilterDropdown: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    .icon {
      width: 20px;
      height: 28px;
      rotate: ${({ $showFilterDropdown }) =>
        $showFilterDropdown ? "0" : "180deg"};
      transition: all 0.1s ease-in-out;

      path {
        fill: var(--lightgray-medium);
      }

      @media screen and (max-width: 480px) {
        width: 18px;
        height: 24px;
      }
    }
  }
`;
