import { OrgStructureDTO } from "../organization/org-structure.dto";

export class TeacherDTO {
  id!: number;
  title: string = "";
  title_id: string = "";
  teacher_title: { title: string } = { title: "" };
  teacher_firstname: string = "";
  teacher_middlename: string = "";
  teacher_lastname: string = "";
  cnic: number;
  user_name: string = "";
  phone: string;
  department_id: string = "";
  is_active: number;
  personal_email: string = "";
  campus_id: string = "";
  campus: OrgStructureDTO;
  is_employee: number;
  ntn: number;
  is_on_leave: number;
  is_visiting: number;
  bank_name: string = "";
  bank_account: string = "";
  bank_account_title: string = "";

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
