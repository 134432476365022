import { FC, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { UpdateUserCommentsDTO } from "utils/helpers/models/system-administration/update-user-comments.dto";
import useSystemAdministration from "containers/private/system-administration/useHooks";

class Props implements Omit<UpdateUserCommentsDTO, ""> {
  setOpen: Function;
  userId: number;
  call_status: string;
  remarks: string;
}

const UpdateUserComments: FC<Props> = ({
  userId,
  setOpen,
  call_status,
  remarks = "",
}) => {
  const [callStatus, setCallStatus] = useState<any>([]);
  const [formData, setFormData] = useState<UpdateUserCommentsDTO>(
    new UpdateUserCommentsDTO({ userId, call_status, remarks })
  );
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<UpdateUserCommentsDTO>();

  const callStatusOptions = [
    "Want Online Study",
    "Outside Karachi Not possible",
    "Financial Issues",
    "Transport Issue",
    "Interested",
    "Applying in irrelevant Programs",
    "Already completed Bachelor's",
    "Intermediate /A-level in process",
    "Applying for certification programs",
    "Transfer student from University",
    "Call me Later",
    "Already AKU Student",
    "Got Admission in other institute",
    "Other",
  ];

  const { updateUserComments, getCallStatusList } = useSystemAdministration();

  const onSubmit = () => {
    updateUserComments(formData, setOpen);
  };

  const handleChange = (event: any) => {
    const {
      target: { name, value },
    } = event;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    const dto = new UpdateUserCommentsDTO();
    for (let key in formData) {
      if (key !== "userId") {
        formData[key] = dto[key];
        setValue(key as keyof UpdateUserCommentsDTO, formData[key]);
      }
    }

    setFormData({ ...formData });
  };

  const getCallAllStatus = () => {
    getCallStatusList(setCallStatus);
  };
  useEffect(() => {
    getCallAllStatus();
  }, []);

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Manage Comments</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label htmlFor="">Remarks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("remarks", { required: true })}
                      value={formData?.remarks}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.remarks} />
                </div>
              </div>
              <div className="input-field">
                <label>Call Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("call_status", { required: true })}
                      value={formData.call_status}
                      onChange={handleChange}
                    >
                      <option value="">Select one</option>
                      {callStatus?.map((status, index) => {
                        return <option key={index}>{status?.title}</option>;
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.call_status} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn">Submit</button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default UpdateUserComments;
