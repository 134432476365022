import { FC, useEffect, useState } from "react";
import {
  CreateSurveyTypesTop,
  CreateSurveyTypesFormSection,
  CreateSurveyTypesMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useQEC from "../../useHooks";
import { SurveyTypesDTO } from "utils/helpers/models/qec/survey-types.dto";
import { useForm } from "react-hook-form";

const CreateSurveyTypes: FC = () => {
  const breadcrumbLinks = [
    { title: "QEC /", path: "" },
    { title: "Survey Types /", path: siteRoutes.surveyTypeListing },
    { title: "Add Survey Types", path: siteRoutes.createSurveyType },
  ];
  const { createSurveysType } = useQEC();
  let [formData, setFormData] = useState<SurveyTypesDTO>(new SurveyTypesDTO());
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm<SurveyTypesDTO>({ defaultValues: formData });
  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = (formData: SurveyTypesDTO) => {
    createSurveysType(formData);
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      // console.log(file);
    }
  };

  return (
    <CreateSurveyTypesMain>
      <CreateSurveyTypesTop>
        <div className="heading">
          <span className="page-heading">Add Survey Type </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateSurveyTypesTop>

      <CreateSurveyTypesFormSection
        className="content-radius-shadow"
        // onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Title"
                  {...register("title", { required: true })}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>Upload</label>
            <div className="upload-container">
              <div className="upload-section">
                <label htmlFor="file-upload" className="upload-label">
                  Choose a file
                </label>
                <input
                  type="file"
                  id="file-upload"
                  className="file-input"
                  {...register("file", { required: true })}
                  onChange={handleFileChange}
                />
              </div>

              <div className="file-details-section" id="file-details">
                <p className="file-placeholder">No file chosen</p>
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>Performa</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Meeting Title"
                  {...register("performa", { required: true })}
                  value={formData.performa}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="input-field">
            <label>Survey Term</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("term_id", { required: true })}
                  value={formData.term_id}
                  onChange={handleChange}
                >
                  <option value="">Select Survey Team</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>Filled By</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("filled_by", { required: true })}
                  value={formData.filled_by}
                  onChange={handleChange}
                >
                  <option value="">Select Person</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>Relation</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("relation", { required: true })}
                  value={formData.relation}
                  onChange={handleChange}
                >
                  <option value="">Select Relation</option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>Status</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_active", { required: true })}
                  value={formData.is_active}
                  onChange={handleChange}
                >
                  <option value="1">Active</option>
                  <option value="0">In Active</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button">
              Reset
            </button>
            <button
              className="lg-rounded-btn"
              type="button"
              onClick={() => submitForm(formData)}
            >
              Submit
            </button>
          </div>
        </div>
      </CreateSurveyTypesFormSection>
    </CreateSurveyTypesMain>
  );
};

export default CreateSurveyTypes;
