import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicExamTypeListingMain,
  AcademicExamTypeListingSection,
  AcademicExamTypeListingTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useMyApps from "../../useHooks";

interface dataInterFace {
  id: number;
  title: string;
  submitted_courses: string;
  verified_courses: string;
}

const ExamInchargeCourses: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "My Apps", path: "" },
    {
      title: " / Exam Incharge Programs Listing",
      path: siteRoutes.examInchargePrograms,
    },
    {
      title: " / Exam Incharge Sections Listing",
      path: siteRoutes.examInchargeSections,
    },
    {
      title: " / Exam Incharge Courses Listing",
      path: siteRoutes.examInchargeCourses,
    },
  ];
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const navigate = useNavigate();

  const { getExamInchargeProgrames } = useMyApps();
  const [data, setData] = useState<dataInterFace[]>([]);
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Course Title",
    "Submitted Courses",
    "Verified Courses",
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const getExamInchargeProgramsList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      search,
      per_page,
    };
    // getExamInchargeProgrames(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getExamInchargeProgramsList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AcademicExamTypeListingMain>
      <AcademicExamTypeListingTop>
        <div className="left">
          <span className="page-heading">Exam Incharge Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcademicExamTypeListingTop>

      <AcademicExamTypeListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => (
                <tr>
                  <td>
                    <div className="mw-150">{item?.title}</div>
                  </td>
                  <td>
                    <div className="status-tile">{item?.submitted_courses}</div>
                  </td>
                  <td>
                    <div className="status-tile">{item?.verified_courses}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !data?.length} />
            <Pagination
              onPageChange={(page: any) => console.log(page)}
              {...pagination}
            />
          </Fragment>
        </div>
      </AcademicExamTypeListingSection>
    </AcademicExamTypeListingMain>
  );
};

export default ExamInchargeCourses;
