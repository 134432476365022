export class LinkVoucherTemplateDTO{
    fee_type_id: number;
    fee_plan_id: number;
    voucher_template_id: number;
    id: number;
    amount: number;
    fee_plan_title: string = '';
    voucher_template_title: string = '';
    total_amount: number = 0;

    constructor(data: Partial<LinkVoucherTemplateDTO> = {}) {
        Object.assign(this,data);
    }
}