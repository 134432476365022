import styled from "styled-components";

export const VMBPMain = styled.div`
  width: 100%;
  height: var(--zoom-view-height);
  background: var(--modal-backshadow);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  align-items: flex-end;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: var(--white-color);
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em 1em 2em;
    border-bottom: 1px solid var(--gray-light);

    @media screen and (max-width: 520px) {
      padding: 0 1em 1em 1em;
    }

    .heading {
      span {
        font-size: var(--font-32);
        color: var(--black-text);
        font-weight: 500;

        @media screen and (max-width: 880px) {
          font-size: var(--font-24);
        }
      }
    }

    .close-icon {
      .icon {
        width: 20px;
        height: 19px;

        @media screen and (max-width: 520px) {
          width: 18px;
          height: 17px;
        }
        path {
          fill: var(--black-text);
        }
      }
    }
  }
`;

export const ListingSection = styled.div`
  width: 100%;

  .data-table {
    padding: 0 1em;
  }
`;

export const AggregateSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: var(--white-constant);
  font-size: var(--font-24);
  align-items: center;
  padding: 1em;
  text-align: center;

  .title,
  .total-amount {
    color: var(--black-text);
  }

  @media screen and (max-width: 620px) {
    padding: 1em 0.5em;
    font-size: var(--font-18);
  }
`;
