import { FC, useEffect, useState } from "react";
import {
  CreateLegalCasesTop,
  CreateLegalCasesSection,
  CreateLegalCasesMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";
import useLegalMatters from "../../useHook";
import useUtils from "hooks/useUtils";
import { LegalCasesDTO } from "utils/helpers/models/legal-matters/legal-cases.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { LegalAdviserDTO } from "utils/helpers/models/legal-matters/legal-adviser.dto";
import { LegalCourtsDTO } from "utils/helpers/models/legal-matters/legal-courts-dto";
import { MatterTypeDTO } from "utils/helpers/models/legal-matters/matter-type.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { LegalCaseNatureDTO } from "utils/helpers/models/legal-matters/legal-case-nature.dto";

const CreateLegalCases: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    { title: "Legal Cases /", path: siteRoutes.legalCasesList },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Cases`,
      path: siteRoutes.createLegalCases,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalCasesDTO>();
  const {
    createLegalCase,
    getLegalCaseByID,
    updateLegalCaseByID,
    getLegalAdviser,
    getLegalCourts,
    getLegalMatterTypesList,
    getLegalCaseNature,
  } = useLegalMatters();
  const { getSpecificOrgStructures } = useOrganization();

  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<LegalCasesDTO>(new LegalCasesDTO());
  const [legalAdviser, setLegalAdviser] = useState<LegalAdviserDTO[]>([]);
  const [legalCourts, setLegalCourts] = useState<LegalCourtsDTO[]>([]);
  const [legalMatterType, setLegalMatterTypes] = useState<MatterTypeDTO[]>([]);
  const [caseNature, setCaseNature] = useState<LegalCaseNatureDTO[]>([]);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalCasesDTO()[key as keyof LegalCasesDTO];
      setValue(key as keyof LegalCasesDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: LegalCasesDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateLegalCaseByID(params?.id, formData);
    } else {
      createLegalCase(formData, addMore, resetForm);
    }
  };

  useEffect(() => {
    getLegalCourts(setLegalCourts);
    getLegalCaseNature(setCaseNature);
    getLegalAdviser(setLegalAdviser);
    getLegalMatterTypesList(setLegalMatterTypes);
    if (params?.id) {
      getLegalCaseByID(params?.id, formData, setValue, setFormData);
    }
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
  }, []);
  return (
    <CreateLegalCasesMain>
      <CreateLegalCasesTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Legal Casses
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateLegalCasesTop>

      <CreateLegalCasesSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Court</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("court_id", { required: true })}
                  value={formData?.court_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {legalCourts?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.court_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Institution Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("institution_date", { required: true })}
                  value={formData?.institution_date}
                  onChange={handleChange}
                  placeholder="institution date"
                />
              </div>
              <FormErrorMessage error={errors.institution_date} />
            </div>
          </div>
          <div className="input-field">
            <label>Case No</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("case_no", { required: true })}
                  value={formData?.case_no}
                  onChange={handleChange}
                  placeholder="case number"
                />
              </div>
              <FormErrorMessage error={errors.case_no} />
            </div>
          </div>
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData?.title}
                  onChange={handleChange}
                  placeholder="title"
                />
              </div>
              <FormErrorMessage error={errors.case_no} />
            </div>
          </div>
          <div className="input-field">
            <label>Plaintiff / Petitioner</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("plaintiff", { required: true })}
                  value={formData?.plaintiff}
                  onChange={handleChange}
                  placeholder="petitioner"
                />
              </div>
              <FormErrorMessage error={errors.plaintiff} />
            </div>
          </div>
          <div className="input-field">
            <label>Matter Categories</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("matter_type_id", { required: true })}
                  value={formData?.matter_type_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {legalMatterType?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.matter_type_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Defendent Department</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("defendant_dept_id", { required: true })}
                  value={formData?.defendant_dept_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {departments?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.title ?? "-"}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.defendant_dept_id} />
            </div>
          </div>

          <div className="input-field">
            <label>legal Advisor</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  name=""
                  {...register("legal_advisor_id", { required: true })}
                  value={formData?.legal_advisor_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {legalAdviser?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.legal_advisor_id} />
            </div>
          </div>
          <div className="radio-field">
            <label htmlFor="no">Status</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_yes"
                  {...register("status", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData?.status == 1}
                />
                <label htmlFor="is_yes">Active</label>
              </div>

              <div className="field">
                <input
                  type="radio"
                  id="is_no"
                  {...register("status", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.status == 0}
                />
                <label htmlFor="is_no">Not Active</label>
              </div>
              <FormErrorMessage error={errors.status} />
            </div>
          </div>
          <div className="input-field">
            <label>Case Nature</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("nature_id", { required: true })}
                  value={formData?.nature_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {caseNature?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.nature_id} />
            </div>
          </div>
        </div>
        <div className="common-fields">
          <div className="input-field">
            <label>Prayer</label>
            <div className="field-wrap">
              <textarea
                {...register("prayer", { required: true })}
                value={formData?.prayer}
                onChange={handleChange}
                placeholder="prayer"
              ></textarea>
            </div>
            <FormErrorMessage error={errors.prayer} />
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button
              type="reset"
              className="lg-rounded-btn gray"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateLegalCasesSection>
    </CreateLegalCasesMain>
  );
};

export default CreateLegalCases;
