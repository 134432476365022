import { FC, Fragment, useEffect, useState } from "react";
import {
  DownArrowLightgrayMediumSvg,
  DownloadTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicEnrollmentsListingMain,
  AcademicEnrollmentsListingSection,
  AcademicEnrollmentsListingTop,
  Filters,
  FilterSection,
  FilterHeader,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";
import useAcademics from "../../useHooks";
import ManageEnrollmentModal from "../../grade-books/components/manage-enrollmets";
import { CertificateLevelDTO } from "utils/helpers/models/organization/certificate-levels.dto";
import useOrganization from "containers/private/organization/useHooks";
import useAdmissions from "containers/private/admissions/useHooks";
import { EnrollmentsListDTO } from "utils/helpers/models/academics/enrollments-list.dto";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";

const AcademicEnrollmentsListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: "" },
    {
      title: "Students Management / ",
      path: "",
    },
    { title: "Enrollments", path: siteRoutes.academicEnrollmentsListing },
  ];
  const columns: string[] = [
    "Registration No.",
    "Student Name",
    "Campus",
    "Program",
    "Repeater",
    "Status",
  ];

  const { getAcademicSessions } = useAcademics();
  const { getPrograms } = useOrganization();
  const {
    getTeachers,
    getSessionAllocatedCourses,
    getEnrollmentsLists,
    deleteManageEnrollementsMultipleData,
  } = useAcademics();
  const [openEnrollmentsModal, setOpenEnrollmentsModal] =
    useState<boolean>(false);

  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [data, setData] = useState<EnrollmentsListDTO[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    program_level_id: "",
    session_id: "",
    course_id: "",
    teacher_id: "",
    reg_number: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const [courses, setCourses] = useState<any[]>([]);
  const [courseId, setCourseId] = useState<number>(0);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [sessions, setSessions] = useState<AcademicSessionDTO[]>([]);
  const [teachers, setTeachers] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    filters[name] = value;
    setFilters({ ...filters });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllEnrollmentsList(pagination?.page, search, pagination?.per_page);
  };

  const handleCourseSelection = (courseId: number) => {
    setSelectedStudents((prevSelectedCourses) => {
      if (prevSelectedCourses.includes(courseId)) {
        return prevSelectedCourses.filter((id) => id !== courseId);
      } else {
        return [...prevSelectedCourses, courseId];
      }
    });
  };

  const handleSelectAll = () => {
    setIsSelectedAll((prevState) => {
      const newIsSelectedAll = !prevState;
      const newSelectedCourses = newIsSelectedAll
        ? data?.map((item) => item?.id)
        : [];
      setSelectedStudents(newSelectedCourses);
      return newIsSelectedAll;
    });
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllEnrollmentsList(page + 1, search, per_page);
  };

  const onApplyFilters = () => {
    getAllEnrollmentsList(pagination?.page, search, pagination?.per_page);
  };

  const handleDeleteMultipleRecords = async () => {
    const response = await confirmationPopup(
      "Are You Want To Delete Enrollments"
    );
    if (response.isConfirmed) {
      const record_ids: string = selectedStudents?.join(", ");
      const course_id = courseId;
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );

      const body = {
        enrollment_ids: record_ids,
      };
      const queryParams = {
        per_page: pagination?.per_page,
        page: pagination?.page,
        search,
        ...filteredFilters,
      };
      deleteManageEnrollementsMultipleData(
        body,
        setData,
        course_id,
        "enrollments",
        setSelectedStudents,
        setIsSelectedAll,
        queryParams
      );
    }
  };

  const getAllEnrollmentsList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    const queryParams = {
      per_page,
      page,
      search,
      ...filteredFilters,
    };
    getEnrollmentsLists(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getSessionAllocatedCourses(setCourses);
    getAcademicSessions(setSessions);
    getTeachers(setTeachers, { per_page: "All" });
    getPrograms(setPrograms);
    getAllEnrollmentsList(pagination.page, search, pagination?.per_page);

    if (data?.length > 0) {
      const x = data?.map((i) => i?.course_id)[0];
      setCourseId(x);
    }
  }, []);

  return (
    <AcademicEnrollmentsListingMain>
      <AcademicEnrollmentsListingTop>
        <div className="left">
          <span className="page-heading">Enrollments</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <button className="lg-rounded-btn black">Upload File</button>
          <button
            className="lg-rounded-btn"
            onClick={() => setOpenEnrollmentsModal(true)}
          >
            + Add Student
          </button>
          {selectedStudents?.length > 0 && (
            <button
              className="lg-rounded-btn red"
              onClick={handleDeleteMultipleRecords}
            >
              Delete ( {selectedStudents?.length} ) Enrolment
            </button>
          )}
        </div>
      </AcademicEnrollmentsListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Select Academics Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_id"
                      value={filters.session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {sessions?.map((session, index) => {
                        return (
                          <option value={session.id} key={index}>
                            {session.type} {session.year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Teacher</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="teacher_id"
                      value={filters.teacher_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {teachers.map((teacher, index) => {
                        const {
                          teacher_lastname = "",
                          teacher_firstname = "",
                        } = teacher || {};
                        const { title: teacher_title = "" } =
                          teacher.teacher_title || {};
                        return (
                          <option
                            value={teacher?.id}
                            key={index}
                          >{`${teacher_title} ${teacher_firstname} ${teacher_lastname}`}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.program_level_id}
                      onChange={handleFilterChange}
                      name="program_level_id"
                    >
                      <option value="">-- Select --</option>
                      {programs?.map((level, index) => {
                        return (
                          <option value={level.id} key={index}>
                            {level.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label>Select Course</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="course_id"
                      value={filters.course_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {courses.map((course, index) => {
                        return (
                          <option value={course?.id} key={index}>
                            {course?.c_title ?? "-"}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label>Registration Number</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Registration Number"
                      value={filters.reg_number}
                      onChange={handleFilterChange}
                      name="reg_number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <AcademicEnrollmentsListingSection
        isTableOverflowing={false}
        className="content-radius-shadow"
      >
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllEnrollmentsList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllEnrollmentsList)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                <td>
                  {data?.length > 0 && (
                    <div className="row-action-cell">
                      <div className="table-checkbox-field">
                        <input
                          type="checkbox"
                          checked={isSelectedAll}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>
                  )}
                </td>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((enrollment, index) => {
                const {
                  reg_number = "",
                  user = { name: "" },
                  campus = { title: "" },
                  program = { title: "" },
                  is_repeater,
                } = enrollment?.student || {};
                const { enrollment_status = { title: "" } } = enrollment;

                return (
                  <tr>
                    <td>
                      <div className="row-action-cell">
                        <div className="table-checkbox-field">
                          <input
                            type="checkbox"
                            checked={selectedStudents?.includes(enrollment?.id)}
                            onChange={() =>
                              handleCourseSelection(enrollment?.id)
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{reg_number ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150"> {user?.name ?? "-"}</div>
                    </td>
                    <td>{campus?.title ?? "-"}</td>
                    <td>
                      <div className="mw-150">{program?.title ?? "-"}</div>
                    </td>
                    <td>
                      <div className="status">
                        <span
                          className={`status-tile ${
                            is_repeater === 1 ? "green" : "red"
                          }`}
                        >
                          {is_repeater === 1 ? "Yes" : "No"}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="status-tile green mw-100">
                        {enrollment_status?.title ?? "-"}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {openEnrollmentsModal && (
            <ManageEnrollmentModal
              setOpen={setOpenEnrollmentsModal}
              course_id={courseId}
              actionToDo="add"
            />
          )}
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </AcademicEnrollmentsListingSection>
    </AcademicEnrollmentsListingMain>
  );
};

export default AcademicEnrollmentsListing;
