export class ConsolidatedResultDTO {
  program_id: number;
  campus_title: string = "";
  program_title: string = "";
  verified_courses_count: number;
  submitted_courses_count: number;

  constructor(data: Partial<ConsolidatedResultDTO> = {}) {
    Object.assign(this, data);
  }
}

export class ConsolidatedResultViewSectionDTO {
  acknowledged_courses_count: number;
  approved_courses_count: number;
  section_id: number;
  section_title: string = "";
  session_title: string = "";
  submitted_courses_count: number;
  verified_courses_count: number;

  constructor(data: Partial<ConsolidatedResultDTO> = {}) {
    Object.assign(this, data);
  }
}
export class SubmittedCoursesListDTO {
  section_id: number;
  approved_status: number;
  course_title: string = "";
  enrolled_students_count: number;
  program_title: string = "";
  receipt_acknowledged_status: number;
  section_title: string = "";
  submitted_by: string = "";
  submitted_status: number;
  tc_credit_hours: number;
  tc_total_marks: number;
  verified_status: number;

  constructor(data: Partial<ConsolidatedResultDTO> = {}) {
    Object.assign(this, data);
  }
}
