import { FC, Fragment, useEffect, useState } from "react";
import {
  StudentAssignmentsSection,
  StudentAssignmentsMain,
  StudentAssignmentsTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useStudentPortal from "../../useHooks";
import { MyEnrollmentsDTO } from "utils/helpers/models/user/my-enrollments.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

const MyEnrollments: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Portal  / ", path: "" },
    { title: "Current Enrollments", path: siteRoutes.myEnrollments },
  ];

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const columns: string[] = [
    "Course Code",
    "Course",
    "Faculty",
    "Credit Hours(Theory -Lab)",
  ];

  const { getMyEnrollment } = useStudentPortal();
  const [data, setData] = useState<MyEnrollmentsDTO[]>([]);
  const getMyEnrollments = () => {
    getMyEnrollment(setData);
  };

  useEffect(() => {
    getMyEnrollments();
  }, []);

  return (
    <StudentAssignmentsMain>
      <StudentAssignmentsTop>
        <div className="left">
          <span className="page-heading">Current Enrollments</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </StudentAssignmentsTop>

      <StudentAssignmentsSection className="content-radius-shadow">
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.course?.c_code}</td>

                    <td>
                      <div className="mw-150">
                        {item?.course?.c_title ?? "-"}
                      </div>
                    </td>

                    <td>
                      <div>==</div>
                    </td>
                    <td>==</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </StudentAssignmentsSection>
      <Fragment>
        <DataNotFound show={!isLoading && !data.length} />
      </Fragment>
    </StudentAssignmentsMain>
  );
};

export default MyEnrollments;
