import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import TimeTableListing from "containers/private/time-table/time-table/listing";
import TimeTableCreate from "containers/private/time-table/time-table/create";
import TimeTablePlannerCreate from "containers/private/time-table/planner/planner/create";
import TimeTablePlannerListing from "containers/private/time-table/planner/planner/listing";
import TimeTablePlannerDashboard from "containers/private/time-table/planner/pages/dashboard";
import DefaultCreditHoursListing from "containers/private/time-table/planner/pages/defaut-credit-hours";
import RoomToSectionListing from "containers/private/time-table/planner/pages/room-to-section-list/listing";
import RoomToSectionCreate from "containers/private/time-table/planner/pages/room-to-section-list/create";
import PrefferedRoomCourseListing from "containers/private/time-table/planner/pages/room-to-course-list/listing";
import PrefferedRoomCourseListCreate from "containers/private/time-table/planner/pages/room-to-course-list/create";

const TimeTableRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();

  console.log(getPathToSetRoute(siteRoutes.roomToSectionListing, true));

  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.timeTableListing)}
        Component={TimeTableListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTableCreate)}
        Component={TimeTableCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTablePlannerCreate, true)}
        Component={TimeTablePlannerCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTablePlannerListing, true)}
        Component={TimeTablePlannerListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.timeTablePlannerDashboard, true)}
        Component={TimeTablePlannerDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.defaultCreditHoursListing, true)}
        Component={DefaultCreditHoursListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.prefferedRoomCourseListing, true)}
        Component={PrefferedRoomCourseListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.prefferedRoomCourseListCreate, true)}
        Component={PrefferedRoomCourseListCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.roomToSectionListing, true)}
        Component={RoomToSectionListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.roomToSectionCreate, true)}
        Component={RoomToSectionCreate}
      />
    </Routes>
  );
};

export default TimeTableRoutes;
