import useStore from "hooks/useStore";
import { ADMIN_APIS } from "libs/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { LoginFormDTO } from "utils/helpers/models/auth/login";
import { ChangePasswordDTO } from "utils/helpers/models/e-portal/change-passwords.dto";

const useUsers = () => {
  // const { postRequest } = useHttp();
  const {
    setToken,
    setUser,
    setPermissions,
    setOrganization,
    setRole,
    setLastLoggedInUserRole,
  } = useStore();

  const navigate = useNavigate();

  const userLogin = async (body: LoginFormDTO) => {
    const response = await ADMIN_APIS.adminLogin(body);
    if (response?.status) {
      setOrganization(response?.organization);
      const userRole = response?.data?.roles[0];
      setRole(userRole);
      delete response?.data?.role_id;
      delete response?.data?.roles;
      setUser(response?.data);
      setToken(response?.token);
      setLastLoggedInUserRole(ROLES.ADMIN);
      const permissionsToSet = [];
      response?.permissions?.forEach(({ permissions }) => {
        permissions.map((permission: any) => {
          const doesAlreadyExist = permissionsToSet?.find(
            (i) => i.name === permission?.name
          );
          if (!doesAlreadyExist) {
            permissionsToSet?.push(permission);
          }
        });
      });

      setPermissions(permissionsToSet);
      if (userRole?.guard_name === "students") {
        navigate(siteRoutes.studentDashboard);
      } else {
        navigate(siteRoutes.adminDashboard);
      }
      successToaster(response?.message);
    }
  };

  const changeAdminPassword = async (
    body: ChangePasswordDTO,
    setOpen: (open: boolean) => void
  ) => {
    const response = await ADMIN_APIS.changeAdminPassword(body);
    const { status = false, message = "" } = response || {};
    if (status) {
      setOpen(false);
      successToaster(message);
    }
  };

  return {
    userLogin,
    changeAdminPassword,
  };
};

export default useUsers;
