import { FC, useEffect, useState } from "react";
import { AllocateCourseRoomsMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import { AddCourseRoomDTO } from "utils/helpers/models/academics/academic-session.dto";
import { warningToaster } from "utils/helpers/common/alert-service";

import FormErrorMessage from "components/particles/forms/form-error-message";
import { useForm } from "react-hook-form";
import { UpdateFeePlanQuotaDTO } from "utils/helpers/models/academics/students-listing-dto";

interface AllocateCourseRoomsProps {
  setOpen: Function;
  student_id: number;
}

const UpdateFeePlanQuotaModal: FC<AllocateCourseRoomsProps> = ({
  setOpen,
  student_id,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<UpdateFeePlanQuotaDTO>();

  const [formData, setFormData] = useState<UpdateFeePlanQuotaDTO[]>([]);

  const onSubmit = () => {
    // if (!formData.room_id) {
    //   warningToaster("Please select the room");
    // } else {
    //   addCourseRoom(formData, setOpen);
    // }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    // getRooms(setRooms);
  }, []);
  return (
    <AllocateCourseRoomsMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Update Fee Plan/Quota</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Fee Plan</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("fee_plan_id", { required: true })}
                      // value={formData.fee_plan_id}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                    </select>
                  </div>
                  <FormErrorMessage error={errors.fee_plan_id} />
                </div>
              </div>
              <div className="input-field">
                <label>Fee Quota</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("fee_quota_id", { required: true })}
                      // value={formData.fee_quota_id}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                    </select>
                  </div>
                </div>
                <FormErrorMessage error={errors.fee_quota_id} />
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button
                  className="lg-rounded-btn"
                  type="button"
                  onClick={onSubmit}
                >
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AllocateCourseRoomsMain>
  );
};

export default UpdateFeePlanQuotaModal;
