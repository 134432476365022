import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";

import {
  CreateTimeTableForm,
  CreateTimeTableMain,
  CreateTimeTableTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { TimeTableDTO } from "utils/helpers/models/time-table/time-table.dto";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import useTimeTable from "../../UseHooks";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";

interface CreateTimeTableProps {}

const TimeTableCreate: FC<CreateTimeTableProps> = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { hasAccess } = useStore();

  const { updateTimeTableById, createTimeTable, getTimeTableById } =
    useTimeTable();

  const { getSpecificOrgStructures } = useOrganization();

  const breadcrumbLinks = [
    {
      title: "Time Table /",
      path: siteRoutes.timeTableListing,
    },
    {
      title: `Time Table Listing / `,
      path: siteRoutes.timeTableListing,
    },
    {
      title: `Time Table ${params?.id ? "Update" : "Create"}`,
      path: siteRoutes.timeTableCreate,
    },
  ];

  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<TimeTableDTO>(new TimeTableDTO());

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<TimeTableDTO>({ defaultValues: formData });

  const handleChange = (event: any): void => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      if (key !== "header_id") {
        setValue(key as keyof TimeTableDTO, "");
      }
    }

    setFormData({
      ...new TimeTableDTO(),
    });
  };

  const onSubmit = (data: TimeTableDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateTimeTableById(params?.id, data);
    } else {
      createTimeTable(data, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params.id) {
      getTimeTableById(params?.id, setFormData, formData, setValue);
    }
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
  }, []);

  return (
    <CreateTimeTableMain>
      <CreateTimeTableTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Time Table
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateTimeTableTop>

      <CreateTimeTableForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field ">
            <label>Center Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData?.title}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <div className="input-field">
            <label>Start Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("start_date", { required: true })}
                  value={formData.start_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.start_date} />
            </div>
          </div>
          <div className="input-field">
            <label>End Date</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("end_date", { required: true })}
                  value={formData.end_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.end_date} />
            </div>
          </div>

          <div className="input-field">
            <label>Campus</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("campus_id", { required: true })}
                  value={formData.campus_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {campuses?.map((campus, index) => {
                    return (
                      <option key={index} value={campus.id}>
                        {campus.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.campus_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Is Admin</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_admin", { required: true })}
                  value={formData.is_admin}
                  onChange={handleChange}
                >
                  <option value="">Select Semester Type</option>
                  <option value={1}>Admin</option>
                  <option value={0}>Not Admin</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_admin} />
            </div>
          </div>

          <div className="radio-field">
            <label htmlFor="no">Status</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_active_yes"
                  {...register("is_active", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_active === 1}
                />
                <label htmlFor="is_active_yes">Active</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_active_no"
                  {...register("is_active", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_active === 0}
                />
                <label htmlFor="is_active_no">Not Active</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_active} />
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <>
                {hasAccess(sitePermissions.timeTableCreate) && (
                  <button
                    className="lg-rounded-btn black"
                    onClick={handleSubmit((data) => onSubmit(data, true))}
                  >
                    Save & Add more
                  </button>
                )}
              </>
            )}

            {hasAccess(sitePermissions.timeTableCreate) && (
              <button
                className="lg-rounded-btn"
                onClick={handleSubmit((data) => onSubmit(data))}
              >
                {params?.id ? "Update" : "Save"} & Exit
              </button>
            )}
          </div>
        </div>
      </CreateTimeTableForm>
    </CreateTimeTableMain>
  );
};

export default TimeTableCreate;
