import { FC, Fragment, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useSystemAdministration from "containers/private/system-administration/useHooks";
import useAcademics from "containers/private/academics/useHooks";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useOrganization from "containers/private/organization/useHooks";
import { errorToaster } from "utils/helpers/common/alert-service";
import { ErrorMessage } from "components/particles/forms/form-error-message/style";

interface pageProps {
  setOpen: Function;
  feePlanId: number;
}

interface dataProps {
  program_id: number;
}

const ManageFeePlanModal: FC<pageProps> = ({ setOpen, feePlanId }) => {
  const { hasAccess } = useStore();
  const { updateManageProgrameFee } = useAcademics();
  const { getPrograms } = useOrganization();
  const [data, setData] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({});

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<dataProps>();

  const { updateGradeBookResultApproveStatus } = useAcademics();

  const handleChange = (event: any) => {
    const {
      target: { name, value },
    } = event;
    setValue(name, value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    const body = {
      ...formData,
      fee_plan_id: feePlanId,
    };
    updateManageProgrameFee(body, setOpen);
  };

  useEffect(() => {
    getPrograms(setData);
  }, []);

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Manage Programe Fee</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-field">
              <label>Select Programe</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("program_id", { required: true })}
                    value={formData?.program_id}
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    {Array.isArray(data) &&
                      data.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item?.id}>
                            {item?.title ?? "-"}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <FormErrorMessage error={errors.program_id} />
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Submit & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default ManageFeePlanModal;
