import { FC, Fragment, useState, useEffect } from "react";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicStudentListingMain,
  AcademicStudentListingSection,
  AcademicStudentListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "containers/private/organization/useHooks";
import useAcademics from "../useHooks";
import { AddProgramDTO as ProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { CertificateDTO } from "utils/helpers/models/organization/certificate.dto";
import { CertificateLevelDTO as CertificateLevelDTO } from "utils/helpers/models/organization/certificate-levels.dto";
import { OrgStructureDTO as OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { StudentStatusDTO } from "utils/helpers/models/academics/student-status.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import UpdateFeePlanQuotaModal from "./components/updateFeePlanQuota";
import { StudentListingDTO } from "utils/helpers/models/academics/students-listing-dto";

const AcademicStudent: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },

    { title: "Students", path: siteRoutes.academicStudent },
  ];
  const columns: string[] = [
    "",
    "Registration #",
    "Student Name",
    "Program",
    "CNIC",
    "Plan Of Study",
    "Student Status",
  ];
  const [search, setSearch] = useState<string>("");
  const {
    getCitiesAdmin,
    getOrgStructures,
    getCertificateLevelsAdmin,
    getPrograms,
  } = useOrganization();
  const { getStudentStatuses, getAcademicStudents } = useAcademics();
  const [cities, setCities] = useState<any[]>([]);
  const [programs, setPrograms] = useState<ProgramDTO[]>([]);
  const [certificateLevels, setCertificateLevels] = useState<
    CertificateLevelDTO[]
  >([]);
  const [orgStructures, setOrgStructures] = useState<OrgStructureDTO[]>([]);
  const [studentStatuses, setStudentStatuses] = useState<StudentStatusDTO[]>(
    []
  );
  const [openFeePlanQuotaModal, setOpenFeePlanQuotaModal] =
    useState<boolean>(false);
  const [studentId, setStudentId] = useState<number>(0);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [filters, setFilters] = useState<any>({
    name: "",
    father_name: "",
    reg_number: "",
    cnic: "",
    std_status_id: "",
    gender: "",
    religion: "",
    academic_session_id: "",
    campus_id: "",
    department_id: "",
    faculty_id: "",
    program_level_id: "",
    program_id: "",
    fee_plan_id: "",
    domicile_id: "",
    city_id: "",
    pos_id: "",
    pos_assigned: "",
    currently_enrolled: "",
    passed_out: "",
  });
  const [data, setData] = useState<StudentListingDTO[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const toggleRowExpand = (index: number) => {
    data[index].isExpanded = !data[index].isExpanded;
    setData([...data]);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };
  const handleOpenFeePlanQuotaModal = (id: number) => {
    setStudentId(id);
    setOpenFeePlanQuotaModal(true);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    filters[name] = value;
    setFilters({ ...filters });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllStudentsList(1, search, filters);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      // deleteAcademicSession(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllStudentsList(page + 1, search, filters);
  };
  const getAllStudentsList = (page: number, search: string, filters: any) => {
    const filtersToSend: any = {};
    for (let key in filters) {
      if (filters[key]) filtersToSend[key] = filters[key];
    }

    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      ...filtersToSend,
    };
    getAcademicStudents(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllStudentsList(pagination.page, search, filters);
    getPrograms(setPrograms);
    getOrgStructures(setOrgStructures);
    getCertificateLevelsAdmin(setCertificateLevels);
    getCitiesAdmin(setCities);
    getStudentStatuses(setStudentStatuses);
  }, []);

  return (
    <AcademicStudentListingMain>
      <AcademicStudentListingTop>
        <div className="left">
          <span className="page-heading">Students </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn">Download List</button>
          </div>
        </div>
      </AcademicStudentListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Student Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Student Name"
                      value={filters.name}
                      onChange={handleFilterChange}
                      name="name"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Father Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Father Name"
                      value={filters.father_name}
                      onChange={handleFilterChange}
                      name="father_name"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="31303-********-*"
                      value={filters.cnic}
                      onChange={handleFilterChange}
                      name="cnic"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Student Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.std_status_id}
                      onChange={handleFilterChange}
                      name="std_status_id"
                    >
                      <option value="">-- Select --</option>
                      {studentStatuses.map((status, index) => {
                        return (
                          <option value={status.id} key={index}>
                            {status.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Gender</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="gender"
                      value={filters.gender}
                      onChange={handleFilterChange}
                    >
                      <option value="" disabled>
                        -- Select --
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Religion</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.religion}
                      onChange={handleFilterChange}
                      name="religion"
                    >
                      <option value="">-- Select --</option>
                      <option>Islam</option>
                      <option>Christianity</option>
                      <option>Judism</option>
                      <option>Jewism</option>
                      <option>Hindu</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label>Select campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.campus_id}
                      onChange={handleFilterChange}
                      name="campus_id"
                    >
                      <option value="">-- Select --</option>
                      {orgStructures.map((structure, index) => {
                        return (
                          <option value={structure.id} key={index}>
                            {structure.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Faculty</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.faculty_id}
                      onChange={handleFilterChange}
                      name="faculty_id"
                    >
                      <option value="">-- Select --</option>
                      {orgStructures.map((structure, index) => {
                        return (
                          <option value={structure.id} key={index}>
                            {structure.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.department_id}
                      onChange={handleFilterChange}
                      name="department_id"
                    >
                      <option value="">-- Select --</option>
                      {orgStructures.map((structure, index) => {
                        return (
                          <option value={structure.id} key={index}>
                            {structure.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program Level</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.program_level_id}
                      onChange={handleFilterChange}
                      name="program_level_id"
                    >
                      <option value="">-- Select --</option>
                      {certificateLevels.map((level, index) => {
                        return (
                          <option value={level.id} key={index}>
                            {level.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Program </label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.program_id}
                      onChange={handleFilterChange}
                      name="program_id"
                    >
                      <option value="">-- Select --</option>
                      {programs.map((program, index) => {
                        return (
                          <option value={program.id} key={index}>
                            {program.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select Domicile</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.domicile_id}
                      onChange={handleFilterChange}
                      name="domicile_id"
                    >
                      <option value="">-- Select --</option>
                      {cities.map((city, index) => {
                        return (
                          <option value={city.id} key={index}>
                            {city.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Select City</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.city_id}
                      onChange={handleFilterChange}
                      name="city_id"
                    >
                      <option value="">-- Select --</option>
                      {cities.map((city, index) => {
                        return (
                          <option value={city.id} key={index}>
                            {city.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Registration Number</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Registration Number"
                      value={filters.reg_number}
                      onChange={handleFilterChange}
                      name="reg_number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={() => getAllStudentsList(1, search, filters)}
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <AcademicStudentListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((student, index) => (
                <Fragment key={index}>
                  <tr
                    className={`expandable ${student.isExpanded && "opened"}`}
                  >
                    <td>
                      <div
                        className="rounded-expand-button"
                        onClick={() => toggleRowExpand(index)}
                      >
                        <span>{student.isExpanded ? "-" : "+"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{student?.reg_number ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-100">
                        {student?.user?.first_name
                          ? `${student?.user?.first_name} ${student?.user?.last_name}`
                          : "-"}
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">
                        {student?.program?.title || "-"}
                      </div>
                    </td>
                    <td>
                      <div className="mw-150">{student?.user?.cnic ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{student?.pos?.title || "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">
                        {student?.std_status?.title ?? "-"}
                      </div>
                    </td>
                  </tr>

                  {student.isExpanded && (
                    <tr>
                      <td></td>
                      <td colSpan={5}>
                        <div className="expanded-content">
                          {/* Expanded Row Details */}

                          <div className="particular-info">
                            <span className="title">Father Name</span>
                            <div className="info">
                              <span className="status">
                                {" "}
                                {student.user.users_meta[0].father_name ?? "--"}
                              </span>
                            </div>
                          </div>

                          <div className="particular-info">
                            <span className="title"> Campus</span>
                            <div className="info">
                              <span className="status">
                                {student?.campus?.title ?? "-"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info">
                            <span className="title">Gender</span>
                            <div className="info">
                              <span className="status">
                                {student?.user?.gender ?? "-"}
                              </span>
                            </div>
                          </div>

                          <div className="particular-info">
                            <span className="title">Fee Plan</span>
                            <div className="info">
                              <span className="status">
                                {student?.fee_plan?.title ?? "-"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info">
                            <span className="title">Fee Quota</span>
                            <div className="info">
                              <span className="status">
                                {student?.fee_quota?.title ?? "-"}
                              </span>
                            </div>
                          </div>
                          <div className="particular-info">
                            <span className="title">Fee Remarks</span>
                            <div className="info">
                              <span className="status">
                                {student?.fee_remarks ?? "-"}
                              </span>
                            </div>
                          </div>

                          <div className="particular-info">
                            <span className="title">Action</span>
                            <div className="info">
                              <div className="table-action-icons">
                                <div className="action-button">
                                  <button className="criteria-btn">
                                    View Transcript
                                  </button>
                                </div>

                                <div className="action-button">
                                  <button>Enrollments</button>
                                </div>

                                <div className="action-button">
                                  <button>Vouchers</button>
                                </div>

                                <Fragment>
                                  <div className="action-button">
                                    <button className="criteria-btn">
                                      Student Status
                                    </button>
                                  </div>

                                  <div className="action-button">
                                    <button className="seats-btn">
                                      Migrated Student Courses
                                    </button>
                                  </div>

                                  <div className="action-button">
                                    <button className="special-btn">
                                      Fulfillment Report
                                    </button>
                                  </div>

                                  <div className="action-button">
                                    <button className="entry-test-btn">
                                      Add Retention
                                    </button>
                                  </div>

                                  <div className="action-button">
                                    <button
                                      className="update-plan-quota-btn"
                                      onClick={() =>
                                        handleOpenFeePlanQuotaModal(student?.id)
                                      }
                                    >
                                      Update Fee (Plan/Quota)
                                    </button>
                                  </div>
                                </Fragment>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <Fragment>
          {openFeePlanQuotaModal && (
            <UpdateFeePlanQuotaModal
              setOpen={setOpenFeePlanQuotaModal}
              student_id={studentId}
            />
          )}
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </AcademicStudentListingSection>
    </AcademicStudentListingMain>
  );
};

export default AcademicStudent;
