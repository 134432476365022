import { FC, Fragment, useEffect, useState } from "react";
import {
  FDContentSection,
  FDTopSection,
  FilterHeader,
  Filters,
  FilterSection,
  FinanceDashboardMain,
  FSListingMain,
  FSListingSection,
  HeadlineCard,
  HeadlineCards,
  LinkSection,
  MainStatCard,
  MainStatCards,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useDSSystem from "../useHook";

import {
  DummyHeadlineGraphSvg,
  GreenFileBgSvg,
} from "assets/images/finance/svgs";
import { Bar } from "react-chartjs-2";

import {
  AuthLinkSvg,
  DownArrowLightgrayMediumSvg,
  JobsSvg,
} from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import {
  ExecutiveSummarryDTO,
  SemesterFeeDTO,
  SemesterFeeInstallmentsDTO,
  YearsWiseSummarryDTO,
} from "utils/helpers/models/ds-system/fee-income-dashboard-data.dto";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface FeeCardItem {
  title: string;
  subTitles: string[];
  values: (string | number)[];
  color: string[];
  Icon: any;
}
interface FinanceDashboardData {
  total_fee?: {
    total_fee?: number;
    total_paid_fee?: number;
    total_pending_fee?: number;
  };
  total_semester_fee?: {
    total_fee?: number;
    total_paid_fee?: number;
    total_pending_fee?: number;
  };
  total_admission_fee?: {
    total_fee?: number;
    total_paid_fee?: number;
    total_pending_fee?: number;
  };
}
interface FinanceDashboardProps {}

const FeeIncomeDashboard: FC<FinanceDashboardProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Decision Support System /", path: "" },
    { title: "Fee Income Dashboard", path: siteRoutes.feeIncomeDashboard },
  ];

  const { getSpecificOrgStructures } = useOrganization();
  const { getAcademicSessions } = useAcademics();
  const {
    getFinanceDashboard,
    getExecutiveSummaryFeeIncomeDashboard,
    getSemesterFeeIncomeDashboard,
    getSemesterFeeInstallments,
    getYearWiseSummaryFee,
  } = useDSSystem();

  const [currentDate, setCurrentDate] = useState("");
  const [dashboardData, setDashboardData] = useState<FinanceDashboardData>({});
  const [activeTab, setActiveTab] = useState<string>("Executive Summary");
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>({
    campus: "",
    departments: "",
    academic_session: "",
  });
  const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>(
    []
  );
  const [yearWiseSummary, setYearsWiseSummary] = useState<
    YearsWiseSummarryDTO[]
  >([]);
  const [executiveSummarry, setExecutiveSummary] =
    useState<ExecutiveSummarryDTO>();
  const [semesterFeeInstallmentsSummary, setSemesterFeeInstallmentsSummary] =
    useState<SemesterFeeInstallmentsDTO[]>([]);
  const [semesterFeeInstallmentsDetails, setSemesterFeeInstallmentsDetails] =
    useState<SemesterFeeInstallmentsDTO[]>([]);
  const [semesterFee, setSemesterFee] = useState<SemesterFeeDTO>();
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleDateChange = (event: any) => {
    setCurrentDate(event.target.value);
  };

  const handleResetFeeIncomeBottomFilters = () => {
    setFilters({
      campus: "",
      departments: "",
      academic_session: "",
    });
  };

  const handleApplyFeeIncomeFilters = () => {
    const filtersToSend = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value)
    );

    getExecutiveSummaryFeeIncomeDashboard(setExecutiveSummary, filtersToSend);
    getSemesterFeeIncomeDashboard(setSemesterFee, filtersToSend);
    getSemesterFeeInstallments(
      setSemesterFeeInstallmentsSummary,
      setSemesterFeeInstallmentsDetails,
      filtersToSend
    );
    getYearWiseSummaryFee(setYearsWiseSummary, filtersToSend);
  };

  useEffect(() => {
    getFinanceDashboard(setDashboardData);
    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
    getAcademicSessions(setAcademicSession);
    const formattedDate = new Date().toISOString().split("T")[0];
    setCurrentDate(formattedDate);

    const filtersToSend = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value)
    );
    getExecutiveSummaryFeeIncomeDashboard(setExecutiveSummary, filtersToSend);
    getSemesterFeeIncomeDashboard(setSemesterFee, filtersToSend);
    getSemesterFeeInstallments(
      setSemesterFeeInstallmentsSummary,
      setSemesterFeeInstallmentsDetails,
      filtersToSend
    );
    getYearWiseSummaryFee(setYearsWiseSummary, filtersToSend);
  }, []);

  const {
    total_fee = {},
    total_semester_fee = {},
    total_admission_fee = {},
  } = dashboardData || {};
  const mainCard: FeeCardItem[] = [
    {
      title: "Overall Fee Status",
      subTitles: ["Total Amount", "Paid Amount", "Pending Amount"],
      color: ["#ffbb3826", "#5bc95393", "#fdc3c3b2"],
      values: [
        total_fee?.total_fee ?? "--",
        total_fee?.total_paid_fee ?? "--",
        total_fee?.total_pending_fee ?? "--",
      ],
      Icon: JobsSvg,
    },
    {
      title: "Admission Fee Status",
      subTitles: ["Total Amount", "Paid Amount", "Pending Amount"],
      color: ["#ffbb3826", "#5bc95393", "#fdc3c3b2"],
      values: [
        total_admission_fee?.total_fee ?? "--",
        total_admission_fee?.total_paid_fee ?? "--",
        total_admission_fee?.total_pending_fee ?? "--",
      ],

      Icon: JobsSvg,
    },
    {
      title: "Semester Fee Status",
      subTitles: ["Total Amount", "Paid Amount", "Pending Amount"],
      color: ["#ffbb3826", "#5bc95393", "#fdc3c3b2"],
      values: [
        total_semester_fee?.total_fee ?? "--",
        total_semester_fee?.total_paid_fee ?? "--",
        total_semester_fee?.total_pending_fee ?? "--",
      ],
      Icon: JobsSvg,
    },
  ];
  const data = [12, 19, 3, 5, 2, 3];
  const labels = ["January", "February", "March", "April", "May", "June"];
  const chartData = {
    labels,
    datasets: [
      {
        label: "Paid",
        data,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 0,
      },
    ],
  };
  const voucherCard = [
    {
      title: "Voucher served",
      amount: "1123123",
      percentage: "10%",
      icon: JobsSvg,
    },
    {
      title: "Voucher Paid",
      amount: "123123",
      percentage: "10%",
      icon: JobsSvg,
    },
    { title: "Voucher Pending", amount: "0", percentage: "0%", icon: JobsSvg },
  ];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  return (
    <FinanceDashboardMain>
      <FDTopSection>
        <div className="heading">
          <span className="page-heading">Fee Income Dashboard</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </FDTopSection>

      <FDContentSection>
        <FilterSection className="content-radius-shadow">
          <FilterHeader>
            <span className="filter-heading">Filter</span>
          </FilterHeader>

          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Start Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="date"
                      onChange={handleDateChange}
                      value={currentDate}
                      id=""
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>End Date</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="date" name="" id="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Apply Filters</button>
              </div>
            </div>
          </Filters>
        </FilterSection>

        <Fragment>
          {mainCard?.map((item, i) => {
            return (
              <LinkSection key={i} className="content-radius-shadow">
                <div className="card-title">{item.title}</div>
                <HeadlineCards>
                  <LinkSection>
                    <div className="link-card-container">
                      {item?.subTitles?.map((card, index) => {
                        const numberValue = item?.values[index];
                        return (
                          <div
                            className="link-card"
                            style={{ backgroundColor: item?.color[index] }}
                            key={index}
                          >
                            <div>
                              <item.Icon />
                            </div>
                            <div className="link-card-text">
                              <div className="link-title">{card}</div>
                              <div className="total-amount">
                                <span className="">
                                  {new Intl.NumberFormat().format(
                                    Number(numberValue)
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </LinkSection>
                </HeadlineCards>
              </LinkSection>
            );
          })}
        </Fragment>

        <FDContentSection>
          <HeadlineCards>
            {voucherCard?.map((card: any, index: number) => {
              const numberValue = card?.amount;
              return (
                <HeadlineCard key={index}>
                  <div className="header">
                    <div className="file-icon">
                      <card.icon />
                    </div>
                    <div className="title">{card?.title}</div>
                  </div>
                  <div className="lower-part">
                    <div className="graph">
                      <DummyHeadlineGraphSvg />
                    </div>
                    <div className="stats">
                      <span className="total">
                        {new Intl.NumberFormat().format(Number(numberValue))}
                      </span>
                      <span className="percentage">{card?.percentage}</span>
                    </div>
                  </div>
                </HeadlineCard>
              );
            })}
          </HeadlineCards>

          <MainStatCards>
            {[1, 1].map((card: any, index: number) => {
              return (
                <MainStatCard key={index}>
                  <div className="header-section">
                    <div className="title">
                      <span>Vouchers</span>
                    </div>
                    <div className="description">
                      Paid/ Pending for payment/ Uploaded, valid & pending
                    </div>
                  </div>

                  <div className="chart-section">
                    <Bar data={chartData} options={options} />
                  </div>
                </MainStatCard>
              );
            })}
          </MainStatCards>
        </FDContentSection>

        <FSListingMain>
          <FSListingSection className="content-radius-shadow">
            <FilterHeader $isFilterOpen={openFilterDropdown}>
              <span className="filter-heading">Filter</span>
              <span
                className="dropdown-arrow cp"
                onClick={toggleFilterDropdown}
              >
                <DownArrowLightgrayMediumSvg />
              </span>
            </FilterHeader>

            {openFilterDropdown && (
              <Filters className="income-management-filters">
                <div className="filter-fields">
                  <div className="input-field">
                    <label htmlFor="campus-select">Campus</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          id="campus"
                          name="campus"
                          value={filters?.campus}
                          onChange={handleFilterChange}
                        >
                          <option value="">-- Select --</option>
                          {campuses?.map((item: any, i: number) => {
                            return (
                              <option key={i} value={item?.id}>
                                {item?.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label htmlFor="campus-select">Departments</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          id="departments"
                          name="departments"
                          value={filters?.departments}
                          onChange={handleFilterChange}
                        >
                          <option value="">-- Select --</option>
                          {departments?.map((item: any, i: number) => {
                            return (
                              <option key={i} value={item?.id}>
                                {item?.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label htmlFor="campus-select">Academic Session</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          id="campus-select"
                          name="academic_session"
                          value={filters.academic_session}
                          onChange={handleFilterChange}
                        >
                          <option value="">-- Select --</option>
                          {academicSession?.map((item: any, i: number) => {
                            return (
                              <option key={i} value={item?.session?.id}>
                                {item?.session?.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-buttons">
                  <div className="buttons">
                    <button
                      className="lg-rounded-btn gray"
                      onClick={handleResetFeeIncomeBottomFilters}
                    >
                      Reset
                    </button>
                    <button
                      className="lg-rounded-btn"
                      onClick={handleApplyFeeIncomeFilters}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
              </Filters>
            )}

            <div className="tabs-section">
              <div className="tab-button">
                <button
                  className={`tab-btn ${
                    activeTab === "Executive Summary" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Executive Summary")}
                >
                  Executive Summary
                </button>
              </div>
              <div className="tab-button">
                <button
                  className={`tab-btn ${
                    activeTab === "Semester Fee" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Semester Fee")}
                >
                  Semester Fee
                </button>
              </div>
              {/* <div className="tab-button">
                <button
                  className={`tab-btn ${
                    activeTab === "Non Discounted Fee" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Non Discounted Fee")}
                >
                  Non Discounted Fee
                </button>
              </div> */}
              <div className="tab-button">
                <button
                  className={`tab-btn ${
                    activeTab === "Semester Fee Installments" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Semester Fee Installments")}
                >
                  Semester Fee Installments
                </button>
              </div>
              <div className="tab-button">
                <button
                  className={`tab-btn ${
                    activeTab === "Year Wise Summary" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Year Wise Summary")}
                >
                  Year Wise Summary
                </button>
              </div>
            </div>
            <div className="tab-content">
              {activeTab === "Executive Summary" && (
                <div>
                  <div className="data-table">
                    <table border={1} className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th></th>
                          <th>No of Students</th>
                          <th>Pending Amount</th>
                          <th>Paid Amount</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Installment Total</strong>
                          </td>
                          <td>
                            {executiveSummarry?.installment_total
                              ?.total_no_of_students ?? "-"}
                          </td>
                          <td>
                            {executiveSummarry?.installment_total
                              ?.total_pending_amount ?? "-"}
                          </td>
                          <td>
                            {executiveSummarry?.installment_total
                              ?.total_paid_amount ?? "-"}
                          </td>
                          <td>
                            {executiveSummarry?.installment_total
                              ?.total_amount ?? "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Regular Fee Total</strong>
                          </td>
                          <td>
                            {executiveSummarry?.regular_fee_total
                              ?.total_no_of_students ?? "-"}
                          </td>
                          <td>
                            {" "}
                            {executiveSummarry?.regular_fee_total
                              ?.total_pending_amount ?? "-"}
                          </td>
                          <td>
                            {" "}
                            {executiveSummarry?.regular_fee_total
                              ?.total_paid_amount ?? "-"}
                          </td>
                          <td>
                            {" "}
                            {executiveSummarry?.regular_fee_total
                              ?.total_amount ?? "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Ehsaas Scholarship Total</strong>
                          </td>

                          <td>
                            {executiveSummarry?.scholarship
                              ?.total_no_of_students ?? "-"}
                          </td>
                          <td>
                            {executiveSummarry?.scholarship
                              ?.total_pending_amount ?? "-"}
                          </td>
                          <td>
                            {executiveSummarry?.scholarship
                              ?.total_paid_amount ?? "-"}
                          </td>
                          <td>
                            {executiveSummarry?.scholarship?.total_amount ??
                              "-"}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Grand Total</strong>
                          </td>
                          <td>
                            <strong>
                              {executiveSummarry?.installment_total
                                ?.total_no_of_students +
                                executiveSummarry?.regular_fee_total
                                  ?.total_no_of_students +
                                executiveSummarry?.scholarship
                                  ?.total_no_of_students}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {executiveSummarry?.installment_total
                                ?.total_pending_amount +
                                executiveSummarry?.regular_fee_total
                                  ?.total_pending_amount +
                                executiveSummarry?.scholarship
                                  ?.total_pending_amount}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {executiveSummarry?.installment_total
                                ?.total_paid_amount +
                                executiveSummarry?.regular_fee_total
                                  ?.total_paid_amount +
                                executiveSummarry?.scholarship
                                  ?.total_paid_amount}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {executiveSummarry?.installment_total
                                ?.total_amount +
                                executiveSummarry?.regular_fee_total
                                  ?.total_amount +
                                executiveSummarry?.scholarship?.total_amount}
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
              {activeTab === "Semester Fee" && (
                <div>
                  <div className="data-table">
                    <table border={1} className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th>Quota</th>
                          <th colSpan={4} style={{ textAlign: "center" }}>
                            BS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Regular</strong>
                          </td>
                          <td>
                            <div className="table-status">
                              <div className="d-flex">
                                {semesterFee?.regular?.pending_students ?? "-"}{" "}
                                <span className="status-tile yellow">
                                  Student
                                </span>
                              </div>

                              <div className="d-flex">
                                {semesterFee?.regular?.total_pending_amount ??
                                  "-"}{" "}
                                <span className="status-tile red ">
                                  Pending Amount
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="table-status">
                              <div className="d-flex">
                                {semesterFee?.regular?.paid_students ?? "-"}
                                <span className="status-tile">Student</span>
                              </div>
                              <div className="d-flex">
                                {semesterFee?.regular?.total_paid_amount ?? "-"}
                                <span className="status-tile green ">
                                  Pending Amount
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Discounted Total</strong>
                          </td>
                          <td>
                            <div className="table-status">
                              <div className="d-flex">
                                {semesterFee?.discounted_total
                                  ?.pending_students ?? "-"}
                                <span className="status-tile yellow">
                                  Student
                                </span>
                              </div>
                              <div className="d-flex">
                                {semesterFee?.discounted_total
                                  ?.total_pending_amount ?? "-"}
                                <span className="status-tile red ">
                                  Pending Amount
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="table-status">
                              <div className="d-flex">
                                {semesterFee?.discounted_total?.paid_students ??
                                  "-"}
                                <span className="status-tile">Student</span>
                              </div>
                              <div className="d-flex">
                                {semesterFee?.discounted_total
                                  ?.total_paid_amount ?? "-"}
                                <span className="status-tile green ">
                                  Pending Amount
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <div className="table-status">
                              <div className="d-flex">
                                {semesterFee?.discounted_total
                                  ?.pending_students +
                                  semesterFee?.regular?.pending_students}
                                <span className="status-tile yellow">
                                  Student
                                </span>
                              </div>
                              <div className="d-flex">
                                {semesterFee?.discounted_total
                                  ?.total_pending_amount +
                                  semesterFee?.regular?.total_pending_amount}
                                <span className="status-tile red">
                                  Pending Amount
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="table-status">
                              <div className="d-flex">
                                {semesterFee?.discounted_total?.paid_students +
                                  semesterFee?.regular?.paid_students}
                                <span className="status-tile">Student</span>
                              </div>
                              <div className="d-flex">
                                {semesterFee?.discounted_total
                                  ?.total_paid_amount +
                                  semesterFee?.regular?.total_paid_amount}
                                <span className="status-tile green ">
                                  Pending Amount
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
              {/* {activeTab === "Non Discounted Fee" && (
                <div>
                  <div className="data-table">
                    <table border={1} className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th>Quota</th>
                          <th colSpan={4} style={{ textAlign: "center" }}>
                            BS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>TITLE</td>
                          <td>
                            <div className="table-status">
                              <span className="status-tile yellow">
                                Students
                              </span>
                              <span className="status-tile red">Pending</span>
                            </div>
                          </td>
                          <td>
                            <div className="table-status">
                              <span className="status-tile yellow">
                                Students
                              </span>
                              <span className="status-tile green">Paid</span>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Non Discounted Fee</td>
                          <td>
                            <div className="table-status">
                              <span className=" ">3901</span>
                              <span className="">1231231</span>
                            </div>
                          </td>
                          <td>
                            <div className="table-status">
                              <span className="">345533</span>
                              <span className="">43554</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <div className="table-status">
                              <span className="">
                                <strong>345533</strong>
                              </span>
                              <span className="">
                                <strong>43554</strong>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="table-status">
                              <span className="">
                                <strong>345533</strong>
                              </span>
                              <span className="">
                                <strong>43554</strong>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )} */}
              {activeTab === "Semester Fee Installments" && (
                <div>
                  <div className="data-table">
                    <div className="table-heading">Installments Summary</div>
                    <table border={1} className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th>No of Installments</th>
                          <th>No of Students</th>
                          <th>Pending Amount</th>
                          <th>Paid Amount</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {semesterFeeInstallmentsSummary?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.installment_no ?? "-"}</td>
                              <td>{item?.total_students ?? "-"}</td>
                              <td>{item?.total_pending_amount ?? "-"}</td>
                              <td>{item?.total_paid_amount ?? "-"}</td>
                              <td>{item?.total_amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Grand Total</strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsSummary?.reduce(
                                (acc, i) => acc + i.total_students,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsSummary?.reduce(
                                (acc, i) => acc + i.total_pending_amount,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsSummary?.reduce(
                                (acc, i) => acc + i.total_paid_amount,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsSummary?.reduce(
                                (acc, i) => acc + i.total_amount,
                                0
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="data-table">
                    <div className="table-heading">Installments Details</div>
                    <table border={1} className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th>Installment#</th>
                          <th>Installments Pending</th>
                          <th>Amount Pending</th>
                          <th>Installment Paid</th>
                          <th>Amount Paid</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {semesterFeeInstallmentsDetails?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.installment_no ?? "-"}</td>
                              <td>{item?.total_pending_installments ?? "-"}</td>
                              <td>{item?.total_pending_amount ?? "-"}</td>
                              <td>{item?.total_paid_installments ?? "-"}</td>
                              <td>{item?.total_paid_amount ?? "-"}</td>
                              <td>{item?.total_amount ?? "-"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Grand Total</strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsDetails?.reduce(
                                (acc, i) => acc + i.total_pending_installments,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsDetails?.reduce(
                                (acc, i) => acc + i.total_pending_amount,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {semesterFeeInstallmentsDetails?.reduce(
                                (acc, i) => acc + i.total_paid_installments,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsDetails?.reduce(
                                (acc, i) => acc + i.total_paid_amount,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {semesterFeeInstallmentsDetails?.reduce(
                                (acc, i) => acc + i.total_amount,
                                0
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
              {activeTab === "Year Wise Summary" && (
                <div>
                  <div className="data-table">
                    <div className="table-heading">
                      Year Wise Semester Fee Summary
                    </div>
                    <table border={1} className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          <th>Year</th>
                          <th>Pending Vouchers</th>
                          <th> Pending Amount</th>
                          <th>Paid Vouchers</th>
                          <th> Paid Amount</th>
                          <th>Total Vouchers</th>
                          <th>Total Amount</th>
                          <th>% Pending</th>
                        </tr>
                      </thead>
                      <tbody>
                        {yearWiseSummary?.map((x, i) => {
                          return (
                            <tr>
                              <td>{x?.year ?? "-"}</td>
                              <td>{x?.total_pending_vouchers ?? "-"}</td>
                              <td>{x?.total_pending_amount ?? "-"}</td>
                              <td>{x?.total_paid_vouchers ?? "-"}</td>
                              <td>{x?.total_paid_amount ?? "-"}</td>
                              <td>{x?.total_vouchers ?? "-"}</td>
                              <td>{x?.total_amount ?? "-"}</td>
                              <td>{x?.pending_percentage ?? "-"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Grand Total</strong>
                          </td>
                          <td>
                            <strong>
                              {yearWiseSummary?.reduce(
                                (acc, i) => acc + i.total_pending_vouchers,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {yearWiseSummary?.reduce(
                                (acc, i) => acc + i.total_pending_amount,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {yearWiseSummary?.reduce(
                                (acc, i) => acc + i.total_paid_vouchers,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {yearWiseSummary?.reduce(
                                (acc, i) => acc + i.total_paid_amount,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {yearWiseSummary?.reduce(
                                (acc, i) => acc + i.total_vouchers,
                                0
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {" "}
                              {yearWiseSummary?.reduce(
                                (acc, i) => acc + i.total_amount,
                                0
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </FSListingSection>
        </FSListingMain>
      </FDContentSection>
    </FinanceDashboardMain>
  );
};

export default FeeIncomeDashboard;
