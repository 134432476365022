import { FC, Fragment, useEffect, useState } from "react";
import {
  StudentVouchersSection,
  StudentVouchersMain,
  StudentVouchersTop,
  FilterSection,
  FilterHeader,
  Filters,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useFinance from "containers/private/finance/useHooks";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "containers/private/academics/useHooks";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { FinanceStudentVouchersDTO } from "utils/helpers/models/finance/finance-student-vouchers.dto";

interface Props {}

const StudentVouchers: FC<Props> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Finance / ", path: "" },
    { title: "Student Vouchers", path: siteRoutes.studentFinancialVouchers },
  ];
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const { getStudentVouchers } = useFinance();
  const [data, setData] = useState<FinanceStudentVouchersDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [academicSessions, setAcademicSessions] = useState<
    AcademicSessionDTO[]
  >([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<any>({
    status: "",
    session_id: "",
  });
  const columns: string[] = [
    "Reg Number",
    "Name",
    "CNIC",
    "Program",
    "Department",
    "Session",
    "Status",
  ];

  const challanStatusOptions = [
    { title: "All", value: "" },
    { title: "Paid", value: "Paid" },
    { title: "Pending", value: "Pending" },
  ];

  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getAcademicSessions } = useAcademics();

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllStudentVouchers(page + 1, search, per_page, filters);
  };
  const getAllStudentVouchers = (
    page: number,
    search: string,
    per_page: number,
    filters: any
  ) => {
    const filtersToSend: any = {};
    for (let key in filters)
      if (filters[key]) filtersToSend[key] = filters[key];
    const queryParams = {
      per_page,
      page,
      search,
      ...filtersToSend,
    };
    getStudentVouchers(setData, queryParams, setPagination);
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }

    getAllStudentVouchers(1, search, pagination.per_page, filters);
    setFilters({ ...filters });
  };

  useEffect(() => {
    getAcademicSessions(setAcademicSessions);
    getAllStudentVouchers(
      pagination.page,
      search,
      pagination.per_page,
      filters
    );
  }, []);

  return (
    <StudentVouchersMain>
      <StudentVouchersTop>
        <div className="left">
          <span className="page-heading">Student Vouchers</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right"></div>
      </StudentVouchersTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={showFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {showFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="">Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="session_id"
                      value={filters.session_id}
                      onChange={handleFilterChange}
                    >
                      <option value="">All</option>
                      {academicSessions.map((session, index) => {
                        return (
                          <option value={session.id} key={index}>
                            {session.session.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Challan Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="status"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      {challanStatusOptions.map((option, index) => {
                        return (
                          <option value={option.value} key={index}>
                            {option.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button
                  className="lg-rounded-btn"
                  onClick={() =>
                    getAllStudentVouchers(
                      1,
                      search,
                      pagination.per_page,
                      filters
                    )
                  }
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <StudentVouchersSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {/* <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div> */}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllStudentVouchers,
                  filters
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllStudentVouchers,
                  filters
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((voucher, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-100">{voucher.reg_number}</div>
                    </td>
                    <td>
                      <div className="mw-150">{voucher.name ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{voucher.cnic ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{voucher.program_title}</div>
                    </td>
                    <td>
                      <div className="mw-150">{voucher.department_title}</div>
                    </td>
                    <td>{voucher.session_year ?? "-"}</td>
                    <td>
                      {voucher.status === "Paid" ? (
                        <span className="status-tile">{voucher.status}</span>
                      ) : (
                        <span className="status-tile yellow">
                          {voucher.status}
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </StudentVouchersSection>
    </StudentVouchersMain>
  );
};

export default StudentVouchers;
