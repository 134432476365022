import { VoucherParticularDTO } from "./voucher-particular.dto";

export class AccSlotInstallmentParticular<
  MultiSelectType extends number[] | string = number[]
> {
  id!: number;
  slot_id!: number;
  particular_id: number[] = [];
  particular!: VoucherParticularDTO;
  amount!: number;
}
