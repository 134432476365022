import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: var(--zoom-view-height);
  background: var(--modal-backshadow);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  align-items: flex-end;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  min-height: 40vh;
  overflow-y: auto;
  background: var(--white-color);
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em 1em 2em;
    border-bottom: 1px solid var(--gray-light);

    @media screen and (max-width: 520px) {
      padding: 0 1em 1em 1em;
    }

    .heading {
      display: flex;
      gap: 1rem;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      span {
        font-size: 32px;
        color: var(--black-text);
        font-weight: 500;

        @media screen and (max-width: 520px) {
          font-size: 28px;
        }
      }
    }

    .close-icon {
      .icon {
        width: 20px;
        height: 19px;

        @media screen and (max-width: 520px) {
          width: 18px;
          height: 17px;
        }
        path {
          fill: var(--black-text);
        }
      }
    }
  }

  form {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding-top: 3em;

    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 3em 1.5em 1.5em 1.5em;
    }

    .common-fields {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1.5em;
      color: var(--lightgray-medium);
      font-size: 15px;
      font-weight: 400;

      @media screen and (max-width: 450px) {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      }
    }

    .action-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 2em;

      .buttons {
        display: flex;
        gap: 2.5em;

        @media screen and (max-width: 600px) {
          width: 100%;
          flex-direction: column;

          button {
            width: 100%;
          }
        }
      }
    }
  }
`;
export const ListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  .button-botom {
    gap: 1rem;
    width: 100%;
    display: flex;
    margin-top: 1rem;
    justify-content: end;
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 600px) {
    width: 70%;
  }

  .content-radius-shadow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 2em 0;
    background-color: transparent;
    box-shadow: none;

    @media screen and (max-width: 630px) {
      padding: 1em;
    }

    .list-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 630px) {
        flex-direction: column;
        gap: 1em;
      }
    }

    .grade-book-title {
      font-size: 1rem;
      font-weight: bold;
      span {
        font-weight: 500;
        color: var(--light-medium-crimson);
      }
    }

    table td {
      .mw-150 {
        min-width: 150px;
      }
    }
  }
  .container-title {
    font-size: var(--font-24);
    border-bottom: 1px solid var(--light-gray);
    color: var(--black-text);
  }
  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .stats-item {
    display: flex;
    flex-direction: column;
  }
  .stats-title {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-text);
    border-bottom: 5px solid var(--lightgray-medium-light);
    width: 40%;
    padding-bottom: 4px;
  }
  .stats-value {
    padding-top: 10px;
    color: var(--black-text);
    font-weight: 400;
    font-size: 15px;
  }

  .table-radio-field {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 4px;
    .radio label {
      padding-right: 5px;
    }
  }
`;
