import { FC } from "react";
import {
  NoticeBoardSection,
  ParticularQuickLink,
  QuickLinksSection,
  StudentDashboardMain,
  StudentDashboardTopSection,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import outlines from "assets/images/common/others/outlines.png";
import {
  AccountStatementSvg,
  FinancialVoucherSvg,
  GreenProfileSvg,
  OrangeDegreeSvg,
  ScholarshipSvg,
  StoreGreenishSvg,
  StudyPurpleSvg,
  TranscriptSvg,
} from "assets/images/common/svgs";

interface StudentDashboardProps {}

const StudentDashboard: FC<StudentDashboardProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Student Dashboard", path: siteRoutes.studentDashboard },
  ];

  const quickLinks: { title: string; Icon: any; background: string }[] = [
    {
      title: "Profile",
      Icon: GreenProfileSvg,
      background: "var(--green-extralight)",
    },
    {
      title: "Current Enrollments",
      Icon: OrangeDegreeSvg,
      background: "var(--yellow-extralight)",
    },
    {
      title: "Plan of Study",
      Icon: StudyPurpleSvg,
      background: "var(--metalic-purple-extralight)",
    },
    {
      title: "Transcript",
      Icon: TranscriptSvg,
      background: "var(--orange-extralight)",
    },
    {
      title: "Financial Voucher",
      Icon: FinancialVoucherSvg,
      background: "var(--sea-green-extralight)",
    },
    {
      title: "Fee plan & Scholarship",
      Icon: ScholarshipSvg,
      background: "var(--glowing-yellow-extralight)",
    },
    {
      title: "Account Statement",
      Icon: AccountStatementSvg,
      background: "var(--purple-extralight)",
    },
    {
      title: "My Store Items",
      Icon: StoreGreenishSvg,
      background: "var(--mature-green-extralight)",
    },
  ];
  return (
    <StudentDashboardMain>
      <StudentDashboardTopSection>
        <span className="page-heading">Student Dashboard</span>
        <Breadcrumb links={breadcrumbLinks} />
      </StudentDashboardTopSection>
      <QuickLinksSection className="content-radius-shadow">
        <div className="section-header">
          <div className="heading">Quick Links</div>
        </div>
        <div className="quick-links-wrapper">
          {quickLinks.map(({ title, Icon, background }, index) => {
            return (
              <ParticularQuickLink key={index} background={background}>
                <div className="icon-section">
                  <Icon />
                </div>
                <span className="title">{title}</span>
              </ParticularQuickLink>
            );
          })}
        </div>
      </QuickLinksSection>

      <NoticeBoardSection className="content-radius-shadow">
        <div className="section-header">
          <div className="heading">Notice Board</div>
          <div className="link-text">View all</div>
        </div>
        <div className="notice-wrapper">
          {[1, 1, 1].map((item, index) => {
            return (
              <div className="particular-notice" key={index}>
                <div className="notice-thumbnail">
                  <img src={outlines} alt="outlines" />
                </div>
                <div className="notice-info">
                  <div className="notice-title">
                    Fixation of Last Date to Deposit Annual Fee for Bachelor
                    Degree Programs Fall 2024
                  </div>
                  <div className="notice-description">
                    All Admission related states & reports
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </NoticeBoardSection>
    </StudentDashboardMain>
  );
};

export default StudentDashboard;
