export class ReportsDTO {
  id: number;
  title: string = "";
  button_text: string = "";
  url_path: string = "";
  sess_req: number;

  constructor(data: Partial<ReportsDTO> = {}) {
    Object.assign(this, data);
  }
}
export class SelectSessionDTO {
  session_id: number;
}
