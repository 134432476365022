import { FC, Fragment, useEffect, useState } from "react";
import {
  SectionCoursesListingSection,
  SectionCoursesListingMain,
  SectionCoursesListingTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  BlackDownloadSvg,
  BlackSettingTableSvg,
  BlackTableViewSvg,
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  SettingsSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useFinance from "containers/private/finance/useHooks";
// import { AcademicCourseInfoDTO } from "utils/helpers/models/finance/bank-info.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "../useHooks";
import { ConsolidatedResultViewSectionDTO } from "utils/helpers/models/academics/consolidated-result.dto";

interface SectionCoursesListingProps {}

const SectionCoursesListing: FC<SectionCoursesListingProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Consolidated Results /",
      path: siteRoutes.consolidatedResultsListing,
    },
    { title: "Section Courses", path: siteRoutes.sectionCoursesListing },
  ];

  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch, getQueryParams, createQuery } =
    useUtils();
  const { getConsolidatedCoursesSectionList } = useAcademics();
  const { program_id, program_title } = getQueryParams();

  const [data, setData] = useState<ConsolidatedResultViewSectionDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Section Title",
    "Session",
    "Submitted Course",
    "Verified Course",
    "Approved Course",
    "Acknowledged Course",
    "Action",
  ];

  const goToSubmittedCourses = (section_id: number, section_title: string) => {
    const params = createQuery({ section_id, program_id, section_title });
    navigate(siteRoutes.submittedCoursesListing + params);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllViewSectionsList(page + 1, search);
  };

  const getAllViewSectionsList = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      program_id,
    };
    getConsolidatedCoursesSectionList(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllViewSectionsList(pagination.page, search);
  }, []);

  return (
    <SectionCoursesListingMain>
      <SectionCoursesListingTop>
        <div className="left">
          <span className="page-heading">Section Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </SectionCoursesListingTop>

      <SectionCoursesListingSection className="content-radius-shadow">
        <div className="table-info-header">
          <div className="heading">
            <span>
              {program_title ? decodeURIComponent(program_title) : ""}
            </span>
          </div>
        </div>
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllViewSectionsList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllViewSectionsList
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((section, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">
                        {section?.section_title ?? "-"}
                      </div>
                    </td>
                    <td>{section?.session_title ?? "-"}</td>
                    <td>{section?.submitted_courses_count ?? "-"}</td>
                    <td>{section?.verified_courses_count ?? "-"}</td>
                    <td>{section?.approved_courses_count ?? "-"}</td>
                    <td>{section?.acknowledged_courses_count ?? "-"}</td>
                    <td>
                      <div className="action-menu mw-150">
                        <div className="table-action-buttons">
                          {hasAccess(
                            sitePermissions.submittedCoursesListing
                          ) && (
                            <div className="table-action-button">
                              <button
                                className="green"
                                onClick={() =>
                                  goToSubmittedCourses(
                                    section?.section_id,
                                    section?.section_title
                                  )
                                }
                              >
                                Submitted Courses
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="table-action-icons">
                          <div className="action-icon">
                            {/* onClick={goToSubmittedCourses} */}
                            <BlackTableViewSvg className="view" />
                          </div>
                          <div className="action-icon">
                            <BlackDownloadSvg />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </SectionCoursesListingSection>
    </SectionCoursesListingMain>
  );
};

export default SectionCoursesListing;
