import { FC, Fragment, memo, useEffect, useState } from "react";
import {
  HeaderMain,
  HeaderMenuBar,
  MenuBarLeft,
  MenuBarRight,
  MenuIcon,
  ModalMain,
  ProfileImage,
  ProfileSection,
  SearchField,
  UtilityIcons,
  SiteLogo,
  ZoomSettings,
  ZoomBtn,
} from "./style";
import {
  DarkThemeSvg,
  LightThemeSvg,
  MenuIconSvg,
  ModalAccountSvg,
  ModalDocumentSvg,
  ModalLockSvg,
  ModalLogoutSvg,
  NotificationSvg,
  SearchSvg,
  SettingsSvg,
} from "assets/images/common/svgs";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useComponentVisible from "hooks/click-outside";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import NotificationsPopup from "./components/notifications";
import { HEADER_UTILITIES } from "utils/helpers/enums/shared.enums";
import SettingsPopup from "./components/settings";
import ChangePasswordModal from "./components/change-password";
import { ROLES } from "utils/helpers/enums/roles.enums";
import useUtils from "hooks/useUtils";

interface HeaderProps {
  openSidebar: boolean;
  setOpenSidebar: Function;
}

const Header: FC<HeaderProps> = ({ openSidebar, setOpenSidebar }) => {
  const {
    setDarkTheme,
    logout,
    getDomainDefaultUnAuthRoute,
    getPriorLoginAsRoute,
    isLoggedInAs,
    removePriorLoginAsRoute,
  } = useStore();
  const { isDarkTheme, token, loginAsToken } = useSelector(
    (state: any) => state.sharedReducer
  );

  const [changePassModal, setChangePasswordModal] = useState<boolean>(false);
  const [currentOpenedDropdown, setCurrentOpenedDropdown] =
    useState<HEADER_UTILITIES | null>(null);
  const navigate = useNavigate();
  const { hasAccess, getRole } = useStore();
  const { user } = useSelector((state: any) => state.sharedReducer);
  const role = getRole();

  const toggleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  const toggleDarkTheme = () => {
    setDarkTheme(!isDarkTheme);
  };

  useEffect(() => {
    if (!loginAsToken && token) {
      const routeToNavigate = getPriorLoginAsRoute();
      removePriorLoginAsRoute();
      navigate(routeToNavigate);
    } else if (!token) {
      const routeToNavigate = getDomainDefaultUnAuthRoute();
      navigate(routeToNavigate);
    }
  }, [token, loginAsToken]);

  const location = useLocation();

  useEffect(() => {
    setShowDropdown(false);
  }, [location]);
  const {
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown,
    ref: dropdownRef,
  } = useComponentVisible();

  const getAvatarLabel = (): string => {
    const [firstName] = user?.name?.split(" ") || ["", ""];
    return `${firstName[0]}`;
  };
  const goToUpdateOrgAdmin = () => {
    navigate(siteRoutes.updateOrganizationAdmin);
  };
  const goToEditProfile = () => {
    navigate(siteRoutes.editEPStudentProfile);
  };
  const goToViewProfile = () => {
    navigate(siteRoutes.viewEPStudentProfile);
  };

  const toggleDropdown = (dropdownType: HEADER_UTILITIES) => {
    if (currentOpenedDropdown === dropdownType) {
      setCurrentOpenedDropdown(null);
      setShowDropdown(false);
    } else {
      setCurrentOpenedDropdown(dropdownType);
      setShowDropdown(true);
    }
  };

  const isChangePassOptionVisible = (): boolean => {
    return role?.guard_name === ROLES.ADMIN || role?.guard_name === ROLES.USER;
  };
  const openChangePasswordModel = () => {
    setChangePasswordModal(true);
  };
  const { getOrganization } = useStore();

  const organization = getOrganization();
  const goToHome = () => {
    if (role === ROLES.SUPER_ADMIN) {
      navigate(siteRoutes.superAdminDashboard);
    } else if (role === ROLES.ADMIN) {
      navigate(siteRoutes.adminDashboard);
    } else {
      navigate(siteRoutes.ePortalDashboard);
    }
  };
  const { setSiteZoomLevel, getSiteZoomLevel } = useStore();
  const [zoomLevel, setZoomLevel] = useState<number>(getSiteZoomLevel());
  const { setCSSVariable } = useUtils();

  const enhanceZoomLevel = () => {
    if (zoomLevel < 100) {
      const zoomLevelToSet = zoomLevel + 10;
      setZoomLevel(zoomLevelToSet);
      setSiteZoomLevel(zoomLevelToSet);
    }
  };

  const reduceZoomLevel = () => {
    if (zoomLevel > 80) {
      const zoomLevelToSet = zoomLevel - 10;
      setZoomLevel(zoomLevelToSet);
      setSiteZoomLevel(zoomLevelToSet);
    }
  };

  const resetZoomLevel = () => {
    setZoomLevel(100);
    setCSSVariable("--zoom-level", "80%");
  };

  return (
    <Fragment>
      <HeaderMain>
        <HeaderMenuBar>
          <MenuBarRight>
            <MenuIcon onClick={toggleSidebarOpen}>
              <MenuIconSvg className="icon" />
            </MenuIcon>
          </MenuBarRight>
          <MenuBarLeft>
            <SearchField>
              <div className="search-icon">
                <SearchSvg className="icon" />
              </div>
              <div className="field">
                <input type="search" placeholder="Search for something" />
              </div>
            </SearchField>
            <UtilityIcons>
              <div className="utility-icon" onClick={toggleDarkTheme}>
                {isDarkTheme ? (
                  <LightThemeSvg className="icon" />
                ) : (
                  <DarkThemeSvg className="icon" />
                )}
              </div>
              <div className="utility">
                <div
                  className="utility-icon"
                  onClick={() => toggleDropdown(HEADER_UTILITIES.SETTINGS)}
                >
                  <SettingsSvg className="icon" />
                </div>
                {currentOpenedDropdown == HEADER_UTILITIES.SETTINGS &&
                showDropdown ? (
                  <div className="modal-section" ref={dropdownRef}>
                    <SettingsPopup />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="utility">
                <div
                  className="utility-icon"
                  onClick={() => toggleDropdown(HEADER_UTILITIES.NOTIFICATIONS)}
                >
                  <NotificationSvg className="icon" />
                </div>
                {currentOpenedDropdown == HEADER_UTILITIES.NOTIFICATIONS &&
                showDropdown ? (
                  <div className="modal-section" ref={dropdownRef}>
                    <NotificationsPopup />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </UtilityIcons>
            <SiteLogo onClick={goToHome}>
              <div className="org-logo">
                <img
                  src={
                    isDarkTheme
                      ? organization?.sidebar_light_logo
                      : organization?.sidebar_dark_logo
                  }
                  alt=""
                />
              </div>
            </SiteLogo>
            <ProfileSection>
              <ProfileImage ref={dropdownRef}>
                {user?.profile_image ? (
                  <div
                    className="image cp"
                    onClick={() => toggleDropdown(HEADER_UTILITIES.PROFILE)}
                  >
                    <img src={user?.profile_image} alt="ser-img" />
                  </div>
                ) : (
                  <div
                    className="avatar cp"
                    onClick={() => toggleDropdown(HEADER_UTILITIES.PROFILE)}
                  >
                    {getAvatarLabel()}
                  </div>
                )}
                {showDropdown &&
                  currentOpenedDropdown == HEADER_UTILITIES.PROFILE && (
                    <ModalMain>
                      <div className="top-img">
                        <ProfileImage>
                          {user?.profile_image ? (
                            <div className="image cp">
                              <img src={user?.profile_image} alt="" />
                            </div>
                          ) : (
                            <div className="avatar cp">{getAvatarLabel()}</div>
                          )}
                        </ProfileImage>
                        <p className="user-name">{user?.name ?? "-"}</p>
                      </div>
                      <div className="modal-listing-section">
                        <ul>
                          {/* {hasAccess(
                            sitePermissions.updateOrganizationAdmin
                          ) && (
                              <li onClick={goToUpdateOrgAdmin}>
                                <div>
                                  <ModalAccountSvg className="modal-icon" />
                                </div>
                                <span>My Account</span>
                              </li>
                            )} */}
                          {hasAccess(sitePermissions.editEPStudentProfile) && (
                            <li onClick={goToEditProfile}>
                              <div>
                                <ModalAccountSvg className="modal-icon" />
                              </div>
                              <span>Edit Profile</span>
                            </li>
                          )}
                          {hasAccess(sitePermissions.viewEPStudentProfile) && (
                            <li onClick={goToViewProfile}>
                              <div>
                                <ModalDocumentSvg className="modal-icon" />
                              </div>
                              <span>View Profile</span>
                            </li>
                          )}

                          {isChangePassOptionVisible() && (
                            <li onClick={openChangePasswordModel}>
                              <div>
                                <ModalLockSvg className="modal-icon" />
                              </div>
                              <span>Change Password</span>
                            </li>
                          )}
                          {!isLoggedInAs() ? (
                            <li onClick={logout}>
                              <div>
                                <ModalLogoutSvg className="modal-icon" />
                              </div>
                              <span>Sign out</span>
                            </li>
                          ) : (
                            <li onClick={logout}>
                              <div>
                                <ModalLogoutSvg className="modal-icon" />
                              </div>
                              <span>Go to parent account</span>
                            </li>
                          )}
                        </ul>
                        <div className="theme-zoom-main">
                          <div className="theme-btn-main">
                            <div className="theme-label">Dark Theme</div>
                            <div
                              className={`app ${
                                isDarkTheme ? "dark-theme" : "light-theme"
                              }`}
                            >
                              <div className="theme-btn">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={isDarkTheme}
                                    onChange={toggleDarkTheme}
                                  />
                                  <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <ZoomSettings>
                            <div className="particular-setting">
                              <div className="heading">
                                <span>Zoom level</span>
                              </div>
                              <div className="btns">
                                <ZoomBtn
                                  onClick={enhanceZoomLevel}
                                  $zoomOverflowed={zoomLevel === 100}
                                >
                                  <span className="operator">+</span>
                                </ZoomBtn>
                                <ZoomBtn
                                  $zoomOverflowed={false}
                                  onClick={resetZoomLevel}
                                >
                                  <span>Reset</span>
                                </ZoomBtn>
                                <ZoomBtn
                                  onClick={reduceZoomLevel}
                                  $zoomOverflowed={zoomLevel === 80}
                                >
                                  <span className="operator">-</span>
                                </ZoomBtn>
                              </div>
                            </div>
                          </ZoomSettings>
                        </div>
                      </div>
                    </ModalMain>
                  )}
              </ProfileImage>
            </ProfileSection>
          </MenuBarLeft>
        </HeaderMenuBar>
      </HeaderMain>

      {changePassModal && (
        <ChangePasswordModal setOpen={setChangePasswordModal} />
      )}
    </Fragment>
  );
};

export default Header;
