import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  ManageAcademicFeePlanListingMain,
  ManageAcademicFeePlanListingSection,
  ManageAcademicFeePlanListingTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import LinkVoucherTemplate from "./components/link-voucher-template";
import useUtils from "hooks/useUtils";
import { FeePlanDTO } from "utils/helpers/models/academics/fee-plan.dto";
import useAcademics from "../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { LinkVoucherTemplateDTO } from "utils/helpers/models/academics/link-voucher-template-to-fee-plan.dto";

const ManageAcademicFeePlanListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Academic Sessions / ", path: siteRoutes.academicSessionListing },
    {
      title: `Manage Fee Plan`,
      path: siteRoutes.academicManageFeePlanListing,
    },
  ];
  const [search, setSearch] = useState<string>("");
  const { getQueryParams, handleSearchChange, handleTableSearch } = useUtils();
  const { id: fee_plan_id = 0 } = getQueryParams();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const columns: string[] = ["Voucher Template", "Amount", "Action"];
  const [feePlan, setFeePlan] = useState(new FeePlanDTO());
  const [data, setData] = useState<LinkVoucherTemplateDTO[]>([]);
  const { getAcademicFeePlanById, getLinkedVoucherTemplates } = useAcademics();
  const [openLinkDialog, setOpenLinkDialog] = useState<boolean>(false);

  const handleOpenLinkVoucherDialog = () => {
    setOpenLinkDialog(true);
  };

  const onCloseLinkDialog = (didChange: boolean = true) => {
    if (didChange) {
      getAcademicFeePlanById(fee_plan_id, feePlan, setFeePlan);
      getAllLinkedVoucherTemplates(1, search, pagination.per_page);
    }

    setOpenLinkDialog(false);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
        fee_plan_id,
      };
      // deleteAcademicFeePlan(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllLinkedVoucherTemplates(page + 1, search, per_page);
  };
  const getAllLinkedVoucherTemplates = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
      fee_plan_id,
    };
    getLinkedVoucherTemplates(fee_plan_id, setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAcademicFeePlanById(fee_plan_id, feePlan, setFeePlan);
    getAllLinkedVoucherTemplates(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <ManageAcademicFeePlanListingMain>
      <ManageAcademicFeePlanListingTop>
        <div className="left">
          <span className="page-heading">Manage Fee Plan</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="add-new-button" onClick={handleOpenLinkVoucherDialog}>
            <button className="lg-rounded-btn">+ Link Voucher Template</button>
          </div>
        </div>
      </ManageAcademicFeePlanListingTop>

      <ManageAcademicFeePlanListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllLinkedVoucherTemplates
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllLinkedVoucherTemplates
                )
              }
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((template, index) => (
                <tr key={index}>
                  <td>
                    <div className="mw-150">
                      {template.voucher_template_title}
                    </div>
                  </td>
                  <td>{template.total_amount}</td>
                  <td>
                    <div className="table-action-icons">
                      <div
                        className="action-icon"
                        onClick={() => handleDelete(template.id)}
                      >
                        <DeleteTableSvg />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
          {openLinkDialog && (
            <LinkVoucherTemplate
              onClose={(didChange: boolean) => onCloseLinkDialog(didChange)}
            />
          )}
        </Fragment>
      </ManageAcademicFeePlanListingSection>
    </ManageAcademicFeePlanListingMain>
  );
};

export default ManageAcademicFeePlanListing;
