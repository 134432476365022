import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  EditEPortalProfileMain,
  EditEPortalProfileTopSection,
  EditProfileContentSection,
  FormSection,
  FormStepper,
  Step1,
  Step2,
  Step3,
  Step4,
  TableWrapper,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdditionalInfoSvg,
  ExamEntrySvg,
  GuardiansSvg,
  PersonalInfoSvg,
} from "assets/images/e-portal/svgs";
import dummyAvatar from "assets/images/common/others/dummy-avatar.png";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import Stepper from "components/particles/forms/stepper";
import {
  DeleteTableSvg,
  DownloadPrimaryTableSvg,
  EditGreenTableSvg,
  SmallUploadSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import AddQualification from "./components/add-career-qualification";
import useEportal from "../../useHooks";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import {
  confirmationPopup,
  warningToaster,
} from "utils/helpers/common/alert-service";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { EditStudentProfile } from "utils/helpers/models/e-portal/edit-student-profile.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import AddEmployment from "./components/add-employement";
import AddPublication from "./components/add-publications";
import AddRefrence from "./components/add-refrence";
import { EmployementInfoDTO } from "utils/helpers/models/e-portal/employement-info.dto";
import { PublicationInfoDTO } from "utils/helpers/models/e-portal/publication-info.dto";
import { ReferenceInfoDTO } from "utils/helpers/models/e-portal/reference-info.dto";
import useMultiClickOutside from "hooks/multi-click-outside";
import { AddQualificationDTO } from "utils/helpers/models/e-portal/add-qualification.dto";
import useUtils from "hooks/useUtils";
import pdfPlaceholder from "assets/images/common/others/pdf-placeholder.png";

interface EditEPortalProfileProps {}

const EditEPortalCareer: FC<EditEPortalProfileProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Eportal /", path: "" },
    { title: "Careers /", path: "" },
    {
      title: "Edit Profile",
      path: siteRoutes.createEportalCareer,
    },
  ];
  const [currentStep, setCurrentStep] = useState<number>(1);
  const academicTableRef = useRef<any>(null);
  const [formData, setFormData] = useState<EditStudentProfile>(
    new EditStudentProfile()
  );

  const [openReferenceModal, setOpenReferenceModal] = useState<boolean>(false);
  const [openEmploymentModal, setOpenEmploymentModal] =
    useState<boolean>(false);
  const [openPublicationModal, setOpenPublicationModal] =
    useState<boolean>(false);

  const [employements, setEmployements] = useState<EmployementInfoDTO[]>([]);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [qualificationToEdit, setQualificationToEdit] = useState<any>(null);
  const [publications, setPublications] = useState<PublicationInfoDTO[]>([]);
  const [references, setReferences] = useState<ReferenceInfoDTO[]>([]);
  const [employementToEdit, setEmployementToEdit] =
    useState<EmployementInfoDTO | null>(null);
  const [publicationToEdit, setPublicationToEdit] =
    useState<PublicationInfoDTO | null>(null);
  const [referenceToEdit, setReferenceToEdit] =
    useState<ReferenceInfoDTO | null>(null);
  const [oldFormData, setOldFormData] = useState<EditStudentProfile>(
    new EditStudentProfile()
  );
  const [openQualificationModal, setOpenQualificationModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const {
    getProfile,
    getCitiesByUser,
    updateUserPersonalInfo,
    getEmployementInfos,
    deleteEmployementInfo,
    getPublicationInfos,
    deletePublicationInfo,
    getReferenceInfos,
    deleteReferenceInfo,
    getQualificationsByUser,
    deleteQualificationByUser,
    downloadQualificationDocumentByUser,
  } = useEportal();
  const { getFileExtensionByUrl } = useUtils();
  const {
    setValue,
    getValues,
    register,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<EditStudentProfile>();
  const {
    currentIndex: qualificationCurrentIndex,
    refs: qualificationRefs,
    setOpen: setOpenQualificationIndex,
  } = useMultiClickOutside();
  const {
    currentIndex: employementCurrentIndex,
    refs: employementRefs,
    setOpen: setOpenEmployementIndex,
  } = useMultiClickOutside();
  const {
    currentIndex: publicationCurrentIndex,
    refs: publicationRefs,
    setOpen: setOpenPublicationIndex,
  } = useMultiClickOutside();
  const {
    currentIndex: referenceCurrentIndex,
    refs: referenceRefs,
    setOpen: setOpenReferenceIndex,
  } = useMultiClickOutside();
  const [cities, setCities] = useState<any[]>([]);
  const academicColumns: string[] = [
    "Certificate/Degree",
    "Roll No./Reg No.",
    "Board University",
    "Passing Year",
    "Total Marks/GPA",
    "Obtained Marks",
    "Result",
    "Action",
  ];
  const publication: string[] = [
    "Research Article",
    "Publisher Name",
    "Author Name",
    "HEC Category ",
    "Pages",
    "Year of Publication",
    "Month of Publication",
    "Action",
  ];
  const employement: string[] = [
    "Organization",
    "Designation/ Appointment",
    "Salary Drawn",
    "From ",
    "To",
    "Duration ",
    "Reason For Leaving",
    "Action",
  ];
  const refrence: string[] = [
    "Reference Name",
    "Designation",
    "Organization",
    "Relationship",
    "Contact",
    "Email",
    "Action",
  ];
  const editProfileSteps = [
    {
      title: "Personal Information",
      icon: PersonalInfoSvg,
      active: true,
      completed: false,
    },
    {
      title: "Academic Information",
      icon: GuardiansSvg,
      active: false,
      completed: false,
    },
    {
      title: "Employment Information",
      icon: AdditionalInfoSvg,
      active: false,
      completed: false,
    },
    {
      title: "Reference",
      icon: ExamEntrySvg,
      active: false,
      completed: false,
    },
  ];

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const goNext = async (step: number, e?: any) => {
    setCurrentStep(step + 1);
  };

  const goBack = (e: any) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const goToEditQualification = (qualification: AddQualificationDTO) => {
    setQualificationToEdit(qualification);
    setOpenQualificationModal(true);
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    // }
    await trigger([name]);
  };

  const handleFileUpload = (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
    setFormData({ ...formData, [name]: URL.createObjectURL(file) });
    setValue(name, file);
  };

  const onSubmit = (data: any, step: number) => {
    if (step === 1) {
      const fields: any = [
        "gender",
        "date_of_birth",
        "email",
        "phone_no",
        "domicile",
        "p_city",
        "m_city",
        "m_address",
        "p_address",
        "is_self_dependent",
        "profile_image",
        "cnic_image",
        "cnic_back_image",
        "domicile_certificate",
      ];

      if (!formData.profile_image) {
        warningToaster("Profile image is required");
      } else if (!formData.cnic_back_image) {
        warningToaster("Cnic back image is required");
      } else if (!formData.cnic_image) {
        warningToaster("Cnic front image is required");
      } else if (!formData.domicile_certificate) {
        warningToaster("Domicile certificate image is required");
      } else {
        const doesChange = didFormDataChange(fields);
        if (doesChange) {
          const form_data = new FormData();
          for (let key in data) {
            if (fields.includes(key)) {
              if (key === "is_self_dependent") {
                form_data.append(key, formData.is_self_dependent);
              } else {
                form_data.append(key, data[key]);
              }
            }
          }
          updateUserPersonalInfo(form_data, goNext, step);
          setOldFormData({ ...formData });
        } else {
          goNext(step);
        }
      }
    } else {
      goNext(currentStep);
    }
  };

  const handleSaveAsAboveCheckbox = async (event: any) => {
    const { checked } = event.target;
    if (checked) {
      const m_city = getValues("m_city");
      const m_address = getValues("m_address");
      setValue("p_city", m_city);
      setValue("p_address", m_address);
      setFormData({ ...formData, p_address: m_address, p_city: m_city });
    } else {
      setValue("p_city", "");
      setValue("p_address", "");
      setFormData({ ...formData, p_address: "", p_city: "" });
    }
    await trigger(["p_city", "p_address"]);
  };

  const handleIsDependentCheckbox = (event: any) => {
    const { checked } = event.target;
    const value = checked ? 1 : 0;
    setValue("is_self_dependent", value);
    setFormData({ ...formData, is_self_dependent: value });
  };

  const handleClearEmployementRefs = () => {
    employementRefs.current = [];
    setOpenEmployementIndex(null);
  };

  const handleClearQualificationRefs = () => {
    qualificationRefs.current = [];
    setOpenQualificationIndex(null);
  };

  const handleClearPublicationsRefs = () => {
    // publicationRefs.current = [];
    // setOpenPublicationIndex(null);
  };

  const handleClearReferenceRefs = () => {
    publicationRefs.current = [];
    setOpenPublicationIndex(null);
  };

  const handleUpload = (event: any, name: keyof typeof formData) => {
    const file = event.target.files![0];
    if (file) {
      setValue(name, file);
      setFormData((prev) => ({ ...prev, [name]: URL.createObjectURL(file) }));
      trigger([name]);
    }
  };

  const didFormDataChange = (fields: string[]) => {
    let doesChange: boolean = false;
    const oldData: any = { ...oldFormData };
    const newData: any = { ...formData };

    for (let key in newData) {
      if (fields.includes(key)) {
        if (oldData[key] !== newData[key]) {
          doesChange = true;
          break;
        }
      }
    }

    return doesChange;
  };

  const handleDeleteEmployement = async (empId: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteEmployementInfo(empId, setEmployements, handleClearEmployementRefs);
    }
  };

  const handleEditEmployement = (employement: EmployementInfoDTO) => {
    setOpenEmploymentModal(true);
    setEmployementToEdit(employement);
    setEmployements([]);
  };

  const handleDeleteQualification = async (empId: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteQualificationByUser(empId, setEmployements);
    }
  };

  const toggleReferenceDropdown = (index: number) => {
    (references as any)[index].isDropdownOpen = !(references as any)[index]
      ?.isDropdownOpen;
    setReferences([...references]);
  };

  const handleDeleteReference = async (empId: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteReferenceInfo(empId, setReferences);
    }
  };

  const handleEditReference = (reference: ReferenceInfoDTO) => {
    setOpenReferenceModal(true);
    setReferenceToEdit(reference);
  };

  const handleOpenEmployementModal = () => {
    setOpenEmploymentModal(true);
    setEmployementToEdit(null);
  };

  const handleDeletePublication = async (empId: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deletePublicationInfo(empId, setEmployements);
      publicationRefs.current = null;
      setOpenPublicationIndex(null);
    }
  };

  const handleEditPublication = (publication: PublicationInfoDTO) => {
    setOpenPublicationModal(true);
    setPublicationToEdit(publication);
  };

  useEffect(() => {
    if (!openEmploymentModal) {
      getEmployementInfos(setEmployements, handleClearEmployementRefs);
    }
  }, [openEmploymentModal]);
  useEffect(() => {
    if (!openPublicationModal) {
      getPublicationInfos(setPublications, handleClearPublicationsRefs);
    }
  }, [openPublicationModal]);
  useEffect(() => {
    if (!openReferenceModal) {
      getReferenceInfos(setReferences, handleClearReferenceRefs);
    }
  }, [openReferenceModal]);
  useEffect(() => {
    if (!openQualificationModal) {
      const queryParams = { per_page: "All", status: 1 };
      getQualificationsByUser(
        setQualifications,
        queryParams,
        handleClearQualificationRefs
      );
    }
  }, [openQualificationModal]);

  useEffect(() => {
    getCitiesByUser(setCities);
    getProfile(setFormData, setOldFormData, setValue, formData);

    {
      register("cnic_back_image", { required: true });
    }
    {
      register("cnic_image", { required: true });
    }
    {
      register("profile_image", { required: true });
    }
    {
      register("domicile_certificate", { required: true });
    }
  }, []);

  return (
    <EditEPortalProfileMain>
      <EditEPortalProfileTopSection>
        <span className="page-heading">Edit Profile</span>
        <Breadcrumb links={breadcrumbLinks} />
      </EditEPortalProfileTopSection>
      <EditProfileContentSection>
        <FormStepper>
          <Stepper
            steps={editProfileSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </FormStepper>
        <FormSection>
          <form>
            {currentStep === 1 ? (
              <Step1>
                <div className="common-fields">
                  <div className="input-field">
                    <label>First Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          {...register("first_name", { required: true })}
                          value={formData?.first_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage error={errors.first_name} />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Last Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          {...register("last_name", { required: true })}
                          value={formData?.last_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage error={errors.last_name} />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Gender</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("gender", { required: true })}
                          value={formData.gender}
                          onChange={handleChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <FormErrorMessage error={errors.gender} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Email</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="email"
                          {...register("email", { required: true })}
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.email} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Mobile Number</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="tel"
                          placeholder="Enter Mobile Number"
                          {...register("phone_no", { required: true })}
                          value={formData.phone_no}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.phone_no} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Date Of Birth</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="date"
                          {...register("date_of_birth", { required: true })}
                          value={formData?.date_of_birth}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors?.date_of_birth} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Domicile City</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("domicile", { required: true })}
                          value={formData.domicile}
                          onChange={handleChange}
                        >
                          <option value="">Select City</option>
                          {cities?.map((city: any, index: number) => {
                            return (
                              <option value={city.id} key={index}>
                                {city.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <FormErrorMessage error={errors.domicile} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Current Mailing Address( Address 1, 2)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter Mailing Address"
                          {...register("m_address", { required: true })}
                          value={formData.m_address}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.m_address} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Current Address (City/Tehsil)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("m_city", { required: true })}
                          value={formData.m_city}
                          onChange={handleChange}
                        >
                          <option value="">Select City/Tehsil</option>
                          {cities?.map((city: any, index: number) => {
                            return (
                              <option value={city.id} key={index}>
                                {city.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <FormErrorMessage error={errors.m_city} />
                    </div>
                  </div>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    id="same-as-above"
                    onChange={handleSaveAsAboveCheckbox}
                    checked={
                      formData.p_city === formData.m_city &&
                      formData.m_address === formData.p_address
                    }
                  />
                  <label htmlFor="same-as-above">Same As Above</label>
                </div>
                <div className="common-fields">
                  <div className="input-field">
                    <label>Permanent Mailing Address( Address 1, 2)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter Mailing Address"
                          {...register("p_address", { required: true })}
                          value={formData.p_address}
                          onChange={handleChange}
                        />
                      </div>
                      <FormErrorMessage error={errors.p_address} />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Permanent Address (City/Tehsil)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select
                          {...register("p_city", { required: true })}
                          value={formData.p_city}
                          onChange={handleChange}
                        >
                          <option value={""}>Select City/Tehsil</option>
                          {cities?.map((city: any, index: number) => {
                            return (
                              <option value={city.id} key={index}>
                                {city.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <FormErrorMessage error={errors.p_city} />
                    </div>
                  </div>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    id="is_self_dependent"
                    {...register("is_self_dependent", { required: false })}
                    checked={formData.is_self_dependent == 1}
                    onChange={handleIsDependentCheckbox}
                  />
                  <label htmlFor="is_self_dependent">I am self dependent</label>
                </div>
                <div className="image-fields">
                  <div className="main-image-container">
                    <div>
                      <label htmlFor="org-logo">Profile Picture</label>
                    </div>
                    <div className="image-field light">
                      <label className="image">
                        {formData?.profile_image ? (
                          <img src={(formData as any).profile_image} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">
                              Upload Profile Picture
                            </p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleUpload(
                              e,
                              "profile_image" as keyof typeof formData
                            )
                          }
                        />
                      </label>
                    </div>
                    <FormErrorMessage
                      error={
                        errors?.["profile_image" as keyof EditStudentProfile]
                      }
                    />
                  </div>
                  <div className="main-image-container">
                    <div>
                      <label htmlFor="cnic_image">CNIC Front Side</label>
                    </div>
                    <div className="image-field">
                      <label className="image">
                        {formData?.cnic_image ? (
                          <img src={(formData as any).cnic_image} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">
                              Upload ID Card Front Side / B Form
                            </p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleUpload(
                              e,
                              "cnic_image" as keyof typeof formData
                            )
                          }
                        />
                      </label>
                    </div>
                    <FormErrorMessage
                      error={errors?.["cnic_image" as keyof EditStudentProfile]}
                    />
                  </div>
                  <div className="main-image-container">
                    <div>
                      <label htmlFor="cnic_back_image">CNIC Back Side</label>
                    </div>
                    <div className="image-field light">
                      <label className="image">
                        {formData?.cnic_back_image ? (
                          <img src={(formData as any).cnic_back_image} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">
                              Upload ID Card Back Side / B Form
                            </p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleUpload(
                              e,
                              "cnic_back_image" as keyof typeof formData
                            )
                          }
                        />
                      </label>
                    </div>
                    <FormErrorMessage
                      error={
                        errors?.["cnic_back_image" as keyof EditStudentProfile]
                      }
                    />
                  </div>
                  <div className="main-image-container">
                    <div>
                      <label htmlFor="cnic_back_image">
                        Domicile Certificate
                      </label>
                    </div>
                    <div className="image-field">
                      <label className="image">
                        {formData?.domicile_certificate ? (
                          <img src={(formData as any).domicile_certificate} />
                        ) : (
                          <div>
                            <p className="placeholder-heading">
                              Upload Domicile Certificate
                            </p>
                            Dimensions must be of 300*300
                          </div>
                        )}
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleUpload(
                              e,
                              "domicile_certificate" as keyof typeof formData
                            )
                          }
                        />
                      </label>
                    </div>
                    <FormErrorMessage
                      error={
                        errors?.[
                          "domicile_certificate" as keyof EditStudentProfile
                        ]
                      }
                    />
                  </div>
                </div>

                <div className="submit-buttons">
                  <div className="buttons">
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 1))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step1>
            ) : currentStep === 2 ? (
              <Step2>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Academic Qualification(s)</span>
                    </div>
                    <div className="add-btn">
                      <button
                        className="lg-rounded-btn"
                        type="button"
                        onClick={() => setOpenQualificationModal(true)}
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                  <div
                    className={`data-table ${
                      qualificationCurrentIndex !== null ? "menu-opened" : ""
                    }`}
                  >
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {academicColumns.map(
                            (item: string, index: number) => {
                              return <th key={index}>{item}</th>;
                            }
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {qualifications.map(
                          (qualification: any, index: number) => {
                            return (
                              <tr>
                                <td>
                                  {qualification?.certificate_type?.title}
                                </td>
                                <td>{qualification?.roll_num}</td>
                                <td>
                                  <div className="mw-150">
                                    {qualification?.board?.title}
                                  </div>
                                </td>
                                <td>{qualification?.passing_year}</td>
                                <td>{qualification?.total_marks}</td>
                                <td>{qualification?.obt_marks}</td>
                                <td>{qualification?.result_type?.title}</td>
                                <td>
                                  <div
                                    className="action-menu"
                                    ref={(el) =>
                                      qualificationRefs.current.push(el!)
                                    }
                                  >
                                    <div
                                      className="menu-icon cp"
                                      onClick={() =>
                                        setOpenQualificationIndex(index)
                                      }
                                    >
                                      <TabPrimaryActionMenu className="icon" />
                                    </div>
                                    {qualificationCurrentIndex === index && (
                                      <div className="table-menu-dropdown">
                                        <div
                                          className="particular-menu cp"
                                          onClick={() =>
                                            goToEditQualification(qualification)
                                          }
                                        >
                                          <div className="action-icon">
                                            <EditGreenTableSvg className="icon" />
                                          </div>
                                          <span className="title">Edit</span>
                                        </div>
                                        <div
                                          className="particular-menu cp"
                                          onClick={() =>
                                            handleDeleteQualification(
                                              qualification?.id
                                            )
                                          }
                                        >
                                          <div className="action-icon">
                                            <DeleteTableSvg className="icon" />
                                          </div>
                                          <span className="title">Delete</span>
                                        </div>
                                        {qualification?.result_document && (
                                          <div
                                            className="particular-menu cp"
                                            onClick={() =>
                                              downloadQualificationDocumentByUser(
                                                qualification?.id
                                              )
                                            }
                                          >
                                            <div className="action-icon">
                                              <DownloadPrimaryTableSvg className="icon" />
                                            </div>
                                            <span className="title">
                                              Download Document
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !qualifications.length} />
                  </Fragment>
                </TableWrapper>

                <div className="submit-buttons">
                  <div className="buttons">
                    <button className="lg-rounded-btn gray" onClick={goBack}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 2))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step2>
            ) : currentStep === 3 ? (
              <Step3>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Employment Information</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={handleOpenEmployementModal}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div
                    className={`data-table ${
                      employementCurrentIndex !== null ? "menu-opened" : ""
                    }`}
                  >
                    <table className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          {employement.map((item: string, index: number) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {employements.map((emp, index) => (
                          <tr key={index}>
                            <td>{emp.employer_name}</td>
                            <td>{emp.designation.title}</td>
                            <td>{emp.salary_drawn}</td>
                            <td>{emp.periodfrom}</td>
                            <td>{emp.periodto}</td>
                            <td>{emp.duration}</td>
                            <td>{emp.leavingreason}</td>

                            <td>
                              <div
                                className="action-menu"
                                ref={(el) => employementRefs.current.push(el!)}
                              >
                                <div className="menu-icon cp">
                                  <TabPrimaryActionMenu
                                    className="icon"
                                    onClick={() =>
                                      setOpenEmployementIndex(index)
                                    }
                                  />
                                </div>
                                {employementCurrentIndex === index && (
                                  <div className="menu-dropdown">
                                    <div
                                      className="particular-menu cp"
                                      onClick={() => handleEditEmployement(emp)}
                                    >
                                      <div className="action-icon">
                                        <EditGreenTableSvg className="icon" />
                                      </div>
                                      <span className="title">Edit</span>
                                    </div>
                                    <div
                                      className="particular-menu cp"
                                      onClick={() =>
                                        handleDeleteEmployement(emp.id)
                                      }
                                    >
                                      <div className="action-icon">
                                        <DeleteTableSvg className="icon" />
                                      </div>
                                      <span className="title">Delete</span>
                                    </div>
                                    <div className="particular-menu cp">
                                      <div className="action-icon">
                                        <DownloadPrimaryTableSvg className="icon" />
                                      </div>
                                      <span className="title">
                                        Download Document
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !employements.length} />
                  </Fragment>
                </TableWrapper>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Publications</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenPublicationModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div
                    className={`data-table ${
                      publicationCurrentIndex !== null ? "menu-opened" : ""
                    }`}
                    style={{ overflowX: "unset" }}
                  >
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {publication.map((item, index) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {publications.map((publication, index) => {
                          return (
                            <tr>
                              <td>{publication.title}</td>
                              <td>{publication.journal_name}</td>
                              <td>{publication.author_name}</td>
                              <td>{publication.impact_factor}</td>
                              <td>{publication.volume_no}</td>
                              <td>{publication.publication_year}</td>

                              <td>{publication.publication_month}</td>
                              <td>
                                <div
                                  className="action-menu"
                                  ref={(el) =>
                                    publicationRefs.current.push(el!)
                                  }
                                >
                                  <div
                                    className="menu-icon cp"
                                    onClick={() =>
                                      setOpenPublicationIndex(index)
                                    }
                                  >
                                    <TabPrimaryActionMenu className="icon" />
                                  </div>
                                  {publicationCurrentIndex === index && (
                                    <div className="menu-dropdown">
                                      <div
                                        className="particular-menu cp"
                                        onClick={() =>
                                          handleEditPublication(publication)
                                        }
                                      >
                                        <div className="action-icon">
                                          <EditGreenTableSvg className="icon" />
                                        </div>
                                        <span className="title">Edit</span>
                                      </div>
                                      <div
                                        className="particular-menu cp"
                                        onClick={() =>
                                          handleDeletePublication(
                                            publication.id
                                          )
                                        }
                                      >
                                        <div className="action-icon">
                                          <DeleteTableSvg className="icon" />
                                        </div>
                                        <span className="title">Delete</span>
                                      </div>
                                      <div className="particular-menu cp">
                                        <div className="action-icon">
                                          <DownloadPrimaryTableSvg className="icon" />
                                        </div>
                                        <span className="title">
                                          Download Document
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !publications.length} />
                  </Fragment>
                </TableWrapper>
                <div className="submit-buttons">
                  <div className="buttons">
                    <button className="lg-rounded-btn gray" onClick={goBack}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 3))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step3>
            ) : (
              <Step4>
                <TableWrapper
                  isTableOverflowing={
                    academicTableRef?.current?.scrollHeight >
                    academicTableRef?.current?.offsetHeight
                  }
                >
                  <div className="header">
                    <div className="heading">
                      <span>References</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenReferenceModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="data-table" style={{ overflowX: "unset" }}>
                    <table className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          {refrence.map((item: string, index: number) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {references.map((reference, index) => {
                          return (
                            <tr>
                              <td>{reference.name}</td>
                              <td>{reference.organization}</td>
                              <td>{reference.relationship}</td>
                              <td>{reference.contact}</td>
                              <td>{reference.email}</td>
                              <td>
                                <div
                                  className="action-menu"
                                  ref={(el) => referenceRefs.current.push(el!)}
                                >
                                  <div
                                    className="menu-icon"
                                    onClick={() => setOpenReferenceIndex(index)}
                                  >
                                    <TabPrimaryActionMenu className="icon" />
                                  </div>
                                  {referenceCurrentIndex === index && (
                                    <div className="menu-dropdown">
                                      <div
                                        className="particular-menu cp"
                                        onClick={() =>
                                          handleEditReference(reference)
                                        }
                                      >
                                        <div className="action-icon">
                                          <EditGreenTableSvg className="icon" />
                                        </div>
                                        <span className="title">Edit</span>
                                      </div>
                                      <div
                                        className="particular-menu cp"
                                        onClick={() =>
                                          handleDeleteReference(reference?.id)
                                        }
                                      >
                                        <div className="action-icon">
                                          <DeleteTableSvg className="icon" />
                                        </div>
                                        <span className="title">Delete</span>
                                      </div>
                                      <div className="particular-menu cp">
                                        <div className="action-icon">
                                          <DownloadPrimaryTableSvg className="icon" />
                                        </div>
                                        <span className="title">
                                          Download Document
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !references.length} />
                  </Fragment>
                </TableWrapper>

                <div className="submit-buttons">
                  <div className="back-button">
                    <button onClick={goBack} className="lg-rounded-btn gray">
                      Back
                    </button>
                    <button
                      onClick={() => navigate(-1)}
                      className="lg-rounded-btn"
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Step4>
            )}
          </form>
        </FormSection>
      </EditProfileContentSection>
      {openReferenceModal && (
        <AddRefrence
          setOpen={setOpenReferenceModal}
          reference={referenceToEdit}
        />
      )}
      {openQualificationModal && (
        <AddQualification
          setOpen={setOpenQualificationModal}
          qualification={qualificationToEdit}
        />
      )}
      {openEmploymentModal && (
        <AddEmployment
          setOpen={setOpenEmploymentModal}
          employement={employementToEdit}
        />
      )}
      {openPublicationModal && (
        <AddPublication
          setOpen={setOpenPublicationModal}
          publication={publicationToEdit}
        />
      )}
    </EditEPortalProfileMain>
  );
};

export default EditEPortalCareer;
