import StudentAccountStatement from "containers/private/student-portal/academics/account-statement";
import MyEnrollments from "containers/private/student-portal/academics/current-enrollments";
import StudentFinancialVouchers from "containers/private/student-portal/academics/financial-vouchers";
import MyClearanceListing from "containers/private/student-portal/academics/my-clearance";
import StudentStudyPlan from "containers/private/student-portal/academics/plan-of-study";
import StudentTranscript from "containers/private/student-portal/academics/transcripts";
import CreateTicket from "containers/private/student-portal/all-ticket/create";
import TicketListing from "containers/private/student-portal/all-ticket/listing";
import StudentAssignments from "containers/private/student-portal/assignments";
import StudentCourseMaterial from "containers/private/student-portal/course-material";
import StudentDashboard from "containers/private/student-portal/dashboard";
import GenerateCourseChallan from "containers/private/student-portal/generate-course-challan";
import HolidayList from "containers/private/student-portal/holiday-list";
import LibraryBooksListing from "containers/private/student-portal/library-books";
import LMSEnrollmentsListing from "containers/private/student-portal/LMS";
import MyFeeVoucher from "containers/private/student-portal/my-fee-vouchers";
import LMSMyFiles from "containers/private/student-portal/my-files";
import StudentPortalDocumentsListing from "containers/private/student-portal/my-links/documents";
import StudentPortalProfile from "containers/private/student-portal/my-links/profile";
import NoticeBoard from "containers/private/student-portal/notice-board";
import CreateSupportTicket from "containers/private/student-portal/support-tickets/create";
import SupportTicketsListing from "containers/private/student-portal/support-tickets/listing";
import TransportSceduleList from "containers/private/student-portal/transport-schedule";
import CreateVehicleEntry from "containers/private/student-portal/vehical-entry/create";
import VehicleEntryListing from "containers/private/student-portal/vehical-entry/listing";
import useUtils from "hooks/useUtils";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const StudentPortalRoutes = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.studentDashboard)}
        Component={StudentDashboard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalNoticeBoard)}
        Component={NoticeBoard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalProfile, true)}
        Component={StudentPortalProfile}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalDocumentsListing, true)}
        Component={StudentPortalDocumentsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalLMS)}
        Component={LMSEnrollmentsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPortalLibraryBooks)}
        Component={LibraryBooksListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.studentPortalSupportTicketsListing,
          true
        )}
        Component={SupportTicketsListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createStudentPortalSupportTicket,
          true
        )}
        Component={CreateSupportTicket}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentVehicalEntryListing, true)}
        Component={VehicleEntryListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createStudentVehicleEntry, true)}
        Component={CreateVehicleEntry}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentTransportScheduleList, true)}
        Component={TransportSceduleList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.myEnrollments, true)}
        Component={MyEnrollments}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentPlanOfStudy, true)}
        Component={StudentStudyPlan}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentTranscript, true)}
        Component={StudentTranscript}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentFinancialVouchers, true)}
        Component={StudentFinancialVouchers}
      />

      <Route
        path={getPathToSetRoute(siteRoutes.studentAccountStatement, true)}
        Component={StudentAccountStatement}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.myClearanceListing, true)}
        Component={MyClearanceListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.myFeeVoucher)}
        Component={MyFeeVoucher}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentHolidayList)}
        Component={HolidayList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.generateCourseChallan)}
        Component={GenerateCourseChallan}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.ticketListing)}
        Component={TicketListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createTicket)}
        Component={CreateTicket}
      />
    </Routes>
  );
};

export default StudentPortalRoutes;
