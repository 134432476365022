import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  ManageGradeTemplatesMain,
  ManageGradeTemplatesSection,
  ManageGradeTemplatesTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import DataNotFound from "components/particles/table/data-not-found";
import LinkGradeTemplate from "./components/link-grade-template";
import useAcademics from "../useHooks";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";

const ManageGradeTemplates: FC = () => {
  const columns: string[] = [
    "",
    "Letter Grade",
    "Grade Point",
    "Percentage Boundary",
    "Action",
  ];

  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Grades Template /",
      path: siteRoutes.academicGradeTemplatesListing,
    },
    { title: "Manage Grades Template", path: siteRoutes.manageGradeTemplates },
  ];

  const navigate = useNavigate();

  const { getQueryParams } = useUtils();
  const { template_id } = getQueryParams();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const {
    getGradingSchemeTemplateRecord,
    deleteGradingSchemeTemplateList,
    updatePercentageBoundary,
  } = useAcademics();

  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [debouncedData, setDebouncedData] = useState<any>(null);
  const debouncedValue = useDebounce(debouncedData, 900);
  const [search, setSearch] = useState<string>("");
  const [openLinkGradesModal, setOpenLinkGradesModal] =
    useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const getTemplateTitle = data[0]?.template?.title || "";

  const goToLinkGradeTemplate = () => {
    setOpenLinkGradesModal(true);
  };

  const getGradingSchemeTemplateList = (
    page: number,
    per_page: number,
    search: string
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
      template_id,
    };
    getGradingSchemeTemplateRecord(setData, queryParams, setPagination);
  };

  const handleCourseSelection = (courseId: number) => {
    if (selectedCourses?.includes(courseId)) {
      const index = selectedCourses.indexOf(courseId);
      selectedCourses.splice(index, 1);
    } else {
      selectedCourses.push(courseId);
    }
    setSelectedCourses([...selectedCourses]);
  };

  const handleInputChange = (e: any, index: number, field: any) => {
    const updatedValue = e.target.value;
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], [field]: updatedValue };
    setData(updatedData);
    setDebouncedData(updatedData[index]);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getGradingSchemeTemplateList(page + 1, per_page, search);
  };

  const handleDeleteMultipleRecords = async () => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const record_ids: string = selectedCourses.join(", ");
      deleteGradingSchemeTemplateList(record_ids, setData, setSelectedCourses);
    }
  };

  const handleDelete = async (id: any) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      deleteGradingSchemeTemplateList(id, setData, setSelectedCourses);
    }
  };

  useEffect(() => {
    if (!openLinkGradesModal) {
      getGradingSchemeTemplateList(
        pagination.page,
        pagination.per_page,
        search
      );
    }
  }, [openLinkGradesModal]);

  useEffect(() => {
    if (debouncedValue) {
      const { id } = debouncedValue;
      const queryParams = {
        page: pagination.page,
        per_page: pagination.per_page,
        search,
        template_id,
      };
      updatePercentageBoundary(
        id,
        debouncedValue?.percentage_boundary,
        setData,
        queryParams,
        setPagination
      );
    }
  }, [debouncedValue]);
  console.log(debouncedValue?.percentage_boundary);
  return (
    <ManageGradeTemplatesMain>
      <ManageGradeTemplatesTop>
        <div className="left">
          <span className="page-heading">Manage Grades Template</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="create-org-btn" onClick={goToLinkGradeTemplate}>
            <button className="lg-rounded-btn">+ Link Grades</button>
          </div>
          {selectedCourses?.length > 0 && (
            <div
              className="delete-template-btn"
              onClick={handleDeleteMultipleRecords}
            >
              <button className="lg-rounded-btn">
                Delete Selected Templates
              </button>
            </div>
          )}
        </div>
      </ManageGradeTemplatesTop>

      <ManageGradeTemplatesSection className="content-radius-shadow">
        {data?.length > 0 && (
          <div className="table-heading">{getTemplateTitle}</div>
        )}
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => {
                const {
                  letter_grade: { grade_point = "", letter_grade = "" } = {},
                } = item;

                return (
                  <Fragment key={index}>
                    <tr>
                      <td>
                        <div className="row-action-cell">
                          <div className="table-checkbox-field">
                            <input
                              type="checkbox"
                              checked={selectedCourses?.includes(item.id)}
                              onChange={() => handleCourseSelection(item?.id)}
                              readOnly
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{letter_grade ?? "-"}</div>
                      </td>
                      <td>{grade_point ?? ""}</td>
                      <td>
                        <div className="input-field">
                          <div className="field-wrap">
                            <div className="field">
                              <input
                                type="number"
                                name="percentage_boundary"
                                value={item?.percentage_boundary}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    "percentage_boundary"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="table-action-icons">
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(item?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !data?.length} />
            <Pagination {...pagination} onPageChange={onPageChange} />
            {openLinkGradesModal && (
              <LinkGradeTemplate
                setOpen={setOpenLinkGradesModal}
                templateTitle={getTemplateTitle}
                template_id={template_id}
              />
            )}
          </Fragment>
        </div>
      </ManageGradeTemplatesSection>
    </ManageGradeTemplatesMain>
  );
};

export default ManageGradeTemplates;
