import { FC } from "react";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { Route, Routes } from "react-router-dom";
import ExamInchargePrograms from "containers/private/my-apps/exam-incharge-programs/listing";
import ExamInchargeCourses from "containers/private/my-apps/exam-incharge-courses/listing";
import ExamInchargeSectios from "containers/private/my-apps/exam-incharge-sections/listing";

const MyAppsRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();

  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.examInchargePrograms, true)}
        Component={ExamInchargePrograms}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.examInchargeSections, true)}
        Component={ExamInchargeSectios}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.examInchargeCourses, true)}
        Component={ExamInchargeCourses}
      />
    </Routes>
  );
};
export default MyAppsRoutes;
