import { FC, Fragment, useEffect, useState } from "react";
import { VouchersListingSection, MyVouchersMain, MyVouchersTop } from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { DownArrowSvg, UpArrowSvg } from "assets/images/e-portal/svgs";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useUtils from "hooks/useUtils";
import useEportal from "../useHooks";
import { SearchFieldSvg } from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

const MyVouchers: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Eportal /", path: siteRoutes.ePortalDashboard },
    { title: "My Vouchers", path: siteRoutes.ePortalMyVouchers },
  ];
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState<string>("");

  const columns: string[] = [
    "Title",
    "Application#",
    "Challan#",
    "Due Date",
    "Amount",
    "Status",
    "File Remarks",
    "Action",
  ];
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [data, setData] = useState<any[]>([]);

  const { handleTableSearch, handleSearchChange } = useUtils();
  const { getMyVouchers } = useEportal();

  useEffect(() => {
    getAllVouchers(pagination.page, search, pagination.per_page);
  }, []);

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllVouchers(page + 1, search, per_page);
  };
  const getAllVouchers = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getMyVouchers(setData, queryParams, setPagination);
  };

  return (
    <MyVouchersMain>
      <MyVouchersTop>
        <div className="left">
          <span className="page-heading">My Vouchers</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </MyVouchersTop>

      <VouchersListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="empty"></div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllVouchers
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllVouchers)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((items, index) => (
                <tr key={index}>
                  <td>
                    <div className="mw-150">{items?.title ?? "-"}</div>
                  </td>
                  <td>
                    <div className="mw-150">{items?.app_no ?? "-"}</div>
                  </td>
                  <td>
                    <div className="mw-150">{items?.challan_no ?? "-"}</div>
                  </td>
                  <td>
                    <div className="mw-150">{items?.due_date ?? "-"}</div>
                  </td>
                  <td>
                    <div className="">{items?.amount ?? "-"}</div>
                  </td>
                  <td>
                    <div
                      className={`status-tile ${
                        items?.status === "Paid" ? "green" : "red"
                      } `}
                    >
                      {items?.status ?? "-"}
                    </div>
                  </td>

                  <td>
                    <div className="">{items?.remarks ?? "-"}</div>
                  </td>
                  <td>
                    <div className="table-action-icons">
                      <div className="action-icon">
                        <DownArrowSvg />
                      </div>
                      <div className="action-icon">
                        <UpArrowSvg />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </VouchersListingSection>
    </MyVouchersMain>
  );
};

export default MyVouchers;
