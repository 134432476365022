import { FC, useEffect, useState } from "react";
import {
  CreateAdmissionOfferLetterFormSection,
  CreateAdmissionOfferLetterMain,
  CreateAdmissionOfferLetterTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import Editor from "components/particles/forms/editor";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { OfferLetterTemplateDTO } from "utils/helpers/models/admissions/offer-letter-template.dto";
import useUtils from "hooks/useUtils";
import useAdmissions from "../../useHooks";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";

interface CreateOfferLetterProps {}

const CreateAdmissionOfferLetter: FC<CreateOfferLetterProps> = () => {
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    {
      title: "Manage Offer Letter Templates /",
      path: siteRoutes.admissionOfferLetterListing,
    },
    {
      title: "Edit/View Offer Letter Templates",
      path: siteRoutes.createAdmissionOfferLetter,
    },
  ];
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<OfferLetterTemplateDTO>();
  const [formData, setFormData] = useState<OfferLetterTemplateDTO>(
    new OfferLetterTemplateDTO()
  );
  const {
    createOfferLetterTemplate,
    updateOfferLetterTemplate,
    getOfferLetterTemplateById,
  } = useAdmissions();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const onSubmit = () => {
    if (params?.id) {
      updateOfferLetterTemplate(params?.id, formData);
    } else {
      createOfferLetterTemplate(formData);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    const form_data: any = new OfferLetterTemplateDTO();
    for (let key in formData) {
      setValue(key as keyof OfferLetterTemplateDTO, form_data[key]);
      (formData as any)[key] = form_data[key];
    }

    setFormData({ ...formData });
  };

  useEffect(() => {
    if (params?.id)
      getOfferLetterTemplateById(params?.id, formData, setValue, setFormData);
  }, []);

  return (
    <CreateAdmissionOfferLetterMain>
      <CreateAdmissionOfferLetterTop>
        <div className="left">
          <span className="page-heading">Manage Offer Letter Templates</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateAdmissionOfferLetterTop>
      <CreateAdmissionOfferLetterFormSection onSubmit={handleSubmit(onSubmit)}>
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Offer Letter"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
          <div className="radio-field">
            <label htmlFor="no">Is Active</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="status_yes"
                  {...register("status", { required: false })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.status == 1}
                />
                <label htmlFor="status_yes">Yes</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="status_no"
                  {...register("status", { required: false })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.status == 0}
                />
                <label htmlFor="status_no">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.status} />
          </div>
        </div>

        <div className="editor-field">
          <label>Body</label>
          <div className="field-wrap">
            <div className="field">
              <Editor
                value={formData.body}
                onChange={(name, value) =>
                  handleChange({ target: { name, value } })
                }
                name="body"
                register={register}
                required={true}
              />
            </div>
            <FormErrorMessage error={errors.body} />
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {/* <button className="lg-rounded-btn black" type="submit">
              Save & Add more
            </button> */}
            <button className="lg-rounded-btn" type="submit">
              Save
            </button>
          </div>
        </div>
      </CreateAdmissionOfferLetterFormSection>
    </CreateAdmissionOfferLetterMain>
  );
};

export default CreateAdmissionOfferLetter;
