import {
  deleteRequest,
  getRequest,
  //   patchRequest,
  postRequest,
  putRequest,
  //   putRequest,
} from "utils/helpers/common/http-methods";
import { PreferedRoomToCourseListDTO } from "utils/helpers/models/time-table/add-room-to-course-list.dto";
import { PreferedRoomToSectionListDTO } from "utils/helpers/models/time-table/add-room-to-section-list.dto";
import { DefaultCreditHoursDTO } from "utils/helpers/models/time-table/default-credit-hours.dto";
import { TimeTablePlanerDTO } from "utils/helpers/models/time-table/time-table-planer.dto";
import { TimeTableDTO } from "utils/helpers/models/time-table/time-table.dto";

export const TIMETABLE_API = {
  createTimeTable: (body: TimeTableDTO) =>
    postRequest("/admin/timetables", body),
  getTimeTableList: (params: TimeTableDTO) =>
    getRequest(`/admin/timetables`, params),
  getTimeTableById: (id: number) => getRequest(`/admin/timetables/${id}`),
  updateTimeTableById: (body: TimeTableDTO, id: number) =>
    putRequest(`/admin/timetables/${id}`, body),
  deleteTimeTableById: (id: number) => deleteRequest(`/admin/timetables/${id}`),

  createTimeTablePlanner: (body: TimeTablePlanerDTO<string>) =>
    postRequest(`admin/timetable/timetable_planners`, body),
  getTimeTablePlanner: (params: any) =>
    getRequest(`/admin/timetable/timetable_planners`, params),
  getTimeTablePlannerById: (id: number) =>
    getRequest(`/admin/timetable/timetable_planners/${id}`),
  getTimeTablePlannerDashboardById: (params: any) =>
    getRequest(`admin/timetable/timetable_planner/dashboard`, params),
  deleteTimeTablePlannerById: (id: number) =>
    deleteRequest(`/admin/timetable/timetable_planners/${id}`),
  updateTimeTablePlannerById: (id: number, body: TimeTablePlanerDTO) =>
    putRequest(`/admin/timetable/timetable_planners/${id}`, body),
  getDefaultCreditHoursList: (params: any) =>
    getRequest(`/admin/timetable/planner_default_credit_hours`, params),
  updateDefaultCreditHoursList: (id: number, body: DefaultCreditHoursDTO) =>
    putRequest(`admin/timetable/planner_default_credit_hours/${id}`, body),

  getRoomToPlannerCourseList: (params: any) =>
    getRequest(`/admin/timetable/planner_room_course_allocation`, params),

  deleteRoomToCourseListById: (id: number) =>
    deleteRequest(`/admin/timetable/planner_room_course_allocation/${id}`),

  getRoomToPlannerSectionList: (params: any) =>
    getRequest(`/admin/timetable/planner_room_section_allocations`, params),
  createRoomToCourseAllocation: (body: PreferedRoomToCourseListDTO<string>) =>
    postRequest(`admin/timetable/planner_room_course_allocation`, body),

  createRoomToSectionAllocation: (body: PreferedRoomToSectionListDTO<string>) =>
    postRequest(`admin/timetable/planner_room_section_allocations`, body),
  deleteRoomToSectionListById: (id: number) =>
    deleteRequest(`admin/timetable/planner_room_section_allocations/${id}`),

  downloadOrganizationsPdfFile: () =>
    getRequest(`/admin/time-table/download/pdf`),
};
