import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreateFeePlanSection,
  CreateFeePlanMain,
  CreateFeePlanTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";
import { FeePlanDTO } from "utils/helpers/models/academics/fee-plan.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useAcademics from "../../useHooks";
import useUtils from "hooks/useUtils";

export const CreateAcademicFeePlan: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },

    { title: "Fee Plans /", path: siteRoutes.academicFeePlansListing },
    { title: `${params?.id ? 'Update' : 'Add'} Fee Plan`, path: siteRoutes.createAcademicFeePlan },
  ]
  const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<FeePlanDTO>();
  const { createAcademicFeePlan, updateAcademicFeePlan, getAcademicFeePlanById } = useAcademics();
  let [formData, setFormData] = useState<FeePlanDTO>(new FeePlanDTO());

  const handleChange = (event: any) => {
    const { target: { name, value } } = event;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    trigger(name);
  }

  const resetForm = () => {
    formData = new FeePlanDTO();
    for (let key in formData) {
      setValue((key as any), formData[key]);
    }

    setFormData({...formData});
  }

  const onSubmit = () => {
    if (!params?.id) {
      createAcademicFeePlan(formData);
    } else {
      updateAcademicFeePlan(params?.id, formData);
    }
  }

  useEffect(() => {
    if (params?.id) {
      getAcademicFeePlanById(params?.id, formData, setFormData, setValue);
    }
  }, []);

  return (
    <CreateFeePlanMain>
      <CreateFeePlanTop>
        <div className="left">
          <span className="page-heading">{params?.id ? 'Update' : 'Add'} Fee Plan</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateFeePlanTop>

      <CreateFeePlanSection className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>
        <div className="common-fields">
          <div className="input-field">
            <label>Name</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" placeholder="Enter Name" {...register('title', { required: true })} value={formData.title} onChange={handleChange} />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
          <div className="input-field">
            <label>Excess Amount</label>
            <div className="field-wrap">
              <div className="field">
                <input type="number" placeholder="Enter Amount" {...register('excess_amount', { required: false })} value={formData.excess_amount} onChange={handleChange} />
              </div>
              <FormErrorMessage error={errors.excess_amount} />
            </div>
          </div>
          <div className="radio-field">
            <label htmlFor="no">Is Active</label>
            <div className="field-wrap">
              <div className="field">
                <input type="radio" id="active" {...register('is_active', { required: true })} value={1} checked={formData.is_active == 1} onChange={handleChange} />
                <label htmlFor="active">Yes</label>
              </div>
              <div className="field">
                <input type="radio" id="inactive" {...register('is_active', { required: true })} value={0} checked={formData.is_active == 0} onChange={handleChange} />
                <label htmlFor="inactive">No</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_active} />
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button" onClick={resetForm}>
              Reset
            </button>
            <button className="lg-rounded-btn">{params?.id ? 'Update' : 'Create'} & Exit</button>
          </div>
        </div>
      </CreateFeePlanSection>
    </CreateFeePlanMain>
  );
};

export default CreateAcademicFeePlan;
