import { FC, Fragment, useEffect, useState } from "react";
import { ExcelSvg, PdfSvg, SearchFieldSvg } from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicExamTypeListingMain,
  AcademicExamTypeListingSection,
  AcademicExamTypeListingTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAcademics from "containers/private/academics/useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useUtils from "hooks/useUtils";
import useMyApps from "../../useHooks";

interface dataInterFace {
  id: number;
  title: string;
  submitted_courses: string;
  verified_courses: string;
}

const ExamInchargeSectios: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "My Apps", path: "" },
    {
      title: " / Exam Incharge Programs Listing",
      path: siteRoutes.examInchargePrograms,
    },
    {
      title: " / Exam Incharge Sections Listing",
      path: siteRoutes.examInchargeSections,
    },
  ];

  const { getQueryParams } = useUtils();
  const { session_id, program_id, campus_id } = getQueryParams();
  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { getExamInchargeSection } = useMyApps();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [data, setData] = useState<dataInterFace[]>([]);
  const [search, setSearch] = useState<string>("");
  const columns: string[] = [
    "Course Title",
    "Submitted Courses",
    "Verified Courses",
    "Actions",
  ];
  const [filters, setFilters] = useState<any>({
    campus: "",
    academic_session: "",
    is_active: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const goToViewCourses = () => {
    navigate(siteRoutes.examInchargeCourses);
  };
  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };
  const onClearFilters = () => {
    const clearedFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
    setFilters(clearedFilters);
    getExamInchargeSectionsList(pagination.page, search, pagination.per_page);
  };

  const onSubmitFilters = () => {
    getExamInchargeSectionsList(pagination.page, search, pagination.per_page);
  };

  const getExamInchargeSectionsList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      search,
      per_page,
      session_id,
      program_id,
      campus_id,
    };
    getExamInchargeSection(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getExamInchargeSectionsList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AcademicExamTypeListingMain>
      <AcademicExamTypeListingTop>
        <div className="left">
          <span className="page-heading">Exam Incharge Section</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcademicExamTypeListingTop>

      {/* <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label htmlFor="campus-select">Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      id="campus"
                      name="campus"
                      value={filters.campus}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {campuses?.map((item: any, i: number) => {
                        return (
                          <option key={i} value={item?.id}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="campus-select">Academic Session</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      id="campus-select"
                      name="academic_session"
                      value={filters.academic_session}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Academic Session</option>
                      {academicSession?.map((item: any, i: number) => {
                        return (
                          <option key={i} value={item?.session?.id}>
                            {item?.session?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="radio-field">
              <label htmlFor="no">Is Active</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="radio"
                    id="is_active_yes"
                    value={1}
                    name="is_active"
                    checked={filters.is_active === "1"}
                    onChange={handleFilterChange}
                  />
                  <label htmlFor="is_active_yes">Activate</label>
                </div>
                <div className="field">
                  <input
                    type="radio"
                    id="is_active_no"
                    value={0}
                    name="is_active"
                    checked={filters.is_active === "0"}
                    onChange={handleFilterChange}
                  />
                  <label htmlFor="is_active_no">Deactivate</label>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={onClearFilters}
                >
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onSubmitFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection> */}

      <AcademicExamTypeListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: number) => (
                <tr>
                  <td>
                    <div className="mw-150">{item?.title}</div>
                  </td>
                  <td>
                    <div className="status-tile">{item?.submitted_courses}</div>
                  </td>
                  <td>
                    <div className="status-tile">{item?.verified_courses}</div>
                  </td>
                  <td>
                    <div className="table-action-icons">
                      {hasAccess(sitePermissions.examInchargeSections) && (
                        <div
                          className="table-action-button"
                          onClick={goToViewCourses}
                        >
                          <button className="black">View Courses</button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <Fragment>
            <DataNotFound show={!isLoading && !data?.length} />
            <Pagination
              onPageChange={(page: any) => console.log(page)}
              {...pagination}
            />
          </Fragment>
        </div>
      </AcademicExamTypeListingSection>
    </AcademicExamTypeListingMain>
  );
};

export default ExamInchargeSectios;
