import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, Fragment, useEffect, useState } from "react";
import {
  CreateStudentStatusSection,
  CreateStudentStatusMain,
  CreateStudentStatusTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { SyncGradeTemplateGradeTemplateDTO } from "utils/helpers/models/academics/sync-grade-template.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import useAcademics from "../useHooks";
import MultiselectField from "components/particles/forms/multiselect-field";
import useUtils from "hooks/useUtils";
// import { errorMessages } from "utils/helpers/enums/messages.enum";
import { useNavigate } from "react-router-dom";
// import { errorToaster } from "utils/helpers/common/alert-service";

export const SyncGradeTemplates: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Grade Templates  / ",
      path: siteRoutes.academicGradeTemplatesListing,
    },
    {
      title: "Sync Grade Templates",
      path: siteRoutes.academicSyncGradeTemplates,
    },
  ];

  const navigate = useNavigate();
  const { getQueryParams } = useUtils();
  const { template_id, title } = getQueryParams();
  const { getAcademicSessions, createGradeSyncTemplate } = useAcademics();
  const { getSpecificStructure, getCertificateLevelsAdmin } = useOrganization();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState<SyncGradeTemplateGradeTemplateDTO>(
    new SyncGradeTemplateGradeTemplateDTO({ template_id })
  );
  const [certificateLevels, setCertificateLevels] = useState<any[]>([]);
  const [faculties, setFaculty] = useState<OrgStructureDTO[]>([]);
  const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>(
    []
  );
  const resetForm = () => {
    for (let key in getValues()) {
      setValue(key as keyof SyncGradeTemplateGradeTemplateDTO, "");
    }
    setFormData({ ...new SyncGradeTemplateGradeTemplateDTO() });
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    formData[name as keyof SyncGradeTemplateGradeTemplateDTO] = value as never;
    setFormData({ ...formData });
    trigger(name);
  };

  const onSelect = (
    value: number[],
    name: keyof SyncGradeTemplateGradeTemplateDTO
  ) => {
    (formData as any)[name] = value;
    setValue(name as keyof SyncGradeTemplateGradeTemplateDTO, formData[name]);
    trigger([name]);
    setFormData({ ...formData });
  };

  useEffect(() => {
    if (!template_id) {
      navigate(siteRoutes.academicGradeTemplatesListing);
    }
    getSpecificStructure(setFaculty, {
      per_page: "All",
      type: OrgStructureTypes.faculty,
    });
    getCertificateLevelsAdmin(setCertificateLevels);
    getAcademicSessions(setAcademicSession);
    register("program_level", { required: true });
    register("faculty", { required: true });
  }, []);

  const onSubmit = () => {
    const data = formData as SyncGradeTemplateGradeTemplateDTO;
    if (template_id) {
      console.log(data?.template_id);
      createGradeSyncTemplate(data);
    } else {
      navigate(siteRoutes.academicGradeTemplatesListing);
    }
  };

  return (
    <CreateStudentStatusMain>
      <CreateStudentStatusTop>
        <div className="left">
          <span className="page-heading">Sync Grading Templates</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateStudentStatusTop>

      <CreateStudentStatusSection
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="table-info-section">
          <div className="left-section">
            <div className="heading">
              <span>{decodeURIComponent(title) ?? ""}</span>
            </div>
          </div>
        </div>

        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="campus-select">Session</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("session_id", { required: true })}
                  value={formData?.session_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {academicSession?.map((item: any, index: number) => (
                    <option key={index} value={item?.id}>
                      {item?.type}-{item?.year}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.session_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Credit Hours</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("credit_hours", { required: true })}
                  value={formData?.credit_hours}
                  onChange={handleChange}
                  placeholder="0000"
                />
              </div>
            </div>
            <FormErrorMessage error={errors.credit_hours} />
          </div>

          <MultiselectField
            options={faculties}
            onSelect={(item) => onSelect(item, "faculty")}
            value={formData.faculty}
            searchable
            label="Faculty"
            helperText="-- Select --"
            error={errors?.faculty}
          />
          <MultiselectField
            options={certificateLevels}
            onSelect={(item) => onSelect(item, "program_level")}
            value={formData?.program_level}
            searchable
            label="Program Level"
            helperText="-- Select --"
            error={errors?.program_level}
          />
          {/* <div className="input-field">
            <label htmlFor="campus-select">Common Course</label>
            <div className="field-wrap">
              <div className="field">
                {/* <input
                  type="text"
                  placeholder="Information Technology"
                  /> */}
          {/* <select
                  {...register("common_course", { required: false })}
                  value={formData?.common_course}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              <FormErrorMessage error={errors.common_course} /> */}
          {/* </div>
          </div> */}

          <div className="radio-field">
            <label htmlFor="no">Common Course</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_common_yes"
                  {...register("common_course", { required: false })}
                  value="true"
                  onChange={handleChange}
                  checked={formData.common_course == "true"}
                />
                <label htmlFor="is_active_yes">True</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_common_no"
                  {...register("common_course", { required: false })}
                  value="false"
                  onChange={handleChange}
                  checked={formData.common_course === "false"}
                />
                <label htmlFor="is_active_no">False</label>
              </div>
            </div>
            <FormErrorMessage error={errors.common_course} />
          </div>

          <div className="input-field">
            <label>Course Code</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("course_code", { required: false })}
                  value={formData.course_code}
                  onChange={handleChange}
                  placeholder="Enter Course Code"
                />
              </div>
            </div>
            <FormErrorMessage error={errors.course_code} />
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" onClick={resetForm}>
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </CreateStudentStatusSection>
    </CreateStudentStatusMain>
  );
};

export default SyncGradeTemplates;
