import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";

import {
  CreateTimeTableForm,
  CreateTimeTableMain,
  CreateTimeTableTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { TimeTablePlanerDTO } from "utils/helpers/models/time-table/time-table-planer.dto";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

import useStore from "hooks/useStore";
import useTimeTable from "../../../UseHooks";
import MultiselectField from "components/particles/forms/multiselect-field";
import {
  AcademicSesionTypes,
  OrgStructureTypes,
} from "utils/helpers/enums/shared.enums";

import useOrganization from "containers/private/organization/useHooks";
import useAcademics from "containers/private/academics/useHooks";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { AddBuildingDTO } from "utils/helpers/models/organization/add-building.dto";

interface CreateTimeTablePlannerProps {}

const TimeTablePlannerCreate: FC<CreateTimeTablePlannerProps> = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { hasAccess } = useStore();

  const breadcrumbLinks = [
    { title: "Time Table /", path: siteRoutes.timeTableListing },
    {
      title: `Time Table Planner ${params?.id ? "Update" : "Create"}`,
      path: siteRoutes.timeTablePlannerCreate,
    },
  ];

  const {
    createTimeTablePlanner,
    getTimeTablePlannerById,
    updateTimeTablePlannerById,
  } = useTimeTable();

  const { getSpecificOrgStructures, getPrograms, getBuildingList } =
    useOrganization();

  const { getAcademicSessions, getAcademicSections } = useAcademics();

  const [buildings, setBuildings] = useState<AddBuildingDTO[]>([]);
  const [section, setSections] = useState<AcademicSessionDTO[]>([]);
  const [faculties, setFaculties] = useState<OrgStructureDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const [linkedTimeTable, setLinkedTimeTable] = useState<any[]>([]);
  const [academicSession, setAcademicSession] = useState<AcademicSessionDTO[]>(
    []
  );
  const [campus, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<TimeTablePlanerDTO>(
    new TimeTablePlanerDTO({
      faculty_id: [],
      department_id: [],
      program_id: [],
      section_id: [],
      building_id: [],
      linked_time_table: [],
    })
  );

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<TimeTablePlanerDTO>();

  const handleChange = (event: any): void => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      if (key !== "header_id") {
        setValue(key as keyof TimeTablePlanerDTO, "");
      }
    }

    setFormData({
      ...new TimeTablePlanerDTO(),
    });
  };

  const onSelect = (value: number[], name: keyof TimeTablePlanerDTO) => {
    (formData as any)[name] = value;
    setValue(name as keyof TimeTablePlanerDTO, formData[name]);
    trigger([name]);
    setFormData({ ...formData });
  };
  const onSubmit = (addMore: boolean = false) => {
    // Convert arrays to strings for `createTimeTablePlanner`
    const stringBody: TimeTablePlanerDTO<string> = {
      ...formData,
      building_id: Array.isArray(formData.building_id)
        ? formData.building_id.join(",")
        : "",
      section_id: Array.isArray(formData.section_id)
        ? formData.section_id.join(",")
        : "",
      department_id: Array.isArray(formData.department_id)
        ? formData.department_id.join(",")
        : "",
      faculty_id: Array.isArray(formData.faculty_id)
        ? formData.faculty_id.join(",")
        : "",
      program_id: Array.isArray(formData.program_id)
        ? formData.program_id.join(",")
        : "",
      linked_time_table: Array.isArray(formData.linked_time_table)
        ? formData.linked_time_table.join(",")
        : "",
    };

    // Convert arrays to number[] for `updateTimeTablePlannerById`
    const numberBody: TimeTablePlanerDTO<number[]> = {
      ...formData,
      building_id: Array.isArray(formData.building_id)
        ? formData.building_id.map(Number)
        : [],
      section_id: Array.isArray(formData.section_id)
        ? formData.section_id.map(Number)
        : [],
      department_id: Array.isArray(formData.department_id)
        ? formData.department_id.map(Number)
        : [],
      faculty_id: Array.isArray(formData.faculty_id)
        ? formData.faculty_id.map(Number)
        : [],
      program_id: Array.isArray(formData.program_id)
        ? formData.program_id.map(Number)
        : [],
      linked_time_table: Array.isArray(formData.linked_time_table)
        ? formData.linked_time_table.map(Number)
        : [],
    };

    if (params?.id) {
      updateTimeTablePlannerById(params.id, numberBody);
    } else {
      createTimeTablePlanner(stringBody, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getTimeTablePlannerById(params?.id, setFormData, formData, setValue);
      console.log("planner pages", formData);
    }
    getAcademicSessions(setAcademicSession);
    getSpecificOrgStructures(setCampuses, {
      per_page: "All",
      type: OrgStructureTypes.campus,
    });
    getSpecificOrgStructures(setFaculties, {
      per_page: "All",
      type: OrgStructureTypes.faculty,
    });
    getSpecificOrgStructures(setDepartments, {
      per_page: "All",
      type: OrgStructureTypes.department,
    });
    getPrograms(setPrograms);
    getAcademicSections(setSections);
    getBuildingList(setBuildings);
    register("program_id", { required: true });
    register("department_id", { required: true });
    register("faculty_id", { required: true });
    register("section_id", { required: true });
    register("building_id", { required: true });
    register("linked_time_table", { required: false });
  }, []);

  return (
    <CreateTimeTableMain>
      <CreateTimeTableTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Time Table Planner
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateTimeTableTop>

      <CreateTimeTableForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field ">
            <label>Time Table Planner Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData?.title}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <div className="input-field">
            <label htmlFor="campus-select">Academic Session</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("academic_session_id", { required: true })}
                  value={formData.academic_session_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {academicSession &&
                    academicSession?.map((item: any, index: number) => (
                      <option key={index} value={item?.id}>
                        {item?.type}-{item?.year}
                      </option>
                    ))}
                </select>
              </div>
              <FormErrorMessage error={errors.academic_session_id} />
            </div>
          </div>

          <div className="input-field">
            <label>Campus</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("campus_id", { required: true })}
                  value={formData.campus_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {campus?.map((item: any, index: number) => (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.campus_id} />
            </div>
          </div>

          <MultiselectField
            options={faculties}
            onSelect={(item) => onSelect(item, "faculty_id")}
            value={formData.faculty_id}
            searchable
            label="Faculties"
            helperText="-- Select --"
            error={errors?.faculty_id}
          />

          <MultiselectField
            options={departments}
            onSelect={(item) => onSelect(item, "department_id")}
            value={formData.department_id}
            searchable
            label="Departments"
            helperText="-- Select --"
            error={errors?.department_id}
          />
          <MultiselectField
            options={programs}
            onSelect={(item) => onSelect(item, "program_id")}
            value={formData.program_id}
            searchable
            label="Programs"
            helperText="-- Select --"
            error={errors?.program_id}
          />

          <MultiselectField
            options={section}
            onSelect={(item) => onSelect(item, "section_id")}
            value={formData.section_id}
            searchable
            label="Sections"
            helperText="-- Select --"
            error={errors?.section_id}
          />
          <MultiselectField
            options={buildings}
            onSelect={(item) => onSelect(item, "building_id")}
            value={formData.building_id}
            searchable
            label="Buildings"
            helperText="-- Select --"
            error={errors?.building_id}
          />
          <MultiselectField
            options={linkedTimeTable}
            onSelect={(item) => onSelect(item, "linked_time_table")}
            value={formData.linked_time_table}
            searchable
            label="Linked Time Table"
            helperText="-- Select --"
            error={errors?.linked_time_table}
          />
          <div className="radio-field">
            <label htmlFor="no">Status</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_active_yes"
                  {...register("is_active", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData.is_active == 1}
                />
                <label htmlFor="is_active_yes">Active</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_active_no"
                  {...register("is_active", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.is_active == 0}
                />
                <label htmlFor="is_active_no">Not Active</label>
              </div>
            </div>
            <FormErrorMessage error={errors.is_active} />
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <>
                {hasAccess(sitePermissions.timeTableCreate) && (
                  <button
                    className="lg-rounded-btn black"
                    onClick={handleSubmit((data) => onSubmit(true))}
                  >
                    Save & Add more
                  </button>
                )}
              </>
            )}

            {hasAccess(sitePermissions.timeTableCreate) && (
              <button
                className="lg-rounded-btn"
                onClick={handleSubmit((data) => onSubmit(false))}
              >
                {params?.id ? "Update" : "Save"} & Exit
              </button>
            )}
          </div>
        </div>
      </CreateTimeTableForm>
    </CreateTimeTableMain>
  );
};

export default TimeTablePlannerCreate;
