import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/helpers/common/http-methods";
import { EmployeesDTO } from "utils/helpers/models/hr-management/employees.dto";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";

export const HR_MANAGEMENT_APIS = {
  getTeachersTitles: (params: any) =>
    getRequest(`admin/teachers/titles`, params),
  createTeachers: (body: TeacherDTO) => postRequest(`admin/teachers`, body),
  updateTeacherById: (body: TeacherDTO, id: number) =>
    putRequest(`admin/teachers/${id}`, body),
  getTeacherById: (id: number) => getRequest(`admin/teachers/${id}`),
  getTeachersLisiting: (params: any) => getRequest(`admin/teachers`, params),
  deleteTeacherById: (id: number) => deleteRequest(`admin/teachers/${id}`),
  getEmployeesLisiting: (params: any) => getRequest(`admin/employees`, params),
  deleteEmployeeById: (id: number) => deleteRequest(`admin/employees/${id}`),
  updateEmployeesById: (body: EmployeesDTO, id: number) =>
    putRequest(`admin/employees/${id}`, body),
  createEmployees: (body: EmployeesDTO) => postRequest(`admin/employees`, body),
  getEmployeeById: (id: number) => getRequest(`admin/employees/${id}`),
};
