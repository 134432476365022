import { FC, Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";

import {
  CreateTimeTableForm,
  CreateTimeTableMain,
  CreateTimeTableTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { TimeTableDTO } from "utils/helpers/models/time-table/time-table.dto";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import useTimeTable from "containers/private/time-table/UseHooks";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import MultiselectField from "components/particles/forms/multiselect-field";
import { PreferedRoomToCourseListDTO } from "utils/helpers/models/time-table/add-room-to-course-list.dto";
import useAcademics from "containers/private/academics/useHooks";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";
import { AddRoomDto } from "utils/helpers/models/organization/add-rooms.dto";
import { warningToaster } from "utils/helpers/common/alert-service";

interface pageProps {}

const PrefferedRoomCourseListCreate: FC<pageProps> = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { hasAccess } = useStore();

  const { createRoomToCourseAllocation } = useTimeTable();
  const { getTemplateCourses } = useAcademics();

  const { getRoomsList } = useOrganization();

  const breadcrumbLinks = [
    {
      title: "Time Table / ",
      path: "",
    },
    {
      title: "Planner Dashboard / ",
      path: siteRoutes.timeTablePlannerDashboard,
    },
    {
      title: `Room To Course Allocation ${params?.id ? "Update" : "Create"}`,
      path: siteRoutes.prefferedRoomCourseListing,
    },
  ];

  const [rooms, setRooms] = useState<AddRoomDto[]>([]);
  const [templateCourses, setTemplateCourses] = useState<TemplateCourseDTO[]>(
    []
  );
  const [formData, setFormData] = useState<PreferedRoomToCourseListDTO>(
    new PreferedRoomToCourseListDTO()
  );
  const [isSectionDropdownOpen, setIsSectionDropdownOpen] =
    useState<boolean>(false);

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<PreferedRoomToCourseListDTO>({ defaultValues: formData });

  const handleChange = (event: any): void => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      if (key !== "header_id") {
        setValue(key as keyof PreferedRoomToCourseListDTO, "");
      }
    }

    setFormData({
      ...new PreferedRoomToCourseListDTO(),
    });
  };

  const onSelectRooms = (value: number[]) => {
    formData.room_id = value;
    setFormData({ ...formData });
  };

  const onSubmit = (
    data: PreferedRoomToCourseListDTO,
    addMore: boolean = false
  ) => {
    if (!formData.room_id.length) {
      warningToaster("Please select at least one quota");
    } else {
      if (data) {
        const body: Partial<PreferedRoomToCourseListDTO<string>> = {};
        if (params.planner_id) body.planner_id = params.planner_id;
        if (formData.tc_id) body.tc_id = formData.tc_id;
        if (formData.room_id && formData.room_id.length > 0) {
          body.room_id = formData.room_id.join(",");
        }

        createRoomToCourseAllocation(
          body as PreferedRoomToCourseListDTO<string>,
          addMore,
          resetForm
        );
      } else {
        warningToaster("Please select required fields");
      }
    }
  };

  useEffect(() => {
    getRoomsList(setRooms, { per_page: "All" });
    getTemplateCourses(setTemplateCourses);
    register("room_id", { required: true });
  }, []);

  return (
    <CreateTimeTableMain>
      <CreateTimeTableTop>
        <div className="left">
          <span className="page-heading">
            Room TO Course Allocation {params?.id ? "Update" : "Create"}
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateTimeTableTop>

      <CreateTimeTableForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Template Course</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("tc_id", { required: true })}
                  value={formData.tc_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {templateCourses?.map((item, index) => {
                    return <option value={item?.id}>{item?.tc_title}</option>;
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.tc_id} />
            </div>
          </div>

          <MultiselectField
            options={rooms}
            onSelect={onSelectRooms}
            value={formData.room_id}
            property="room_name"
            searchable
            label="Rooms"
            helperText="Select Rooms"
            onOpen={() => {
              setIsSectionDropdownOpen(true);
            }}
            onClose={() => setIsSectionDropdownOpen(false)}
          />
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button
              className="lg-rounded-btn black"
              onClick={handleSubmit((data: PreferedRoomToCourseListDTO) =>
                onSubmit(data, true)
              )}
            >
              Save & Add more
            </button>
            <button
              className="lg-rounded-btn"
              onClick={handleSubmit((data: PreferedRoomToCourseListDTO) =>
                onSubmit(data)
              )}
            >
              Save & Exit
            </button>
          </div>
        </div>
      </CreateTimeTableForm>
    </CreateTimeTableMain>
  );
};

export default PrefferedRoomCourseListCreate;
