export class ExamInchargeDTO<
  MultiSelectType extends number[] | string = number[]
> {
  id: number;
  program_id: number | string = "";
  teacher_ids: MultiSelectType;
  semester_allowed: string = "";
  can_verify!: number;
  campus_id: string = "";
  constructor(data: Partial<ExamInchargeDTO> = {}) {
    Object.assign(this, data);
  }
}
