import { OrgStructureDTO } from "../organization/org-structure.dto";

export class EmployeesDTO {
  id!: number;
  title_id: string = "";
  title: { title: string } = { title: "" };
  user: {
    phone_no: string;
    users_meta: { father_name: string; religion: string };
    profile_image: string;
    address: string;
    cnic: string;
    gender: string;
    cnic_image: string;
  } = {
    cnic: "",
    gender: "",
    address: "",
    phone_no: "",
    cnic_image: "",
    profile_image: "",
    users_meta: { father_name: "", religion: "" },
  };

  domicile_id: number;
  phone_no: string;
  users_meta: { father_name: string; religion: string };
  profile_image: string;
  address: string;
  cnic: string;
  gender: string;
  cnic_image: string;
  first_name: string = "";
  nok_mobile_number: number;
  stage_id: number;
  nok_relation: string = "";
  is_teaching: string = "";
  job_status_date: string = "";
  remarks: string = "";
  job_status: string = "";
  nok_name: string = "";
  mobile_number: string = "";
  city_id: number;
  joining_letter_no: string = "";
  app_letter_no: string = "";
  acc_number: string = "";
  blood_group: string = "";
  experience: number;
  is_hod: string = "";
  dob: string = "";
  dor: string = "";
  doa: string = "";
  salary: number;
  job_type: string = "";
  passport_number: string = "";
  religion: string = "";
  cast: string = "";
  emp_number: string = "";
  present_address: string = "";
  permanent_address: string = "";
  domicile: { title: string } = { title: "" };
  nationality: { name: string } = { name: "" };
  name: string = "";
  joining_notify_date: string = "";
  designation_id: number;
  user_name: string = "";
  is_active: number;
  nationality_id: number;
  email: string = "";
  campus_id: string = "";
  campus: OrgStructureDTO;
  department_id: number;
  user_id: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
