export class JobDesignationDTO {
  id!: number;
  title: string = "";
  bps: string = "";
  description: string = "";
}
export class JobApplicantsDTO {
  id!: number;
  isExpanded: any;
  title: string = "";
  date_applied: string = "";
  user: {
    cnic: string;
    name: string;
    phone_no: string;
    email: string;
    gender: string;
    users_meta: {
      father_name: string;
      date_applied: string;
    };
  } = {
    cnic: "",
    name: "",
    phone_no: "",
    email: "",
    gender: "",
    users_meta: {
      father_name: "",
      date_applied: "",
    },
  };
  job: {
    job_designation: string;
    internal_employee: number;
    job_batch: { title: string };
  } = {
    job_designation: "",
    internal_employee: 0,
    job_batch: { title: "" },
  };
}
