import { STUDENT_PORTAL_APIS } from "libs/apis/student-portal.api";

const useStudentPortal = () => {
  const getMyPlanOfStudy = async (setData: Function) => {
    const response = await STUDENT_PORTAL_APIS.getMyPlanOfStudy();
    console.log(response, "response");
  };
  const getMyVouchers = async (setData: Function) => {
    const response = await STUDENT_PORTAL_APIS.getMyVouchers();
    console.log(response, "response");
  };
  const getMyEnrollment = async (setData: Function) => {
    const response = await STUDENT_PORTAL_APIS.getMyEnrollment();
    const { status } = response;
    if (status) {
      setData(response?.response);
    }
  };
  const getMyFeePlan = async (setData: Function) => {
    const response = await STUDENT_PORTAL_APIS.getMyFeePlan();
    console.log(response, "response");
  };

  return {
    getMyPlanOfStudy,
    getMyVouchers,
    getMyEnrollment,
    getMyFeePlan,
  };
};

export default useStudentPortal;
