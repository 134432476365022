import styled from "styled-components";

export const GradeBooksListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const GradeBooksListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  .grade-book-title {
    font-size: 1rem;
    font-weight: bold;
    span {
      font-weight: 500;
      color: var(--light-medium-crimson);
    }
  }

  table td {
    .org-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .mw-150 {
      min-width: 150px;
    }
    .mw-100 {
      min-width: 100px;
    }
  }

  .menu-dropdown {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .action-button {
      display: flex;
      align-items: center;
      gap: 0.5em;
      padding: 0.3rem 1rem;

      .action-icon {
        display: flex;
        align-items: center;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const GradeBooksListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;

    .create-org-btn {
      &.upload {
        margin-top: 0.8em;
      }
      label {
        padding: 0.9em 1.5em;
      }
    }
  }
`;

export const TopCardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  .container-title {
    font-size: var(--font-24);
    border-bottom: 1px solid var(--light-gray);
    color: var(--black-text);
  }
  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .stats-item {
    display: flex;
    flex-direction: column;
  }
  .stats-title {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-text);
    border-bottom: 5px solid var(--lightgray-medium-light);
    width: 40%;
    padding-bottom: 4px;
  }
  .stats-value {
    padding-top: 10px;
    color: var(--black-text);
    font-weight: 400;
    font-size: 15px;
  }

  .table-radio-field {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 4px;
    .radio label {
      padding-right: 5px;
    }
  }
`;
export const LogsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  .content-radius-shadow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 2em 0;
    background-color: transparent;
    box-shadow: none;

    @media screen and (max-width: 630px) {
      padding: 1em;
    }

    .list-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 630px) {
        flex-direction: column;
        gap: 1em;
      }
    }

    .grade-book-title {
      font-size: 1rem;
      font-weight: bold;
      span {
        font-weight: 500;
        color: var(--light-medium-crimson);
      }
    }

    table td {
      .mw-150 {
        min-width: 150px;
      }
    }
  }
  .container-title {
    font-size: var(--font-24);
    border-bottom: 1px solid var(--light-gray);
    color: var(--black-text);
  }
  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .stats-item {
    display: flex;
    flex-direction: column;
  }
  .stats-title {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-text);
    border-bottom: 5px solid var(--lightgray-medium-light);
    width: 40%;
    padding-bottom: 4px;
  }
  .stats-value {
    padding-top: 10px;
    color: var(--black-text);
    font-weight: 400;
    font-size: 15px;
  }

  .table-radio-field {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 4px;
    .radio label {
      padding-right: 5px;
    }
  }
`;
