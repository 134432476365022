import { FC, useEffect, useState } from "react";
import {
  CreateLegalAdvisorsTop,
  CreateLegalAdvisorsSection,
  CreateLegalAdvisorsMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import InputMask from "react-input-mask";
import useUtils from "hooks/useUtils";
import {
  LegalAdviserDTO,
  LegalAdviserTypeDTO,
} from "utils/helpers/models/legal-matters/legal-adviser.dto";
import useLegalMatters from "../../useHook";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { LegalStatusesCategoryDTO } from "utils/helpers/models/legal-matters/legal-statuses.dto";
const CreateLegalAdvisors: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    { title: "Legal Advisors /", path: siteRoutes.legalAdvisorListing },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Advisor`,
      path: siteRoutes.createLegalAdvisor,
    },
  ];

  const {
    createLegalAdvisor,
    updateLegalAdviserByID,
    getLegalAdviserByID,
    getLegalAdviserType,
  } = useLegalMatters();
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalAdviserDTO>();

  const [formData, setFormData] = useState<LegalAdviserDTO>(
    new LegalAdviserDTO()
  );
  const [adviserType, setAdviserType] = useState<LegalAdviserTypeDTO[]>([]);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalAdviserDTO()[
        key as keyof LegalAdviserDTO
      ];
      setValue(key as keyof LegalAdviserDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: LegalAdviserDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateLegalAdviserByID(params?.id, formData);
    } else {
      createLegalAdvisor(formData, addMore, resetForm);
    }
  };
  useEffect(() => {
    if (params?.id) {
      getLegalAdviserByID(params?.id, formData, setValue, setFormData);
    }
    getLegalAdviserType(setAdviserType);
  }, []);
  return (
    <CreateLegalAdvisorsMain>
      <CreateLegalAdvisorsTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Legal Advisors
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateLegalAdvisorsTop>

      <CreateLegalAdvisorsSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("name", { required: true })}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="name"
                />
              </div>
              <FormErrorMessage error={errors.name} />
            </div>
          </div>
          <div className="input-field">
            <label>Designation</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("designation", { required: true })}
                  value={formData.designation}
                  onChange={handleChange}
                  placeholder="designation"
                />
              </div>
              <FormErrorMessage error={errors.designation} />
            </div>
          </div>
          <div className="input-field">
            <label>Type</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("type_id", { required: true })}
                  value={formData.type_id}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  {adviserType?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.type_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Category</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("category", { required: true })}
                  value={formData.category}
                  onChange={handleChange}
                >
                  <option value="">-- select --</option>
                  {["A", "B", "C", "D"]?.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item ?? "-"}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.category} />
            </div>
          </div>
          <div className="input-field">
            <label>CNIC</label>
            <div className="field-wrap">
              <div className="field">
                <InputMask
                  mask="99999-9999999-9"
                  {...register("cnic", { required: true })}
                  placeholder="cnic"
                  value={formData.cnic}
                  onChange={handleChange}
                >
                  {(inputProps) => <input type="text" {...inputProps} />}
                </InputMask>
              </div>
              <FormErrorMessage error={errors.cnic} />
            </div>
          </div>
          <div className="input-field">
            <label>License No.</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("license_no", { required: true })}
                  value={formData.license_no}
                  onChange={handleChange}
                  placeholder="license number"
                />
              </div>
              <FormErrorMessage error={errors.license_no} />
            </div>
          </div>
          <div className="input-field">
            <label>Mobile</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("mobile", { required: true })}
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="mobile"
                />
              </div>
              <FormErrorMessage error={errors.mobile} />
            </div>
          </div>
          <div className="input-field">
            <label>Email</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="email"
                  {...register("email", { required: true })}
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="john@mail.com"
                />
              </div>
              <FormErrorMessage error={errors.email} />
            </div>
          </div>
          <div className="input-field">
            <label>Adress</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("address", { required: true })}
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.address} />
            </div>
          </div>
          <div className="input-field">
            <label>Date of Comencement of contract</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("commencement_date", { required: true })}
                  value={formData.commencement_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.commencement_date} />
            </div>
          </div>

          <div className="input-field">
            <label>Ending Date Of Contract</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="date"
                  {...register("ending_date", { required: true })}
                  value={formData.ending_date}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.ending_date} />
            </div>
          </div>
          <div className="input-field">
            <label>Is Active</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_active", { required: true })}
                  value={formData.is_active}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_active} />
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="reset"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateLegalAdvisorsSection>
    </CreateLegalAdvisorsMain>
  );
};

export default CreateLegalAdvisors;
