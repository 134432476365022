import styled from "styled-components";

export const AcademicSessionManagementListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const AcademicSessionManagementListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }
  .curser-pointer {
    cursor: pointer;
  }
  table {
    tr {
      td {
        @media screen and (min-width: 2200px) {
          border-bottom: 1px solid var(--lightgray-extralight);
        }
      }
    }

    tr.expanded-row {
      @media screen and (min-width: 2780px) {
        display: none;
      }

      .gradebook-result-status {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
  }
  .data-wrap {
    display: flex;

    gap: 10px;
    align-items: center;
  }
`;

export const AcademicSessionManagementListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    @media screen and (max-width: 490px) {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
`;
