import { FC, useEffect, useState } from "react";
import {
  LinkVoucherMain,
  Container,
  ContentWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useFinance from "containers/private/finance/useHooks";
import { VoucherTemplateHeaderDTO } from "utils/helpers/models/finance/voucher-template-header.dto";
import { useForm } from "react-hook-form";
import { LinkVoucherTemplateDTO } from "utils/helpers/models/academics/link-voucher-template-to-fee-plan.dto";
import useAcademics from "containers/private/academics/useHooks";
import { FeeTypesDTO } from "utils/helpers/models/academics/academic-session.dto";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";

interface AddParticularsProps {
  onClose: (didChange?: boolean) => void
}

const LinkVoucherTemplate: FC<AddParticularsProps> = ({ onClose }) => {
  const {getQueryParams} = useUtils();
  const {id: fee_plan_id} = getQueryParams();
  const [formData, setFormData] = useState<LinkVoucherTemplateDTO>(new LinkVoucherTemplateDTO({fee_plan_id}));
  const {register,setValue,handleSubmit, formState: {errors}} = useForm<LinkVoucherTemplateDTO>();
  const {getVoucherTemplateHeaders} = useFinance();
  const {getFeeTypes, linkVoucherTemplateToFeePlan} = useAcademics();
  const [voucherTemplates, setVoucherTemplates] = useState<VoucherTemplateHeaderDTO[]>([]);
  const [feeTypes, setFeeTypes] = useState<FeeTypesDTO[]>([]);

  const onSubmit = () => {
    linkVoucherTemplateToFeePlan(formData, onClose);
  }

  const handleChange = (event: any) => {
    const { target: {value,name}} = event;
    setValue(name,value, {shouldValidate: true});
    setFormData({...formData, [name] : value});
  }

  useEffect(() => {
    getVoucherTemplateHeaders(setVoucherTemplates);
    getFeeTypes(setFeeTypes);
  }, []);

  return (
    <LinkVoucherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Link Voucher Template</span>
            </div>
            <div className="close-icon cp" onClick={() => onClose(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label htmlFor="">Voucher Template</label>
                <div className="field-wrap">
                  <div className="field">
                    <select {...register('voucher_template_id', {required: true})} value={formData.voucher_template_id} onChange={handleChange}>
                      <option value="">Select one</option>
                      {voucherTemplates.map((template,index) => {
                        return <option value={template.id} key={index}>{template.title}</option>
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.voucher_template_id}/>
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Fee Type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select {...register('fee_type_id', {required: true})} value={formData.fee_type_id} onChange={handleChange}>
                      <option value="">Select one</option>
                      {feeTypes.map((feeType,index) => {
                        return <option value={feeType.id} key={index}>{feeType.title}</option>
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.fee_type_id}/>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="reset">
                  Close
                </button>
                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </LinkVoucherMain>
  );
};

export default LinkVoucherTemplate;
