import styled from "styled-components";

export const CreateFrameworkMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const CreateFrameworkTop = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;

export const CreateFrameworkForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .common-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }

    .upload-images {
      width: 30%;
      &.cover {
        width: 70%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      @media (max-width: 768px) {
        width: 50%;
      }
      @media (max-width: 450px) {
        width: 100%;
      }
      .image-field {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        background-color: var(--silver-color);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        &.light {
          background-color: transparent;
          border: 1px solid var(--lightgray-light);
        }

        .image {
          height: 160px;
          width: 100%;
          .placeholder-heading {
            color: var(--black-text);
            font-size: 17px;
            font-weight: 600;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        .login-image {
          height: 160px;
          width: 100%;
          .placeholder-heading {
            color: var(--black-text);
            font-size: 17px;
            font-weight: 600;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }

        label {
          text-align: center;
          color: var(--black-text);
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;
      .green {
        background-color: var(--green-dark);
      }
      @media screen and (max-width: 650px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;
