export class LegalCasesDTO {
  id!: number;
  court_id: number;
  case_no: number;
  title: number;
  prayer: number;
  plaintiff: number;
  status: number;
  defendant_dept_id: number;
  institution_date: number;
  nature_id: number;
  matter_type_id: number;
  legal_advisor_id: number;

  legal_case_nature: { title: string } = { title: "" };
  legal_matter_type: { title: string } = { title: "" };
  legal_court: { title: string } = { title: "" };
  legal_advisor: { name: string } = { name: "" };
  category: { title: string } = { title: "" };

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
