import { FC, useState } from "react";
import {
    CreateAcademicSessionOrganizationTop,
    CreateAcademicSessionOrganizationFormSection,
    CreateAcademicSessionOrganizationMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useForm } from "react-hook-form";


interface CreateAdmissionSessionProps { }

const CreateTicket: FC<CreateAdmissionSessionProps> = () => {
    const breadcrumbLinks = [
        { title: "Student Dashboard /", path: "" },
        {
            title: "Create Tickets",
            path: siteRoutes.createTicket,
        },
    ]

 const { handleSubmit, register, setValue, trigger, getValues, formState: { errors } } = useForm();
    const [fileName, setFileName] = useState('');

    const handleFileUpload = (event: any) => {
        const { name, files } = event.target;
        const file = files[0];
        setFileName(file.name);
        setValue(name, file);
       
        trigger([name]);
      };
    
   


  



    return (
        <CreateAcademicSessionOrganizationMain>
            <CreateAcademicSessionOrganizationTop>
                <div className="heading">
                    <span className="page-heading">Create Tickets</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
            </CreateAcademicSessionOrganizationTop>

            <CreateAcademicSessionOrganizationFormSection
                className="content-radius-shadow"

            >
                <div className="common-fields">
                    <div className="input-field">
                        <label>Support Type</label>
                        <div className="field-wrap">
                            <div className="field">
                            <select name="" id="">
                                <option value="">Select Support Type</option>
                            </select>
                            </div>
                           
                        </div>
                    </div>
                    <div className="input-field">
                  <label>Attachment</label>
                  <div className="upload-container">
                    <div className="upload-section">
                      <label htmlFor="file-upload" className="upload-label">
                        Choose a file
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        className="file-input"
                       
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div className="file-details-section" id="file-details">
                      {fileName ? (
                        <p className="file-placeholder">{fileName}</p>
                      ) : (
                        <p className="file-placeholder">No file chosen</p>
                      )}
                    </div>


                  </div>
                </div>
               
                </div>
                <div className="">
                    <div className="input-field">
                        <label htmlFor="">Description</label>
                    </div>
                    <div className="text-area">
                        <textarea name="" id=""></textarea>
                    </div>
                </div>
                <div className="action-buttons">
                    <div className="buttons">
                        <button
                            className="lg-rounded-btn gray"
                            type="button"

                        >
                            Cancel
                        </button>
                        <button className="lg-rounded-btn" type="submit">
                            Submit
                        </button>
                    </div>
                </div>
            </CreateAcademicSessionOrganizationFormSection>
        </CreateAcademicSessionOrganizationMain>
    );
};

export default CreateTicket;
