import { FC, useEffect, useState } from "react";
import { AddAllocateTeacherMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";

import { AddCourseTeacherDTO } from "utils/helpers/models/academics/academic-session.dto";
import useAcademics from "containers/private/academics/useHooks";
import { useForm } from "react-hook-form";
import { CourseTypeDTO } from "utils/helpers/models/academics/course-type.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import DataNotFound from "components/particles/table/data-not-found";

interface AddAllocateTeacherProps {
  setOpen: Function;
  course_id: number[];
}

const AllocateTeacher: FC<AddAllocateTeacherProps> = ({
  setOpen,
  course_id,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<AddCourseTeacherDTO>();
  const { addCourseTeacher, getTeachers, getCourseTypes } = useAcademics();
  const { getSpecificOrgStructures } = useOrganization();
  const [teachers, setTeachers] = useState<any[]>([]);
  const [courseTypes, setCourseTypes] = useState<CourseTypeDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const [formData, setFormData] = useState<AddCourseTeacherDTO>(
    new AddCourseTeacherDTO({ course_id })
  );

  const onSubmit = () => {
    const body: AddCourseTeacherDTO<string> = {
      ...formData,
      course_id: formData.course_id.join(","),
    };
    addCourseTeacher(body, setOpen);
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    (formData as any)[name] = value;
    if (name === "department_id") {
      formData.teacher_id = "";
      setValue("teacher_id", formData.teacher_id);
      getTeachers(setTeachers, {
        department_id: formData.department_id,
        per_page: "All",
      });
    }
    trigger(name);
    setFormData({ ...formData });
  };

  useEffect(() => {
    getCourseTypes(setCourseTypes);
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
  }, []);

  return (
    <AddAllocateTeacherMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Allocate Teacher</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label htmlFor="">Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("department_id", { required: true })}
                      value={formData.department_id}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      {departments.map((dept, index) => {
                        return (
                          <option value={dept.id} key={index}>
                            {dept.title}
                          </option>
                        );
                      })}
                      {departments?.length <= 1 && (
                        <option value="" disabled>
                          <DataNotFound show={!departments?.length} />
                        </option>
                      )}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.department_id} />
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">Teacher</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("teacher_id", { required: true })}
                      value={formData.teacher_id}
                      onChange={handleChange}
                    >
                      <option value="">-- Select --</option>
                      {teachers?.map((teacher, index) => {
                        const {
                          teacher_lastname = "",
                          teacher_firstname = "",
                        } = teacher || {};
                        const { title: teacher_title = "" } =
                          teacher.teacher_title || {};
                        return (
                          <option
                            value={teacher.id}
                            key={index}
                          >{`${teacher_title} ${teacher_firstname} ${teacher_lastname}`}</option>
                        );
                      })}
                      {teachers?.length <= 1 && (
                        <option value="" disabled>
                          <DataNotFound show={!teachers?.length} />
                        </option>
                      )}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.teacher_id} />
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="">-- Course --</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("course_type_id", { required: true })}
                      value={formData.course_type_id}
                      onChange={handleChange}
                    >
                      <option value="">Select course type</option>
                      {courseTypes.map((type, index) => {
                        return (
                          <option value={type.id} key={index}>
                            {type.title}
                          </option>
                        );
                      })}
                      {courseTypes?.length <= 1 && (
                        <option value="" disabled>
                          <DataNotFound show={!courseTypes?.length} />
                        </option>
                      )}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.course_type_id} />
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddAllocateTeacherMain>
  );
};

export default AllocateTeacher;
