export class PreferedRoomToSectionListDTO<
  MultiSelectType extends number[] | string = number[]
> {
  id: number;
  section_id: number;
  planner_id: number;
  tc_title: string = "";
  section: { title: string } | null = null;
  room: { room_name: string } | null = null;
  room_id: MultiSelectType;
  weight_percentage: number | string;

  constructor(data: Partial<PreferedRoomToSectionListDTO> = {}) {
    Object.assign(this, data);
  }
}
