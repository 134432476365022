import { FC, Fragment, useEffect, useState } from "react";
import {
  AddEntryTestMain,
  Container,
  ContentWrapper,
  AdvertisementListingSection,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import { useDebounce } from "hooks/useDebounce";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

interface GradingSchemaProps {
  setOpen: Function;
  course_id: number;
}

const GradingSchema: FC<GradingSchemaProps> = ({ setOpen, course_id }) => {
  const columns: string[] = [
    "Letter Grade",
    "Grade Point",
    "Percentage Boundary",
  ];

  const { getViewGradingSchemeData } = useAcademics();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [data, setData] = useState<any[]>([]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    getViewGradingSchemeData(setData, course_id);
  }, []);

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Grading Scheme</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <AdvertisementListingSection>
            <div className="data-table">
              <table className="bottom-bordered-cells">
                <thead>
                  <tr>
                    {columns.map((column: string, index: number) => {
                      return <th key={index}>{column}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item: any, index: number) => {
                    const {
                      letter_grades: {
                        grade_point = "",
                        letter_grade = "",
                      } = {},
                      percentage_boundary = "",
                    } = item;

                    return (
                      <Fragment key={index}>
                        <tr>
                          <td>
                            <div className="mw-150">{letter_grade ?? "-"}</div>
                          </td>
                          <td>{grade_point ?? "-"}</td>
                          <td>{percentage_boundary ?? "-"}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
              <DataNotFound show={!isLoading && !data?.length} />
              <div className="action-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn black"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <Fragment></Fragment>
          </AdvertisementListingSection>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default GradingSchema;
