import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  AcademicExamTypeListingMain,
  AcademicExamTypeListingSection,
  AcademicExamTypeListingTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { ExamTypeDTO } from "utils/helpers/models/academics/exam-types.dto";
import { useSelector } from "react-redux";
import useAcademics from "../../useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import useUtils from "hooks/useUtils";
import { confirmationPopup } from "utils/helpers/common/alert-service";

const AcademicExamTypeListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Exam Types", path: siteRoutes.academicExamTypeListing },
  ];

  const columns: string[] = ["Exam Type", "Action"];
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch, createQuery } = useUtils();
  const navigate = useNavigate();
  const { getExamType, deleteExamTypeByID } = useAcademics();

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllExamTypesListing(page + 1, search, per_page);
  };
  const [search, setSearch] = useState("");
  const [data, setData] = useState<ExamTypeDTO[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const goToCreateExamType = () => {
    navigate(siteRoutes.createAcademicExamType);
  };
  const handleEdit = (id: number) => {
    const params = createQuery({ id });
    navigate(siteRoutes.createAcademicExamType + params);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deleteExamTypeByID(id, setData, queryParams, setPagination);
    }
  };

  const getAllExamTypesListing = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getExamType(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAllExamTypesListing(pagination.page, search, pagination.per_page);
  }, []);
  return (
    <AcademicExamTypeListingMain>
      <AcademicExamTypeListingTop>
        <div className="left">
          <span className="page-heading">Exam Types</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createAcademicExamType) && (
            <div className="add-new-button">
              <button className="lg-rounded-btn" onClick={goToCreateExamType}>
                + Add Exam Type
              </button>
            </div>
          )}
        </div>
      </AcademicExamTypeListingTop>

      <AcademicExamTypeListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllExamTypesListing
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllExamTypesListing
                )
              }
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="mw-150">{item?.title}</div>
                  </td>
                  <td>
                    <div className="table-action-icons">
                      <button
                        className="action-icon"
                        onClick={() => handleEdit(item?.id)}
                      >
                        <EditTableSvg />
                      </button>
                      <button
                        className="action-icon"
                        onClick={() => handleDelete(item?.id)}
                      >
                        <DeleteTableSvg />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </AcademicExamTypeListingSection>
    </AcademicExamTypeListingMain>
  );
};

export default AcademicExamTypeListing;
