import { FC, Fragment, useEffect, useState } from "react";
import {
  AdminDashboarMain,
  ADashboardTop,
  ADashboradSection,
  LinkSection,
  AllApplicantsSection,
  RegisteredSection,
  FilterSection,
  FilterHeader,
  Filters,
  LegendWrapper,
  ChartSection,
  LegendLabel,
  LegendBullet,
  LegendItem,
  AdmApplicationsLineChart,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

import {
  AuthLinkSvg,
  DownArrowLightgrayMediumSvg,
  FacilitiesSvg,
  ProfileLinkSvg,
} from "assets/images/common/svgs";

import useDSSystem from "../useHook";
import {
  LineChart,
  PieChart,
} from "containers/private/dashboard/admin-dashboard/style";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import useOrganization from "containers/private/organization/useHooks";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";

const AdmissionDashboard: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Decision Support System /", path: "" },

    { title: "Admission Dashboard", path: siteRoutes.admissionDashboard },
  ];
  const navigate = useNavigate();
  const { getAdmissionDashboard, getFilterDataForAdmDashboard } = useDSSystem();
  const { getOrganization } = useStore();
  const { getPrograms } = useOrganization();

  const [appFilter, setAppFilter] = useState({
    start_date: "",
    end_date: "",
  });
  const organization = getOrganization();

  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const [isApplicantOpen, setIsApplicantOpen] = useState<boolean>(true);
  const [isRegisteredOpen, setIsRegisteredOpen] = useState<boolean>(true);
  const [isFilterOpen, setIsFilterFilter] = useState<boolean>(true);
  const [data, setData] = useState<any>({});
  const { getYears } = useUtils();
  const [studentdata, setStudentData] = useState<any>([]);
  const [filters, setFilters] = useState<any>({
    campus_id: "",
    program_id: "",
    session_year: new Date().getFullYear(),
    session_type: "Spring",
    start_date: "",
    end_date: "",
  });

  const [registerdStudentsStats, setRegisterdStudentsStats] = useState<any>({
    datasets: [
      {
        label: "Registerd Students",
        data: [],
        borderColor: organization?.primary_color,
        backgroundColor: organization?.primary_color,
        tension: 0.4,
      },
    ],
    labels: [],
  });

  const [applicationStats, setApplicationsStats] = useState<any>({
    datasets: [
      {
        label: "No. of Applications",
        data: [],
        borderColor: organization?.primary_color,
        backgroundColor: organization?.primary_color,
        tension: 0.4,
      },
    ],
    labels: [],
  });

  const getFilteredAdmissionDashboard = () => {
    const filterOptions = { ...filters };
    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }
    const queryParams = {
      ...filterOptions,
    };
    getAdmissionDashboard(setData, queryParams);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const toggleApplicant = () => {
    setIsApplicantOpen((prevState) => !prevState);
  };
  const toggleRegistered = () => {
    setIsRegisteredOpen((prevState) => !prevState);
  };

  const toggleFilter = () => {
    setIsFilterFilter((prevState) => !prevState);
  };

  const handleApplyFilters = () => {
    getFilteredAdmissionDashboard();
  };

  const goToAddmissionApplicationById = (id: number) => {
    navigate(`${siteRoutes.admissionStudentListing}?program_id=${id}`);
  };

  const generateColor = (index: number) => {
    const hue = (index * 127.5) % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const renderLegend = () => {
    const labels =
      data?.how_did_know_count?.map((item) => item?.how_did_know) || [];
    const userCount =
      data?.how_did_know_count?.map((item) => item.user_count) || [];
    const colors = labels.map((_, index) => generateColor(index));
    return (
      <LegendWrapper>
        {labels.map((label: string, index: number) => (
          <LegendItem key={index}>
            <LegendBullet style={{ backgroundColor: colors[index] }} />
            <LegendLabel>{`${label} ( ${userCount[index]} )`}</LegendLabel>
          </LegendItem>
        ))}
      </LegendWrapper>
    );
  };

  const pieChartData = {
    labels: data?.how_did_know_count?.map(
      (item: any) => item?.how_did_know
    ) || ["No data"],
    datasets: [
      {
        label:
          data?.how_did_know_count?.map(
            (item: any, index: number) => item.how_did_know
          ) || [],
        data: data?.how_did_know_count?.map((item) => item.user_count) || [],
        backgroundColor:
          data?.how_did_know_count?.map((_, index: number) =>
            generateColor(index)
          ) || [],
        hoverOffset: 10,
        borderWidth: 1,
        borderRadius: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      animateRotate: true,
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.raw;
            return ` ${value}`;
          },
        },
      },
    },

    legend: {
      onClick: (e: any) => e.native.event.stopImmediatePropagation(),
    },
    cutout: "70%",
  };

  const data2 =
    data?.all_applications_by_program?.map((item) => item?.total_count) || [];
  const labels =
    data?.all_applications_by_program?.map((item) => item?.program_code) || [];

  const chartColor = organization?.primary_color || "#128B9B";

  const chartDataTwo = {
    labels,
    datasets: [
      {
        label: "Details",
        data: data2,
        backgroundColor: chartColor,
        borderColor: chartColor,
        borderWidth: 1,
      },
    ],
  };

  const BarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Details",
      },
    },
    // cutout: "80%",
  };

  const handleNavigateTotalApplication = (pageRoute: string) => {
    let filterParams = [];
    if (filters.campus_id) filterParams.push(`campus_id=${filters.campus_id}`);
    if (filters.session_year)
      filterParams.push(`session_year=${filters.session_year}`);
    if (filters.session_type)
      filterParams.push(`session_type=${filters.session_type}`);

    const queryString = filterParams.join("&");
    const url = `${pageRoute}?${queryString}`;

    return url;
  };
  console.log("data", data);

  useEffect(() => {
    getAdmissionDashboard(setData, filters);
    getFilterDataForAdmDashboard(setStudentData);
    getPrograms(setPrograms);
  }, []);

  useEffect(() => {
    if (Object.keys(data ?? {}).length) {
      const {
        admission_count_by_date = [],
        registered_students_count_by_date = [],
      } = data;
      if (admission_count_by_date?.length) {
        const labels = admission_count_by_date.map((i) => i.created_date);
        const data = admission_count_by_date.map((i) => i.user_count);
        applicationStats.datasets[0].data = data;
        applicationStats.labels = labels;
        setApplicationsStats({ ...applicationStats });
      }
      if (registered_students_count_by_date?.length) {
        const labels = registered_students_count_by_date.map(
          (i) => i.created_date
        );
        const data = admission_count_by_date.map((i) => i.user_count);
        registerdStudentsStats.datasets[0].data = data;
        registerdStudentsStats.labels = labels;
        setRegisterdStudentsStats({ ...registerdStudentsStats });
      }
    }
  }, [data]);

  return (
    <AdminDashboarMain>
      <ADashboardTop>
        <div className="left">
          <span className="page-heading">Admission Dashboards</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </ADashboardTop>

      <ADashboradSection>
        <FilterSection className="content-radius-shadow">
          <FilterHeader $isFilterOpen={isFilterOpen}>
            <span className="filter-heading">Filter</span>
            <span className="dropdown-arrow cp" onClick={toggleFilter}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </FilterHeader>
          {isFilterOpen && (
            <Filters>
              <div className="filter-fields">
                <div className="input-field">
                  <label>Campus</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select name="campus_id" onChange={handleFilterChange}>
                        <option value="">All Campus</option>
                        {studentdata?.map((item: any, index: number) => {
                          return (
                            <option value={item?.campus_id} key={index}>
                              {item?.campus_title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Session Year</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="session_year"
                        value={filters.session_year}
                        onChange={handleFilterChange}
                      >
                        <option value="">All Years</option>
                        {getYears(2000)?.map((year, index) => {
                          return <option key={index}>{year}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Session type</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="session_type"
                        value={filters.session_type}
                        onChange={handleFilterChange}
                      >
                        <option value="">All Types</option>
                        {["Fall", "Spring", "Summer"]?.map((type, index) => {
                          return <option key={index}>{type}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-buttons">
                <div className="buttons">
                  <button className="lg-rounded-btn gray">Reset</button>
                  <button
                    className="lg-rounded-btn"
                    onClick={handleApplyFilters}
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </Filters>
          )}
        </FilterSection>

        <LinkSection className="content-radius-shadow">
          <div className="link-card-container">
            {[
              {
                title: "Registerd users (Eportal)",
                value: data?.total_users ?? "-", // Primitive value
                color: "#ffbb3826",
                Icon: AuthLinkSvg,
                navigateTo: siteRoutes.systemUsersListing,
              },
              {
                title: " Total Applications",
                value: data?.all_applications_count?.total_count ?? "-",
                color: "#128BAB26",
                Icon: AuthLinkSvg,
                navigateTo: handleNavigateTotalApplication(
                  siteRoutes.admissionStudentListing
                ),
              },
              {
                title: "Unique Applicants",
                value: data?.unique_applicants_count?.total_count ?? "-",
                color: "#0FCB0226",
                Icon: ProfileLinkSvg,
                navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=applied_on_admission`,
              },
              {
                title: "Registered Students",
                value: data?.all_register_users?.total_count ?? "-",
                color: "#4A3AFF1A",
                Icon: AuthLinkSvg,
                navigateTo: handleNavigateTotalApplication(
                  siteRoutes.studentRegListing
                ),
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
                onClick={() => navigate(card.navigateTo)}
              >
                <div>
                  <card.Icon />
                </div>
                <div className="link-card-text">
                  <div className="link-title">{card.title ?? "-"}</div>
                  <div className="total-amount">{card.value ?? "-"}</div>
                </div>
              </div>
            ))}
          </div>
        </LinkSection>
        {(data?.all_applications_by_program ?? [])?.length ? (
          <AllApplicantsSection $isApplicantOpen={isApplicantOpen}>
            <div className="link-main">
              <div className="link-heading">
                All Applications(
                {data?.all_applications_count?.total_count ?? 0})
              </div>
              {(data?.all_applications_by_program ?? [])?.length ? (
                <span className="dropdown-arrow cp" onClick={toggleApplicant}>
                  <DownArrowLightgrayMediumSvg />
                </span>
              ) : (
                ""
              )}
            </div>
            {isApplicantOpen && (
              <div className="top-cards-container">
                {data?.all_applications_by_program?.map(
                  (item: any, index: number) => (
                    <div
                      className={`top-cards`}
                      key={index}
                      onClick={() =>
                        goToAddmissionApplicationById(item?.program_id)
                      }
                    >
                      <div
                        className="icon"
                        style={{ backgroundColor: "#0FCB0226" }}
                      >
                        <FacilitiesSvg />
                      </div>
                      <div className="card-title-main">
                        <div className="title">{item?.program ?? "-"}</div>
                        <div className="bottom-title">
                          <span className="title-value">
                            {item?.total_count ?? "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </AllApplicantsSection>
        ) : (
          ""
        )}
        {/* dont remove commit below due to data not available from backend yet   <-- commit by faizan rasheed --> */}
        {/* {(data?.all_reg_applications_by_program ?? [])?.length ? (
          <RegisteredSection $isRegisterOpen={isRegisteredOpen}>
            <div className="link-main">
              <div className="link-heading">Unique Applications</div>

              <span className="dropdown-arrow cp" onClick={toggleRegistered}>
                <DownArrowLightgrayMediumSvg />
              </span>
            </div>
            {isRegisteredOpen && (
              <div className="top-cards-container">
                {/* {[12, 12, 12, 12, 12, 2, 1].map((item: any, index: number) => ( */}
        {/* {data?.all_reg_applications_by_program?.map(
                  (item: any, index: number) => (
                    <div className="top-cards" key={index}>
                      <div className="icon">
                        <FacilitiesSvg />
                      </div>
                      <div className="card-title-main">
                        <div className="title">{item?.program ?? "-"}</div>
                        <div className="bottom-title">
                          <span className="title-value">
                            {item?.total_count ?? "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )} */}
        {/* </div>
            )}
          </RegisteredSection>
        ) : ( */}
        {/* "" */}
        {/* )} */}
        {(data?.all_reg_applications_by_program ?? [])?.length ? (
          <RegisteredSection $isRegisterOpen={isRegisteredOpen}>
            <div className="link-main">
              <div className="link-heading">Registered Students({data?.all_register_users?.total_count})</div>

              <span className="dropdown-arrow cp" onClick={toggleRegistered}>
                <DownArrowLightgrayMediumSvg />
              </span>
            </div>
            {isRegisteredOpen && (
              <div className="top-cards-container">
                {data?.all_reg_applications_by_program?.map(
                  (item: any, index: number) => (
                    <div className="top-cards" key={index}>
                      <div className="icon">
                        <FacilitiesSvg />
                      </div>
                      <div className="card-title-main">
                        <div className="title">{item?.program ?? "-"}</div>
                        <div className="bottom-title">
                          <span className="title-value">
                            {item?.total_count ?? "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </RegisteredSection>
        ) : (
          ""
        )}

        <ChartSection>
          {/* Pie Chart */}
          {data?.how_did_know_count?.length ? (
            <PieChart>
              <div className="header-section">
                <div className="title">
                  <span>How Users Know About Us</span>
                </div>
                <div className="pie-container">
                  <div className="description">{renderLegend()}</div>
                  <div className="pie-chart-section">
                    <Pie data={pieChartData} options={options} />
                  </div>
                </div>
              </div>
            </PieChart>
          ) : (
            <Fragment></Fragment>
          )}

          {/* Line Chart */}
          {data?.all_applications_by_program?.length ? (
            <LineChart>
              <div className="header-section">
                <div className="title">
                  <span>Admission Applications</span>
                </div>
                <div className="line-chart-section">
                  <Bar
                    className="BarChart"
                    data={chartDataTwo}
                    options={BarChartOptions}
                  />
                </div>
              </div>
            </LineChart>
          ) : (
            <Fragment></Fragment>
          )}
        </ChartSection>

        {applicationStats.labels?.length ? (
          <AdmApplicationsLineChart className="content-radius-shadow">
            <div className="header-part">
              <div className="heading">
                <span>Applications</span>
              </div>
              <div className="filter-part">
                <div className="input-field">
                  <label htmlFor="">Select Program</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="program_id"
                        value={filters.program_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">-- Select --</option>
                        {programs.map((prog, i) => (
                          <option key={i} value={prog.id}>
                            {prog?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label htmlFor="">From</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="date"
                        name="start_date"
                        value={filters.start_date}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label htmlFor="">To</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="date"
                        name="end_date"
                        value={filters.end_date}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    className="lg-rounded-btn sm"
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="chart-wrapper">
              <Line
                data={{
                  ...applicationStats,
                  datasets: applicationStats.datasets.map((dataset) => ({
                    ...dataset,
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 8,
                    pointBackgroundColor: dataset.borderColor,
                  })),
                }}
                options={{
                  // responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    tooltip: {
                      enabled: true, // Ensure tooltips are enabled
                      mode: "nearest", // Show tooltips for the nearest point
                      intersect: false, // Display tooltips even if the hover is not exactly on the point
                      callbacks: {
                        label: (context) => {
                          // Customize tooltip label
                          const label = context.dataset.label || "";
                          const value = context.raw;
                          return `${label}: ${value}`;
                        },
                      },
                    },
                  },
                  hover: {
                    mode: "nearest",
                    intersect: false, // Ensure smooth tooltip interaction
                  },
                }}
              />
            </div>
          </AdmApplicationsLineChart>
        ) : (
          ""
        )}
        {registerdStudentsStats.labels?.length ? (
          <AdmApplicationsLineChart className="content-radius-shadow">
            <div className="header-part">
              <div className="heading">
                <span>Registerd Students</span>
              </div>
              <div className="filter-part">
                <div className="input-field">
                  <label htmlFor="">Select Program</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="program_id"
                        value={filters.program_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">-- Select --</option>
                        {programs.map((prog, i) => (
                          <option key={i} value={prog.id}>
                            {prog?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label htmlFor="">From</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="date"
                        name="start_date"
                        value={filters.start_date}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label htmlFor="">To</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="date"
                        name="end_date"
                        value={filters.end_date}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    className="lg-rounded-btn sm"
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="chart-wrapper">
              <Line
                data={{
                  ...registerdStudentsStats,
                  datasets: registerdStudentsStats.datasets.map((dataset) => ({
                    ...dataset,
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 8,
                    pointBackgroundColor: dataset.borderColor,
                  })),
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    tooltip: {
                      enabled: true, // Ensure tooltips are enabled
                      mode: "nearest", // Show tooltips for the nearest point
                      intersect: false, // Display tooltips even if the hover is not exactly on the point
                      callbacks: {
                        label: (context) => {
                          // Customize tooltip label
                          const label = context.dataset.label || "";
                          const value = context.raw;
                          return `${label}: ${value}`;
                        },
                      },
                    },
                  },
                  hover: {
                    mode: "nearest",
                    intersect: false, // Ensure smooth tooltip interaction
                  },
                }}
              />
            </div>
          </AdmApplicationsLineChart>
        ) : (
          ""
        )}
      </ADashboradSection>
    </AdminDashboarMain>
  );
};

export default AdmissionDashboard;
