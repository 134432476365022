import { FC, useEffect, useMemo, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuItem,
  IconedMenu,
  MenuItem,
  Menus,
  ParticularDropdownMenu,
  SearchWrapper,
  ShortLogo,
  SidebarMain,
  SiteLogo,
  SubDeepMenuDropdown,
} from "./style";
import {
  AdministrationSvg,
  AdmissionSvg,
  ApplyVehicleSvg,
  AuthoritiesSvg,
  BooksSvg,
  BuildingsSvg,
  CareerSvg,
  CoursesSvg,
  CyfyFaviconSvg,
  CyfyLogoDark,
  CyfyLogoSvg,
  DownArrowLightgrayMediumSvg,
  GraduationSvg,
  GrayTvSvg,
  HomeSvg,
  HRMangementSvg,
  JobsSvg,
  LegalMatterSvg,
  LibraryBookSvg,
  LibrarySvg,
  MeritListSvg,
  NoticeBoardSvg,
  PrivilegesSvg,
  QECSvg,
  SettingsSolidSvg,
  SideShortCoursesSvg,
  SupportTicketSvg,
  TestingServicesSvg,
  TransportSvg,
  VoucherSvg,
  WorldGraySvg,
  EmailSvg,
  TimeTableSvg,
} from "assets/images/common/svgs";
import { useLocation, useNavigate } from "react-router-dom";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { domains } from "utils/helpers/enums/shared.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useSelector } from "react-redux";
import { FinanceSvg } from "assets/images/finance/svgs";
import { infoPopup } from "utils/helpers/common/alert-service";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { useDebounce } from "hooks/useDebounce";

interface SidebarProps {
  openSidebar: boolean;
  setOpenSidebar: Function;
}
const Sidebar: FC<SidebarProps> = ({ openSidebar, setOpenSidebar }) => {
  const { hasAccess, getOrganization, getRole } = useStore();
  const role = getRole();
  const { isCurrentWidthMinimum, splitPathBySlash } = useUtils();
  const { pathname } = useLocation();
  const { user, loginAsToken } = useSelector(
    (state: any) => state.sharedReducer
  );
  const [search, setSearch] = useState<string>("");
  const debounceSearch = useDebounce(search, 300);
  const { isDarkTheme } = useSelector((state: any) => state.sharedReducer);

  const organization = getOrganization();

  const sidebarMenus = useMemo(() => {
    return [
      {
        title: "Dashboard",
        path: splitPathBySlash(siteRoutes.superAdminDashboard)[3],
        subMenus: [],
        permission: sitePermissions.superAdminDashboard,
        icon: HomeSvg,
        link: siteRoutes.superAdminDashboard,
        isDropdownOpen: false,
      },
      {
        title: "Home",
        path: splitPathBySlash(siteRoutes.adminDashboard)[3],
        subMenus: [],
        permission: sitePermissions.adminDashboard,
        icon: HomeSvg,
        link: siteRoutes.adminDashboard,
        isDropdownOpen: false,
      },
      {
        title: "Decision Support System",
        path: splitPathBySlash(siteRoutes.admissionDashboard)[2],
        subMenus: [
          {
            title: "Dashboards",
            subDeepMenus: [
              {
                title: "Admission Dashboard",
                path: siteRoutes.admissionDashboard,
                active: splitPathBySlash(siteRoutes.admissionDashboard)[3],
                subMenus: [],
                permission: sitePermissions.admissionDashboard,
                subPagesPermissions: [sitePermissions.admissionDashboard],
                link: siteRoutes.admissionDashboard,
                isDropdownOpen: false,
              },
              {
                title: "Academics Dashboard",
                path: siteRoutes.academicsDashboard,
                active: splitPathBySlash(siteRoutes.academicsDashboard)[3],
                subMenus: [],
                permission: sitePermissions.academicsDashboard,
                subPagesPermissions: [sitePermissions.academicsDashboard],
                link: siteRoutes.academicsDashboard,
                isDropdownOpen: false,
              },
              {
                title: "Fee Income Dashboard",
                path: siteRoutes.feeIncomeDashboard,
                active: splitPathBySlash(siteRoutes.feeIncomeDashboard)[3],
                subPagesPermissions: [sitePermissions.feeIncomeDashboard],
                permission: sitePermissions.feeIncomeDashboard,
              },
              {
                title: "Testing Service Dashboard",
                path: siteRoutes.testingServicesDashboard,
                active: splitPathBySlash(
                  siteRoutes.testingServicesDashboard
                )[3],
                permission: sitePermissions.testingServicesDashboard,
                subPagesPermissions: [sitePermissions.testingServicesDashboard],
              },
            ],
          },
          {
            title: "Reports",
            path: siteRoutes.resportsListing,
            active: splitPathBySlash(siteRoutes.resportsListing)[3],
            permission: sitePermissions.resportsListing,
            subPagesPermissions: [
              sitePermissions.resportsListing,
              sitePermissions.resportsCreate,
            ],
          },
        ],

        icon: WorldGraySvg,
        isDropdownOpen: false,
      },

      {
        title: "Dashboard",
        path: splitPathBySlash(siteRoutes.studentDashboard)[3],
        subMenus: [],
        permission: sitePermissions.studentDashboard,
        icon: HomeSvg,
        link: siteRoutes.studentDashboard,
        isDropdownOpen: false,
      },

      {
        title: "Notice Board",
        path: splitPathBySlash(siteRoutes.studentPortalNoticeBoard)[3],
        subMenus: [],
        permission: sitePermissions.studentPortalNoticeBoard,
        icon: NoticeBoardSvg,
        link: siteRoutes.studentPortalNoticeBoard,
        isDropdownOpen: false,
      },
      {
        title: "My Links",
        path: splitPathBySlash(siteRoutes.studentPortalProfile)[3],
        subMenus: [
          {
            title: "Profile",
            path: siteRoutes.studentPortalProfile,
            active: splitPathBySlash(siteRoutes.studentPortalProfile)[4],
            subMenus: [],
            permission: sitePermissions.studentPortalProfile,
            subPagesPermissions: [sitePermissions.studentPortalProfile],
            link: siteRoutes.studentPortalProfile,
            isDropdownOpen: false,
          },
          {
            title: "Documents",
            path: siteRoutes.studentPortalDocumentsListing,
            active: splitPathBySlash(
              siteRoutes.studentPortalDocumentsListing
            )[4],
            subMenus: [],
            permission: sitePermissions.studentPortalDocumentsListing,
            subPagesPermissions: [
              sitePermissions.studentPortalDocumentsListing,
            ],
            isDropdownOpen: false,
          },
        ],
        icon: WorldGraySvg,
        isDropdownOpen: false,
      },
      {
        title: "Academics",
        path: splitPathBySlash(siteRoutes.myEnrollments)[3],
        subMenus: [
          {
            title: "Current Enrollments",
            path: siteRoutes.myEnrollments,
            active: splitPathBySlash(siteRoutes.myEnrollments)[4],
            subMenus: [],
            permission: sitePermissions.myEnrollments,
            subPagesPermissions: [sitePermissions.myEnrollments],
            link: siteRoutes.myEnrollments,
            isDropdownOpen: false,
          },
          {
            title: "Plan of Study",
            path: siteRoutes.studentPlanOfStudy,
            active: splitPathBySlash(siteRoutes.studentPlanOfStudy)[4],
            subMenus: [],
            permission: sitePermissions.studentPlanOfStudy,
            subPagesPermissions: [sitePermissions.studentPlanOfStudy],
            link: siteRoutes.studentPlanOfStudy,
            isDropdownOpen: false,
          },
          {
            title: "Transcript",
            path: siteRoutes.studentTranscript,
            active: splitPathBySlash(siteRoutes.studentTranscript)[4],
            subMenus: [],
            permission: sitePermissions.studentTranscript,
            subPagesPermissions: [sitePermissions.studentTranscript],
            link: siteRoutes.studentTranscript,
            isDropdownOpen: false,
          },
          {
            title: "Financial Vouchers",
            path: siteRoutes.studentFinancialVouchers,
            active: splitPathBySlash(siteRoutes.studentFinancialVouchers)[4],
            subMenus: [],
            permission: sitePermissions.studentFinancialVouchers,
            subPagesPermissions: [sitePermissions.studentFinancialVouchers],
            link: siteRoutes.studentFinancialVouchers,
            isDropdownOpen: false,
          },
          {
            title: "Account Statement",
            path: siteRoutes.studentAccountStatement,
            active: splitPathBySlash(siteRoutes.studentAccountStatement)[4],
            subMenus: [],
            permission: sitePermissions.studentAccountStatement,
            subPagesPermissions: [sitePermissions.studentAccountStatement],
            link: siteRoutes.studentAccountStatement,
            isDropdownOpen: false,
          },
          {
            title: "My Clearance",
            path: siteRoutes.myClearanceListing,
            active: splitPathBySlash(siteRoutes.myClearanceListing)[4],
            subMenus: [],
            permission: sitePermissions.myClearanceListing,
            subPagesPermissions: [sitePermissions.myClearanceListing],
            link: siteRoutes.myClearanceListing,
            isDropdownOpen: false,
          },
        ],
        icon: BooksSvg,
        isDropdownOpen: false,
      },
      {
        title: "My Fee Voucher",
        path: splitPathBySlash(siteRoutes.myFeeVoucher)[3],
        subMenus: [],
        permission: sitePermissions.myFeeVoucher,
        icon: GrayTvSvg,
        link: siteRoutes.myFeeVoucher,
        isDropdownOpen: false,
      },
      {
        title: "Holiday List",
        path: splitPathBySlash(siteRoutes.studentHolidayList)[3],
        subMenus: [],
        permission: sitePermissions.studentHolidayList,
        icon: GrayTvSvg,
        link: siteRoutes.studentHolidayList,
        isDropdownOpen: false,
      },
      {
        title: "Generate Course Challan",
        path: splitPathBySlash(siteRoutes.generateCourseChallan)[3],
        subMenus: [],
        permission: sitePermissions.generateCourseChallan,
        icon: GrayTvSvg,
        link: siteRoutes.generateCourseChallan,
        isDropdownOpen: false,
      },
      {
        title: "All Tickets",
        path: splitPathBySlash(siteRoutes.ticketListing)[3],
        subMenus: [],
        permission: sitePermissions.ticketListing,
        icon: GrayTvSvg,
        link: siteRoutes.ticketListing,
        isDropdownOpen: false,
      },
      {
        title: "LMS",
        path: splitPathBySlash(siteRoutes.studentPortalLMS)[3],
        subMenus: [],
        permission: sitePermissions.studentPortalLMS,
        icon: GrayTvSvg,
        link: siteRoutes.studentPortalLMS,
        isDropdownOpen: false,
      },
      {
        title: "Library",
        path: splitPathBySlash(siteRoutes.studentPortalLibraryBooks)[3],
        subMenus: [],
        permission: sitePermissions.studentPortalLibraryBooks,
        icon: LibraryBookSvg,
        link: siteRoutes.studentPortalLibraryBooks,
        isDropdownOpen: false,
      },
      {
        title: "Support Tickets",
        path: splitPathBySlash(
          siteRoutes.studentPortalSupportTicketsListing
        )[3],
        subMenus: [],
        permission: sitePermissions.studentPortalSupportTicketsListing,
        icon: SupportTicketSvg,
        link: siteRoutes.studentPortalSupportTicketsListing,
        isDropdownOpen: false,
      },
      {
        title: "Vehicle Entry",
        path: splitPathBySlash(siteRoutes.studentVehicalEntryListing)[3],
        subMenus: [],
        permission: sitePermissions.studentVehicalEntryListing,
        icon: ApplyVehicleSvg,
        link: siteRoutes.studentVehicalEntryListing,
        isDropdownOpen: false,
      },
      {
        title: "Transport Schedule",
        path: splitPathBySlash(siteRoutes.studentTransportScheduleList)[3],
        subMenus: [],
        permission: sitePermissions.studentTransportScheduleList,
        icon: TransportSvg,
        link: siteRoutes.studentTransportScheduleList,
        isDropdownOpen: false,
      },

      {
        title: "Organization",
        path: splitPathBySlash(siteRoutes.organizationListing)[2],
        icon: BuildingsSvg,
        subMenus: [
          {
            title: "Organization",
            path: siteRoutes.organizationListing,
            active: splitPathBySlash(siteRoutes.organizationListing)[3],
            permission: sitePermissions.organizationListing,
            subPagesPermissions: [
              sitePermissions.organizationListing,
              sitePermissions.createOrganization,
            ],
          },
          {
            title: "Structure Types",
            path: siteRoutes.structureTypeListing,
            active: splitPathBySlash(siteRoutes.structureTypeListing)[3],
            permission: sitePermissions.structureTypeListing,
            subPagesPermissions: [
              sitePermissions.structureTypeListing,
              sitePermissions.createStructureType,
            ],
          },
          {
            title: "Organizational Structure",
            path: siteRoutes.orgStructureListing,
            active: splitPathBySlash(siteRoutes.orgStructureListing)[3],
            permission: sitePermissions.orgStructureListing,
            subPagesPermissions: [
              sitePermissions.orgStructureListing,
              sitePermissions.createOrgStructure,
            ],
          },
          {
            title: "Update Organization",
            path: siteRoutes.updateOrganizationAdmin,
            active: splitPathBySlash(siteRoutes.updateOrganizationAdmin)[3],
            permission: sitePermissions.updateOrganizationAdmin,
          },
          {
            title: "Session Management",
            path: siteRoutes.admissionSessionListing,
            active: splitPathBySlash(siteRoutes.admissionSessionListing)[3],
            permission: sitePermissions.admissionSessionListing,
            subPagesPermissions: [
              sitePermissions.createAdmissionSession,
              sitePermissions.admissionSessionListing,
            ],
          },
          {
            title: "Admins",
            path: siteRoutes.superAdminManagementListing,
            active: splitPathBySlash(siteRoutes.superAdminManagementListing)[3],
            permission: sitePermissions.superAdminManagementListing,
            subPagesPermissions: [sitePermissions.superAdminManagementListing],
          },
          {
            title: "Admins",
            path: siteRoutes.organizationAdminListing,
            active: splitPathBySlash(siteRoutes.organizationAdminListing)[3],
            permission: sitePermissions.organizationAdminListing,
            subPagesPermissions: [
              sitePermissions.organizationAdminListing,
              sitePermissions.createOrganizationAdmin,
            ],
          },
          {
            title: "Role Management",
            path: siteRoutes.superRoleManagementListing,
            active: splitPathBySlash(siteRoutes.superRoleManagementListing)[3],
            permission: sitePermissions.superRoleManagementListing,
            subPagesPermissions: [sitePermissions.superRoleManagementListing],
          },
          {
            title: "Permission Management",
            path: siteRoutes.permissionManagementListing,
            active: splitPathBySlash(siteRoutes.permissionManagementListing)[3],
            permission: sitePermissions.permissionManagementListing,
            subPagesPermissions: [sitePermissions.permissionManagementListing],
          },
          {
            title: "Module Management",
            path: siteRoutes.moduleManagement,
            active: splitPathBySlash(siteRoutes.moduleManagement)[3],
            permission: sitePermissions.moduleManagement,
            subPagesPermissions: [sitePermissions.moduleManagement],
          },
          {
            title: "Certificate Levels",
            path: siteRoutes.certificateLevelList,
            active: splitPathBySlash(siteRoutes.certificateLevelList)[3],
            permission: sitePermissions.CertificateLevelList,
            subPagesPermissions: [sitePermissions.CertificateLevelList],
          },
          {
            title: "Academic Session",
            path: siteRoutes.academicSessionOrganizationListing,
            active: splitPathBySlash(
              siteRoutes.academicSessionOrganizationListing
            )[3],
            permission: sitePermissions.academicSessionOrganizationListing,
            subPagesPermissions: [
              sitePermissions.academicSessionOrganizationListing,
            ],
          },
        ],
        isDropdownOpen: false,
      },
      {
        title: "My Apps",
        path: "my-apps",
        icon: SettingsSolidSvg,
        subMenus: [
          {
            title: "Exam Incharge Programs",
            path: siteRoutes.examInchargePrograms,
            active: splitPathBySlash(siteRoutes.examInchargePrograms)[3],
            permission: sitePermissions.examInchargePrograms,
            subPagesPermissions: [
              sitePermissions.examInchargeSections,
              sitePermissions.examInchargeCourses,
            ],
          },
        ],
        isDropdownOpen: false,
      },
      {
        title: "Academic",
        path: "academics",
        subMenus: [
          {
            title: "Academic Sessions",
            path: siteRoutes.academicSessionListing,
            active: splitPathBySlash(siteRoutes.academicSessionListing)[3],
            permission: sitePermissions.academicSessionListing,
            subPagesPermissions: [
              sitePermissions.academicSessionListing,
              sitePermissions.academicSessionManagementListing,
              sitePermissions.academicCourseListing,
              sitePermissions.createAcademicCourse,
            ],
          },
          {
            title: "Programs",
            path: siteRoutes.programListing,
            active: "programs",
            permission: sitePermissions.programListing,
            subPagesPermissions: [
              sitePermissions.programListing,
              sitePermissions.createProgram,
            ],
          },
          {
            title: "Plan of Studies",
            path: siteRoutes.academicPlanofStudies,
            active: splitPathBySlash(siteRoutes.academicPlanofStudies)[3],
            permission: sitePermissions.academicPlanofStudies,
          },
          {
            title: "Program Specialization",
            path: siteRoutes.academicProgramSpecializationListing,
            active: splitPathBySlash(
              siteRoutes.academicProgramSpecializationListing
            )[3],
            permission: sitePermissions.academicProgramSpecializationListing,
            subPagesPermissions: [
              sitePermissions.academicProgramSpecializationListing,
            ],
          },
          {
            title: "Student Management",
            subDeepMenus: [
              {
                title: "Enrollments",
                path: siteRoutes.academicEnrollmentsListing,
                active: splitPathBySlash(
                  siteRoutes.academicEnrollmentsListing
                )[3],
                permission: sitePermissions.academicEnrollmentsListing,
                subPagesPermissions: [
                  sitePermissions.academicEnrollmentsListing,
                ],
              },
              {
                title: "Students",
                path: siteRoutes.academicStudent,
                active: splitPathBySlash(siteRoutes.academicStudent)[3],
                permission: sitePermissions.academicStudent,
                subPagesPermissions: [sitePermissions.academicStudent],
              },
              {
                title: "Student Card Management",
                path: siteRoutes.studentCardManagementListing,
                active: splitPathBySlash(
                  siteRoutes.studentCardManagementListing
                )[3],
                permission: sitePermissions.studentCardManagementListing,
                subPagesPermissions: [
                  sitePermissions.studentCardManagementListing,
                ],
              },
              {
                title: "Students List",
                path: siteRoutes.academicDefaulterStudent,
                active: splitPathBySlash(
                  siteRoutes.academicDefaulterStudent
                )[3],
                permission: sitePermissions.academicDefaulterStudent,
                subPagesPermissions: [
                  sitePermissions.academicDefaulterStudent,
                  sitePermissions.academicDefaulterStudent,
                ],
              },
              {
                title: "Student Clearance",
                path: siteRoutes.academicStudentClearence,
                active: splitPathBySlash(
                  siteRoutes.academicStudentClearence
                )[3],
                permission: sitePermissions.academicStudentClearence,
                subPagesPermissions: [sitePermissions.academicStudentClearence],
              },
            ],
          },
          {
            title: "Examination & Result",
            subDeepMenus: [
              {
                title: "Exams",
                path: siteRoutes.academicExamsListing,
                active: splitPathBySlash(siteRoutes.academicExamsListing)[3],
                permission: sitePermissions.academicExamsListing,
                subPagesPermissions: [
                  sitePermissions.academicExamsListing,
                  sitePermissions.createAcademicExam,
                ],
              },
              {
                title: "Invigilators",
                path: siteRoutes.academicInvigilatorsListing,
                active: splitPathBySlash(
                  siteRoutes.academicInvigilatorsListing
                )[3],
                permission: sitePermissions.academicInvigilatorsListing,
                subPagesPermissions: [
                  sitePermissions.academicInvigilatorsListing,
                  sitePermissions.createAcademicInvigilator,
                ],
              },
              {
                title: "Exam Incharge",
                path: siteRoutes.academicManageExamIncharge,
                active: splitPathBySlash(
                  siteRoutes.academicManageExamIncharge
                )[3],
                permission: sitePermissions.academicManageExamIncharge,
                subPagesPermissions: [
                  sitePermissions.academicManageExamIncharge,
                  sitePermissions.academicExamIncharge,
                ],
              },
              {
                title: "Exam Types",
                path: siteRoutes.academicExamTypeListing,
                active: splitPathBySlash(siteRoutes.academicExamTypeListing)[3],
                permission: sitePermissions.academicExamTypeListing,
                subPagesPermissions: [
                  sitePermissions.academicExamTypeListing,
                  sitePermissions.createAcademicExamType,
                ],
              },
              {
                title: "Consolidated Results",
                path: siteRoutes.consolidatedResultsListing,
                active: splitPathBySlash(
                  siteRoutes.consolidatedResultsListing
                )[3],
                permission: sitePermissions.consolidatedResultsListing,
                subPagesPermissions: [
                  sitePermissions.consolidatedResultsListing,
                  sitePermissions.sectionCoursesListing,
                  sitePermissions.academicsConsolidatedResultsViewPostition,
                ],
              },
              {
                title: "Approved & Receipt",
                path: siteRoutes.approvedReceiptAcknowledgedListing,
                active: splitPathBySlash(
                  siteRoutes.approvedReceiptAcknowledgedListing
                )[3],
                permission: sitePermissions.approvedReceiptAcknowledgedListing,
                subPagesPermissions: [
                  sitePermissions.approvedReceiptAcknowledgedListing,
                  sitePermissions.appRecAckViewAwardList,
                ],
              },
              {
                title: "Grade Templates",
                path: siteRoutes.academicGradeTemplatesListing,
                active: splitPathBySlash(
                  siteRoutes.academicGradeTemplatesListing
                )[3],
                permission: sitePermissions.academicGradeTemplatesListing,
                subPagesPermissions: [
                  sitePermissions.academicGradeTemplatesListing,
                  sitePermissions.createAcademicGradeTemplate,
                ],
              },
              {
                title: "Letter Grades",
                path: siteRoutes.academicLetterGradesListing,
                active: splitPathBySlash(
                  siteRoutes.academicLetterGradesListing
                )[3],
                permission: sitePermissions.academicLetterGradesListing,
                subPagesPermissions: [
                  sitePermissions.academicLetterGradesListing,
                  sitePermissions.createAcademicLetterGrade,
                ],
              },
              {
                title: "Verified Gradebooks",
                path: siteRoutes.academicsVerifiedGradebookListing,
                active: splitPathBySlash(
                  siteRoutes.academicsVerifiedGradebookListing
                )[3],
                permission: sitePermissions.academicsVerifiedGradebookListing,
                subPagesPermissions: [
                  sitePermissions.academicsVerifiedGradebookListing,
                  sitePermissions.academicsVerifiedGradebookListing,
                ],
              },
            ],
          },
          {
            title: "Financial Operations",
            subDeepMenus: [
              {
                title: "Semester Fee Vouchers",
                path: siteRoutes.semesterFeeVoucherListing,
                active: splitPathBySlash(
                  siteRoutes.semesterFeeVoucherListing
                )[3],
                permission: sitePermissions.semesterFeeVoucherListing,
                subPagesPermissions: [
                  sitePermissions.semesterFeeVoucherListing,
                ],
              },
              {
                title: "Finance Academic Sessions",
                path: siteRoutes.financeAcademicSessionListing,
                active: splitPathBySlash(
                  siteRoutes.financeAcademicSessionListing
                )[3],
                permission: sitePermissions.financeAcademicSessionListing,
                subPagesPermissions: [
                  sitePermissions.financeAcademicSessionListing,
                  sitePermissions.createFinanceAcademicSession,
                ],
              },
              {
                title: "Students Fine",
                path: siteRoutes.academicStudentFine,
                active: splitPathBySlash(siteRoutes.academicStudentFine)[3],
                permission: sitePermissions.academicStudentFine,
                subPagesPermissions: [
                  sitePermissions.academicStudentFine,
                  sitePermissions.academicStudentFine,
                ],
              },
              {
                title: "Fee Types",
                path: siteRoutes.academicFeeTypeListing,
                active: splitPathBySlash(siteRoutes.academicFeeTypeListing)[3],
                permission: sitePermissions.academicFeeTypeListing,
                subPagesPermissions: [
                  sitePermissions.academicFeeTypeListing,
                  sitePermissions.createAcademicFeeType,
                ],
              },
              {
                title: "Fee Plans",
                path: siteRoutes.academicFeePlansListing,
                active: splitPathBySlash(siteRoutes.academicFeePlansListing)[3],
                permission: sitePermissions.academicFeePlansListing,
                subPagesPermissions: [
                  sitePermissions.academicFeePlansListing,
                  sitePermissions.createAcademicFeePlan,
                ],
              },
              {
                title: "Fee Transactions",
                path: siteRoutes.academicFeeTransactionsListing,
                active: splitPathBySlash(
                  siteRoutes.academicFeeTransactionsListing
                )[3],
                permission: sitePermissions.academicFeeTransactionsListing,
                subPagesPermissions: [
                  sitePermissions.academicFeeTransactionsListing,
                  sitePermissions.createAcademicFeeReceipt,
                ],
              },
              {
                title: "Paid Vouchers",
                path: siteRoutes.academicPaidVouchersListing,
                active: splitPathBySlash(
                  siteRoutes.academicPaidVouchersListing
                )[3],
                permission: sitePermissions.academicPaidVouchersListing,
                subPagesPermissions: [
                  sitePermissions.academicPaidVouchersListing,
                ],
              },
            ],
          },
          {
            title: "Templates & Configuration",
            subDeepMenus: [
              {
                title: "Template Courses",
                path: siteRoutes.academicTemplateCoursesListing,
                active: splitPathBySlash(
                  siteRoutes.academicTemplateCoursesListing
                )[3],
                permission: sitePermissions.academicTemplateCoursesListing,
                subPagesPermissions: [
                  sitePermissions.academicTemplateCoursesListing,
                  sitePermissions.createAcademicTemplateCourse,
                ],
              },
              {
                title: "Course types",
                path: siteRoutes.courseTypesListing,
                active: splitPathBySlash(siteRoutes.courseTypesListing)[3],
                permission: sitePermissions.courseTypesListing,
                subPagesPermissions: [
                  sitePermissions.courseTypesListing,
                  sitePermissions.createCourseType,
                ],
              },
              {
                title: "Common Courses Pools",
                path: siteRoutes.commonCoursesPoolsListing,
                active: splitPathBySlash(
                  siteRoutes.commonCoursesPoolsListing
                )[3],
                permission: sitePermissions.commonCoursesPoolsListing,
                subPagesPermissions: [
                  sitePermissions.commonCoursesPoolsListing,
                  sitePermissions.createCommonCoursesPools,
                ],
              },
              {
                title: "Academic Status",
                path: siteRoutes.academicStatusListing,
                active: splitPathBySlash(siteRoutes.academicStatusListing)[3],
                permission: sitePermissions.academicStatusListing,
                subPagesPermissions: [
                  sitePermissions.academicStatusListing,
                  sitePermissions.createAcademicStatus,
                ],
              },
              {
                title: "Authorities",
                path: siteRoutes.academicAuthorityListing,
                active: splitPathBySlash(
                  siteRoutes.academicAuthorityListing
                )[3],
                permission: sitePermissions.academicAuthorityListing,
                subPagesPermissions: [
                  sitePermissions.academicAuthorityListing,
                  sitePermissions.createAcademicAuthority,
                ],
              },
              {
                title: "Result Setting Template",
                path: siteRoutes.resultSettingTemplateListing,
                active: splitPathBySlash(
                  siteRoutes.resultSettingTemplateListing
                )[3],
                permission: sitePermissions.resultSettingTemplateListing,
                subPagesPermissions: [
                  sitePermissions.resultSettingTemplateListing,
                ],
              },
              {
                title: "Template Header",
                path: siteRoutes.templateHeaderListing,
                active: splitPathBySlash(siteRoutes.templateHeaderListing)[3],
                permission: sitePermissions.templateHeaderListing,
                subPagesPermissions: [sitePermissions.templateHeaderListing],
              },
              {
                title: "Teachers Titles",
                path: siteRoutes.teachersTitlesListing,
                active: splitPathBySlash(siteRoutes.teachersTitlesListing)[3],
                permission: sitePermissions.teachersTitlesListing,
                subPagesPermissions: [
                  sitePermissions.teachersTitlesListing,
                  sitePermissions.createTeachersTitle,
                ],
              },
            ],
          },
          {
            title: "Clearance Authorities",
            path: siteRoutes.clearanceAuthoritiesListing,
            active: splitPathBySlash(siteRoutes.clearanceAuthoritiesListing)[3],
            permission: sitePermissions.clearanceAuthoritiesListing,
            subPagesPermissions: [
              sitePermissions.clearanceAuthoritiesListing,
              sitePermissions.createClearanceAuthority,
            ],
          },
          {
            title: "Student Status",
            path: siteRoutes.studentStatusListing,
            active: splitPathBySlash(siteRoutes.studentStatusListing)[3],
            permission: sitePermissions.studentStatusListing,
            subPagesPermissions: [
              sitePermissions.studentStatusListing,
              sitePermissions.createStudentStatus,
            ],
          },
          {
            title: "Student Group",
            path: siteRoutes.studentGroupListing,
            active: splitPathBySlash(siteRoutes.studentGroupListing)[3],
            permission: sitePermissions.studentGroupListing,
            subPagesPermissions: [
              sitePermissions.studentGroupListing,
              sitePermissions.createStudentGroup,
            ],
          },
          {
            title: "Student Specialization",
            path: siteRoutes.studentSpecializationsListing,
            active: splitPathBySlash(
              siteRoutes.studentSpecializationsListing
            )[3],
            permission: sitePermissions.studentSpecializationsListing,
            subPagesPermissions: [
              sitePermissions.studentSpecializationsListing,
              sitePermissions.createStudentSpecialization,
            ],
          },
          {
            title: "Semester Types",
            path: siteRoutes.academicSemesterTypesListing,
            active: splitPathBySlash(
              siteRoutes.academicSemesterTypesListing
            )[3],
            permission: sitePermissions.academicSemesterTypesListing,
            subPagesPermissions: [
              sitePermissions.academicSemesterTypesListing,
              sitePermissions.createAcademicSemesterType,
            ],
          },
          {
            title: "Manage Sections",
            path: siteRoutes.academicSectionsListing,
            active: splitPathBySlash(siteRoutes.academicSectionsListing)[3],
            permission: sitePermissions.academicSectionsListing,
            subPagesPermissions: [
              sitePermissions.academicSectionsListing,
              sitePermissions.createAcademicSection,
            ],
          },
          {
            title: "Manage Internships",
            path: siteRoutes.academicInternshipsListing,
            active: splitPathBySlash(siteRoutes.academicInternshipsListing)[3],
            permission: sitePermissions.academicInternshipsListing,
            subPagesPermissions: [
              sitePermissions.academicInternshipsListing,
              sitePermissions.createAcademicInternship,
            ],
          },
          {
            title: "Student Clearence List",
            path: siteRoutes.studentClearenceListing,
            active: splitPathBySlash(siteRoutes.studentClearenceListing)[3],
            permission: sitePermissions.studentClearenceListing,
            subPagesPermissions: [sitePermissions.studentClearenceListing],
          },
          {
            title: "Currrent Enrollments",
            path: siteRoutes.academicsCurrentEnrollment,
            active: splitPathBySlash(siteRoutes.academicsCurrentEnrollment)[3],
            permission: sitePermissions.academicsCurrentEnrollment,
            subPagesPermissions: [sitePermissions.academicsCurrentEnrollment],
          },
          {
            title: "Student Plan Of Studies",
            path: siteRoutes.academicsStudentPlanOfStudies,
            active: splitPathBySlash(
              siteRoutes.academicsStudentPlanOfStudies
            )[3],
            permission: sitePermissions.academicsStudentPlanOfStudies,
            subPagesPermissions: [
              sitePermissions.academicsStudentPlanOfStudies,
            ],
          },
          {
            title: "Transcript",
            path: siteRoutes.academicsStudentTranscript,
            active: splitPathBySlash(siteRoutes.academicsStudentTranscript)[3],
            permission: sitePermissions.academicsStudentTranscript,
            subPagesPermissions: [sitePermissions.academicsStudentTranscript],
          },
          {
            title: "Student Financial Voucher",
            path: siteRoutes.studentFinancialVouchers,
            active: splitPathBySlash(siteRoutes.studentFinancialVouchers)[3],
            permission: sitePermissions.studentFinancialVouchers,
            subPagesPermissions: [sitePermissions.studentFinancialVouchers],
          },
          {
            title: "Student Account Statement",
            path: siteRoutes.studentFinancialVouchers,
            active: splitPathBySlash(siteRoutes.studentFinancialVouchers)[3],
            permission: sitePermissions.studentFinancialVouchers,
            subPagesPermissions: [sitePermissions.studentFinancialVouchers],
          },
          {
            title: "Holidays",
            path: siteRoutes.academicHolidaysList,
            active: splitPathBySlash(siteRoutes.academicHolidaysList)[3],
            permission: sitePermissions.academicHolidaysList,
            subPagesPermissions: [sitePermissions.academicHolidaysList],
          },
          {
            title: "My Clearance",
            path: siteRoutes.academicStudentClearance,
            active: splitPathBySlash(siteRoutes.academicStudentClearance)[3],
            permission: sitePermissions.academicStudentClearance,
            subPagesPermissions: [sitePermissions.academicStudentClearance],
          },
          {
            title: "Course Repeat Challan",
            path: siteRoutes.studentAcademicCourseChallan,
            active: splitPathBySlash(
              siteRoutes.studentAcademicCourseChallan
            )[3],
            permission: sitePermissions.studentAcademicCourseChallan,
            subPagesPermissions: [sitePermissions.studentAcademicCourseChallan],
          },
        ],
        icon: BooksSvg,
        isDropdownOpen: false,
      },
      {
        title: "Time Table",
        path: "time-table",
        subMenus: [
          {
            title: "Time Table Listing",
            path: siteRoutes.timeTableListing,
            active: splitPathBySlash(siteRoutes.timeTableListing)[3],
            permission: sitePermissions.timeTableListing,
          },
          {
            title: "Time Table Planner",
            subDeepMenus: [
              {
                title: "Planner Listing",
                path: siteRoutes.timeTablePlannerListing,
                active: splitPathBySlash(siteRoutes.timeTablePlannerListing)[3],
                permission: sitePermissions.timeTablePlannerListing,
                subPagesPermissions: [
                  sitePermissions.studentAcademicCourseChallan,
                ],
              },
            ],
          },
        ],
        icon: TimeTableSvg,
        isDropdownOpen: false,
      },
      {
        title: "Authorities & Committees",
        path: splitPathBySlash(siteRoutes.authoritiesListing)[2],
        subMenus: [
          {
            title: "Authorities & Committees",
            path: siteRoutes.authoritiesListing,
            active: splitPathBySlash(siteRoutes.authoritiesListing)[3],
            permission: sitePermissions.authoritiesListing,
            subPagesPermissions: [sitePermissions.authoritiesListing],
          },
          {
            title: "Authority & Committee Member Types",
            path: siteRoutes.authorityMemberTypeListing,
            active: splitPathBySlash(siteRoutes.authorityMemberTypeListing)[3],
            permission: sitePermissions.authorityMemberTypeListing,
            subPagesPermissions: [sitePermissions.authorityMemberTypeListing],
          },
          {
            title: "Authority Boards",
            path: siteRoutes.authorityBoardsListing,
            active: splitPathBySlash(siteRoutes.authorityBoardsListing)[3],
            permission: sitePermissions.authorityBoardsListing,
            subPagesPermissions: [
              sitePermissions.authorityBoardsListing,
              sitePermissions.createAuthorityBoard,
            ],
          },
          {
            title: "Authority & Committee Types",
            path: siteRoutes.authorityTypeListing,
            active: splitPathBySlash(siteRoutes.authorityTypeListing)[3],
            permission: sitePermissions.authorityTypeListing,
            subPagesPermissions: [sitePermissions.authorityTypeListing],
          },
        ],
        icon: AuthoritiesSvg,
        isDropdownOpen: false,
      },
      {
        title: "Eportal Admissions",
        path: splitPathBySlash(siteRoutes.admissionCampaignListing)[2],
        subMenus: [
          {
            title: "Admission Campaigns",
            path: siteRoutes.admissionCampaignListing,
            active: splitPathBySlash(siteRoutes.admissionCampaignListing)[3],
            permission: sitePermissions.admissionCampaignListing,
            subPagesPermissions: [
              sitePermissions.createAdmissionCampaign,
              sitePermissions.admissionCampaignListing,
              sitePermissions.campaignProgramsListing,
              sitePermissions.programLinkMeritList,
              sitePermissions.createLinkedProgramEntryTest,
              sitePermissions.linkedProgramEntryTestListing,
              sitePermissions.createProgramLinkMeritList,
              sitePermissions.programLinkMeritList,
              sitePermissions.eligibilityTemplatesListing,
              sitePermissions.eligibilityTemplatesCreate,
            ],
          },

          {
            title: "Students Applications",
            path: siteRoutes.admissionStudentListing,
            active: splitPathBySlash(siteRoutes.admissionStudentListing)[3],
            permission: sitePermissions.admissionStudentListing,
            subPagesPermissions: [sitePermissions.admissionStudentListing],
          },
          // {
          //   title: "Student applications view only",
          //   path: siteRoutes.admissionApplicantsListing,
          //   active: splitPathBySlash(siteRoutes.admissionApplicantsListing)[3],
          //   permission: sitePermissions.admissionApplicantsListing,
          //   subPagesPermissions: [sitePermissions.admissionApplicantsListing],
          // },

          // {
          //   title: "Test Types",
          //   path: siteRoutes.admissionTestTypesListing,
          //   active: splitPathBySlash(siteRoutes.admissionTestTypesListing)[3],
          //   permission: sitePermissions.admissionTestTypesListing,
          //   subPagesPermissions: [
          //     sitePermissions.admissionTestTypesListing,
          //     sitePermissions.createAdmissionTestType,
          //   ],
          // },
          {
            title: "Registered Students",
            path: siteRoutes.studentRegListing,
            active: splitPathBySlash(siteRoutes.studentRegListing)[3],
            permission: sitePermissions.studentRegListing,
            subPagesPermissions: [sitePermissions.studentRegListing],
          },

          // {
          //   title: "Offer Letter Templates",
          //   path: siteRoutes.admissionOfferLetterListing,
          //   active: splitPathBySlash(siteRoutes.admissionOfferLetterListing)[3],
          //   permission: sitePermissions.admissionOfferLetterListing,
          //   subPagesPermissions: [
          //     sitePermissions.admissionOfferLetterListing,
          //     sitePermissions.createAdmissionOfferLetter,
          //   ],
          // },
          // {
          //     title: "Board Management",
          //     path: siteRoutes.editAdmissionBoard,
          // active: splitPathBySlash(siteRoutes.editAdmissionBoard)[2],
          //     permission: sitePermissions.editAdmissionBoard,
          //     subPagesPermissions: [
          //         sitePermissions.editAdmissionBoard
          //     ]
          // },
          // {
          //     title: "Certificate Management",
          //     path: siteRoutes.editAdmissionCertificate,
          // active: splitPathBySlash(siteRoutes.editAdmissionCertificate)[2],
          //     permission: sitePermissions.editAdmissionCertificate,
          //     subPagesPermissions: [
          //         sitePermissions.editAdmissionCertificate
          //     ]
          // },
          {
            title: "Documents Master",
            path: siteRoutes.admissionManageDocumentsMasterListing,
            active: splitPathBySlash(
              siteRoutes.admissionManageDocumentsMasterListing
            )[3],
            permission: sitePermissions.admissionManageDocumentsMasterListing,
            subPagesPermissions: [
              sitePermissions.admissionManageDocumentsMasterListing,
            ],
          },
          // {
          //   title: "Eligibility Templates Header",
          //   path: siteRoutes.admissioneligibilityTemplateHeadersListing,
          //   active: splitPathBySlash(
          //     siteRoutes.admissioneligibilityTemplateHeadersListing
          //   )[3],
          //   permission:
          //     sitePermissions.admissioneligibilityTemplateHeadersListing,
          //   subPagesPermissions: [
          //     sitePermissions.admissioneligibilityTemplateHeadersListing,
          //     sitePermissions.createAdmissioneligibilityTemplateHeaders,
          //   ],
          // },

          // {
          //   title: "Merit List",
          //   path: siteRoutes.admissionMeritListing,
          //   active: splitPathBySlash(siteRoutes.admissionMeritListing)[3],
          //   permission: sitePermissions.admissionMeritListing,
          //   subPagesPermissions: [sitePermissions.admissionMeritListing],
          // },
          {
            title: "Merit List Management",
            subDeepMenus: [
              {
                title: "Merit List",
                path: siteRoutes.admissionMeritListing,
                active: splitPathBySlash(siteRoutes.admissionMeritListing)[3],
                permission: sitePermissions.admissionMeritListing,
                subPagesPermissions: [sitePermissions.admissionMeritListing],
              },
              {
                title: "Locked Merit List",
                path: siteRoutes.admissionLockedMeritListing,
                active: splitPathBySlash(
                  siteRoutes.admissionLockedMeritListing
                )[3],
                permission: sitePermissions.admissionLockedMeritListing,
                subPagesPermissions: [
                  sitePermissions.admissionLockedMeritListing,
                ],
              },
              // {
              //   title: "Merit List Formula",
              //   path: siteRoutes.meritListFormulaListing,
              //   active: splitPathBySlash(siteRoutes.meritListFormulaListing)[3],
              //   permission: sitePermissions.meritListFormulaListing,
              //   subPagesPermissions: [
              //     sitePermissions.meritKeysListing,
              //     sitePermissions.createMeitKeys,
              //   ],
              // },
              {
                title: "Manage Merit List",
                path: siteRoutes.manageMeritListListing,
                active: splitPathBySlash(siteRoutes.manageMeritListListing)[3],
                permission: sitePermissions.manageMeritListListing,
                subPagesPermissions: [
                  sitePermissions.manageMeritListListing,
                  sitePermissions.manageMeritListListing,
                ],
              },
            ],
          },

          {
            title: "Templates & Configuration",
            subDeepMenus: [
              {
                title: "Eligibility Templates Header",
                path: siteRoutes.admissioneligibilityTemplateHeadersListing,
                active: splitPathBySlash(
                  siteRoutes.admissioneligibilityTemplateHeadersListing
                )[3],
                permission:
                  sitePermissions.admissioneligibilityTemplateHeadersListing,
                subPagesPermissions: [
                  sitePermissions.admissioneligibilityTemplateHeadersListing,
                  sitePermissions.createAdmissioneligibilityTemplateHeaders,
                ],
              },
              {
                title: "Offer Letter Templates",
                path: siteRoutes.admissionOfferLetterListing,
                active: splitPathBySlash(
                  siteRoutes.admissionOfferLetterListing
                )[3],
                permission: sitePermissions.admissionOfferLetterListing,
                subPagesPermissions: [
                  sitePermissions.admissionOfferLetterListing,
                  sitePermissions.createAdmissionOfferLetter,
                ],
              },
              {
                title: "Merit List Formula",
                path: siteRoutes.meritListFormulaListing,
                active: splitPathBySlash(siteRoutes.meritListFormulaListing)[3],
                permission: sitePermissions.meritListFormulaListing,
                subPagesPermissions: [
                  sitePermissions.meritKeysListing,
                  sitePermissions.createMeitKeys,
                ],
              },
            ],
          },

          // {
          //   title: "Merit List",
          //   path: siteRoutes.admissionMeritListing,
          //   active: splitPathBySlash(siteRoutes.admissionMeritListing)[3],
          //   permission: sitePermissions.admissionMeritListing,
          //   subPagesPermissions: [sitePermissions.admissionMeritListing],
          // },
          // {
          //   title: "Merit Keys",
          //   path: siteRoutes.meritKeysListing,
          //   active: splitPathBySlash(siteRoutes.meritKeysListing)[3],
          //   permission: sitePermissions.meritKeysListing,
          //   subPagesPermissions: [
          //     sitePermissions.meritKeysListing,
          //     sitePermissions.createMeitKeys,
          //   ],
          // },
          // {
          //   title: "Merit List Formula",
          //   path: siteRoutes.meritListFormulaListing,
          //   active: splitPathBySlash(siteRoutes.meritListFormulaListing)[3],
          //   permission: sitePermissions.meritListFormulaListing,
          //   subPagesPermissions: [
          //     sitePermissions.meritKeysListing,
          //     sitePermissions.createMeitKeys,
          //   ],
          // },
          // {
          //   title: "Manage Merit List",
          //   path: siteRoutes.manageMeritListListing,
          //   active: splitPathBySlash(siteRoutes.manageMeritListListing)[3],
          //   permission: sitePermissions.manageMeritListListing,
          //   subPagesPermissions: [
          //     sitePermissions.manageMeritListListing,
          //     sitePermissions.manageMeritListListing,
          //   ],
          // },

          {
            title: "Quotas Management",
            subDeepMenus: [
              {
                title: "Quotas List",
                path: siteRoutes.quotasAdmissionListing,
                active: splitPathBySlash(siteRoutes.quotasAdmissionListing)[3],
                permission: sitePermissions.quotasAdmissionListing,
                subPagesPermissions: [
                  sitePermissions.quotasAdmissionListing,
                  sitePermissions.quotasAdmissionListing,
                ],
              },
              {
                title: "Manage Quotas",
                path: siteRoutes.admissionQuotasListing,
                active: splitPathBySlash(siteRoutes.admissionQuotasListing)[3],
                permission: sitePermissions.admissionQuotasListing,
                subPagesPermissions: [
                  sitePermissions.admissionQuotasListing,
                  sitePermissions.createAdmissionQuotas,
                ],
              },
            ],
          },

          {
            title: "Master Records",
            subDeepMenus: [
              {
                title: "Manage Subjects",
                path: siteRoutes.admissionSubjectManagementListing,
                active: splitPathBySlash(
                  siteRoutes.admissionSubjectManagementListing
                )[3],
                permission: sitePermissions.admissionSubjectManagementListing,
                subPagesPermissions: [
                  sitePermissions.admissionSubjectManagementListing,
                  sitePermissions.createAdmissionSubjectManagement,
                ],
              },
              {
                title: "Manage Certificate/Degree",
                path: siteRoutes.admissionCertificateManagementListing,
                active: splitPathBySlash(
                  siteRoutes.admissionCertificateManagementListing
                )[3],
                permission:
                  sitePermissions.admissionCertificateManagementListing,
                subPagesPermissions: [
                  sitePermissions.admissionCertificateManagementListing,
                  sitePermissions.createAdmissionCertificateManagement,
                ],
              },
              {
                title: "Test Types",
                path: siteRoutes.admissionTestTypesListing,
                active: splitPathBySlash(
                  siteRoutes.admissionTestTypesListing
                )[3],
                permission: sitePermissions.admissionTestTypesListing,
                subPagesPermissions: [
                  sitePermissions.admissionTestTypesListing,
                  sitePermissions.createAdmissionTestType,
                ],
              },
              {
                title: "Manage Document",
                path: siteRoutes.admissionDocumentListing,
                active: splitPathBySlash(
                  siteRoutes.admissionDocumentListing
                )[3],
                permission: sitePermissions.admissionDocumentListing,
                subPagesPermissions: [
                  sitePermissions.admissionDocumentListing,
                  sitePermissions.editAdmissionDocument,
                ],
              },
            ],
          },

          // {
          //   title: "Manage Document",
          //   path: siteRoutes.admissionDocumentListing,
          //   active: splitPathBySlash(siteRoutes.admissionDocumentListing)[3],
          //   permission: sitePermissions.admissionDocumentListing,
          //   subPagesPermissions: [
          //     sitePermissions.admissionDocumentListing,
          //     sitePermissions.editAdmissionDocument,
          //   ],
          // },

          // {
          //   title: "Manage Subjects",
          //   path: siteRoutes.admissionSubjectManagementListing,
          //   active: splitPathBySlash(
          //     siteRoutes.admissionSubjectManagementListing
          //   )[3],
          //   permission: sitePermissions.admissionSubjectManagementListing,
          //   subPagesPermissions: [
          //     sitePermissions.admissionSubjectManagementListing,
          //     sitePermissions.createAdmissionSubjectManagement,
          //   ],
          // },
          {
            title: "Manage Boards/Universities",
            path: siteRoutes.admissionBoardManagementListing,
            active: splitPathBySlash(
              siteRoutes.admissionBoardManagementListing
            )[3],
            permission: sitePermissions.admissionBoardManagementListing,
            subPagesPermissions: [
              sitePermissions.admissionBoardManagementListing,
              sitePermissions.createAdmissionBoardManagement,
            ],
          },
          // {
          //   title: "Manage Certificate/Degree",
          //   path: siteRoutes.admissionCertificateManagementListing,
          //   active: splitPathBySlash(
          //     siteRoutes.admissionCertificateManagementListing
          //   )[3],
          //   permission: sitePermissions.admissionCertificateManagementListing,
          //   subPagesPermissions: [
          //     sitePermissions.admissionCertificateManagementListing,
          //     sitePermissions.createAdmissionCertificateManagement,
          //   ],
          // },
          // {
          //   title: "Locked Merit List",
          //   path: siteRoutes.admissionLockedMeritListing,
          //   active: splitPathBySlash(siteRoutes.admissionLockedMeritListing)[3],
          //   permission: sitePermissions.admissionLockedMeritListing,
          //   subPagesPermissions: [sitePermissions.admissionLockedMeritListing],
          // },
        ],
        icon: GraduationSvg,
        isDropdownOpen: false,
      },
      {
        title: "Eportal Careers",
        path: splitPathBySlash(siteRoutes.careerDesignationListing)[2],
        permission: sitePermissions.careerDesignationListing,

        subMenus: [
          {
            title: "Applicant",
            path: siteRoutes.careerApplicantListing,
            active: splitPathBySlash(siteRoutes.careerApplicantListing)[3],
            permission: sitePermissions.careerApplicantListing,
            subPagesPermissions: [sitePermissions.careerApplicantListing],
          },
          {
            title: "Post",
            path: siteRoutes.careerPostListing,
            active: splitPathBySlash(siteRoutes.careerPostListing)[3],
            permission: sitePermissions.careerPostListing,
            subPagesPermissions: [sitePermissions.careerPostListing],
          },
          {
            title: "Post Template",
            path: siteRoutes.careerPostTemplateListing,
            active: splitPathBySlash(siteRoutes.careerPostTemplateListing)[3],
            permission: sitePermissions.careerPostTemplateListing,
            subPagesPermissions: [sitePermissions.createCareerPostTemplate],
          },
          {
            title: "Advertisement",
            path: siteRoutes.careerAdvertisementListing,
            active: splitPathBySlash(siteRoutes.careerAdvertisementListing)[3],
            permission: sitePermissions.careerAdvertisementListing,
            subPagesPermissions: [sitePermissions.careerAdvertisementListing],
          },
          {
            title: "Designation",
            path: siteRoutes.careerDesignationListing,
            active: splitPathBySlash(siteRoutes.careerDesignationListing)[3],
            permission: sitePermissions.careerDesignationListing,
            subPagesPermissions: [
              sitePermissions.careerDesignationListing,
              sitePermissions.createCareerDesignation,
            ],
          },
        ],
        icon: CareerSvg,
        isDropdownOpen: false,
      },
      {
        title: "Eportal Testing Services",
        path: splitPathBySlash(siteRoutes.testTypesListing)[2],
        subMenus: [
          {
            title: "Test Types",
            path: siteRoutes.testTypesListing,
            active: splitPathBySlash(siteRoutes.testTypesListing)[3],
            permission: sitePermissions.testTypesListing,
          },
          {
            title: "Test Center",
            path: siteRoutes.TestingServicesTestCenterlist,
            active: splitPathBySlash(
              siteRoutes.TestingServicesTestCenterlist
            )[3],
            permission: sitePermissions.TestingServicesTestCenterlist,
            subPagesPermissions: [
              sitePermissions.TestingServicesTestCenterlist,
              sitePermissions.TestingServicesTestCentercreate,
            ],
          },
          {
            title: "Test Schedule",
            path: siteRoutes.TestingServicesTestSchedulelist,
            active: splitPathBySlash(
              siteRoutes.TestingServicesTestSchedulelist
            )[3],
            permission: sitePermissions.TestingServicesTestSchedulelist,
            subPagesPermissions: [
              sitePermissions.TestingServicesTestSchedulelist,
              sitePermissions.createTestingServicesTestSchedule,
            ],
          },
          {
            title: "Applicant List",
            path: siteRoutes.testingServicescandidatelisting,
            active: splitPathBySlash(
              siteRoutes.testingServicescandidatelisting
            )[3],
            permission: sitePermissions.testingServicescandidatelisting,
            subPagesPermissions: [
              sitePermissions.testingServicescandidatelisting,
              // sitePermissions.createTestingServicesTestSchedule,
            ],
          },
          {
            title: "Upload Result",
            path: siteRoutes.testingServicescandidateTestResultlist,
            active: splitPathBySlash(
              siteRoutes.testingServicescandidateTestResultlist
            )[3],
            permission: sitePermissions.testingServicescandidateTestResultlist,
            subPagesPermissions: [
              sitePermissions.testingServicescandidateTestResultlist,
            ],
          },
          {
            title: "Publish Results",
            path: siteRoutes.testingServicespublishresult,
            active: splitPathBySlash(
              siteRoutes.testingServicespublishresult
            )[3],
            permission: sitePermissions.testingServicespublishresult,
            subPagesPermissions: [sitePermissions.testingServicespublishresult],
          },
        ],
        icon: TestingServicesSvg,
        isDropdownOpen: false,
      },
      {
        title: "Finance",
        path: splitPathBySlash(siteRoutes.studentFinancialClearanceListing)[2],
        subMenus: [
          {
            title: "Student Financial Clearance",
            path: siteRoutes.studentFinancialClearanceListing,
            active: splitPathBySlash(
              siteRoutes.studentFinancialClearanceListing
            )[3],
            permission: sitePermissions.studentFinancialClearanceListing,
          },
          {
            title: "Check Challan Status",
            path: siteRoutes.checkFinancialChallanStatus,
            active: splitPathBySlash(siteRoutes.checkFinancialChallanStatus)[3],
            subPagesPermissions: [sitePermissions.checkFinancialChallanStatus],
            permission: sitePermissions.checkFinancialChallanStatus,
          },
          {
            title: "Student Vouchers",
            path: siteRoutes.financeStudentVouchersListing,
            active: splitPathBySlash(
              siteRoutes.financeStudentVouchersListing
            )[3],
            permission: sitePermissions.financeStudentVouchersListing,
          },
          {
            title: "Master Book",
            path: siteRoutes.masterBookListing,
            active: splitPathBySlash(siteRoutes.masterBookListing)[3],
            permission: sitePermissions.masterBookListing,
            subPagesPermissions: [
              sitePermissions.masterBookListing,
              sitePermissions.masterBookTransactionDetail,
              sitePermissions.showMasterBookTransactionDetail,
            ],
          },
          {
            title: "Bank Transactions Logs",
            path: siteRoutes.bankTransactionLogsListing,
            active: splitPathBySlash(siteRoutes.bankTransactionLogsListing)[3],
            subPagesPermissions: [sitePermissions.bankTransactionLogsListing],
            permission: sitePermissions.bankTransactionLogsListing,
          },
          {
            title: "Bank Scroll Logs",
            path: siteRoutes.bankScrollLogsListing,
            active: splitPathBySlash(siteRoutes.bankScrollLogsListing)[3],
            subPagesPermissions: [sitePermissions.bankScrollLogsListing],
            permission: sitePermissions.bankScrollLogsListing,
          },
          {
            title: "Templates & Configuration",
            subDeepMenus: [
              {
                title: "Voucher Template Header",
                path: siteRoutes.voucherTemplateHeaderListing,
                active: splitPathBySlash(
                  siteRoutes.voucherTemplateHeaderListing
                )[3],
                subPagesPermissions: [
                  sitePermissions.voucherTemplateHeaderListing,
                  sitePermissions.createVoucherTemplateHeader,
                ],
                permission: sitePermissions.voucherTemplateHeaderListing,
              },
              {
                title: "Template Programs Account",
                path: siteRoutes.templateProgramAccountCodeListing,
                active: splitPathBySlash(
                  siteRoutes.templateProgramAccountCodeListing
                )[3],
                subPagesPermissions: [
                  sitePermissions.templateProgramAccountCodeListing,
                ],
                permission: sitePermissions.templateProgramAccountCodeListing,
              },
              {
                title: "Fine slots",
                path: siteRoutes.fineSlotsListing,
                active: splitPathBySlash(siteRoutes.fineSlotsListing)[3],
                subPagesPermissions: [
                  sitePermissions.fineSlotsListing,
                  sitePermissions.createFineSlot,
                ],
                permission: sitePermissions.fineSlotsListing,
              },
            ],
          },
          {
            title: "Master Records",
            subDeepMenus: [
              {
                title: "Voucher Types",
                path: siteRoutes.voucherTypesListing,
                active: splitPathBySlash(siteRoutes.voucherTypesListing)[3],
                subPagesPermissions: [
                  sitePermissions.voucherTypesListing,
                  sitePermissions.createVoucherType,
                ],
                permission: sitePermissions.voucherTypesListing,
              },
              {
                title: "Voucher Particulars",
                path: siteRoutes.voucherParticularListing,
                active: splitPathBySlash(
                  siteRoutes.voucherParticularListing
                )[3],
                subPagesPermissions: [
                  sitePermissions.voucherParticularListing,
                  sitePermissions.createVoucherParticular,
                ],
                permission: sitePermissions.voucherParticularListing,
              },
              {
                title: "Banks List",
                path: siteRoutes.banksListing,
                active: splitPathBySlash(siteRoutes.banksListing)[3],
                subPagesPermissions: [
                  sitePermissions.banksListing,
                  sitePermissions.createBank,
                ],
                permission: sitePermissions.banksListing,
              },
            ],
          },
          {
            title: "Applications",
            path: siteRoutes.financeApplicationsListing,
            active: splitPathBySlash(siteRoutes.financeApplicationsListing)[3],
            subPagesPermissions: [
              sitePermissions.financeApplicationsListing,
              sitePermissions.createFinanceApplications,
            ],
            permission: sitePermissions.financeApplicationsListing,
          },
          // {
          //   title: "Application Templates",
          //   path: siteRoutes.applicationTemplateLinkListing,
          //   active: splitPathBySlash(
          //     siteRoutes.applicationTemplateLinkListing
          //   )[3],
          //   subPagesPermissions: [
          //     sitePermissions.applicationTemplateLinkListing,
          //     sitePermissions.createApplicationTemplateLink,
          //   ],
          //   permission: sitePermissions.applicationTemplateLinkListing,
          // },
        ],
        icon: FinanceSvg,
        isDropdownOpen: false,
      },

      {
        title: "Hostel",
        path: "hostel",
        subMenus: [
          {
            title: "Applicant Management",
            path: siteRoutes.applicantListing,
            active: splitPathBySlash(siteRoutes.applicantListing)[3],
            permission: sitePermissions.applicantListing,
            subPagesPermissions: [
              sitePermissions.applicantListing,
              sitePermissions.createApplicant,
            ],
          },
          {
            title: "Merit Management",
            path: siteRoutes.hostelMeritListing,
            active: splitPathBySlash(siteRoutes.hostelMeritListing)[3],
            permission: sitePermissions.hostelMeritListing,
            subPagesPermissions: [
              sitePermissions.hostelMeritListing,
              sitePermissions.createHostelMerit,
            ],
          },
          {
            title: "Sessions Management",
            path: siteRoutes.hostelSessionListing,
            active: splitPathBySlash(siteRoutes.hostelSessionListing)[3],
            permission: sitePermissions.hostelSessionListing,
            subPagesPermissions: [
              sitePermissions.hostelSessionListing,
              sitePermissions.createHostelSession,
            ],
          },
          {
            title: "Applied Applicants",
            path: siteRoutes.appliedApplicant,
            active: splitPathBySlash(siteRoutes.appliedApplicant)[3],
            permission: sitePermissions.appliedApplicant,
            subPagesPermissions: [sitePermissions.appliedApplicant],
          },
          {
            title: "Applicant Enrollment",
            path: siteRoutes.applicantEnrollment,
            active: splitPathBySlash(siteRoutes.applicantEnrollment)[3],
            permission: sitePermissions.applicantEnrollment,
            subPagesPermissions: [sitePermissions.applicantEnrollment],
          },
          {
            title: "Registered Applicant",
            path: siteRoutes.registeredApplicant,
            active: splitPathBySlash(siteRoutes.registeredApplicant)[3],
            permission: sitePermissions.registeredApplicantList,
            subPagesPermissions: [sitePermissions.registeredApplicantList],
          },
          {
            title: "Hostel Applicant Merit List",
            path: siteRoutes.hostelApplicantMeritList,
            active: splitPathBySlash(siteRoutes.hostelApplicantMeritList)[3],
            permission: sitePermissions.hostelApplicantMeritList,
            subPagesPermissions: [sitePermissions.hostelApplicantMeritList],
          },
          {
            title: "Master Records",
            subDeepMenus: [
              {
                title: "Hostel Management",
                path: siteRoutes.hostelListing,
                active: splitPathBySlash(siteRoutes.hostelListing)[3],
                permission: sitePermissions.hostelListing,
                subPagesPermissions: [
                  sitePermissions.hostelListing,
                  sitePermissions.createHostel,
                ],
              },
              {
                title: "Hostel Room Management",
                path: siteRoutes.hostelRoomsListing,
                active: splitPathBySlash(siteRoutes.hostelRoomsListing)[3],
                permission: sitePermissions.hostelRoomsListing,
                subPagesPermissions: [
                  sitePermissions.hostelRoomsListing,
                  sitePermissions.createHostelRoom,
                ],
              },
            ],
          },
          {
            title: "Finance Management",
            path: siteRoutes.hostelFinanceManagement,
            active: splitPathBySlash(siteRoutes.hostelFinanceManagement)[3],
            permission: sitePermissions.hostelFinanceManagement,
            subPagesPermissions: [sitePermissions.hostelFinanceManagement],
          },
        ],
        icon: GraduationSvg,
        isDropdownOpen: false,
      },
      {
        title: "HR Management",
        path: splitPathBySlash(siteRoutes.hrManagementLeaveDashboard)[2],
        subMenus: [
          {
            title: "Remuneration",
            path: siteRoutes.remunerationListing,
            active: splitPathBySlash(siteRoutes.remunerationListing)[3],
            permission: sitePermissions.remunerationListing,
            subPagesPermissions: [sitePermissions.remunerationListing],
          },
          {
            title: "Overtime Manual Entry",
            path: siteRoutes.overtimeManualEntryListing,
            active: splitPathBySlash(siteRoutes.overtimeManualEntryListing)[3],
            permission: sitePermissions.overtimeManualEntryListing,
            subPagesPermissions: [sitePermissions.overtimeManualEntryListing],
          },
          {
            title: "Employees Overtime",
            path: siteRoutes.employeesOvertimeListing,
            active: splitPathBySlash(siteRoutes.employeesOvertimeListing)[3],
            permission: sitePermissions.employeesOvertimeListing,
            subPagesPermissions: [sitePermissions.employeesOvertimeListing],
          },
          {
            title: "Teachers",
            path: siteRoutes.teachersListing,
            active: splitPathBySlash(siteRoutes.teachersListing)[3],
            permission: sitePermissions.teachersListing,
            subPagesPermissions: [sitePermissions.teachersListing],
          },
          {
            title: "Employees",
            path: siteRoutes.employeesListing,
            active: splitPathBySlash(siteRoutes.employeesListing)[3],
            permission: sitePermissions.employeesListing,
            subPagesPermissions: [sitePermissions.employeesListing],
          },
          {
            title: "Requested Leaves",
            path: siteRoutes.requestedLeavesListing,
            active: splitPathBySlash(siteRoutes.requestedLeavesListing)[3],
            permission: sitePermissions.requestedLeavesListing,
            subPagesPermissions: [sitePermissions.requestedLeavesListing],
          },
          {
            title: "My Attendance",
            path: siteRoutes.myAttendanceList,
            active: splitPathBySlash(siteRoutes.myAttendanceList)[3],
            permission: sitePermissions.myAttendanceList,
            subPagesPermissions: [sitePermissions.myAttendanceList],
          },
          {
            title: "Leave Types",
            path: siteRoutes.leaveTypeListing,
            active: splitPathBySlash(siteRoutes.leaveTypeListing)[3],
            permission: sitePermissions.leaveTypeListing,
            subPagesPermissions: [
              sitePermissions.leaveTypeListing,
              sitePermissions.createLeaveTypes,
            ],
          },
          {
            title: "Set Off Days",
            path: siteRoutes.setOffDays,
            active: splitPathBySlash(siteRoutes.setOffDays)[3],
            permission: sitePermissions.setOffDays,
            subPagesPermissions: [sitePermissions.setOffDays],
          },
          {
            title: "Request Details",
            path: siteRoutes.requestDetails,
            active: splitPathBySlash(siteRoutes.requestDetails)[3],
            permission: sitePermissions.requestDetails,
            subPagesPermissions: [sitePermissions.requestDetails],
          },
          {
            title: "Set Holidays",
            path: siteRoutes.setHolidaysListing,
            active: splitPathBySlash(siteRoutes.setHolidaysListing)[3],
            permission: sitePermissions.setHolidaysListing,
            subPagesPermissions: [
              sitePermissions.setHolidaysListing,
              sitePermissions.createSetHolidays,
            ],
          },
          {
            title: "Leave Dashboard",
            path: siteRoutes.hrManagementLeaveDashboard,
            active: splitPathBySlash(siteRoutes.hrManagementLeaveDashboard)[3],
            permission: sitePermissions.hrManagementLeaveDashboard,
            subPagesPermissions: [sitePermissions.hrManagementLeaveDashboard],
          },
          {
            title: "Overtime Slots",
            path: siteRoutes.overtimeSlotsListing,
            active: splitPathBySlash(siteRoutes.overtimeSlotsListing)[3],
            permission: sitePermissions.overtimeSlotsListing,
            subPagesPermissions: [
              sitePermissions.overtimeSlotsListing,
              sitePermissions.createOvertimeSlot,
            ],
          },
          {
            title: "Absent Employees",
            path: siteRoutes.absentEmployeesListing,
            active: splitPathBySlash(siteRoutes.absentEmployeesListing)[3],
            permission: sitePermissions.absentEmployeesListing,
            subPagesPermissions: [sitePermissions.absentEmployeesListing],
          },
          {
            title: "Leave Cancellation",
            path: siteRoutes.leaveCancellationListing,
            active: splitPathBySlash(siteRoutes.leaveCancellationListing)[3],
            permission: sitePermissions.leaveCancellationListing,
            subPagesPermissions: [sitePermissions.leaveCancellationListing],
          },
          {
            title: "Release Balance",
            path: siteRoutes.releasedLeaveBalancingReportListing,
            active: splitPathBySlash(
              siteRoutes.releasedLeaveBalancingReportListing
            )[3],
            permission: sitePermissions.releasedLeaveBalancingReportListing,
            subPagesPermissions: [
              sitePermissions.releasedLeaveBalancingReportListing,
            ],
          },
        ],
        icon: HRMangementSvg,
        isDropdownOpen: false,
      },
      {
        title: "Infrastructure & Asset Management",
        path: splitPathBySlash(siteRoutes.assetsListing)[2],
        subMenus: [
          {
            title: "Asset Management",
            path: siteRoutes.assetsListing,
            active: "assets-management",
            permission: sitePermissions.assetListing,
            subPagesPermissions: [
              sitePermissions.assetListing,
              sitePermissions.createAsset,
            ],
          },
          {
            title: "Building Strucuture",
            path: siteRoutes.buildingStructureListing,
            active: "buildings-structure",
            permission: sitePermissions.buildingStructureListing,
            subPagesPermissions: [
              sitePermissions.buildingStructureListing,
              sitePermissions.createBuildingStructure,
            ],
          },
          {
            title: "Building Managament",
            path: siteRoutes.buildingListing,
            active: "buildings-management",
            permission: sitePermissions.buildingListing,
            subPagesPermissions: [
              sitePermissions.buildingListing,
              sitePermissions.createBuilding,
            ],
          },
        ],
        icon: AdministrationSvg,
        isDropdownOpen: false,
      },
      {
        title: "Library",
        path: splitPathBySlash(siteRoutes.libraryLanguagelist)[2],
        subMenus: [
          {
            title: "Languages",
            path: siteRoutes.libraryLanguagelist,
            active: splitPathBySlash(siteRoutes.libraryLanguagelist)[3],
            permission: sitePermissions.libraryLanguagelist,
            subPagesPermissions: [sitePermissions.libraryLanguagelist],
          },
          {
            title: "Publishers",
            path: siteRoutes.libraryPublisherlist,
            active: splitPathBySlash(siteRoutes.libraryPublisherlist)[3],
            permission: sitePermissions.libraryPublisherlist,
            subPagesPermissions: [sitePermissions.libraryPublisherlist],
          },
          {
            title: "Sellers",
            path: siteRoutes.librarySellerlist,
            active: splitPathBySlash(siteRoutes.librarySellerlist)[3],
            permission: sitePermissions.librarySellerlist,
            subPagesPermissions: [sitePermissions.librarySellerlist],
          },
          {
            title: "Bills",
            path: siteRoutes.libraryBillslist,
            active: splitPathBySlash(siteRoutes.libraryBillslist)[3],
            permission: sitePermissions.libraryBillslist,
            subPagesPermissions: [sitePermissions.libraryBillslist],
          },
          {
            title: "Books",
            path: siteRoutes.libraryBookslist,
            active: splitPathBySlash(siteRoutes.libraryBookslist)[3],
            permission: sitePermissions.libraryBookslist,
            subPagesPermissions: [sitePermissions.libraryBookslist],
          },
          {
            title: "Accession Register Listing",
            path: siteRoutes.libraryAccessRegisterlist,
            active: splitPathBySlash(siteRoutes.libraryAccessRegisterlist)[3],
            permission: sitePermissions.libraryAccessRegisterlist,
            subPagesPermissions: [sitePermissions.libraryAccessRegisterlist],
          },
        ],
        icon: LibrarySvg,
        isDropdownOpen: false,
      },
      {
        title: "Manage Legal Matters",
        path: splitPathBySlash(siteRoutes.legalFrameworkList)[2],
        subMenus: [
          {
            title: "Legal Cases",
            path: siteRoutes.legalCasesList,
            active: splitPathBySlash(siteRoutes.legalCasesList)[3],
            permission: sitePermissions.legalCasesList,
            subPagesPermissions: [sitePermissions.legalCasesList],
          },
          {
            title: "Frame Work Documents",
            path: siteRoutes.legalFrameworkList,
            active: splitPathBySlash(siteRoutes.legalFrameworkList)[3],
            permission: sitePermissions.legalFrameworkList,
            subPagesPermissions: [sitePermissions.legalFrameworkList],
          },
          {
            title: "Legal Advisors",
            path: siteRoutes.legalAdvisorListing,
            active: splitPathBySlash(siteRoutes.legalAdvisorListing)[3],
            permission: sitePermissions.legalAdvisorListing,
            subPagesPermissions: [sitePermissions.legalAdvisorListing],
          },
          {
            title: "Advisors Rates",
            path: siteRoutes.advisorRatesListing,
            active: splitPathBySlash(siteRoutes.advisorRatesListing)[3],
            permission: sitePermissions.advisorRatesListing,
            subPagesPermissions: [sitePermissions.advisorRatesListing],
          },
          {
            title: "Bill Request",
            path: siteRoutes.billRequestList,
            active: splitPathBySlash(siteRoutes.billRequestList)[3],
            permission: sitePermissions.billRequestList,
            subPagesPermissions: [sitePermissions.billRequestList],
          },
          {
            title: "Master Records",
            subDeepMenus: [
              {
                title: "Matter Types",
                path: siteRoutes.legalMatterTypeListing,
                active: splitPathBySlash(siteRoutes.legalMatterTypeListing)[3],
                permission: sitePermissions.legalMatterTypeListing,
                subPagesPermissions: [sitePermissions.legalMatterTypeListing],
              },
              {
                title: "Legal Status",
                path: siteRoutes.legalStatusList,
                active: splitPathBySlash(siteRoutes.legalStatusList)[3],
                permission: sitePermissions.legalStatusList,
                subPagesPermissions: [sitePermissions.legalStatusList],
              },
              {
                title: "Legal Courts",
                path: siteRoutes.legalCourtList,
                active: splitPathBySlash(siteRoutes.legalCourtList)[3],
                permission: sitePermissions.legalCourtList,
                subPagesPermissions: [sitePermissions.legalCourtList],
              },
              {
                title: "Documents Organization",
                path: siteRoutes.legalOrganizationList,
                active: splitPathBySlash(siteRoutes.legalOrganizationList)[3],
                permission: sitePermissions.legalOrganizationList,
                subPagesPermissions: [sitePermissions.legalOrganizationList],
              },
              {
                title: "Framework Document Types",
                path: siteRoutes.frameworkDocumentTypeList,
                active: splitPathBySlash(
                  siteRoutes.frameworkDocumentTypeList
                )[3],
                permission: sitePermissions.frameworkDocumentTypeList,
                subPagesPermissions: [
                  sitePermissions.frameworkDocumentTypeList,
                ],
              },
            ],
          },
        ],
        icon: LegalMatterSvg,
        isDropdownOpen: false,
      },
      {
        title: "ORIC",
        path: splitPathBySlash(siteRoutes.oricDashboard)[3],
        subMenus: [
          {
            title: "Dashboard",
            path: siteRoutes.oricDashboard,
            active: splitPathBySlash(siteRoutes.oricDashboard)[3],
            permission: sitePermissions.oricDashboard,
            subPagesPermissions: [sitePermissions.oricDashboard],
          },
          {
            title: "Communities",
            path: siteRoutes.oricCommunityListing,
            active: splitPathBySlash(siteRoutes.oricCommunityListing)[3],
            permission: sitePermissions.oricCommunityListing,
            subPagesPermissions: [sitePermissions.oricCommunityListing],
          },
          {
            title: "MoU Parties",
            path: siteRoutes.oricMouPartyListing,
            active: splitPathBySlash(siteRoutes.oricMouPartyListing)[3],
            permission: sitePermissions.oricMouPartyListing,
            subPagesPermissions: [sitePermissions.oricMouPartyListing],
          },
          {
            title: "MoU's",
            path: siteRoutes.oricMouListing,
            active: splitPathBySlash(siteRoutes.oricMouListing)[3],
            permission: sitePermissions.oricMouListing,
            subPagesPermissions: [sitePermissions.oricMouListing],
          },
          {
            title: "Manage Project Time",
            path: siteRoutes.oricProjectDateListing,
            active: splitPathBySlash(siteRoutes.oricProjectDateListing)[3],
            permission: sitePermissions.oricProjectDateListing,
            subPagesPermissions: [sitePermissions.oricProjectDateListing],
          },
          {
            title: "Project Management",
            path: siteRoutes.oricProjectlisting,
            active: splitPathBySlash(siteRoutes.oricProjectlisting)[3],
            permission: sitePermissions.oricProjectlisting,
            subPagesPermissions: [sitePermissions.oricProjectlisting],
          },
          {
            title: "Reasearch Grant Applications",
            path: siteRoutes.oricResearchGrantApplicationslisting,
            active: splitPathBySlash(
              siteRoutes.oricResearchGrantApplicationslisting
            )[3],
            permission: sitePermissions.oricResearchGrantApplicationslisting,
            subPagesPermissions: [
              sitePermissions.oricResearchGrantApplicationslisting,
            ],
          },
          {
            title: "Reasearch Projects",
            path: siteRoutes.oricResearchProjectListing,
            active: splitPathBySlash(siteRoutes.oricResearchProjectListing)[3],
            permission: sitePermissions.oricResearchProjectListing,
            subPagesPermissions: [sitePermissions.oricResearchProjectListing],
          },
          {
            title: "Major Subjects",
            path: siteRoutes.oricMajorSubjectsListing,
            active: splitPathBySlash(siteRoutes.oricMajorSubjectsListing)[3],
            permission: sitePermissions.oricMajorSubjectsListing,
            subPagesPermissions: [sitePermissions.oricMajorSubjectsListing],
          },
          {
            title: "Event Types",
            path: siteRoutes.oricEventTypesListing,
            active: splitPathBySlash(siteRoutes.oricEventTypesListing)[3],
            permission: sitePermissions.oricEventTypesListing,
            subPagesPermissions: [sitePermissions.oricEventTypesListing],
          },
          {
            title: "Conference's",
            path: siteRoutes.oricConferenceListing,
            active: splitPathBySlash(siteRoutes.oricConferenceListing)[3],
            permission: sitePermissions.oricConferenceListing,
            subPagesPermissions: [sitePermissions.oricConferenceListing],
          },
          {
            title: "Calls",
            path: siteRoutes.oricCallListing,
            active: splitPathBySlash(siteRoutes.oricCallListing)[3],
            permission: sitePermissions.oricCallListing,
            subPagesPermissions: [sitePermissions.oricCallListing],
          },
          {
            title: "Career Development",
            path: siteRoutes.oricCareerDevelopmentListing,
            active: splitPathBySlash(
              siteRoutes.oricCareerDevelopmentListing
            )[3],
            permission: sitePermissions.oricCareerDevelopmentListing,
            subPagesPermissions: [sitePermissions.oricCareerDevelopmentListing],
          },
          {
            title: "Key Performance Indicators",
            path: siteRoutes.keyPerformanceIndicatorYearListing,
            active: splitPathBySlash(
              siteRoutes.keyPerformanceIndicatorYearListing
            )[3],
            permission: sitePermissions.keyPerformanceIndicatorYearListing,
            subPagesPermissions: [
              sitePermissions.keyPerformanceIndicatorYearListing,
            ],
          },
          {
            title: "Research Incentive",
            path: siteRoutes.researchIncentivesListing,
            active: splitPathBySlash(siteRoutes.researchIncentivesListing)[3],
            permission: sitePermissions.researchIncentivesListing,
            subPagesPermissions: [sitePermissions.researchIncentivesListing],
          },
        ],
        icon: HomeSvg,
        isDropdownOpen: false,
      },
      {
        title: "QEC",
        path: splitPathBySlash(siteRoutes.reportCategoriesList)[2],
        subMenus: [
          {
            title: "Survey Reports",
            path: siteRoutes.qecSurveyReportlist,
            active: splitPathBySlash(siteRoutes.qecSurveyReportlist)[3],
            permission: sitePermissions.qecSurveyReportlist,
            subPagesPermissions: [sitePermissions.qecSurveyReportlist],
          },
          {
            title: "Report Categories",
            path: siteRoutes.reportCategoriesList,
            active: splitPathBySlash(siteRoutes.reportCategoriesList)[3],
            permission: sitePermissions.reportCategoriesList,
            subPagesPermissions: [sitePermissions.reportCategoriesList],
          },
          {
            title: "Manage Reports",
            path: siteRoutes.qecReportManagelist,
            active: splitPathBySlash(siteRoutes.qecReportManagelist)[3],
            permission: sitePermissions.qecReportManagelist,
            subPagesPermissions: [sitePermissions.qecReportManagelist],
          },
          {
            title: "Survey",
            path: siteRoutes.surveyListing,
            active: splitPathBySlash(siteRoutes.surveyListing)[3],
            permission: sitePermissions.surveyListing,
            subPagesPermissions: [sitePermissions.surveyListing],
          },

          {
            title: "Survey Types",
            path: siteRoutes.surveyTypeListing,
            active: splitPathBySlash(siteRoutes.surveyTypeListing)[3],
            permission: sitePermissions.surveyListing,
            subPagesPermissions: [sitePermissions.surveyTypeListing],
          },
          {
            title: "Question List",
            path: siteRoutes.questionListing,
            active: splitPathBySlash(siteRoutes.questionListing)[3],
            permission: sitePermissions.questionListing,
            subPagesPermissions: [sitePermissions.questionListing],
          },
        ],
        icon: QECSvg,
        isDropdownOpen: false,
      },
      {
        title: "Email Templates",
        path: splitPathBySlash(siteRoutes.emailTemplateListing)[2],
        subMenus: [
          {
            title: "Email Templates",
            path: siteRoutes.emailTemplateListing,
            active: splitPathBySlash(siteRoutes.emailTemplateListing)[3],
            permission: sitePermissions.emailTemplateListing,
            subPagesPermissions: [sitePermissions.emailTemplateListing],
          },
        ],
        icon: LegalMatterSvg,
        isDropdownOpen: false,
      },
      {
        title: "Eportal Home",
        path: splitPathBySlash(siteRoutes.ePortalDashboard)[3],
        subMenus: [],
        icon: HomeSvg,
        link: siteRoutes.ePortalDashboard,
        isDropdownOpen: false,
        permission: sitePermissions.ePortalDashboard,
      },
      // {
      //   title: "My Profile",
      //   path: splitPathBySlash(siteRoutes.viewEportalProfile)[3],
      //   subMenus: [],
      //   icon: ProfileSvg,
      //   link: siteRoutes.viewEportalProfile,
      //   isDropdownOpen: false,
      //   permission: sitePermissions.viewEportalProfile,
      // },
      // {
      //   title: "Generate Challan",
      //   path: splitPathBySlash(siteRoutes.ePortalChallanListing)[3],
      //   subMenus: [],
      //   icon: ChallanSvg,
      //   link: siteRoutes.ePortalChallanListing,
      //   permission: sitePermissions.ePortalChallanListing,
      //   isDropdownOpen: false,
      // },
      {
        title: "Admissions",
        path: splitPathBySlash(siteRoutes.eportalAdmissionsListing)[3],
        subMenus: [],
        icon: AdmissionSvg,
        link: siteRoutes.ePortalEditProfile,
        permission: sitePermissions.eportalAdmissionsListing,
        isDropdownOpen: false,
      },
      {
        title: "Careers",
        path: splitPathBySlash(siteRoutes.eportalCareersListing)[3],

        subMenus: [
          {
            title: "Profile",
            path: siteRoutes.eportalCarrerProfile,
            active: splitPathBySlash(siteRoutes.eportalCarrerProfile)[4],
            permission: sitePermissions.eportalCarrerProfile,
            subPagesPermissions: [
              sitePermissions.eportalCarrerProfile,
              sitePermissions.eportalCarrerProfile,
            ],
            // notAvailable: true,
          },
          {
            title: "Applied Jobs",
            path: siteRoutes.eportalAppliedJobListing,
            active: splitPathBySlash(siteRoutes.eportalAppliedJobListing)[4],
            permission: sitePermissions.eportalAppliedJobListing,
            subPagesPermissions: [
              sitePermissions.eportalAppliedJobListing,
              sitePermissions.eportalAppliedJobListing,
            ],
            // notAvailable: true,
          },
          {
            title: "Jobs",
            path: siteRoutes.eportalCareersListing,
            active: splitPathBySlash(siteRoutes.eportalCareersListing)[4],
            permission: sitePermissions.eportalCareersListing,
            subPagesPermissions: [
              sitePermissions.eportalCareersListing,
              sitePermissions.createEportalCareer,
            ],
            // notAvailable: true,
          },
        ],
        icon: CareerSvg,
        isDropdownOpen: false,
      },
      {
        title: "Short Courses",
        path: splitPathBySlash(siteRoutes.ePortalShortCoursesListing)[3],
        subMenus: [],
        icon: SideShortCoursesSvg,
        link: siteRoutes.ePortalShortCoursesListing,
        permission: sitePermissions.ePortalShortCoursesListing,
        isDropdownOpen: false,
        notAvailable: true,
      },
      {
        title: "My Vouchers",
        path: splitPathBySlash(siteRoutes.ePortalMyVouchers)[3],
        subMenus: [],
        icon: VoucherSvg,
        link: siteRoutes.ePortalMyVouchers,
        permission: sitePermissions.ePortalMyVouchers,
        isDropdownOpen: false,
      },

      // eportal-career
      // {
      //   title: "Testing Services",
      //   path: splitPathBySlash(siteRoutes.eportalTestingServiceListing)[3],
      //   subMenus: [],
      //   icon: TestingSvg,
      //   link: siteRoutes.eportalTestingServiceListing,
      //   permission: sitePermissions.eportalTestingServiceListing,
      //   isDropdownOpen: false,
      // },
      // {
      //   title: "Hostel",
      //   path: splitPathBySlash(siteRoutes.eportalHostelPortal)[3],
      //   subMenus: [],
      //   icon: HostelSvg,
      //   link: siteRoutes.eportalHostelPortal,
      //   permission: sitePermissions.eportalHostelPortal,
      //   isDropdownOpen: false,
      // },

      {
        title: "Manage Merit List",
        path: "/manage-merit-list",
        subMenus: [],
        icon: MeritListSvg,
        isDropdownOpen: false,
      },
      {
        title: "Jobs Portal",
        path: "/manage-merit-list",
        subMenus: [],
        icon: JobsSvg,
        isDropdownOpen: false,
      },
      {
        title: "Short Courses",
        path: "/short-courses",
        subMenus: [],
        icon: CoursesSvg,
        isDropdownOpen: false,
      },
      {
        title: "My Privileges",
        path: "/my-privileges",
        subMenus: [],
        icon: PrivilegesSvg,
        isDropdownOpen: false,
      },
      {
        title: "Setting",
        path: "/settings",
        subMenus: [],
        icon: SettingsSolidSvg,
        isDropdownOpen: false,
      },
      {
        title: "System Administration",
        path: splitPathBySlash(siteRoutes.systemLogsListing)[2],
        subMenus: [
          {
            title: "Organization Settings",
            path: siteRoutes.organizationSettingCreate,
            active: splitPathBySlash(siteRoutes.organizationSettingCreate)[3],
            permission: sitePermissions.organizationSettingView,
            // subPagesPermissions: [sitePermissions.organizationSettingView],
          },
          {
            title: "Logs",
            path: siteRoutes.systemLogsListing,
            active: splitPathBySlash(siteRoutes.systemLogsListing)[3],
            permission: sitePermissions.systemLogsListing,
            subPagesPermissions: [sitePermissions.systemLogsListing],
          },
          {
            title: "Users",
            path: siteRoutes.systemUsersListing,
            active: splitPathBySlash(siteRoutes.systemUsersListing)[3],
            permission: sitePermissions.systemUsersListing,
            subPagesPermissions: [sitePermissions.systemUsersListing],
          },
          {
            title: "Role Management",
            path: siteRoutes.roleManagementListing,
            active: splitPathBySlash(siteRoutes.roleManagementListing)[3],
            permission: sitePermissions.roleManagementListing,
            subPagesPermissions: [sitePermissions.roleManagementListing],
          },
          {
            title: "User Audit",
            path: siteRoutes.userAuditListing,
            active: splitPathBySlash(siteRoutes.userAuditListing)[3],
            permission: sitePermissions.userAuditListing,
            subPagesPermissions: [sitePermissions.userAuditListing],
          },
          {
            title: "WorkFlows",
            path: siteRoutes.workflowListing,
            active: splitPathBySlash(siteRoutes.workflowListing)[3],
            permission: sitePermissions.workflowListing,
            subPagesPermissions: [sitePermissions.workflowListing],
          },
        ],
        icon: AdministrationSvg,
        isDropdownOpen: false,
      },
    ];
  }, []);

  const getCurrentMenu = (): string => {
    const splittedPath: string[] = splitPathBySlash(pathname);
    const currentMenu = sidebarMenus.find(
      (menu) => menu.path === splittedPath[2]
    );
    return currentMenu?.subMenus?.length ? splittedPath[2] : splittedPath[3];
  };

  const getCurrentSubMenu = (): string => {
    const subModuledRoutes: string[] = [
      siteRoutes.eportalCarrerProfile,
      siteRoutes.eportalAppliedJobListing,
      siteRoutes.eportalCareersListing,
      siteRoutes.studentPortalDocumentsListing,
      siteRoutes.studentPortalProfile,
    ];
    const splittedPath: string[] = splitPathBySlash(pathname);
    if (subModuledRoutes.includes(pathname)) {
      return splittedPath[4];
    } else {
      return splittedPath[3];
    }
  };
  const [currentMenu, setCurrentMenu] = useState(getCurrentMenu());
  const [currentSubMenu, setCurrentSubMenu] = useState(pathname);
  const navigate = useNavigate();

  const handleMainMenu = (menu: any, index: number) => {
    if (menu?.link) {
      if (!menu?.notAvailable) {
        if (menu?.link === siteRoutes.ePortalEditProfile) {
          if (user?.is_already_apply) {
            navigate(siteRoutes.eportalAdmissionsListing);
          } else {
            navigate(siteRoutes.ePortalEditProfile);
          }
        } else {
          navigate(menu.link);
        }
        if (isCurrentWidthMinimum()) {
          setOpenSidebar(false);
        }
      } else {
        infoPopup("This feature is currently not available");
      }
    }
    const { isDropdownOpen } = menu;
    menus.forEach((menu: any) => {
      menu.isDropdownOpen = false;
    });
    if (!isDropdownOpen) {
      menus[index].isDropdownOpen = true;
      if (menu?.subMenus?.length) setOpenSidebar(true);
    }
    setMenus([...menus]);
  };
  const handleSubmenu = (subMenu: any) => {
    const { path, notAvailable = false, subDeepMenus = [] } = subMenu;
    if (!notAvailable) {
      if (subDeepMenus.length) {
        subMenu.isDropdownOpen = !subMenu.isDropdownOpen;
        setMenus([...menus]);
      } else {
        if (isCurrentWidthMinimum()) {
          setOpenSidebar(false);
        }
        navigate(path);
      }
    } else {
      infoPopup("This feature is currently not available");
    }
  };

  useEffect(() => {
    setCurrentMenu(getCurrentMenu());
    setCurrentSubMenu(getCurrentSubMenu());
  }, [pathname]);

  const isSubMenuActive = (subMenu: any): boolean => {
    const { subDeepMenus = [], active = "" } = subMenu;
    let isActive: boolean = false;
    if (subDeepMenus.length) {
      isActive = subDeepMenus.some(
        (subDeepMenu: any) => subDeepMenu.active === currentSubMenu
      );
    } else {
      isActive = active === currentSubMenu;
    }

    return isActive;
  };

  const setSidebarMenus = () => {
    let menus: any[] = [];
    sidebarMenus.forEach((menu: any) => {
      const { subMenus = [], permission = "" } = menu;
      let permittedMenu: any = { ...menu, subMenus: [] };
      if (subMenus.length) {
        subMenus.forEach((subMenu: any) => {
          const { subDeepMenus = [], permission = "" } = subMenu;
          if (subDeepMenus.length) {
            const subDeepMenuWithPermissions = subDeepMenus.filter(
              (subDeepMenu: any) => hasAccess(subDeepMenu.permission)
            );
            if (subDeepMenuWithPermissions.length) {
              subMenu.subDeepMenus = subDeepMenuWithPermissions;
              permittedMenu.subMenus.push(subMenu);
            }
          } else {
            if (hasAccess(permission)) {
              permittedMenu.subMenus.push(subMenu);
            }
          }
        });
        if (permittedMenu.subMenus.length) {
          menus.push(permittedMenu);
        }
      } else {
        if (hasAccess(permission)) {
          menus.push(permittedMenu);
        }
      }
    });
    return menus;
  };

  const [menus, setMenus] = useState<any[]>([]);
  const [allPermittedMenus, setAllPermittedMenus] = useState<any[]>([]);

  useEffect(() => {
    const menus = setSidebarMenus();
    setMenus([...menus]);
    setAllPermittedMenus([...menus]);
  }, [loginAsToken]);

  const showIconedMenu = (): boolean => {
    return !openSidebar && window.innerWidth > 1279;
  };

  const goToHome = () => {
    if (role === ROLES.SUPER_ADMIN) {
      navigate(siteRoutes.superAdminDashboard);
    } else if (role === ROLES.ADMIN) {
      navigate(siteRoutes.adminDashboard);
    } else {
      navigate(siteRoutes.ePortalDashboard);
    }
  };

  const handleSearch = (event: any) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
    if (!value) setMenus([...allPermittedMenus]);
  };

  const setSearchedMenus = (search: string) => {
    let searchedMenus: any[] = [];
    allPermittedMenus.forEach((menu: any) => {
      const { subMenus = [], title = "" } = menu;
      let permittedMenu: any = { ...menu, subMenus: [] };
      if (title.toLowerCase().includes(search.toLowerCase())) {
        searchedMenus.push(menu);
      } else if (subMenus.length) {
        subMenus.forEach((subMenu: any) => {
          const { title, subDeepMenus = [] } = subMenu;
          if (title.toLowerCase().includes(search.toLowerCase())) {
            permittedMenu.subMenus.push(subMenu);
          } else if (subDeepMenus.length) {
            const subDeepMenuWithPermissions = subDeepMenus.filter(
              ({ title }) => title.toLowerCase().includes(search.toLowerCase())
            );
            if (subDeepMenuWithPermissions.length) {
              subMenu.subDeepMenus = subDeepMenuWithPermissions;
              permittedMenu.subMenus.push(subMenu);
            }
          }
        });
        if (permittedMenu.subMenus.length) {
          searchedMenus.push(permittedMenu);
        }
      }
    });
    return searchedMenus;
  };

  useEffect(() => {
    if (debounceSearch) {
      setMenus(setSearchedMenus(debounceSearch));
    }
  }, [debounceSearch]);

  return (
    <SidebarMain $showIconedMenu={showIconedMenu()}>
      {!organization?.logo ? (
        showIconedMenu() ? (
          <ShortLogo onClick={goToHome}>
            <CyfyFaviconSvg />
          </ShortLogo>
        ) : (
          <SiteLogo onClick={goToHome}>
            {isDarkTheme ? (
              <CyfyLogoDark className="icon" />
            ) : (
              <CyfyLogoSvg className="icon" />
            )}
          </SiteLogo>
        )
      ) : showIconedMenu() ? (
        <ShortLogo onClick={goToHome}>
          <img
            src={isDarkTheme ? organization?.logo : organization?.dark_logo}
            alt=""
          />
        </ShortLogo>
      ) : (
        <SiteLogo onClick={goToHome}>
          <div className="org-logo">
            <img
              src={
                isDarkTheme
                  ? organization?.sidebar_light_logo
                  : organization?.sidebar_dark_logo
              }
              alt=""
            />
          </div>
        </SiteLogo>
      )}
      <Menus
        className="p-custom-scrollbar-4"
        $showIconedMenu={showIconedMenu()}
      >
        {!showIconedMenu() && (
          <SearchWrapper>
            <div className="input-field">
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="search"
                    placeholder="Search..."
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </SearchWrapper>
        )}
        {menus.map((item: any, menuIndex: number) => {
          const MenuIcon = item.icon;
          return showIconedMenu() ? (
            <IconedMenu
              key={menuIndex}
              $active={currentMenu === item.path}
              onClick={() => handleMainMenu(item, menuIndex)}
            >
              <div className="menu-icon">
                <MenuIcon />
              </div>
            </IconedMenu>
          ) : (
            <div className="particular-menu" key={menuIndex}>
              <MenuItem
                $active={currentMenu === item.path}
                onClick={() => handleMainMenu(item, menuIndex)}
                $isDropdownOpen={item.isDropdownOpen}
                $isSidebarOpened={openSidebar}
              >
                <div className="link-wrapper">
                  <div className="link">
                    <span className="menu-icon">
                      <MenuIcon className="icon" />
                    </span>
                    <span className="menu-text">{item.title}</span>
                  </div>
                  <span className="dropdown-icon">
                    {item.subMenus.length ? (
                      <DownArrowLightgrayMediumSvg className="icon" />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </MenuItem>
              <DropdownMenu $show={item.isDropdownOpen && item.subMenus.length}>
                {item.subMenus.map((subMenu: any, subMenuIndex: number) => {
                  const { subDeepMenus = [] } = subMenu;
                  return (
                    <ParticularDropdownMenu key={subMenuIndex}>
                      <DropdownMenuItem
                        $active={isSubMenuActive(subMenu)}
                        onClick={() => handleSubmenu(subMenu)}
                        $isDropdownOpen={
                          subMenu?.isDropdownOpen && subDeepMenus.length
                        }
                      >
                        <span className="menu-text">{subMenu.title}</span>
                        <div className="dropdown-icon">
                          {subDeepMenus.length ? (
                            <DownArrowLightgrayMediumSvg />
                          ) : (
                            ""
                          )}
                        </div>
                      </DropdownMenuItem>
                      <SubDeepMenuDropdown
                        $show={subMenu?.isDropdownOpen && subDeepMenus?.length}
                      >
                        {subDeepMenus.map(
                          (subDeepMenu: any, subDeepMenuIndex: number) => {
                            return (
                              <DropdownMenuItem
                                $active={isSubMenuActive(subDeepMenu)}
                                key={subDeepMenuIndex}
                                $isDeepMenu={true}
                                onClick={() => handleSubmenu(subDeepMenu)}
                              >
                                <span className="menu-text">
                                  {subDeepMenu.title}
                                </span>
                              </DropdownMenuItem>
                            );
                          }
                        )}
                      </SubDeepMenuDropdown>
                    </ParticularDropdownMenu>
                  );
                })}
              </DropdownMenu>
            </div>
          );
        })}
      </Menus>
    </SidebarMain>
  );
};

export default Sidebar;
