export class AdviserRatesDTO {
  id!: number;
  title: string = "";
  description: string = "";
  rate: number;
  year: number;
  legal_advisor_category: string = "";
  is_active: number;
  constructor(data = {}) {
    Object.assign(this, data);
  }
}
