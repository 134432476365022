import { FC, Fragment, useEffect, useState } from "react";
import {
  AcadDashboardMain,
  AcadDashboardSection,
  AcadDashboardTop,
  FilterHeader,
  Filters,
  FilterSection,
  EnrolledSection,
  CourseSection,
  AnomiliesSection,
  AcademicDataSection,
  EnrolledCourses,
  SemesterData,
  NotEnrolledSection,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { DownArrowLightgrayMediumSvg } from "assets/images/common/svgs";
import useDSSystem from "../useHook";
import { AcademicSessionDTO } from "utils/helpers/models/academics/academic-session.dto";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import useAcademics from "containers/private/academics/useHooks";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { EnrolledStudentsDTO } from "utils/helpers/models/ds-system/enrolled-students.dto";
import {
  AcademicDashboardGradebookDataDTO,
  CourseAnomaliesDTO,
} from "utils/helpers/models/ds-system/course-anomalies.dto";
import { StudentAnomaliesDTO } from "utils/helpers/models/ds-system/student-anomalies.dto";
import { AcademicsDataDTO } from "utils/helpers/models/ds-system/academics-data.dto";
import { EnrolledCourseTeachersDTO } from "utils/helpers/models/ds-system/enrolled-courses-teachers.dto";
import useAdmissions from "containers/private/admissions/useHooks";

interface Props {}

const AcademicsDashboard: FC<Props> = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Decision Support System /", path: "" },

    { title: "Academics Dashboard", path: siteRoutes.academicsDashboard },
  ];

  const {
    getCourseAnomalies,
    getStudentAnomalies,
    getAcademicsData,
    getEnrolledCoursesAndTeachers,
    getEnrolledStudents,
    getAcademicDashboardGradebookData,
  } = useDSSystem();

  const { getAcademicSessions } = useAcademics();

  const [gradeBookData, setGradeBookData] =
    useState<AcademicDashboardGradebookDataDTO>(null);
  const [isFilterOpen, setIsFilterFilter] = useState<boolean>(true);
  const [enStudentOpen, setEnStudentOpen] = useState<boolean>(true);
  const [isCAOpen, setIsCAOpen] = useState<boolean>(true);
  const [isSAOpen, setIsSAOpen] = useState<boolean>(true);
  const [isAcadDataOpen, setIsAcadDataOpen] = useState<boolean>(true);
  const [isCoursesOpen, setIsCoursesOpen] = useState<boolean>(true);
  const [isSRDOpen, setIsSRDOpen] = useState<boolean>(true);
  const [enrolledStudents, setEnrolledStudents] = useState<EnrolledStudentsDTO>(
    new EnrolledStudentsDTO()
  );
  const [courseAnomalies, setCourseAnomalies] = useState<CourseAnomaliesDTO>(
    new CourseAnomaliesDTO()
  );
  const [studentAnomalies, setStudentAnomalies] = useState<StudentAnomaliesDTO>(
    new StudentAnomaliesDTO()
  );
  const [academicsData, setAcademicsData] = useState<AcademicsDataDTO>(
    new AcademicsDataDTO()
  );
  const [enrolledCoursesTeachers, setEnrolledCourseTeachers] =
    useState<EnrolledCourseTeachersDTO>(new EnrolledCourseTeachersDTO());
  const [filters, setFilters] = useState<any>({
    academic_session_id: "",
    campus_id: "",
    department_id: "",
  });
  const [sessions, setSessions] = useState<AcademicSessionDTO[]>([]);
  const [campuses, setCampuses] = useState<OrgStructureDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);

  const { getSpecificOrgStructures } = useOrganization();
  const { getAdmissionSessions } = useAdmissions();

  const toggleFilter = () => {
    setIsFilterFilter((prevState) => !prevState);
  };
  const toggleEnStudentOpen = () => {
    setEnStudentOpen((prevState) => !prevState);
  };

  const toggleCAOpen = () => {
    setIsCAOpen((prevState) => !prevState);
  };
  const toggleSAOpen = () => {
    setIsSAOpen((prevState) => !prevState);
  };
  const toggleAcadDataOpen = () => {
    setIsAcadDataOpen((prevState) => !prevState);
  };
  const toggleIsCoursesOpen = () => {
    setIsCoursesOpen((prevState) => !prevState);
  };
  const toggleIsSRDOpen = () => {
    setIsSRDOpen((prevState) => !prevState);
  };

  const getAllDashboardData = (filters: any) => {
    const filtersToSend = { ...filters };
    for (let key in filters) {
      if (!filters[key]) {
        delete filtersToSend[key];
      }
    }

    getAcademicDashboardGradebookData(setGradeBookData, filtersToSend);
    getCourseAnomalies(setCourseAnomalies, filtersToSend);
    getStudentAnomalies(setStudentAnomalies, filtersToSend);
    getEnrolledCoursesAndTeachers(setEnrolledCourseTeachers, filtersToSend);
    getEnrolledStudents(setEnrolledStudents, filtersToSend);
    getAcademicsData(setAcademicsData, filtersToSend);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    getAllDashboardData(filters);
  };

  const getSessionTitle = (): string => {
    if (sessions?.length) {
      let session: any;
      if (filters?.academic_session_id) {
        session = sessions.find((i) => i.id == filters.academic_session_id);
      }

      const sessionData = session?.session?.title;
      return sessionData;
    } else {
      return "";
    }
  };

  useEffect(() => {
    getAllDashboardData(filters);
    getAcademicSessions(setSessions);

    getSpecificOrgStructures(setCampuses, {
      type: OrgStructureTypes.campus,
      per_page: "All",
    });
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
  }, []);

  return (
    <AcadDashboardMain>
      <AcadDashboardTop>
        <div className="left">
          <span className="page-heading">Academics Dashboards</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </AcadDashboardTop>
      <AcadDashboardSection>
        <FilterSection className="content-radius-shadow">
          <FilterHeader $isFilterOpen={isFilterOpen}>
            <span className="filter-heading">Filter</span>
            <span className="dropdown-arrow cp" onClick={toggleFilter}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </FilterHeader>
          {isFilterOpen && (
            <Filters>
              <div className="filter-fields">
                <div className="input-field">
                  <label>Select Academic Session</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="academic_session_id"
                        value={filters.academic_session_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">-- Select --</option>
                        {sessions.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <option value={item?.id}>
                                {item?.type ?? "-"} {item?.year ?? "-"}
                              </option>
                            </Fragment>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Select Campus</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="campus_id"
                        value={filters.campus_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">-- Select --</option>
                        {campuses.map((campus, index) => {
                          return (
                            <option value={campus.id} key={index}>
                              {campus.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Select Department</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        name="department_id"
                        value={filters.department_id}
                        onChange={handleFilterChange}
                      >
                        <option value="">-- Select --</option>
                        {departments.map((dept, index) => {
                          return (
                            <option value={dept.id} key={index}>
                              {dept.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn gray"
                    onClick={resetFilters}
                  >
                    Reset
                  </button>
                  <button
                    className="lg-rounded-btn"
                    onClick={() => getAllDashboardData(filters)}
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </Filters>
          )}
        </FilterSection>
        <EnrolledSection
          className="content-radius-shadow"
          $EnStudentOpen={enStudentOpen}
        >
          <div className="link-heading">
            Students Status ({getSessionTitle()})
            <span className="dropdown-arrow cp" onClick={toggleEnStudentOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {enStudentOpen && (
            <div className="link-card-container">
              {[
                {
                  title: "Total Active Students",
                  color: "#0FCB0226",
                  value:
                    enrolledStudents?.total_active_enrolled_students
                      .total_count,
                },
                {
                  title: "Active Not Enrolled Students",
                  value:
                    enrolledStudents?.total_active_not_enrolled_students
                      .total_count,
                  color: "#ffbb3826",
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div className="link-card-text">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </EnrolledSection>

        <CourseSection className="content-radius-shadow" $isCAOpen={isCAOpen}>
          <div className="link-heading">
            Course Anomilies
            <span className="dropdown-arrow cp" onClick={toggleCAOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isCAOpen && (
            <div className="link-card-container">
              {[
                {
                  title: "Course Enrollments Not Assigned",
                  value: courseAnomalies.course_enrollemts_not_assigned,
                  color: "#ffbb3826",
                },
                {
                  title: "Enrollments not belong to Plan Of Study",
                  value: courseAnomalies.enrollments_not_belongsto_pos,
                  color: "#128BAB26",
                },
                {
                  title: "Duplicate Template Courses",
                  value: courseAnomalies.duplicate_template_courses,
                  color: "#0FCB0226",
                },
                {
                  title: "Course Teacher Not Assigned",
                  value: courseAnomalies.course_teachers_not_assigned,
                  color: "#ffbb3826",
                },
                {
                  title: "Course Sections Not Assigned",
                  value: courseAnomalies.course_sections_not_assigned,
                  color: "#0FCB0226",
                },
                {
                  title: "Template Courses which have multiple child courses",
                  value: 0,
                  color: "#ffbb3826",
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div className="link-card-text">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CourseSection>
        <AnomiliesSection
          className="content-radius-shadow"
          $isSAOpen={isSAOpen}
        >
          <div className="link-heading" onClick={toggleSAOpen}>
            Student Anomilies
            <span className="dropdown-arrow cp">
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isSAOpen && (
            <div className="link-card-container">
              {[
                {
                  title: "Campus Not Assigned",
                  value: studentAnomalies.campus_not_assigned,
                  color: "#4A3AFF1A",
                },
                {
                  title: "Department Not Assigned",
                  value: studentAnomalies.department_not_assigned,
                  color: "#128BAB26",
                },
                {
                  title: "Plan Of Study Not Assigned",
                  value: studentAnomalies.pos_not_assigned,
                  color: "#ffbb3826",
                },
                {
                  title: "Program Not Assigned",
                  value: studentAnomalies.program_not_assigned,
                  color: "#128BAB26",
                },
                {
                  title: "CNIC Not Provided",
                  value: studentAnomalies.cnic_not_provided,
                  color: "#ffbb3826",
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div className="link-card-text">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </AnomiliesSection>
        <AcademicDataSection
          className="content-radius-shadow"
          $isAcadDataOpen={isAcadDataOpen}
        >
          <div className="link-heading">
            Academic Data
            <span className="dropdown-arrow cp" onClick={toggleAcadDataOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isAcadDataOpen && (
            <div className="link-card-container">
              {[
                {
                  title: "Total Facilities",
                  value: academicsData.total_faculties,
                  color: "#ffbb3826",
                },
                {
                  title: "Total Department",
                  value: academicsData.total_departments,
                  color: "#0FCB0226",
                },
                {
                  title: "Total Programs",
                  value: academicsData.total_programs,
                  color: "#128BAB26",
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div className="link-card-text">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </AcademicDataSection>
        <EnrolledCourses
          className="content-radius-shadow"
          $isCoursesOpen={isCoursesOpen}
        >
          <div className="link-heading">
            Enrolled Courses & Teachers
            <span className="dropdown-arrow cp" onClick={toggleIsCoursesOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isCoursesOpen && (
            <div className="link-card-container">
              <div
                className="link-card2"
                style={{ backgroundColor: "#4A3AFF1A" }}
              >
                <div className="link-card-text">
                  <div className="link-title">
                    Total Courses:{" "}
                    {enrolledCoursesTeachers?.tatal_courses?.courses_count}
                  </div>
                  <div className="total-amount">
                    Credit hours:{" "}
                    {enrolledCoursesTeachers?.tatal_courses?.credit_hours_count}
                  </div>
                </div>
              </div>
              {[
                {
                  title: "Regular Workload Courses",
                  value:
                    enrolledCoursesTeachers.regular_workload_courses
                      .courses_count,
                  color: "#4A3AFF1A",
                },
                {
                  title: "Regular Part Time Courses",
                  value:
                    enrolledCoursesTeachers?.regular_part_time_courses
                      ?.courses_count,
                  color: "#0FCB0226",
                },
                {
                  title: "Non Teaching Courses",
                  value:
                    enrolledCoursesTeachers.non_teaching_courses.courses_count,
                  color: "#4A3AFF1A",
                },
                {
                  title: "Visiting Workload Courses",
                  value:
                    enrolledCoursesTeachers.visiting_workload_courses
                      .courses_count,
                  color: "#0FCB0226",
                },
                {
                  title: "Total Enrolled Teachers",
                  value: enrolledCoursesTeachers.total_enrolled_teachers,
                  color: "#0FCB0226",
                },
                {
                  title: "Regular Teachers ENROLLED",
                  value: enrolledCoursesTeachers.regular_teachers_enrolled,
                  color: "#4A3AFF1A",
                },
                {
                  title: "Regular Teachers NOT ENROLLED",
                  value: enrolledCoursesTeachers.regular_teachers_not_enrolled,
                  color: "#0FCB0226",
                },
                {
                  title: "Visiting Teachers Enrolled",
                  value: enrolledCoursesTeachers.visiting_teachers_enrolled,
                  color: "#0FCB0226",
                },
                {
                  title:
                    "Regular Teachers 0 Workload Enrolled in Part Time Course(s)",
                  value:
                    enrolledCoursesTeachers.regular_teachers_zero_workload_enrolled_in_part_time,
                  color: "#0FCB0226",
                },
                {
                  title:
                    "Course having less or equal to 5 students enrollments",
                  value:
                    enrolledCoursesTeachers.courses_having_Enrollments_less_then_five,
                  color: "#0FCB0226",
                },
                {
                  title:
                    "Courses having greater than 5 and less then or equal 10 students enrollments",
                  value:
                    enrolledCoursesTeachers.courses_having_Enrollments_gt_5_lt_10,
                  color: "#4A3AFF1A",
                },
                {
                  title:
                    "Courses having greater than 10 and less then or equal to 20 students enrollments",
                  value:
                    enrolledCoursesTeachers.courses_having_Enrollments_gt_10_lt_20,
                  color: "#0FCB0226",
                },
                {
                  title:
                    "Courses having greater than 20 and less then or equal to 30 students enrollments",
                  value:
                    enrolledCoursesTeachers.courses_having_Enrollments_gt_20_lt_30,
                  color: "#4A3AFF1A",
                },
                {
                  title:
                    "Courses having greater than 30 and less then or equal to 40 students enrollments",
                  value:
                    enrolledCoursesTeachers.courses_having_Enrollments_gt_30_lt_40,
                  color: "#0FCB0226",
                },
                {
                  title: "Courses having greater then 40 students enrollments",
                  value:
                    enrolledCoursesTeachers.courses_having_Enrollments_gt_40,
                  color: "#4A3AFF1A",
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div className="link-card-text">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </EnrolledCourses>

        <SemesterData className="content-radius-shadow" $isSRDOpen={isSRDOpen}>
          <div className="link-heading">
            Semester Result Data
            <span className="dropdown-arrow cp" onClick={toggleIsSRDOpen}>
              <DownArrowLightgrayMediumSvg />
            </span>
          </div>
          {isSRDOpen && (
            <div className="link-card-container">
              {[
                {
                  title: "Total Gradebook Submitted",
                  value: gradeBookData?.gradebooks_submitted ?? "--",
                  color: "#0FCB0226",
                },
                {
                  title: "Gradebooks Submitted but Not Verified",
                  value: gradeBookData?.gradebooks_submitted_not_verified,
                  color: "#4A3AFF1A",
                },
                {
                  title: "Gradebook Submitted and Verified ",
                  value: gradeBookData?.gradebooks_submitted_and_verified,
                  color: "#0FCB0226",
                },
                {
                  title: "Gradebook Recipt Acknowledge",
                  value: gradeBookData?.gradebooks_receipt_acknowledged ?? "-",
                  color: "#4A3AFF1A",
                },
                {
                  title: "0 Marks records in verified Gradebook",
                  value:
                    gradeBookData?.zero_marks_in_verified_gradebooks ?? "-",
                  color: "#0FCB0226",
                  navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=applied_on_admission`,
                },
                {
                  title: "0 Marks records in submitted Gradebook",
                  value:
                    gradeBookData?.zero_marks_in_submitted_gradebooks ?? "-",
                  color: "#4A3AFF1A",

                  navigateTo: `${siteRoutes.systemUsersListing}?user_action_status=signedup_users`,
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div className="link-card-text">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </SemesterData>
      </AcadDashboardSection>
    </AcadDashboardMain>
  );
};

export default AcademicsDashboard;
