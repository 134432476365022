import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  LinkIconSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import { useNavigate } from "react-router-dom";
import {
  ProgrameListingTopButton,
  ProgramsListingMain,
  ProgramsListingSection,
  ProgramsListingTop,
} from "./style";
import useAlert from "hooks/useAlert";
import useOrganization from "../../useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import ManageCriteria from "./components/manage-criteria";
import ManageSeats from "./components/manage-seats";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useUtils from "hooks/useUtils";
import useAdmissions from "containers/private/admissions/useHooks";
import LinkProgramToAdmissionCampaign from "./components/link-program";
import UpdateLinkedProgram from "./components/update-linked-program";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { CampaignLinkedProgramDTO } from "utils/helpers/models/organization/add-program.dto";

interface ProgramsListingProps {}

const ProgramsListing: FC<ProgramsListingProps> = ({}) => {
  const { getQueryParams, createQuery } = useUtils();
  const { admission_session_header_id = null, level_id = null } =
    getQueryParams();
  const query = createQuery({ admission_session_header_id, level_id });
  const breadcrumbLinks = [
    { title: "Admissions /", path: siteRoutes.admissionCampaignListing },
    {
      title: "Programs",
      path:
        admission_session_header_id && level_id
          ? siteRoutes.campaignProgramsListing + query
          : siteRoutes.programListing,
    },
  ];

  const {
    getPrograms,
    deleteProgram,
    downloadProgramsExcelFile,
    downloadProgramsPdfFile,
  } = useOrganization();

  const [openManageCriteriaModal, setOpenManageCriteriaModal] = useState(false);
  const [openManageSeatsModal, setOpenManageSeatsModal] = useState(false);
  const [openLinkProgramModal, setOpenLinkProgramModal] = useState(false);
  const [openUpdateLinkedProgramModal, setOpenUpdateLinkedProgramModal] =
    useState(false);

  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [admissionSessionToUpdate, setAdmissionSessionToUpdate] =
    useState<any>();
  const { getCampaignLinkedPrograms, deleteCampaignLinkedProgram } =
    useAdmissions();
  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { handleSearchChange, handleTableSearch } = useUtils();

  const { hasAccess } = useStore();

  const { getDateFromDateTime } = useUtils();
  const columns: string[] = [
    "",
    "Program Name",
    "Department",
    "Credit Hours",
    "Program Type",
    "Duration",
    "Program Level",
    "Program Shortcut",
    "Degree Title",
    "Semesters",
    "Program Shift",
    "Student Registration Prefix",
    "Status",
    "Action",
  ];
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const goToCreateProgram = () => {
    navigate(siteRoutes.createProgram);
  };

  const goToUpdateProgram = (id: number) => {
    navigate(`${siteRoutes.createProgram}?id=${id}`);
  };
  const goToEligiblityTemplates = (program: CampaignLinkedProgramDTO) => {
    const { id, title, level_id, admission_session_id } = program;
    const query = createQuery({ id, title, admission_session_id, level_id });
    navigate(siteRoutes.eligibilityTemplatesListing + query);
  };

  const toggleRowExpand = (index: number) => {
    const programs = [...data];
    programs[index].isExpanded = !programs[index].isExpanded;
    setData([...programs]);
  };
  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        per_page: pagination.per_page,
        page: 1,
        search,
      };
      deleteProgram(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllPrograms(page + 1, search, per_page);
  };

  const getAllPrograms = (page: number, search: string, per_page: number) => {
    const queryParams = {
      per_page,
      page,
      search,
    };

    if (admission_session_header_id) {
      getCampaignLinkedPrograms(setData, admission_session_header_id);
    } else {
      getPrograms(setData, queryParams, setPagination);
    }
  };

  const goToLinkEntryTest = (program: any) => {
    const { admission_session_id, id, title, level_id } = program;
    const query = createQuery({ admission_session_id, id, title, level_id });
    navigate(siteRoutes.linkedProgramEntryTestListing + query);
  };

  const navigateToLinkedSpecialization = (
    program: CampaignLinkedProgramDTO
  ) => {
    const { admission_session_id, level_id, title: program_title } = program;
    const query = createQuery({
      admission_session_id,
      program_title,
      level_id,
    });
    navigate(siteRoutes.linkedSpecializationListing + query);
  };

  const goToLinkMeritList = (program: any) => {
    const {
      id: program_id,
      title: program_title,
      admission_session_id,
      level_id,
    } = program;
    const query = createQuery({
      program_id,
      program_title,
      admission_session_id,
      level_id,
    });
    navigate(siteRoutes.programLinkMeritList + query);
  };
  const handleOpenUpdateLinkedProgram = (program: any) => {
    setOpenUpdateLinkedProgramModal(true);
    setAdmissionSessionToUpdate(program);
  };

  const goToApplicationTemplateListing = (id: number) => {
    navigate(`${siteRoutes.applicationTemplateLinkListing}?level_id=${id}`);
  };
  const goToManageExamIncharge = (id: number) => {
    const query = createQuery({ id });
    // navigate(siteRoutes?.academicExamIncharge);
    navigate(siteRoutes.academicManageExamIncharge + query);
  };

  const handleDeleteLinkedProgram = async (admission_session_id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteCampaignLinkedProgram(
        admission_session_id,
        admission_session_header_id,
        setData
      );
    }
  };

  useEffect(() => {
    getAllPrograms(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <ProgramsListingMain>
      <ProgramsListingTop>
        <div className="left">
          <span className="page-heading">Program Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <ProgrameListingTopButton>
            {hasAccess(sitePermissions.createProgram) &&
              !admission_session_header_id && (
                <div className="create-btn green">
                  <button
                    className="lg-rounded-btn"
                    onClick={goToCreateProgram}
                  >
                    + Add Program
                  </button>
                </div>
              )}

            {level_id ? (
              <div className="create-btn " title="Attach Voucher Template">
                <button
                  className="lg-rounded-btn link-btn gr"
                  onClick={() => goToApplicationTemplateListing(level_id)}
                >
                  <div className="icon">
                    <LinkIconSvg />
                  </div>
                  Attach Voucher
                </button>
              </div>
            ) : (
              ""
            )}

            {admission_session_header_id && (
              <div className="create-btn">
                <button
                  className="lg-rounded-btn link-btn"
                  onClick={() => setOpenLinkProgramModal(true)}
                >
                  <div className="icon">
                    <LinkIconSvg />
                  </div>
                  Link Program
                </button>
              </div>
            )}
          </ProgrameListingTopButton>
        </div>
      </ProgramsListingTop>
      <ProgramsListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadProgramsPDF) && (
              <div className="export-btn" onClick={downloadProgramsPdfFile}>
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadProgramsExcel) && (
              <div className="export-btn" onClick={downloadProgramsExcelFile}>
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllPrograms
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllPrograms)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      key={index}
                      className={
                        index > 4 ? `extra-head extra-head-${index - 4}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {data.map((program, index) => {
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${program.isExpanded && "opened"}`}
                    >
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{program.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>

                      <td>
                        <div className="mw-150">{program.title}</div>
                      </td>
                      <td>{program?.category?.title}</td>

                      <td>{`${program?.min_credit_hrs} - ${program?.max_credit_hrs}`}</td>
                      <td>{program.program_type}</td>
                      <td className="extra-col extra-col-1">
                        {program?.years} Years
                      </td>

                      <td className="extra-col extra-col-2">
                        {" "}
                        {program?.level?.title}
                      </td>
                      <td className="extra-col extra-col-3">{program?.code}</td>
                      <td className="extra-col extra-col-4">
                        {" "}
                        {program?.degree_title}
                      </td>

                      <td className="extra-col extra-col-5">
                        {" "}
                        {program?.semesters}
                      </td>
                      <td className="extra-col extra-col-6">
                        {" "}
                        {program?.program_shift}
                      </td>
                      <td className="extra-col extra-col-7">
                        {" dwwwwww"}
                        {program?.reg_prefix}
                      </td>

                      {admission_session_header_id ? (
                        <td className="extra-col extra-col-8">
                          {program?.admission_status == 1 ? (
                            <span className="status-tile green">Active</span>
                          ) : (
                            <span className="status-tile yellow">Inactive</span>
                          )}
                        </td>
                      ) : (
                        <td className="extra-col extra-col-8">
                          {program?.status == 1 ? (
                            <span className="status-tile green">Active</span>
                          ) : (
                            <span className="status-tile yellow">Inactive</span>
                          )}
                        </td>
                      )}

                      <td className="extra-col extra-col-9">
                        <div className="table-action-icons">
                          {hasAccess(sitePermissions.editProgram) &&
                            !admission_session_header_id && (
                              <div
                                className="action-icon"
                                onClick={() => goToUpdateProgram(program?.id)}
                              >
                                <EditTableSvg />
                              </div>
                            )}

                          {admission_session_header_id ? (
                            <div
                              className="table-action-button"
                              onClick={() => goToLinkMeritList(program)}
                            >
                              <button className="criteria-btn">
                                Link Merit
                              </button>
                            </div>
                          ) : (
                            ""
                          )}

                          {admission_session_header_id ? (
                            <div
                              className="table-action-button"
                              onClick={() => goToEligiblityTemplates(program)}
                            >
                              <button>Link Eligibility</button>
                            </div>
                          ) : (
                            ""
                          )}

                          {admission_session_header_id && (
                            <Fragment>
                              {hasAccess(
                                sitePermissions.linkedSpecializationListing
                              )}

                              <div className="table-action-button">
                                <button
                                  className="special-btn"
                                  onClick={() =>
                                    navigateToLinkedSpecialization(program)
                                  }
                                >
                                  Specialization
                                </button>
                              </div>

                              {hasAccess(
                                sitePermissions.linkedProgramEntryTestListing
                              ) && (
                                <div
                                  className="table-action-button"
                                  onClick={() => goToLinkEntryTest(program)}
                                >
                                  <button className="entry-test-btn">
                                    Link Entry Test
                                  </button>
                                </div>
                              )}
                            </Fragment>
                          )}

                          {hasAccess(sitePermissions.deleteProgram) &&
                            !admission_session_header_id && (
                              <div
                                className="action-icon"
                                onClick={() => handleDelete(program?.id)}
                              >
                                <DeleteTableSvg />
                              </div>
                            )}

                          {admission_session_header_id && (
                            <div
                              className="action-icon"
                              onClick={() =>
                                handleOpenUpdateLinkedProgram(program)
                              }
                            >
                              <EditTableSvg />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {program.isExpanded && (
                      <tr className="expanded-row">
                        <td></td>
                        <td colSpan={5}>
                          <div className="expanded-content particular-info">
                            <div className="particular-info particular-info-1">
                              <span className="title">Duration</span>
                              <span className="info">
                                {program?.years} Years
                              </span>
                            </div>

                            <div className="particular-info particular-info-2">
                              <span className="title">Program Level</span>
                              <div className="info">
                                <span className="status">
                                  {program?.level?.title}
                                </span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Program Shortcuts</span>
                              <div className="info">
                                <span className="status">{program?.code}</span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">Degree Title</span>
                              <div className="info">
                                <span className="status">
                                  {program?.degree_title}
                                </span>
                              </div>
                            </div>

                            <div className="particular-info particular-info-5">
                              <span className="title">Samester</span>
                              <div className="info">
                                <span className="status">
                                  {program?.semesters}
                                </span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-6">
                              <span className="title">Program Shift</span>
                              <div className="info">
                                <span className="status">
                                  {program?.program_shift}
                                </span>
                              </div>
                            </div>
                            <div className="particular-info particular-info-7">
                              <span className="title">
                                Student Registration Prefix
                              </span>
                              <div className="info">
                                <span className="status">
                                  {program?.reg_prefix}
                                </span>
                              </div>
                            </div>

                            {admission_session_header_id ? (
                              <div>
                                <div className="particular-info particular-info-8">
                                  <span className="title">Fee Due Date</span>
                                  <div className="info">
                                    <span className="status">
                                      {getDateFromDateTime(
                                        program?.fee_due_date
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="particular-info particular-info-9">
                                  <span className="title">
                                    Class Start Date
                                  </span>
                                  <div className="info">
                                    <span className="status">
                                      {getDateFromDateTime(
                                        program?.class_start_date
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {admission_session_header_id ? (
                              <div className="particular-info particular-info-10">
                                <span className="title">Status</span>
                                <div className="info">
                                  {program?.admission_status == "1" ? (
                                    <span className="status-tile green">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="status-tile yellow">
                                      Inactive
                                    </span>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="particular-info particular-info-10">
                                <span className="title">Status</span>
                                <div className="info">
                                  {program?.status == 1 ? (
                                    <span className="status-tile green">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="status-tile yellow">
                                      Inactive
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="particular-info particular-info-11">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  {hasAccess(sitePermissions.editProgram) &&
                                    !admission_session_header_id && (
                                      <div
                                        className="action-icon"
                                        onClick={() =>
                                          goToUpdateProgram(program?.id)
                                        }
                                      >
                                        <EditTableSvg />
                                      </div>
                                    )}

                                  {admission_session_header_id ? (
                                    <div
                                      className="action-button"
                                      onClick={() => goToLinkMeritList(program)}
                                    >
                                      <button className="criteria-btn">
                                        Link Merit
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {admission_session_header_id ? (
                                    <div
                                      className="action-button"
                                      onClick={() =>
                                        goToEligiblityTemplates(program)
                                      }
                                    >
                                      <button>Link Eligibility</button>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {!admission_session_header_id && (
                                    <Fragment>
                                      {hasAccess(
                                        sitePermissions?.academicExamIncharge
                                      ) && (
                                        <div
                                          className="action-button"
                                          onClick={() =>
                                            goToManageExamIncharge(program?.id)
                                          }
                                        >
                                          <button>Manage Exam Incharge</button>
                                        </div>
                                      )}
                                    </Fragment>
                                  )}

                                  {admission_session_header_id && (
                                    <Fragment>
                                      {hasAccess(
                                        sitePermissions.linkedSpecializationListing
                                      ) && (
                                        <div className="action-button">
                                          <button
                                            className="special-btn"
                                            onClick={() =>
                                              navigateToLinkedSpecialization(
                                                program
                                              )
                                            }
                                          >
                                            Specialization
                                          </button>
                                        </div>
                                      )}

                                      {hasAccess(
                                        sitePermissions.linkedProgramEntryTestListing
                                      ) && (
                                        <div
                                          className="action-button"
                                          onClick={() =>
                                            goToLinkEntryTest(program)
                                          }
                                        >
                                          <button className="entry-test-btn">
                                            Link Entry Test
                                          </button>
                                        </div>
                                      )}
                                    </Fragment>
                                  )}

                                  {hasAccess(sitePermissions.deleteProgram) &&
                                    !admission_session_header_id && (
                                      <div
                                        className="action-icon"
                                        onClick={() =>
                                          handleDelete(program?.id)
                                        }
                                      >
                                        <DeleteTableSvg />
                                      </div>
                                    )}

                                  {admission_session_header_id && (
                                    <div
                                      className="action-icon"
                                      onClick={() =>
                                        handleOpenUpdateLinkedProgram(program)
                                      }
                                    >
                                      <EditTableSvg />
                                    </div>
                                  )}
                                  {admission_session_header_id && (
                                    <div
                                      className="action-icon"
                                      onClick={() =>
                                        handleDeleteLinkedProgram(
                                          program?.admission_session_id
                                        )
                                      }
                                    >
                                      <DeleteTableSvg />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          {!admission_session_header_id && (
            <Pagination onPageChange={onPageChange} {...pagination} />
          )}
        </Fragment>
      </ProgramsListingSection>
      {openManageCriteriaModal && (
        <ManageCriteria setOpen={setOpenManageCriteriaModal} />
      )}
      {openManageSeatsModal && (
        <ManageSeats setOpen={setOpenManageSeatsModal} />
      )}
      {openLinkProgramModal && (
        <LinkProgramToAdmissionCampaign
          admission_session_header_id={admission_session_header_id}
          setOpen={setOpenLinkProgramModal}
          setData={setData}
        />
      )}
      {openUpdateLinkedProgramModal && (
        <UpdateLinkedProgram
          setOpen={setOpenUpdateLinkedProgramModal}
          admissionSession={admissionSessionToUpdate}
          setPrograms={setData}
        />
      )}
    </ProgramsListingMain>
  );
};

export default ProgramsListing;
