import { FC, memo, useEffect, useState } from "react";
import { BreadCrumbLink, BreadCrumbMain } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";

interface BreadcrumbProps {
    links: BreadcrumbLink[]
}

export interface BreadcrumbLink {
    title: string;
    path: string;
    state?: any;
    isAccessible?: boolean;
    pathWithoutQuery?: string;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ links: options }) => {
    const { pathname } = useLocation();
    const [links, setLinks] = useState<BreadcrumbLink[]>([...options]);
    const navigate = useNavigate();
    const { hasAccess, getPermissionByRoute } = useStore();

    const handleNavigation = (link: BreadcrumbLink) => {
        const { state = null, isAccessible, pathWithoutQuery = '', path: route} = link;
        if(pathWithoutQuery === pathname) return;
        if (isAccessible) navigate(route, {state});
    };

    useEffect(() => {
        setLinks(links.map((link) => {
            link.pathWithoutQuery= link.path.split('?')[0]
            const permission = getPermissionByRoute(link.pathWithoutQuery);
            link.isAccessible = hasAccess(permission);
            return link;
        }));
    }, []);

    return (
        <BreadCrumbMain>
            {links.map((link, index) => {
                return (
                    <BreadCrumbLink
                        $active={pathname === link.path.split('?')[0]}
                        key={index}
                        onClick={() => handleNavigation(link)}
                        title={link.isAccessible ? link.title : 'Not Accessible'}
                    >
                        {link.title}
                    </BreadCrumbLink>
                );
            })}
        </BreadCrumbMain>
    );
};

export default memo(Breadcrumb);
