import { FC, Fragment, useEffect, useState } from "react";
import { Main, ListingSection, Top } from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  // ExcelSvg,
  // PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useTimeTable from "containers/private/time-table/UseHooks";
import { ReportsDTO } from "utils/helpers/models/ds-system/reports.dto";
import useDSSystem from "../../useHook";
import SelectSessionModal from "./components/sessions";

interface StructureTypeListingProps {}

const ReportsListing: FC<StructureTypeListingProps> = () => {
  const breadcrumbLinks = [
    {
      title: "DS System /",
      path: "",
    },
    {
      title: "Reports Listing ",
      path: siteRoutes.resportsListing,
    },
  ];

  const columns = ["Reports Title", "Action"];

  const { getReportsList, downloadReportsWithUrl, deleteReportsById } =
    useDSSystem();

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { handleSearchChange, handleTableSearch } = useUtils();

  const [openSessionModal, setOpenSessionModal] = useState<boolean>(false);
  const [data, setData] = useState<ReportsDTO[]>([]);
  const [fileURL, setFileURL] = useState<string>("");

  const { hasAccess } = useStore();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        per_page: pagination.per_page,
        page: pagination.page,
        search,
      };
      deleteReportsById(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllReportsList();
  };

  const getAllReportsList = () => {
    const queryParams = {
      per_page: pagination.per_page,
      page: pagination.page,
      search,
    };
    getReportsList(setData, queryParams, setPagination);
  };

  const downloadReport = (url: string, is_required: number) => {
    if (is_required === 1) {
      setFileURL(url);
      setOpenSessionModal(true);
    } else {
      downloadReportsWithUrl(url);
    }
  };

  const updateByID = (id: number) => {
    navigate(`${siteRoutes.resportsCreate}?id=${id}`);
  };

  const GoToAddReports = () => {
    navigate(siteRoutes.resportsCreate);
  };

  useEffect(() => {
    getAllReportsList();
  }, []);

  return (
    <Main>
      <Top>
        <div className="left">
          <span className="page-heading">Reports Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.resportsCreate) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={GoToAddReports}>
                + Add New Report
              </button>
            </div>
          )}
        </div>
      </Top>

      <ListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {/* {hasAccess(sitePermissions.downloadStructureTypesListPdf) && ( */}
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            {/* )} */}
            {/* {hasAccess(sitePermissions.downloadStructureTypesListExcel) && ( */}
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
            {/* )} */}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllReportsList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllReportsList)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((reports, index: number) => {
                return (
                  <tr key={index}>
                    <td>{reports?.title ?? "--"}</td>
                    <td>
                      <div className="mw-100">
                        <div className="table-action-icons">
                          <div className="action-button">
                            <button
                              className="primary"
                              onClick={() =>
                                downloadReport(
                                  reports?.url_path,
                                  reports?.sess_req
                                )
                              }
                            >
                              {reports?.button_text ?? "--"}
                            </button>
                          </div>
                          <div
                            className="action-icon"
                            onClick={() => updateByID(reports?.id)}
                          >
                            <EditTableSvg />
                          </div>
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(reports?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {openSessionModal && (
            <SelectSessionModal setOpen={setOpenSessionModal} url={fileURL} />
          )}
          <DataNotFound show={!isLoading && !data?.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ListingSection>
    </Main>
  );
};

export default ReportsListing;
