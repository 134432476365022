export class ExamDTO {
  id?: number;
  title: string = "";
  start_date: string = "";
  end_date: string = "";
  academic_session_id: string = "";
  campus_id: string = "";
  exam_type_id: string = "";
  course_type_id: string = "";

  constructor(data: Partial<ExamDTO> = {}) {
    Object.assign(this, data);
  }
}
