import { FC, Fragment, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useAcademics from "containers/private/academics/useHooks";
import Avatar from "assets/images/common/others/cnic-upload-images.png";
import { uploadProfileImageDTO } from "utils/helpers/models/academics/card-managements-list.dto";
import { errorToaster } from "utils/helpers/common/alert-service";
interface pageProps {
  setOpen: Function;
  studentData: {
    student_id: number;
    avatar: string;
  };
}

const UpdateProfileImageModal: FC<pageProps> = ({ setOpen, studentData }) => {
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<uploadProfileImageDTO>();

  const { updateStudentAvatar } = useAcademics();
  const [formData, setFormData] = useState<uploadProfileImageDTO>(
    new uploadProfileImageDTO({ profile_image: studentData?.avatar })
  );

  const handleFileUpload = async (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
    setFormData({ ...formData, [name]: URL.createObjectURL(file) });
    setValue(name, file);
    await trigger(name);
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new uploadProfileImageDTO()[
        key as keyof uploadProfileImageDTO
      ];
      setValue(key as keyof uploadProfileImageDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: uploadProfileImageDTO) => {
    const form_data = new FormData();
    form_data.append("profile_image", data.profile_image);
    if (formData?.profile_image) {
      updateStudentAvatar(form_data, studentData?.student_id, setOpen);
    } else {
      errorToaster("Profile Image Required");
    }
  };

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Update Avatar</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="upload-field">
                <label htmlFor="profile_image">Avatar</label>
                <label className="field-wrapper" htmlFor="profile_image">
                  <div className="file-name-section">
                    <div className="inner-content">
                      <div className="upload-text">
                        <div className="upload-icon">
                          <SmallUploadSvg className="icon" />
                        </div>
                        <span className="text">Upload Avatar</span>
                      </div>
                      <div className="upload-restrictions">
                        Select a 300x300 jpg image with maximum size of 400 KB
                      </div>
                    </div>
                  </div>
                  <div className="uploaded-image">
                    <img
                      src={
                        formData.profile_image === ""
                          ? Avatar
                          : formData.profile_image
                      }
                      alt="avatar"
                    />
                  </div>
                  <input
                    type="file"
                    className="d-none"
                    id="profile_image"
                    {...register("profile_image", { required: false })}
                    onChange={handleFileUpload}
                  />
                </label>
                <FormErrorMessage error={errors.profile_image} />
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={resetForm}
                >
                  Reset
                </button>

                <Fragment>
                  <button className="lg-rounded-btn">Update Image</button>
                </Fragment>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default UpdateProfileImageModal;
