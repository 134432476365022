import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { ADMISSION_APIS } from "libs/apis/admission.api";
import { useNavigate } from "react-router-dom";
import {
  errorToaster,
  successToaster,
} from "utils/helpers/common/alert-service";
import { successMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AdmissionCampaignDTO } from "utils/helpers/models/admissions/admission-campaign.dto";
import { AddAdmissionSessionDTO } from "utils/helpers/models/admissions/add-admission-session.dto";
import { MeritFormulaDTO } from "utils/helpers/models/admissions/merit-formula.dto";
import { AddMeritKeyDTO } from "utils/helpers/models/admissions/add-merit-key.dto";
import { AddQuotaProgramLinkDTO } from "utils/helpers/models/admissions/add-quota-program-link.dto";
import { CreateAdmissionQuotaDTO } from "utils/helpers/models/admissions/create-quota.dto";
import { extendDateDto } from "utils/helpers/models/admissions/extend-date.dto";
import { OfferLetterTemplateDTO } from "utils/helpers/models/admissions/offer-letter-template.dto";
import { RegStudentDTO } from "utils/helpers/models/admissions/registered-student.dto";
import { UpdateLinkedProgramDTO } from "utils/helpers/models/admissions/update-linked-program.dto";
import { LoginAsUserDTO } from "utils/helpers/models/auth/login-as-user.dto";
import {
  AddProgramDTO,
  CampaignLinkedProgramDTO,
} from "utils/helpers/models/organization/add-program.dto";
import { TestTypeDTO } from "utils/helpers/models/admissions/test-type.dto";
import { ProgramLinkToMeritDTO } from "utils/helpers/models/admissions/program-link-to-merit.dto";
import { EligibilityTemplateHeaderDTO } from "utils/helpers/models/admissions/eligibility-template-header.dto";
import { AddToMeritDTO } from "utils/helpers/models/academics/add-to-merit.dto";
import { SyncRegisteredStudentsDTO } from "utils/helpers/models/admissions/sync-registered-students.dto";

const useAdmissions = () => {
  const navigate = useNavigate();
  const {
    setFiltersForLoginAsUser,
    setToken,
    setUser,
    setPermissions,
    setPriorLoginAsRoute,
    setRole,
  } = useStore();
  const { getDateFromDateTime, downloadFileWithUrl, openExternalLink } =
    useUtils();

  const createTemplateHeader = async (
    body: any,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createTemplateHeader(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(siteRoutes.admissioneligibilityTemplateHeadersListing);
      }
    }
  };

  const getTemplateHeaders = async (
    setData: Function,
    queryParams: any = { per_page: "All", page: 1 },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getTemplateHeaders(queryParams);
    if (response?.status) {
      const {
        total: totalRecords,
        current_page: page,
        per_page,
      } = response?.response;
      if (setPagination) {
        setPagination({ per_page, totalRecords, page });
        const dataToSet = response?.response?.data;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new EligibilityTemplateHeaderDTO(i))
            : []
        );
      } else {
        const dataToSet = response?.response;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new EligibilityTemplateHeaderDTO(i))
            : []
        );
      }
    }
  };

  const getTemplateHeaderById = async (
    id: number,
    formData: any,
    setFormData: Function,
    setValue: Function
  ) => {
    const response = await ADMISSION_APIS.getTemplateHeaderById(id);
    if (response?.status) {
      const data = response?.response;
      const form_data: any = {};
      for (let key in formData) {
        setValue(key, data[key]);
        form_data[key] = data[key];
      }

      setFormData(form_data);
    }
  };
  const updateTemplateHeader = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.updateTemplateHeader(id, body);
    if (response?.status) {
      successToaster(successMessages.templateHeaderUpdated);
      navigate(siteRoutes.admissioneligibilityTemplateHeadersListing);
    }
  };

  const deleteTemplateHeader = async (
    id: number,
    setData: Function,
    pagination: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteTemplateHeader(id);
    if (response?.status) {
      successToaster(response?.message);
      getTemplateHeaders(setData, pagination, setPagination);
    }
  };

  const createTemplateBody = async (
    body: any,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createTemplateBody(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(-1);
      }
    }
  };

  const getTemplateBodies = async (
    headerId: number,
    setData: Function,
    queryParams: any = { per_page: "All", page: 1 }
  ) => {
    const response = await ADMISSION_APIS.getTemplateBodies(
      headerId,
      queryParams
    );
    if (response?.status) {
      setData(response?.response?.templates_body);
    }
  };

  const getTemplateBodyById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getTemplateBodyById(id);
    if (response?.status) {
      const data = response?.response;
      for (let key in formData) {
        setValue(key, data[key]);
        formData[key] = data[key];
      }

      setFormData({ ...formData });
    }
  };
  const updateTemplateBody = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.updateTemplateBody(id, body);
    if (response?.status) {
      successToaster(successMessages.templateHeaderUpdated);
      navigate(siteRoutes.admissioneligibilityTemplateHeadersListing);
    }
  };

  const deleteTemplateBody = async (
    id: number,
    headerId: number,
    setData: Function,
    queryParams: any
  ) => {
    const response = await ADMISSION_APIS.deleteTemplateBody(id);
    if (response?.status) {
      successToaster(response?.message);
      getTemplateBodies(headerId, setData, queryParams);
    }
  };

  const createAdmissionQuota = async (
    body: CreateAdmissionQuotaDTO,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createAdmissionQuota(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(siteRoutes.admissionQuotasListing);
      }
    }
  };
  const updateAdmissionQuota = async (
    id: number,
    body: CreateAdmissionQuotaDTO
  ) => {
    const response = await ADMISSION_APIS.updateAdmissionQuota(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.admissionQuotasListing);
    }
  };

  const getAdmissionQuotas = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionQuotas(queryParams);
    if (response?.status) {
      const {
        total: totalRecords,
        current_page: page,
        per_page,
      } = response?.response;
      if (setPagination) {
        setPagination({ per_page, totalRecords, page });
        setData(response?.response?.data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getAdmissionQuotaById = async (
    id: number,
    setValue: Function,
    formData: any,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionQuotaById(id);
    if (response?.status) {
      const quota = response?.response;
      if (quota?.extra_fields && typeof quota?.extra_fields === "string") {
        quota.extra_fields = JSON.parse(quota.extra_fields);
      }
      for (const key in formData) {
        formData[key] = quota[key];
        setValue(
          key as keyof CreateAdmissionQuotaDTO,
          quota[key as keyof CreateAdmissionQuotaDTO]
        );
      }
      setFormData({ ...formData });
    }
  };

  const deleteAdmissionQuota = async (
    id: number,
    setData: Function,
    pagination: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteAdmissionQuota(id);
    if (response?.status) {
      successToaster(response?.message);
      getAdmissionQuotas(setData, pagination, setPagination);
    }
  };

  const createAdmissionSession = async (body: AddAdmissionSessionDTO) => {
    const response = await ADMISSION_APIS.createAdmissionSession(body);
    if (response?.status) {
      successToaster(response.message);
      navigate(siteRoutes.admissionSessionListing);
    }
  };

  const updateAdmissionSession = async (
    id: number,
    body: AddAdmissionSessionDTO
  ) => {
    const response = await ADMISSION_APIS.updateAdmissionSession(id, body);
    if (response?.status) {
      successToaster(response.message);
      navigate(siteRoutes.admissionSessionListing);
    }
  };

  const getAdmissionSessions = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionSessions(queryParams);
    if (!response?.data?.length && response?.data) {
      const {
        total: totalRecords,
        current_page: page,
        per_page,
      } = response?.data;
      if (setPagination) {
        setPagination({ per_page, totalRecords, page });
      }
      const dataToSet = response?.data?.data ?? response?.data;
      setData(
        Array.isArray(dataToSet)
          ? dataToSet.map((i) => new AddAdmissionSessionDTO(i))
          : []
      );
    } else {
      setData(
        Array.isArray(response?.data)
          ? response?.data?.map((i) => new AddAdmissionSessionDTO(i))
          : []
      );
    }
  };

  const getAdmissionSessionById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionSessionById(id);
    if (response?.status) {
      for (const key in response?.response) {
        if (key === "start_date" || key == "end_date") {
          formData[key] = getDateFromDateTime(response?.response[key]);
          setValue(key, formData[key]);
        } else {
          formData[key] = response?.response[key];
          setValue(key, response?.response[key]);
        }
      }

      setFormData({ ...formData });
    }
  };

  const deleteAdmissionSession = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteAdmissionSession(id);
    if (response?.status) {
      successToaster(response?.message);
      getAdmissionSessions(setData, queryParams, setPagination);
    }
  };

  const createAdmissionCampaign = async (body: AdmissionCampaignDTO) => {
    const response = await ADMISSION_APIS.createAdmissionCampaign(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.admissionCampaignListing);
    }
  };

  const updateAdmissionCampaign = async (body: AdmissionCampaignDTO) => {
    const response = await ADMISSION_APIS.updateAdmissionCampaign(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.admissionCampaignListing);
    }
  };

  const deleteAdmissionCampaign = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteAdmissionCampaign(id);
    if (response?.status) {
      successToaster(response?.message);
      getAdmissionCampaigns(setData, queryParams, setPagination);
    }
  };

  const getAdmissionCampaigns = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionCampaigns(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        const { response: { data = [] } = {} } = response || {};
        setData(
          Array.isArray(data)
            ? data.map((i) => new AdmissionCampaignDTO(i))
            : []
        );
        setPagination({ totalRecords, per_page, page });
      } else {
        const data = response?.response;
        setData(
          Array.isArray(data)
            ? data.map((i) => new AdmissionCampaignDTO(i))
            : []
        );
      }
    }
  };

  const getAdmissionCampaignById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionCampaignById(id);
    if (response?.status) {
      for (const key in formData ?? {}) {
        if (key === "start_date" || key == "end_date") {
          formData[key] = getDateFromDateTime(response?.response[key]);
          setValue(key, formData[key]);
        } else {
          formData[key] = response?.response[key];
          setValue(key, formData[key]);
        }
      }

      setFormData({ ...formData });
    }
  };

  const getProgramsBySessionId = async (
    setData: Function,
    admission_session_header_id: number,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await ADMISSION_APIS.getProgramsBySessionId(
      admission_session_header_id,
      queryParams
    );
    if (response?.status) {
      setData(response?.response);
    }
  };

  const loginAsUser = async (body: LoginAsUserDTO) => {
    const response = await ADMISSION_APIS.loginAsUser(body);
    const {
      status = false,
      is_eligible = false,
      is_already_apply = false,
      is_admin = false,
    } = response || {};
    if (status) {
      if (response?.permissions?.length) {
        setRole(response?.data?.roles[0], true);
        delete response?.data?.role_id;
        delete response?.data?.roles;
        setUser(
          { ...response?.data, is_eligible, is_already_apply, is_admin },
          true
        );
        // setFiltersForLoginAsUser();
        setToken(response?.token, true);
        setPermissions(response?.permissions, true);
        setPriorLoginAsRoute(window.location.pathname);
        successToaster(response?.message);
        navigate(siteRoutes.ePortalDashboard, { replace: true });
      } else {
        errorToaster("User has no permissions");
      }
    }
  };

  const updateLinkedProgramsBySessionId = async (
    header_id: number,
    body: any,
    setOpenModal: Function,
    setData: Function
  ) => {
    const response = await ADMISSION_APIS.updateLinkedProgramsBySessionId(body);
    if (response?.status) {
      successToaster(response?.message);
      getCampaignLinkedPrograms(setData, header_id);
      setOpenModal(false);
    }
  };

  const getCampaignLinkedPrograms = async (
    setData: Function,
    header_id: number
  ) => {
    const response = await ADMISSION_APIS.getAdmissionCampaignById(header_id);
    const { status, response: data = {} } = response || {};
    if (status) {
      const { admission_sessions = [] } = data || {};
      const programs: AddProgramDTO[] = admission_sessions.map(
        (i) =>
          new CampaignLinkedProgramDTO({
            ...i.program,
            fee_due_date: i.fee_due_date,
            admission_session_id: i.id,
            class_start_date: i.class_start_date,
            admission_status: i.admission_status,
          })
      );
      setData([...programs]);
    }
  };

  const createMeritKey = async (
    body: AddMeritKeyDTO,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createMeritKey(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(siteRoutes.meritKeysListing);
      }
    }
  };

  const deleteCampaignLinkedProgram = async (
    admission_session_id: number,
    admission_session_header_id: number,
    setData: Function
  ) => {
    const response = await ADMISSION_APIS.deleteCampaignLinkedProgram(
      admission_session_id
    );
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
      getCampaignLinkedPrograms(setData, admission_session_header_id);
    }
  };

  const updateMeritKey = async (id: number, body: AddMeritKeyDTO) => {
    const response = await ADMISSION_APIS.updateMeritKey(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.meritKeysListing);
    }
  };

  const getMeritKeys = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritKeys(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };

  const getMeritKeyById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritKeyById(id);
    if (response?.status) {
      for (let key in formData) {
        formData[key] = response?.response[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };

  const deleteMeritKey = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteMeritKey(id);
    if (response?.status) {
      successToaster(response?.message);
      getMeritKeys(setData, queryParams, setPagination);
    }
  };

  const createMeritFormula = async (
    body: MeritFormulaDTO,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createMeritFormula(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(siteRoutes.meritListFormulaListing);
      }
    }
  };
  const updateMeritFormula = async (id: number, body: MeritFormulaDTO) => {
    const response = await ADMISSION_APIS.updateMeritFormula(id, body);
    if (response?.status) {
      navigate(siteRoutes.meritListFormulaListing);
    }
  };

  const getMeritFormulas = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritFormulas(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        const dataToSet = response?.response?.data;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new MeritFormulaDTO(i))
            : []
        );
        setPagination({ totalRecords, per_page, page });
      } else {
        const dataToSet = response?.response;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new MeritFormulaDTO(i))
            : []
        );
      }
    }
  };

  const getMeritFormulaById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritFormulaById(id);
    const { response: data } = response || {};

    for (let key in formData) {
      if (key === "merit_weighted_data") {
        formData[key] = data["weighted"];
        setValue(key, formData[key]);
      } else {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
    }
    // if (response?.status) {
    //   const data = response?.response;
    //   for (let key in formData) {
    //     formData[key] = data[key];
    //     setValue(key, formData[key]);
    //     if (key === "merit") {
    //       const meritStr: string = data[key];
    //       meritStr.split("{").map((str: string, index: number) => {
    //         const meritKey = str.replace("}", "");
    //       });
    //     }
    //   }
    setFormData({ ...formData });
    // }

    //
  };

  const deleteMeritFormula = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteMeritFormula(id);
    if (response?.status) {
      successToaster(response?.message);
      getMeritFormulas(setData, queryParams, setPagination);
    }
  };

  const createAdmissionEntryTest = async (body: any) => {
    const response = await ADMISSION_APIS.createAdmissionEntryTests(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.admissionTestTypesListing);
    }
  };

  const updateAdmissionEntryTest = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.updateAdmissionEntryTest(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.admissionTestTypesListing);
    }
  };

  const getAdmissionEntryTests = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionEntryTests(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        const dataToSet = response?.response?.data;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new TestTypeDTO(i))
            : []
        );
        setPagination({ totalRecords, per_page, page });
      } else {
        const dataToSet = response?.response;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new TestTypeDTO(i))
            : []
        );
      }
    }
  };

  const getAdmissionEntryTestById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getAdmissionEntryTestById(id);
    const data = response?.response;
    if (data) {
      for (let key in data) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
    }

    setFormData({ ...formData });
  };

  const deleteAdmissionEntryTest = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteAdmissionEntryTest(id);
    if (response?.status) {
      getAdmissionEntryTests(setData, queryParams, setPagination);
    }
  };

  const getEligibilityFieldData = async (
    headerId: number,
    setData: Function
  ) => {
    const response = await ADMISSION_APIS.getEligibilityFieldData(headerId);
    const data = response?.response;
    if (data) {
      setData(data);
    }
  };

  const createTemplateLinkToProgram = async (body: any, queryParams: any) => {
    const response = await ADMISSION_APIS.createTemplateLinkToProgram(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };

  const createQuotaHeader = async (
    body: any,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createQuotaHeader(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(siteRoutes.quotasAdmissionListing);
      }
    }
  };

  const updateQuotaHeader = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.updateQuotaHeader(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.quotasAdmissionListing);
    }
  };

  const getQuotaHeaders = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getQuotaHeaders(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };

  const getQuotaHeaderById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getQuotaHeaderById(id);
    const data = response?.response;
    if (data) {
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };

  const deleteQuotaHeader = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteQuotaHeader(id);
    if (response?.status) {
      successToaster(response?.message);
      getQuotaHeaders(setData, queryParams, setPagination);
    }
  };

  const createQuotaProgramLink = async (body: any, setOpenModal: Function) => {
    const response = await ADMISSION_APIS.createQuotaProgramLink(body);
    if (response?.status) {
      successToaster(response?.message);
      setOpenModal(false);
    }
  };

  const updateQuotaProgramLink = async (
    id: number,
    body: AddQuotaProgramLinkDTO,
    setOpenModal: Function
  ) => {
    const response = await ADMISSION_APIS.updateQuotaProgramLink(id, body);
    if (response?.status) {
      successToaster(response?.message);
      setOpenModal(false);
    }
  };

  const getQuotaProgramLinks = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getQuotaProgramLinks(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };

  const getQuotaProgramLinkById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getQuotaProgramLinkById(id);
    const data = response?.response;
    if (data) {
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };

  const deleteQuotaProgramLink = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteQuotaProgramLink(id);
    if (response?.status) {
      successToaster(response?.message);
      getQuotaProgramLinks(setData, queryParams, setPagination);
    }
  };
  const createOfferLetterTemplate = async (body: OfferLetterTemplateDTO) => {
    const response = await ADMISSION_APIS.createOfferLetterTemplate(body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(-1);
    }
  };

  const updateOfferLetterTemplate = async (
    id: number,
    body: OfferLetterTemplateDTO
  ) => {
    const response = await ADMISSION_APIS.updateOfferLetterTemplate(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.admissionOfferLetterListing);
    }
  };

  const getOfferLetterTemplates = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getOfferLetterTemplates(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };

  const getOfferLetterTemplateById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getOfferLetterTemplateById(id);
    const data = response?.response;
    if (data) {
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };

  const deleteOfferLetterTemplate = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteOfferLetterTemplate(id);
    if (response?.status) {
      successToaster(response?.message);
      getOfferLetterTemplates(setData, queryParams, setPagination);
    }
  };

  const createLinkSubjectToCertificate = async (
    body: any,
    setOpenModal: Function
  ) => {
    const response = await ADMISSION_APIS.createLinkSubjectToCertificate(body);
    if (response?.status) {
      successToaster(response?.message);
      setOpenModal(false);
    }
  };

  const updateLinkSubjectToCertificate = async (
    id: string,
    body: any,
    setOpenModal: Function
  ) => {
    const response = await ADMISSION_APIS.updateLinkSubjectToCertificate(
      id,
      body
    );
    if (response?.status) {
      successToaster(response?.message);
      setOpenModal(false);
    }
  };

  const getLinkSubjectToCertificates = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getLinkSubjectToCertificates(
      queryParams
    );
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };

  const getLinkSubjectToCertificateById = async (
    certificateId: number,
    formData: any,
    setValue: Function,
    setFormData: Function,
    setLinkId: Function
  ) => {
    const response = await ADMISSION_APIS.getLinkSubjectToCertificateById(
      certificateId
    );
    const data = response?.response;
    if (data) {
      for (let key in formData) {
        if (key === "subject_id") {
          formData.subject_id = data.subject_ids;
          setValue("subject_id", formData[key]);
        } else {
          formData[key] = data[key];
          setValue(key, formData[key]);
        }
      }
    }
    setLinkId(data?.id);
    setFormData({ ...formData });
  };

  const deleteLinkSubjectToCertificate = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteLinkSubjectToCertificate(id);
    if (response?.status) {
      successToaster(response?.message);
      getQuotaProgramLinks(setData, queryParams, setPagination);
    }
  };

  const mergeTemplateBodies = async (
    body: any,
    headerId: number,
    setData: Function,
    queryParams: any,
    setOpen: Function
  ) => {
    const response = await ADMISSION_APIS.mergeTemplateBodies(body);
    if (response?.status) {
      successToaster(response?.message);
      getTemplateBodies(headerId, setData, queryParams);
      setOpen(false);
    }
  };

  const removeTemplateBodiesGroup = async (
    body: any,
    headerId: number,
    setData: Function,
    queryParams: any
  ) => {
    const response = await ADMISSION_APIS.removeTemplateBodiesGroup(body);
    if (response?.status) {
      successToaster(response?.message);
      getTemplateBodies(headerId, setData, queryParams);
    }
  };

  const createMeritListAutomation = async (
    body: any,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createMeritListAutomation(body);
    if (response.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(siteRoutes.manageMeritListListing);
      }
    }
  };

  const updateMeritListAutomation = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.updateMeritListAutomation(id, body);
    if (response.status) {
      successToaster(response?.message);
      navigate(siteRoutes.manageMeritListListing);
    }
  };

  const getMeritListAutomations = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritListAutomations(queryParams);
    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };

  const getMeritListAutomationById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritListAutomationById(id);
    const data = response?.response;
    if (data) {
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };

  const deleteMeritAutomation = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteMeritListAutomation(id);
    if (response?.status) {
      successToaster(response?.message);
      getMeritListAutomations(setData, queryParams, setPagination);
    }
  };

  const getProgramLinkTemplate = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getProgramLinkTemplate(queryParams);

    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        setData(response?.response?.data);
        setPagination({ totalRecords, per_page, page });
      } else {
        setData(response?.response);
      }
    }
  };
  const deleteProgramLinkTemplate = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteProgramLinkTemplate(id);
    if (response?.status) {
      successToaster(response?.message);
      getProgramLinkTemplate(setData, queryParams, setPagination);
    }
  };

  const createProgramLinkMerit = async (
    body: any,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await ADMISSION_APIS.createProgramLinkMerit(body);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        reset();
      } else {
        navigate(-1);
      }
    }
  };

  const updateProgramLinkMerit = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.updateProgramLinkMerit(id, body);
    if (response?.status) {
      successToaster(response?.message);
      navigate(siteRoutes.programLinkMeritList);
    }
  };
  const getProgramLinkMerits = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getProgramLinkMerits(queryParams);

    if (response?.response || response?.data) {
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        const dataToSet = response?.response?.data;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new ProgramLinkToMeritDTO(i))
            : []
        );
        setPagination({ totalRecords, per_page, page });
      } else {
        const dataToSet = response?.response;
        setData(
          Array.isArray(dataToSet)
            ? dataToSet.map((i) => new ProgramLinkToMeritDTO(i))
            : []
        );
      }
    }
  };

  const getProgramLinkMeritById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await ADMISSION_APIS.getProgramLinkMeritById(id);
    const data = response?.response;
    if (data) {
      for (let key in data) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };
  const deleteProgramLinkMerit = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.deleteProgramLinkMerit(id);
    if (response?.status) {
      successToaster(response?.message);
      getProgramLinkMerits(setData, queryParams, setPagination);
    }
  };

  const updateLinkedProgram = async (
    body: UpdateLinkedProgramDTO,
    setOpen: Function,
    setPrograms: Function
  ) => {
    const response = await ADMISSION_APIS.updateLinkedProgram(body);
    if (response?.status) {
      successToaster(response?.message);
      setOpen(false);
      setPrograms((programs) => {
        const index = programs.findIndex(
          (p) => p.id == +body.admission_session_id
        );
        programs[index] = { ...programs[index], ...body };
        return programs;
      });
    }
  };
  const getCampusList = async (setData: Function) => {
    const response = await ADMISSION_APIS.getCampusList();
    if (response?.status) {
      setData(response?.response);
    }
  };
  const getStudentApplicants = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await ADMISSION_APIS.getStudentApplicants(queryParams);
    const { status = false } = response;
    if (status) {
      const data = response?.response?.details?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response?.details;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response?.data);
      }
    }
  };
  const getStudenApplicantsFilterData = async (
    setAcademicSessions: Function,
    setCategories: Function
  ) => {
    const response = await ADMISSION_APIS.getStudenApplicantsFilterData();
    if (response?.status) {
      const { academic_sessions, certificate_levels } = response?.response;
      setAcademicSessions(academic_sessions);
      setCategories(certificate_levels);
    }
  };
  const getStudentApplicantFilterProgram = async (
    queryParams: any,
    setPrograms: Function,
    setQuotas: Function,
    setMeritLists: Function
  ) => {
    const response = await ADMISSION_APIS.getStudentApplicantFilterProgram(
      queryParams
    );
    if (response?.response) {
      const { programs, program_quotas, mlArray } = response?.response;
      setPrograms(programs ?? []);
      setQuotas(program_quotas ?? []);
      setMeritLists(mlArray ?? []);
    }
  };
  const getQuotaPrograms = async (setData: Function, queryParams: any) => {
    const response = await ADMISSION_APIS.getQuotaPrograms(queryParams);

    if (response?.status) {
      setData(response?.response);
    }
  };
  const getStudentProgram = async (setPrograms: Function, queryParams: any) => {
    const response = await ADMISSION_APIS.getStudentApplicantFilterProgram(
      queryParams
    );
    const data = response?.response;

    if (response?.status) {
      setPrograms(data);
    }
  };

  const bulkLock = async (
    automationId: number,
    setData: Function,
    params: any = {}
  ) => {
    const response = await ADMISSION_APIS.bulkLock(automationId, params);
    const { programs } = response?.response || {};
    if (programs) {
      setData(programs);
    }
  };

  const bulkUnlock = async (
    automationId: number,
    setData: Function,
    params: any = {}
  ) => {
    const response = await ADMISSION_APIS.bulkUnlock(automationId, params);
    const { programs } = response?.response || {};
    if (programs) {
      setData(programs);
    }
  };

  const createBulkLock = async (
    automationId: number,
    params: { programs: number[] },
    handleCloseModal: Function
  ) => {
    const response = await ADMISSION_APIS.bulkLock(automationId, params);
    if (response?.status) {
      successToaster(response?.message);
      handleCloseModal();
    }
  };
  const createBulkUnLock = async (
    automationId: number,
    params: { programs: number[] },
    handleCloseModal: Function
  ) => {
    const response = await ADMISSION_APIS.bulkUnlock(automationId, params);
    if (response?.status) {
      successToaster(response?.message);
      handleCloseModal();
    }
  };
  const downloadBulkList = async (id: number) => {
    const response = await ADMISSION_APIS.downlodBulkList(id);
    if (response?.status) {
      downloadFileWithUrl(response?.response?.url ?? "");
    }
  };
  const getMeritList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setSummary: Function,
    setUrlData: Function
  ) => {
    const response = await ADMISSION_APIS.getMeritList(queryParams);

    if (response?.status) {
      const { records = [], summary } = response?.response || {};
      if (records) {
        setSummary(summary ?? null);
        setData(Array.isArray(records) ? records : records.data);
      } else {
        setUrlData(response?.response);
      }
    }
  };

  const getAppExpiryDate = async (
    offer_letter: string,
    formData: any,
    setFormData: Function,
    setValue: Function
  ) => {
    const paramsToSend = { offer_letter, per_page: "All" };
    const response = await ADMISSION_APIS.getAppExpiryDate(paramsToSend);
    const { response: data } = response || {};
    if (data) {
      const { merit_app = {} } = data || {};
      for (let key in merit_app) {
        formData[key] = merit_app[key];
        setValue(key, merit_app[key]);
      }

      setFormData({ ...formData });
    }
  };

  const addToMerit = async (formData: AddToMeritDTO, setOpen: Function) => {
    const response = await ADMISSION_APIS.addToMerit(formData);
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const downloadAdmissionAppByAdmin = async (app_no: string) => {
    const response = await ADMISSION_APIS.downloadAdmissionAppByAdmin(app_no);
    const { status, response: data } = response || {};
    if (status) {
      const { url } = data || {};
      downloadFileWithUrl(url);
    }
  };
  const editProgramLinkQuota = async (id: number, body: any) => {
    const response = await ADMISSION_APIS.editProgramLinkQuota(id, body);
    if (response?.status) {
      successToaster(response?.message);
    }
  };
  const getLockedMeritList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await ADMISSION_APIS.getLockedMeritList(queryParams);
    if (response?.status) {
      const data = response?.response?.merit_apps;
      if (setPagination) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response.merit_apps;
        setPagination({ per_page, totalRecords, page });
        setData(data?.data);
      } else {
        setData(response?.response);
      }
    }
  };
  const getRegisteredStudents = async (
    setStudentData: Function,
    params: any = { per_page: "All" }
  ) => {
    const response = await ADMISSION_APIS.getRegisteredStudents(params);
    const { status, message } = response;
    const details = response?.response?.details;

    console.log(details?.length, details);
    if (status) {
      if (details?.length > 0) {
        setStudentData(details);
      } else {
        setStudentData(details);
      }
    } else {
      errorToaster(message);
    }
  };

  const getStudentFilterData = async (setData: Function) => {
    const response = await ADMISSION_APIS.getStudenApplicantsFilterData();
    if (response?.status) {
      setData(response?.response);
    }
  };
  const createRegStudent = async (id: number, setData: Function) => {
    const response = await ADMISSION_APIS.createRegStudent(id, setData);
    if (response?.status) {
      successToaster(response?.message);
    }
  };
  const extendDate = async (
    id: number,
    body: extendDateDto,
    setOpen: Function
  ) => {
    const response = await ADMISSION_APIS.extendDate(id, body);
    if (response?.status) {
      successToaster(response?.message);
      setOpen(false);
    }
  };
  const getQuotasFeeList = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await ADMISSION_APIS.getQuotasFeeList(queryParams);
    const data = response?.response;
    setData(Array.isArray(data) ? data : data?.data ?? []);
  };

  const downloadFeeChallan = async (item: string) => {
    const response = await ADMISSION_APIS.dowloadFeeChallan(item);
    const { status, response: data } = response || {};
    const { url } = data || {};

    if (status && url) {
      openExternalLink(url);
    } else {
      errorToaster("An error occurd");
    }
  };

  const syncRegisteredStudents = async (
    body: SyncRegisteredStudentsDTO<string>,
    setOpen: Function
  ) => {
    const response = await ADMISSION_APIS.syncRegisteredStudents(body);
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const getFeeVoucherDetails = async (
    offer_letter: string,
    setVoucherData: Function,
    setVoucheDetails: Function,
    setInstallmentSlots: Function,
    setIsChallan: Function
  ) => {
    const response = await ADMISSION_APIS.getFeeVoucherDetails(offer_letter);
    const { status = false, message = "" } = response || {};

    const isAlreadyChallan =
      response?.response?.already_voucher?.account_transactions || [];
    const voucherDetails =
      response?.response?.voucher_data?.voucher_template || [];
    const installmentsDetails =
      response?.response?.voucher_data_installments || [];

    if (status) {
      const installmentSlots =
        installmentsDetails.map((item) => item?.installment_slots).flat() || [];

      setIsChallan(isAlreadyChallan);
      setInstallmentSlots(installmentSlots);
      setVoucherData(installmentsDetails);
      setVoucheDetails(voucherDetails);
    }
  };

  // const createInstallmentsByAdmin = async (
  //   offer_letter: string,
  //   body: any,
  //   getFeeVocherData: Function,
  //   setIsCreateInstallments: boolean
  // ) => {
  //   const response = await ADMISSION_APIS.createInstallmentsByAdmin(
  //     offer_letter,
  //     body
  //   );
  //   const { status = false, message = "" } = response || {};
  //   if (status && message) {
  //     successToaster(message);
  //     setIsCreateInstallments(false);
  //     getFeeVocherData();
  //   } else {
  //     errorToaster("An error occurd");
  //   }
  // };

  const createInstallmentsByAdmin = async (
    offer_letter: string,
    body: any,
    getFeeVocherData: Function,
    setIsCreateInstallments: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const response = await ADMISSION_APIS.createInstallmentsByAdmin(
      offer_letter,
      body
    );
    const { status = false, message = "" } = response || {};

    if (status && message) {
      successToaster(message);
      setIsCreateInstallments(false);
      getFeeVocherData();
    } else {
      errorToaster("An error occurred");
    }
  };

  return {
    createInstallmentsByAdmin,
    getFeeVoucherDetails,
    extendDate,
    getQuotasFeeList,
    createRegStudent,
    getRegisteredStudents,
    editProgramLinkQuota,
    getStudentProgram,
    getStudentFilterData,
    getMeritList,
    bulkUnlock,
    createBulkLock,
    createBulkUnLock,
    bulkLock,
    getCampusList,
    deleteLinkSubjectToCertificate,
    updateLinkedProgram,
    getProgramLinkMerits,
    deleteProgramLinkMerit,
    updateProgramLinkMerit,
    getProgramLinkMeritById,
    createProgramLinkMerit,
    getMeritListAutomationById,
    deleteMeritAutomation,
    loginAsUser,
    getMeritListAutomations,
    updateMeritListAutomation,
    createMeritListAutomation,
    removeTemplateBodiesGroup,
    getLinkSubjectToCertificateById,
    mergeTemplateBodies,
    getLinkSubjectToCertificates,
    updateLinkSubjectToCertificate,
    createLinkSubjectToCertificate,
    createQuotaHeader,
    getQuotaProgramLinks,
    deleteQuotaProgramLink,
    getQuotaProgramLinkById,
    updateQuotaProgramLink,
    createQuotaProgramLink,
    getQuotaHeaderById,
    deleteQuotaHeader,
    getQuotaHeaders,
    updateQuotaHeader,
    updateMeritFormula,
    deleteAdmissionEntryTest,
    getAdmissionEntryTestById,
    updateAdmissionEntryTest,
    deleteMeritFormula,
    createAdmissionEntryTest,
    getMeritFormulaById,
    createMeritFormula,
    getMeritFormulas,
    createMeritKey,
    getAppExpiryDate,
    updateMeritKey,
    getCampaignLinkedPrograms,
    getMeritKeyById,
    deleteMeritKey,
    getMeritKeys,
    getAdmissionCampaigns,
    updateLinkedProgramsBySessionId,
    getProgramsBySessionId,
    getAdmissionCampaignById,
    deleteAdmissionCampaign,
    updateAdmissionCampaign,
    createAdmissionCampaign,
    deleteAdmissionSession,
    getAdmissionSessionById,
    updateAdmissionSession,
    getAdmissionSessions,
    getAdmissionEntryTests,
    createAdmissionSession,
    createTemplateHeader,
    getTemplateHeaders,
    addToMerit,
    getTemplateHeaderById,
    updateTemplateHeader,
    downloadAdmissionAppByAdmin,
    deleteTemplateHeader,
    createAdmissionQuota,
    updateAdmissionQuota,
    getAdmissionQuotas,
    getAdmissionQuotaById,
    createTemplateBody,
    getTemplateBodies,
    getTemplateBodyById,
    updateTemplateBody,
    deleteAdmissionQuota,
    deleteTemplateBody,
    getEligibilityFieldData,
    createTemplateLinkToProgram,
    getProgramLinkTemplate,
    deleteProgramLinkTemplate,
    getStudentApplicants,
    getStudenApplicantsFilterData,
    deleteCampaignLinkedProgram,
    getStudentApplicantFilterProgram,
    getQuotaPrograms,
    downloadBulkList,
    getLockedMeritList,
    createOfferLetterTemplate,
    updateOfferLetterTemplate,
    getOfferLetterTemplateById,
    getOfferLetterTemplates,
    deleteOfferLetterTemplate,
    downloadFeeChallan,
    syncRegisteredStudents,
  };
};

export default useAdmissions;
