export class JobTemplateDTO {
  id!: number;
  no_of_seats: number;
  title: string = "";
  description: string = "";
  required_experience: string = "";
  pay_scale: string = "";
  age_min: string = "";
  age_max: string = "";
  eligibility: string = "";
  department: string = "";
  challan_fee: string = "";
  certificate_level: string = "";
  voucher_template_id: string = "";
  designation_id: string = "";
  research: string = "";
  preferences: string = "";
  min_qualification: string = "";
  modified_date: string = "";
  additional_notes: string = "";
  acc_application_id: string = "";
}
