import ForgetPassword from "containers/public/users/forget-password";
import UsersLogin from "containers/public/users/login";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

const UsersPublicRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/login" Component={UsersLogin} />
            <Route path="/login/forget-password" Component={ForgetPassword} />
        </Routes>
    )
}

export default UsersPublicRoutes;