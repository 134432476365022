import useUtils from "hooks/useUtils";
import { DSSYSTEM_APIS } from "libs/apis/d-support-system.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AcademicsDataDTO } from "utils/helpers/models/ds-system/academics-data.dto";
import {
  AcademicDashboardGradebookDataDTO,
  CourseAnomaliesDTO,
} from "utils/helpers/models/ds-system/course-anomalies.dto";
import { EnrolledCourseTeachersDTO } from "utils/helpers/models/ds-system/enrolled-courses-teachers.dto";
import { EnrolledStudentsDTO } from "utils/helpers/models/ds-system/enrolled-students.dto";

import { NotEnrolledStudentsDTO } from "utils/helpers/models/ds-system/not-enrolled-students.dto";
import { ReportsDTO } from "utils/helpers/models/ds-system/reports.dto";
import { StudentAnomaliesDTO } from "utils/helpers/models/ds-system/student-anomalies.dto";

const useDSSystem = () => {
  const navigate = useNavigate();
  const { downloadFileWithUrl } = useUtils();

  const getAdmissionDashboard = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getAdmissionDashboard(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };
  const getFilterDataForAdmDashboard = async (setData: Function) => {
    const response = await DSSYSTEM_APIS.getFilterDataForAdmDashboard();
    if (response?.status) {
      setData(response?.response);
    }
  };

  const getCourseAnomalies = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getCourseAnomalies(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new CourseAnomaliesDTO(data ?? {}));
    }
  };
  const getEnrolledStudents = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getEnrolledStudents(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new EnrolledStudentsDTO(data ?? {}));
    }
  };
  const getAcademicDashboardGradebookData = async (
    setData: Function,
    params: any
  ) => {
    const response = await DSSYSTEM_APIS.getAcademicDashboardGradebookData(
      params
    );
    const { status, response: data } = response || {};
    if (status) {
      setData(new AcademicDashboardGradebookDataDTO(data ?? {}));
    }
  };
  const getNotEnrolledStudents = async (
    setData: Function,
    queryParams: any
  ) => {
    const response = await DSSYSTEM_APIS.getNotEnrolledStudents(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new NotEnrolledStudentsDTO(data ?? {}));
    }
  };
  const getStudentAnomalies = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getStudentAnomalies(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new StudentAnomaliesDTO(data ?? {}));
    }
  };
  const getEnrolledCoursesAndTeachers = async (
    setData: Function,
    queryParams: any
  ) => {
    const response = await DSSYSTEM_APIS.getEnrolledCoursesAndTeachers(
      queryParams
    );
    const { status, response: data } = response || {};
    if (status) {
      setData(new EnrolledCourseTeachersDTO(data ?? {}));
    }
  };
  const getAcademicsData = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getAcademicsData(queryParams);
    const { status, response: data } = response || {};
    if (status) {
      setData(new AcademicsDataDTO(data ?? {}));
    }
  };

  const getFinanceDashboard = async (setDashData: Function) => {
    const response = await DSSYSTEM_APIS.getFinanceDashboard();
    const data = response?.response;
    if (response?.status) {
      setDashData(data);
    }
  };
  const getReportsList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await DSSYSTEM_APIS.getReportsList(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(Array.isArray(data) ? data?.map((i) => new ReportsDTO(i)) : []);
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i: ReportsDTO) => new ReportsDTO(i))
            : []
        );
      }
    }
  };
  const getReportById = async (id: number, setFormData: Function) => {
    const response = await DSSYSTEM_APIS.getReportById(id);
    const { status } = response || {};
    if (status) {
      setFormData(response?.response);
    }
  };

  const deleteReportsById = async (
    id: number,
    setData: Function,
    queryParams: any,
    setPagination: Function
  ) => {
    const response = await DSSYSTEM_APIS.deleteReportsById(id);
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
      getReportsList(setData, queryParams, setPagination);
    }
  };

  const createReports = async (
    body: ReportsDTO,
    addMore: boolean = false,
    resetForm: Function
  ) => {
    const response = await DSSYSTEM_APIS.createReports(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.resportsListing);
      }
    }
  };
  const updateReports = async (id: number, body: ReportsDTO) => {
    const response = await DSSYSTEM_APIS.updateReports(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.resportsListing);
    }
  };

  const downloadReportsWithUrl = async (urlToSend: string) => {
    const response = await DSSYSTEM_APIS.downloadReportsWithUrl(urlToSend);
    const { status = false } = response || {};
    const url = response?.response?.url || "";
    if (status) {
      if (url) {
        downloadFileWithUrl(url);
      }
    }
  };
  const downloadReportsWithUrlAndSessionID = async (
    session_id: number,
    urlToSend: string
  ) => {
    const urlWithParams = `${urlToSend}/${session_id}`;
    const response = await DSSYSTEM_APIS.downloadReportsWithUrlAndSessionID(
      urlWithParams
    );
    const { status = false } = response || {};
    const url = response?.response?.url || "";
    if (url) {
      downloadFileWithUrl(url);
    }
  };

  const getExecutiveSummaryFeeIncomeDashboard = async (
    setData: Function,
    params: any
  ) => {
    const response = await DSSYSTEM_APIS.getExecutiveSummaryFeeIncomeDashboard(
      params
    );
    const { status } = response || {};
    if (status) {
      setData(response?.response);
    }
  };
  const getSemesterFeeIncomeDashboard = async (
    setData: Function,
    params: any
  ) => {
    const response = await DSSYSTEM_APIS.getSemesterFeeIncomeDashboard(params);
    const { status } = response || {};
    if (status) {
      setData(response?.response);
    }
  };
  const getYearWiseSummaryFee = async (setData: Function, params: any) => {
    const response = await DSSYSTEM_APIS.getYearWiseSummaryFee(params);
    const { status } = response || {};
    if (status) {
      setData(response?.response?.year_wise_semester_fee_summary);
    }
  };
  const getSemesterFeeInstallments = async (
    setDataSummary: Function,
    setDataDetails: Function,
    params: any
  ) => {
    const response = await DSSYSTEM_APIS.getSemesterFeeInstallments(params);
    const { status } = response || {};

    if (status) {
      setDataSummary(response?.response?.installment_summary || []);
      setDataDetails(response?.response?.installment_details || []);
    }
  };

  return {
    getFinanceDashboard,
    getAdmissionDashboard,
    getAcademicsData,
    getFilterDataForAdmDashboard,
    getEnrolledCoursesAndTeachers,
    getStudentAnomalies,
    getNotEnrolledStudents,
    getEnrolledStudents,
    getAcademicDashboardGradebookData,
    getCourseAnomalies,
    getReportsList,
    getReportById,
    createReports,
    updateReports,
    downloadReportsWithUrl,
    deleteReportsById,
    downloadReportsWithUrlAndSessionID,
    getExecutiveSummaryFeeIncomeDashboard,
    getSemesterFeeInstallments,
    getSemesterFeeIncomeDashboard,
    getYearWiseSummaryFee,
  };
};

export default useDSSystem;
