export class LegalFrameWorksDocumentDTO {
  id!: number;
  title: string = "";
  type_id: number;
  superior_law: string = "";
  date: string = "";
  validity_from: string = "";
  validity_to: string = "";
  file_path: string = "";
  version: string = "";
  legal_doc_type: {
    legal_organization: { name: string };
  } = { legal_organization: { name: "" } };
  constructor(data = {}) {
    Object.assign(this, data);
  }
}
