import { FC, Fragment, useState, useEffect } from "react";
import {
  AddTablSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  DownloadTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdvisorsRatesListingMain,
  AdvisorsRatesListingSection,
  AdvisorsRatesListingTop,
} from "./style";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import DataNotFound from "components/particles/table/data-not-found";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { AdviserRatesDTO } from "utils/helpers/models/legal-matters/adviser-rates.dto";
import useUtils from "hooks/useUtils";
import useLegalMatters from "../../useHook";
import { useSelector } from "react-redux";

const AdvisorsRatesListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    { title: "Advisors Rates", path: siteRoutes.advisorRatesListing },
  ];

  const columns: string[] = [
    "Title",
    "Rate",
    "Year",
    "Description",
    "Category",
    "Is Avtive",
    "Actions",
  ];

  const navigate = useNavigate();

  const { handleSearchChange, handleTableSearch, createQuery } = useUtils();
  const { getAdviserRates, deleteAdviserRatesByID } = useLegalMatters();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<AdviserRatesDTO[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const goToCreateAdvsorRates = () => {
    navigate(siteRoutes.createAdvisorRates);
  };
  const handleEdit = (id: number) => {
    const params = createQuery({ id });
    navigate(siteRoutes.createAdvisorRates + params);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deleteAdviserRatesByID(id, setData, queryParams, setPagination);
    }
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllLegalRatesList(page + 1, search, per_page);
  };

  const getAllLegalRatesList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getAdviserRates(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getAllLegalRatesList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AdvisorsRatesListingMain>
      <AdvisorsRatesListingTop>
        <div className="left">
          <span className="page-heading">Advisors Rates</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn" onClick={goToCreateAdvsorRates}>
              + Add New
            </button>
          </div>
        </div>
      </AdvisorsRatesListingTop>

      <AdvisorsRatesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllLegalRatesList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllLegalRatesList)
              }
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Fragment>
                {data?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">{item?.title ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.rate ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-100">{item?.year ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">{item?.description ?? "-"}</div>
                    </td>
                    <td>
                      <div className="mw-150">
                        {item?.legal_advisor_category ?? "-"}
                      </div>
                    </td>
                    <td>
                      <span
                        className={`status-tile ${
                          item?.is_active === 1 ? "green" : "red"
                        }`}
                      >
                        {item?.is_active === 1 ? "Yes" : "No"}
                      </span>
                    </td>
                    <td>
                      <div className="table-action-icons">
                        <button
                          className="action-icon"
                          onClick={() => handleEdit(item?.id)}
                        >
                          <EditTableSvg />
                        </button>

                        <button
                          className="action-icon"
                          onClick={() => handleDelete(item?.id)}
                        >
                          <DeleteTableSvg />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </Fragment>
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </AdvisorsRatesListingSection>
    </AdvisorsRatesListingMain>
  );
};

export default AdvisorsRatesListing;
