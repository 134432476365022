import { FC, Fragment, useEffect, useState } from "react";
import {
  SubmittedCoursesListingSection,
  SubmittedCoursesListingMain,
  SubmittedCoursesListingTop,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  BlackDownloadSvg,
  BlackSettingTableSvg,
  BlackTableViewSvg,
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  SettingsSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useFinance from "containers/private/finance/useHooks";
// import { AcademicCourseInfoDTO } from "utils/helpers/models/finance/bank-info.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "../useHooks";
import { SubmittedCoursesListDTO } from "utils/helpers/models/academics/consolidated-result.dto";

interface SubmittedCoursesListingProps {}

const SubmittedCoursesListing: FC<SubmittedCoursesListingProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Consolidated Results /",
      path: siteRoutes.consolidatedResultsListing,
    },
    { title: "Section Courses /", path: siteRoutes.sectionCoursesListing },
    { title: "Submitted Courses", path: siteRoutes.submittedCoursesListing },
  ];

  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { getSubmittedCoursesList, downloadConsolidatedResult } =
    useAcademics();
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const { section_id, program_id, section_title } = getQueryParams();

  const [data, setData] = useState<SubmittedCoursesListDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Course Title",
    "Credit Hours",
    "Total Marks",
    "Students",
    "Submitted By",
    "Verified",
    "Approved",
    "Acknowledged",
    "Action",
  ];

  const downloadConsolidatedResultList = () => {
    downloadConsolidatedResult(section_id);
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAcademicCourseInfos(page + 1, search);
  };

  const getAllAcademicCourseInfos = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      section_id,
      program_id,
    };
    getSubmittedCoursesList(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getAllAcademicCourseInfos(pagination.page, search);
  }, []);

  return (
    <SubmittedCoursesListingMain>
      <SubmittedCoursesListingTop>
        <div className="left">
          <span className="page-heading">Submitted Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </SubmittedCoursesListingTop>

      <SubmittedCoursesListingSection className="content-radius-shadow">
        <div className="table-info-header">
          <div className="heading">
            <span>
              {section_title ? decodeURIComponent(section_title) : ""}
            </span>
          </div>
        </div>
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllAcademicCourseInfos
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllAcademicCourseInfos
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((course, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="mw-150">
                        {course?.course_title ?? "-"}
                      </div>
                    </td>
                    <td>{course?.tc_credit_hours ?? "-"}</td>
                    <td>{course?.tc_total_marks ?? "-"}</td>
                    <td>{course?.enrolled_students_count ?? "-"}</td>
                    <td>{course?.submitted_by ?? "-"}</td>
                    <td>
                      <div className="status">
                        <span
                          className={`status-tile ${
                            course?.verified_status === 1 ? "green" : "red"
                          }`}
                        >
                          {course?.verified_status === 1 ? "Yes" : "No"}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span
                          className={`status-tile ${
                            course?.approved_status === 1 ? "green" : "red"
                          }`}
                        >
                          {course?.approved_status === 1 ? "Yes" : "No"}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span
                          className={`status-tile ${
                            course?.receipt_acknowledged_status === 1
                              ? "green"
                              : "red"
                          }`}
                        >
                          {course?.receipt_acknowledged_status === 1
                            ? "Yes"
                            : "No"}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className="table-action-buttons">
                        <div className="table-action-button">
                          <button
                            className="green"
                            onClick={downloadConsolidatedResultList}
                          >
                            Download list
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </SubmittedCoursesListingSection>
    </SubmittedCoursesListingMain>
  );
};

export default SubmittedCoursesListing;
