import { FC, useEffect, useState } from "react";
import {
  CreateLegalCourtsTop,
  CreateLegalCourtsSection,
  CreateLegalCourtsMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import { useForm } from "react-hook-form";
import useLegalMatters from "../../useHook";
import { LegalCourtsDTO } from "utils/helpers/models/legal-matters/legal-courts-dto";

const CreateLegalCourts: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    { title: "Legal Courts /", path: siteRoutes.legalCourtList },
    {
      title: `${params?.id ? "Update" : "Add"} Legal Court`,
      path: siteRoutes.createLegalCourt,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LegalCourtsDTO>();
  const { updateLegalCourts, createLegalCourts, getLegalCourtsByID } =
    useLegalMatters();

  const [formData, setFormData] = useState<LegalCourtsDTO>(
    new LegalCourtsDTO()
  );

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new LegalCourtsDTO()[
        key as keyof LegalCourtsDTO
      ];
      setValue(key as keyof LegalCourtsDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = (data: LegalCourtsDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateLegalCourts(params?.id, data);
    } else {
      createLegalCourts(data, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getLegalCourtsByID(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreateLegalCourtsMain>
      <CreateLegalCourtsTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Legal Courts
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateLegalCourtsTop>

      <CreateLegalCourtsSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Title </label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData?.title}
                  onChange={handleChange}
                  placeholder="title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
          <div className="input-field">
            <label>Description</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("description", { required: true })}
                  value={formData?.description}
                  onChange={handleChange}
                  placeholder="descryption"
                />
              </div>
              <FormErrorMessage error={errors.description} />
            </div>
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <button
                type="button"
                className="lg-rounded-btn green"
                onClick={handleSubmit((data) => onSubmit(data, true))}
              >
                Submit & Addmore
              </button>
            )}
            <button
              type="button"
              className="lg-rounded-btn"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              {params?.id ? "Update" : "Submit"} & Close
            </button>
          </div>
        </div>
      </CreateLegalCourtsSection>
    </CreateLegalCourtsMain>
  );
};

export default CreateLegalCourts;
