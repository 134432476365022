import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreatePlanOfStudyForm,
  CreatePlanOfStudyMain,
  CreatePlanOfStudyTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAcademics from "../../useHooks";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useOrganization from "containers/private/organization/useHooks";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import SingleSelectField from "components/particles/forms/single-select-field";

export const CreatePlanOfStudy: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Plan of Studies  / ", path: siteRoutes.academicPlanofStudies },
    {
      title: `${params?.id ? "Update" : "Add"} Plan Of Studies`,
      path: siteRoutes.createStudyPlans,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<PlanOfStudyDTO>();
  let [formData, setFormData] = useState<PlanOfStudyDTO>(new PlanOfStudyDTO());
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const { createPlanOfStudy, updatePlanOfStudy, getPlanOfStudyById } =
    useAcademics();
  const { getPrograms } = useOrganization();

  const onSubmit = () => {
    if (params?.id) {
      updatePlanOfStudy(params?.id, formData);
    } else {
      createPlanOfStudy(formData);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    formData = new PlanOfStudyDTO();
    for (let key in formData) {
      setValue(
        key as keyof PlanOfStudyDTO,
        formData[key as keyof PlanOfStudyDTO]
      );
    }
    setFormData({ ...formData });
  };

  useEffect(() => {
    if (params?.id) {
      getPlanOfStudyById(params?.id, formData, setFormData, setValue);
    }
    getPrograms(setPrograms);
  }, []);

  const onSelectCustom = (value: number, name: string) => {
    handleChange({ target: { name, value } });
  };

  useEffect(() => {
    register("program_id", { required: true });
  }, []);

  return (
    <CreatePlanOfStudyMain>
      <CreatePlanOfStudyTop>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Plan of study
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreatePlanOfStudyTop>

      <CreatePlanOfStudyForm
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Enter Title"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <SingleSelectField
            options={programs}
            value={formData.program_id}
            onSelect={(program) => onSelectCustom(program, "program_id")}
            label="Program"
            helperText="Select Program"
            searchable={true}
            error={errors.program_id}
          />

          <div className="input-field">
            <label>Total Semesters</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("total_semesters", { required: true })}
                  value={formData.total_semesters}
                  onChange={handleChange}
                >
                  <option value="">Select Semester</option>
                  {Array.from({ length: 8 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.total_semesters} />
            </div>
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn">
              {params?.id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </CreatePlanOfStudyForm>
    </CreatePlanOfStudyMain>
  );
};

export default CreatePlanOfStudy;
