import axios from "utils/helpers/common/axios.config";
import useStore from "./useStore";
import { errorToaster } from "utils/helpers/common/alert-service";
import { errorMessages } from "utils/helpers/enums/messages.enum";
import { ORGANIZATION_APIS } from "libs/apis/organization.api";
import { USER_APIS } from "libs/apis/user.api";

const useHttp = () => {
  // const { errorToaster } = useToaster();
  const MAX_RETRIES = 2;
  const { logout, setLoading, setOrganization, setPermissions, setOrganizationGlobals, setPriorLogoutRoute } = useStore();

  function configureHeaders() {
    axios.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        // Promise.reject(error)
      }
    );
  }

  const configureInterceptors = () => {
    axios.interceptors.response.use(
      (response: any) => {
        setLoading(false);
        if (response?.data?.status === false || response?.data?.status == 0)
          displayApiErrors(response);
        return response;
      },
      async (error) => {
        const config = error.config || {};
        config.__retryCount = (config?.__retryCount || 0);
        if (error?.response?.status === 401) {
          setPriorLogoutRoute(window.location.pathname + window.location.search);
          setTimeout(() => {
            logout();
          }, 100);
        } else {
          const isTimeout = error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK ';
          const isNetworkError = !error.response && error.message === 'Network Error';
          if (isTimeout || isNetworkError) {
            if (config?.__retryCount < MAX_RETRIES) {
              config.__retryCount += 1;
              return axios(config);
            }
          }
        }
        setLoading(false);
        return Promise.reject(error);
      }
    );
  };

  const displayApiErrors = (response: any) => {
    errorToaster(
      response?.data?.errors?.length &&
        Array.isArray(response?.data?.errors)
        ? response?.data?.errors[0]
        : response?.data?.message?.length
          ? response?.data?.message
          : response?.data?.error?.length
            ? response?.data?.error : response?.data?.errors ? response?.data?.errors
              : errorMessages.somethingWentWrong
    );
  };

  const getCurrentOrganization = async () => {
    const response = await ORGANIZATION_APIS.getCurrentOrganization();
    const organization = response;
    if (organization) {
      setOrganizationGlobals(organization);
      setOrganization(organization);
    }
  };

  const getMyPermissions = async () => {
    const response = await USER_APIS.getMyPermissions();
    const { status = false, response: { permissions = [] } = {} } = response || {};
    if (status && permissions.length) {
      const permissionsToSet = [];
      permissions?.forEach(({ permissions }) => {
        permissions.map((permission) => {
          const doesAlreadyExist = permissionsToSet.find(i => i.name === permission.name);
          if (!doesAlreadyExist) {
            permissionsToSet.push(permission);
          }
        })
      });
    setPermissions(permissionsToSet);
    }
  }

  return {
    configureHeaders,
    getMyPermissions,
    getCurrentOrganization,
    configureInterceptors,
  };
};

export default useHttp;
