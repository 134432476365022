import { FC, Fragment, useState, useEffect } from "react";
import {
  CallTableSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  TableBlackRightArrowSvg,
  TableDownloadPrimarySvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  LegalCasesListingMain,
  LegalCasesListingSection,
  LegalCasesListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import { LegalCasesDTO } from "utils/helpers/models/legal-matters/legal-cases.dto";
import useUtils from "hooks/useUtils";
import useLegalMatters from "../../useHook";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { OrgStructureTypes } from "utils/helpers/enums/shared.enums";
import { OrgStructureDTO } from "utils/helpers/models/organization/org-structure.dto";
import useOrganization from "containers/private/organization/useHooks";
import { LegalCourtsDTO } from "utils/helpers/models/legal-matters/legal-courts-dto";
import { LegalAdviserDTO } from "utils/helpers/models/legal-matters/legal-adviser.dto";
import { MatterTypeDTO } from "utils/helpers/models/legal-matters/matter-type.dto";

interface AdmissionStudentListingProps {}

const LegalCasesListing: FC<AdmissionStudentListingProps> = ({}) => {
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    {
      title: "Legal Cases",
      path: siteRoutes.legalCasesList,
    },
  ];
  const columns: string[] = [
    "Sr.",
    "Institution Date",
    "Case No",
    "Title",
    "Nature",
    "Matter",
    "Court",
    "Deffendant",
    "Plaintiff/Petitioner",
    "Current Legal Advisor",
    "Current Status",
    // "Decision",
    // "Decision Date",  // hide by backend team decision
    "Action",
  ];

  const { handleSearchChange, handleTableSearch, getYears } = useUtils();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const { getLegalCase, deleteLegalCaseByID } = useLegalMatters();
  const { getSpecificOrgStructures } = useOrganization();
  const { getLegalCourts, getLegalAdviser, getLegalMatterTypesList } =
    useLegalMatters();

  const [data, setData] = useState<LegalCasesDTO[]>([]);
  const [search, setSearch] = useState("");
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [legalCourts, setLegalCourts] = useState<LegalCourtsDTO[]>([]);
  const [departments, setDepartments] = useState<OrgStructureDTO[]>([]);
  const [legalAdviser, setLegalAdviser] = useState<LegalAdviserDTO[]>([]);
  const [matterType, setLegalMatter] = useState<MatterTypeDTO[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [filters, setFilters] = useState<any>({
    court: "",
    department: "",
    status: "",
    legal_advisor: "",
    matter_category: "",
    year: "",
  });
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllLegalCasesList(page + 1, search, per_page);
  };
  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const goToCreateLegalCases = () => {
    navigate(siteRoutes.createLegalCases);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    filters[name] = value;
    setFilters({ ...filters });
  };
  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllLegalCasesList(pagination?.page, search, pagination?.per_page);
  };

  const onApplyFilters = () => {
    getAllLegalCasesList(pagination?.page, search, pagination?.per_page);
  };
  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deleteLegalCaseByID(id, setData, queryParams, setPagination);
    }
  };
  const getAllLegalCasesList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    const queryParams = {
      page,
      per_page,
      search,
      ...filteredFilters,
    };
    getLegalCase(setData, queryParams, setPagination);
  };
  useEffect(() => {
    getLegalCourts(setLegalCourts);
    getLegalAdviser(setLegalAdviser);
    getLegalMatterTypesList(setLegalMatter);
    getSpecificOrgStructures(setDepartments, {
      type: OrgStructureTypes.department,
      per_page: "All",
    });
    getAllLegalCasesList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <LegalCasesListingMain>
      <LegalCasesListingTop>
        <div className="left">
          <span className="page-heading">Legal Cases</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn" onClick={goToCreateLegalCases}>
              + Add New
            </button>
          </div>
        </div>
      </LegalCasesListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Court</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="court"
                      value={filters.court}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {legalCourts?.map((item, index) => {
                        return (
                          <option key={index} value={item?.id}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="department"
                      value={filters.department}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {departments?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.title ?? "-"}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="status"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      <option value="1">Active</option>
                      <option value="0">Not Active</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Legal Advisor</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="legal_advisor"
                      value={filters.legal_advisor}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {legalAdviser?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.name ?? "-"}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Matter Category</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="matter_category"
                      value={filters.matter_category}
                      onChange={handleFilterChange}
                    >
                      <option value="">-- Select --</option>
                      {matterType?.map((item, index) => {
                        return (
                          <option key={index} value={item?.id}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Year</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      name="year"
                      value={filters.year}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Year</option>
                      {getYears(2010).map((year, index) => {
                        return (
                          <option value={year} key={index}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>

      <LegalCasesListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllLegalCasesList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllLegalCasesList)
              }
              placeholder="Search"
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Fragment>
                {data?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <div className="mw-100">
                          {item?.institution_date ?? "-"}
                        </div>
                      </td>
                      <td>{item?.case_no ?? ""}</td>
                      <td>
                        <div className="mw-100">{item?.title ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {item?.legal_case_nature?.title ?? "-"}
                        </div>
                      </td>
                      <td>
                        <div className="mw-100">
                          {item.legal_matter_type?.title ?? "-"}
                        </div>
                      </td>
                      <td>
                        <div className="mw-100">
                          {item?.legal_court?.title ?? "-"}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {item?.category?.title ?? "-"}
                        </div>
                      </td>
                      <td>
                        <div className="mw-150">{item?.plaintiff ?? "-"}</div>
                      </td>
                      <td>
                        <div className="mw-150">
                          {item?.legal_advisor?.name ?? "-"}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`status-tile ${
                            item?.status === 1 ? "green" : "red"
                          }`}
                        >
                          {item?.status === 1 ? "Active" : "Not Active"}
                        </div>
                      </td>
                      {/* <td>-</td>
                      <td>-</td> */}
                      <td>
                        <div className="table-action-icons">
                          <div className="action-button">
                            <button className="special-btn">TimeLine</button>
                          </div>
                          <div className="action-button">
                            <button className="seats-btn">Document</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </LegalCasesListingSection>
    </LegalCasesListingMain>
  );
};

export default LegalCasesListing;
