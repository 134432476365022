export class EnrollmentsListDTO {
  id: number;
  student: {
    reg_number: string;
    campus: { title: string };
    program: { title: string };
    is_repeater: number;
    user: { name: string };
  };
  enrollment_status: { title: string; id: number };
  course_id: number;

  constructor(data: Partial<EnrollmentsListDTO> = {}) {
    this.course_id = null;
    this.enrollment_status = { title: "", id: 0 };
    this.student = {
      reg_number: "",
      campus: { title: "" },
      program: { title: "" },
      is_repeater: 0,
      user: { name: "" },
      ...data.student,
    };

    Object.assign(this, data);
  }
}
