import { FC, useEffect, useState } from "react";
import {
  CreateMatterTypesTop,
  CreateMatterTypesSection,
  CreateMatterTypesMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { MatterTypeDTO } from "utils/helpers/models/legal-matters/matter-type.dto";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import useLegalMatters from "../../useHook";

const CreateMatterTypes: FC = () => {
  const breadcrumbLinks = [
    { title: "Manage Legal Matters /", path: "" },
    { title: "Matter Types /", path: siteRoutes.legalMatterTypeListing },
    { title: "Add Matter Types", path: siteRoutes.createLegalMatterType },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<MatterTypeDTO>();

  const { getQueryParams } = useUtils();
  const {
    getLegalmatterTypeById,
    updateLegalmatterTypeById,
    createLegalMatterType,
  } = useLegalMatters();
  const params = getQueryParams();
  const [formData, setFormData] = useState<MatterTypeDTO>(new MatterTypeDTO());
  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      (formData as any)[key] = new MatterTypeDTO()[key as keyof MatterTypeDTO];
      setValue(key as keyof MatterTypeDTO, (formData as any)[key]);
    }
    setFormData({ ...formData });
  };

  const onSubmit = () => {
    if (params?.id) {
      updateLegalmatterTypeById(params?.id, formData);
    } else {
      createLegalMatterType(formData, false, resetForm);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getLegalmatterTypeById(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreateMatterTypesMain>
      <CreateMatterTypesTop>
        <div className="heading">
          <span className="page-heading">Add Matter Types</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateMatterTypesTop>

      <CreateMatterTypesSection
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <div className="input-field">
            <label>Descryption</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("description", { required: false })}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Descryption"
                />
              </div>
              <FormErrorMessage error={errors.description} />
            </div>
          </div>

          <div className="input-field">
            <label>Parent Category</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("parent_id", { required: false })}
                  value={formData?.parent_id}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                </select>
              </div>
            </div>
            <FormErrorMessage error={errors.parent_id} />
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </CreateMatterTypesSection>
    </CreateMatterTypesMain>
  );
};

export default CreateMatterTypes;
