export class UploadUpdateMasterBookDTO {
  remarks: string = "";
  status: string = "";
  transaction_id: number = 0;
  master_id: number = 0;
  challan_file: any;
  collection_date: string = "";

  constructor(data: Partial<UploadUpdateMasterBookDTO> = {}) {
    Object.assign(this, data);
  }
}
