import useUtils from "hooks/useUtils";
import { USER_APIS } from "libs/apis/user.api";
import {
  errorToaster,
  successToaster,
} from "utils/helpers/common/alert-service";
import { AddAdmissionApplicationDTO } from "utils/helpers/models/e-portal/add-admission-application.dto";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { ChangePasswordDTO } from "utils/helpers/models/e-portal/change-passwords.dto";
import { CityDTO } from "utils/helpers/models/organization/city.dto";

const useEportal: any = () => {
  const { openExternalLink, downloadFileWithUrl } = useUtils();
  const navigate = useNavigate();
  const { setUserAlreadyApplied, setUser, logout } = useStore();

  const getProfile = async (
    setFormData: Function,
    setOldFormData?: Function,
    setValue?: Function,
    formData?: any
  ) => {
    const response = await USER_APIS.getProfile();
    if (response?.status) {
      const { users_meta } = response?.response;
      const data: any = { ...response?.response, ...users_meta[0] };
      // delete data?.users_meta;
      if (formData && setOldFormData && setValue) {
        for (let key in formData) {
          if (key === "users_meta") {
            for (let prop in data[key][0]) {
              formData[prop] = data[key][0][prop];
              setValue(prop, formData[prop]);
            }
          } else {
            formData[key] = data[key];
            setValue(key, formData[key]);
          }
        }
        setFormData({ ...formData });
        setOldFormData({ ...formData });
      } else {
        setFormData({ ...data });
      }
    }
  };

  const getStudentPersonalInfo = async (
    formData: any,
    setFormData: Function,
    setValue: Function
  ) => {
    const response = await USER_APIS.getProfile();

    if (response?.status) {
      const { users_meta } = response?.response;
      const data: any = { ...response?.response, ...users_meta[0] };
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };

  const getCitiesByUser: any = async (setData: Function) => {
    const response = await USER_APIS.getCitiesByUser({ per_page: "All" });
    if (response?.status) {
      setData(
        Array.isArray(response?.response)
          ? response?.response?.map((i) => new CityDTO(i))
          : []
      );
    }
  };

  const getLevelsDegreesAndBoards = async (
    setCertificateLevels: Function,
    setDegreeCertificates: Function,
    setBoards: Function
  ) => {
    const response = await USER_APIS.getLevelsDegreesAndBoards({});
    if (response?.response) {
      const {
        response: { boards, certificatelevels, degreeertificates },
      } = response || { response: {} };
      setCertificateLevels(certificatelevels);
      setDegreeCertificates(degreeertificates);
      setBoards(boards);
    }
  };

  const updateUserPersonalInfo = async (
    formData: any,
    goNext?: any,
    step?: any,
    setIsErrored?: Function
  ) => {
    const response = await USER_APIS.updateUserPersonalInfo(formData);
    if (response?.status) {
      successToaster(response?.message);
      if (goNext && step) {
        goNext(step);
        if (setIsErrored) setIsErrored(false);
      } else {
        navigate(siteRoutes.ePortalDashboard);
      }
      getMyProfile();
    } else {
      if (setIsErrored) setIsErrored(true);
    }
  };

  const updateUserGuradianInfo = async (
    formData: any,
    goNext: any,
    step: any,
    setIsErrored: Function
  ) => {
    const response = await USER_APIS.updateUserGuradianInfo(formData);
    if (response?.status) {
      successToaster(response?.message);
      setIsErrored(false);
      goNext(step);
    } else {
      setIsErrored(true);
    }
  };

  const updateUserAdditionalInfo = async (
    formData: any,
    goNext: any,
    step: any,
    setIsErrored: Function
  ) => {
    const response = await USER_APIS.updateUserAdditionalInfo(formData);
    if (response?.status) {
      successToaster(response?.message);
      setIsErrored(false);
      goNext(step);
    } else {
      setIsErrored(true);
    }
  };

  const getMyVouchers = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await USER_APIS.getMyVouchers(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const createEntryTest = async (
    formData: FormData,
    handleCloseModal: Function,
    addMore: boolean = false,
    resetForm: Function
  ) => {
    const response = await USER_APIS.createEntryTest(formData);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        resetForm();
      } else {
        handleCloseModal();
      }
    }
  };

  const updateEntryTest = async (
    id: number,
    formData: FormData,
    handleCloseModal: Function
  ) => {
    const response = await USER_APIS.updateEntryTest(id, formData);
    if (response?.status) {
      successToaster(response?.message);
      handleCloseModal();
    }
  };

  const getEntryTestsByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getEntryTestsByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };

  const createQualification = async (
    formData: FormData,
    handleCloseModal: Function,
    addMore: boolean = false,
    resetForm: Function
  ) => {
    const response = await USER_APIS.createQualification(formData);
    if (response?.status) {
      successToaster(response?.message);
      if (addMore) {
        resetForm();
      } else {
        handleCloseModal();
      }
    }
  };

  const updateQualification = async (
    id: number,
    formData: FormData,
    handleCloseModal: Function
  ) => {
    const response = await USER_APIS.updateQualification(id, formData);
    if (response?.status) {
      successToaster(response?.message);
      handleCloseModal();
    }
  };

  const getQualificationsByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All", status: 1 },
    clearQualificationRefs?: Function
  ) => {
    const response = await USER_APIS.getQualificationsByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
      if (clearQualificationRefs) {
        // clearQualificationRefs();
      }
    }
  };

  const getStudentsApplications = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getStudentsApplications(queryParams);
  };

  const getStudentProgramsToApply = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getStudentProgramsToApply(queryParams);
  };

  const getStudentCertificateLevels = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getStudentProgramsToApply(queryParams);
  };

  const getStudentDegreeCertificates = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getStudentDegreeCertificates(queryParams);
  };

  const getSubjectsByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getSubjectsByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };

  const getStudentBoards = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getStudentBoards(queryParams);
  };

  const getBoardsByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getBoardsByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };

  const getCertificateLevelsByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getCertificateLevelsByUser(queryParams);
    // console.log(response);

    if (response?.status) {
      setData(response?.response);
    }
  };

  const getDegreeCertificatesByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getDegreeCertificatesByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };
  const getJobTypesByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getJobTypesByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };

  const getResultTypesByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getResultTypesByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };

  const deleteQualificationByUser = async (
    id: number,
    setQualifications: Function
  ) => {
    const response = await USER_APIS.deleteQualificationByUser(id);
    if (response?.status) {
      successToaster(response?.message);
      getQualificationsByUser(setQualifications);
    }
  };

  const downloadQualificationDocumentByUser = async (id: number) => {
    const response = await USER_APIS.downloadQualificationDocumentByUser(id);
    if (response?.url) {
      downloadFileWithUrl(response?.url);
    }
  };

  const deleteEntryTestByUser = async (id: number, setEntryTests: Function) => {
    const response = await USER_APIS.deleteEntryTestByUser(id);
    if (response?.status) {
      successToaster(response?.message);
      getEntryTestsByUser(setEntryTests);
    }
  };

  const getEntryTestTypesByUser = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getEntryTestTypesByUser(queryParams);
    if (response?.status) {
      setData(response?.response);
    }
  };

  const getAdmissionPrograms = async (
    setPrograms: Function,
    queryParams: any = {}
  ) => {
    const response = await USER_APIS.getAdmissionPrograms(queryParams);
    if (response) {
      setPrograms(response.programs);
    }
  };

  const submitAdmissionApplication = async (
    body: AddAdmissionApplicationDTO
  ) => {
    const response = await USER_APIS.submitAdmissionApplication(body);
    if (response?.status) {
      setUserAlreadyApplied(true);
      successToaster(response?.message);
      navigate(siteRoutes.eportalAdmissionsListing);
    }
  };

  const getStudentApplications = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getStudentApplications(queryParams);
    const data = response?.response;
    if (data) {
      const dataToSet: any[] = data?.programs?.map((p: any, i: number) => ({
        ...p,
        app_merit: data?.app_merit[p.app_no],
        p_fee_template_link: data?.p_fee_template_link[p.app_no],
        eligibility: response?.apps_eligibility[i],
      }));
      setData(dataToSet);
    }
  };

  const changePasswordByUser = async (body: ChangePasswordDTO) => {
    const response = await USER_APIS.changePasswordByUser(body);
    const { message = "", status = false } = response || {};
    if (status) {
      successToaster(message);
      logout();
    }
  };

  const createEmployementInfo = async (
    body: any,
    setOpen: Function,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await USER_APIS.createEmployementInfo(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      if (addMore) {
        reset();
      } else {
        setOpen(false);
      }
    }
  };

  const updateEmployementInfo = async (
    id: number,
    body: any,
    setOpen: Function
  ) => {
    const response = await USER_APIS.updateEmployementInfo(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const getEmployementInfos = async (
    setData: Function,
    clearEmployementRefs?: Function
  ) => {
    const response = await USER_APIS.getEmployementInfos({ per_page: "All" });
    const { status, response: data = [] } = response || {};
    if (status) {
      if (clearEmployementRefs) clearEmployementRefs();
      setData(data);
    }
  };

  const getEmployementInfoById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await USER_APIS.getEmployementInfoById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteEmployementInfo = async (
    id: number,
    setData: Function,
    clearEmployementRefs: Function
  ) => {
    const response = await USER_APIS.deleteEmployementInfo(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getEmployementInfos(setData, clearEmployementRefs);
    }
  };
  const createPublicationInfo = async (
    body: any,
    setOpen: Function,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await USER_APIS.createPublicationInfo(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      if (addMore) {
        reset();
      } else {
        setOpen(false);
      }
    }
  };

  const updatePublicationInfo = async (
    id: number,
    body: any,
    setOpen: Function
  ) => {
    const response = await USER_APIS.updatePublicationInfo(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const getPublicationInfos = async (
    setData: Function,
    clearPublicationRefs?: Function
  ) => {
    const response = await USER_APIS.getPublicationInfos({ per_page: "All" });
    const { status, response: data = [] } = response || {};
    if (status) {
      setData(data);
      if (clearPublicationRefs) clearPublicationRefs();
    }
  };

  const getPublicationInfoById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await USER_APIS.getPublicationInfoById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deletePublicationInfo = async (id: number, setData: Function) => {
    const response = await USER_APIS.deletePublicationInfo(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getPublicationInfos(setData);
    }
  };
  const createReferenceInfo = async (
    body: any,
    setOpen: Function,
    addMore: boolean,
    reset: Function
  ) => {
    const response = await USER_APIS.createReferenceInfo(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      if (addMore) {
        reset();
      } else {
        setOpen(false);
      }
    }
  };

  const updateReferenceInfo = async (
    id: number,
    body: any,
    setOpen: Function
  ) => {
    const response = await USER_APIS.updateReferenceInfo(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpen(false);
    }
  };

  const getReferenceInfos = async (
    setData: Function,
    clearReferenceRefs?: Function
  ) => {
    const response = await USER_APIS.getReferenceInfos({ per_page: "All" });
    const { status, response: data = [] } = response || {};
    if (status) {
      if (clearReferenceRefs) clearReferenceRefs();
      setData(data);
    }
  };

  const getReferenceInfoById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await USER_APIS.getReferenceInfoById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteReferenceInfo = async (id: number, setData: Function) => {
    const response = await USER_APIS.deleteReferenceInfo(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getReferenceInfos(setData);
    }
  };
  const createJobApplication = async (body: any, setOpenModal: Function) => {
    const response = await USER_APIS.createJobApplication(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      setOpenModal(false);
    }
  };

  const updateJobApplication = async (
    id: number,
    body: any,
    setOpen: Function
  ) => {
    const response = await USER_APIS.updateJobApplication(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.eportalAppliedJobListing);
    }
  };

  const getJobApplications = async (setData: Function) => {
    const response = await USER_APIS.getJobApplications({ per_page: "All" });
    const { status, response: data = [] } = response || {};
    if (status) {
      setData(data);
    }
  };

  const getJobApplicationById = async (
    id: number,
    formData: any,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await USER_APIS.getJobApplicationById(id);
    const { response: data } = response || {};
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const deleteJobApplication = async (id: number, setData: Function) => {
    const response = await USER_APIS.deleteJobApplication(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getJobApplications(setData);
    }
  };

  const getJobDesignations = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getJobDesignations(queryParams);
    const { response: data = [], status } = response || {};
    if (status) setData(data);
  };

  const checkJobEligibility = async (
    job_id: number,
    setOpenJobModal: Function,
    setJobDepts: Function,
    setJobCampuses: Function,
    setJobTypes: Function
  ) => {
    const response = await USER_APIS.checkJobEligibility({ job_id });
    const { status, response: data } = response || {};
    const { campuses = [], departments = [], job_types = [] } = data || {};
    if (status) {
      setOpenJobModal(true);
      setJobCampuses(campuses);
      setJobDepts(departments);
      setJobTypes(job_types);
    }
  };

  const getJobsToApply = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await USER_APIS.getJobsToApply(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(data);
      } else {
        setData(response?.response);
      }
    }
  };

  const getAppliedTests = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await USER_APIS.getAppliedTests(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData([...data]);
      } else {
        setData(response?.response);
      }
    }
  };

  const getTestSchedules = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await USER_APIS.getTestSchedules(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData([...data]);
      } else {
        setData(response?.response);
      }
    }
  };

  const checkTestEligibility = async (
    schedule_id: number,
    setApplyTest: Function,
    setTestCenters: Function,
    setTestType: Function,
    setIsPhd: Function
  ) => {
    const response = await USER_APIS.checkTestEligibility({ schedule_id });
    const { status, response: data } = response || {};
    const {
      centers = [],
      test_schedules = [],
      phd_programs = [],
    } = response || {};

    if (status) {
      setApplyTest(true);
      setTestCenters(centers);
      setTestType(test_schedules);
      setIsPhd(phd_programs);
    }
  };

  const generateFeeChallan = async (id: number) => {
    const response = await USER_APIS.generateFeeChallan(id);
    const { status, response: data } = response || {};

    if (status) {
      const { url } = data?.data;
      openExternalLink(url);
    }
  };

  const downloadResultCard = async (body: any, newWindow: Window | null) => {
    const response = await USER_APIS.downloadResultCard(body);
    const { status, message, response: htmlContent } = response || {};
    if (status && newWindow) {
      newWindow.document.open();
      newWindow.document.write(htmlContent);
      newWindow.document.close();

      newWindow.focus();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      // Handle error cases
      successToaster(
        message || "An error occurred while generating the result card."
      );
    }
  };

  const downloadTestSlip = async (body: any, newWindow: Window | null) => {
    const response = await USER_APIS.downloadTestSlip(body);
    const { status, message, response: htmlContent } = response || {};
    if (status && newWindow) {
      newWindow.document.open();
      newWindow.document.write(htmlContent);
      newWindow.document.close();

      newWindow.focus();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      // Handle error cases
      successToaster(
        message || "An error occurred while generating the result card."
      );
    }
  };

  const createTestApplication = async (body: any, setOpenModal: Function) => {
    const response = await USER_APIS.createTestApplication(body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
    }
  };

  const uploadChallan = async (
    body: FormData,
    app_id: any,
    setOpenModal: Function
  ) => {
    // console.log(app_id, "app");
    const response = await USER_APIS.uploadChallan(app_id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
    }
  };

  const admissionGenerateFeeChallan = async (id: number) => {
    const response = await USER_APIS.admissionGenerateFeeChallan(id); // Post request to send body data
    const { status, response: data } = response || {};

    if (status) {
      const { url } = data || {};
      openExternalLink(url);
    }
  };

  const admissionDownloadApplication = async (id: number) => {
    const response = await USER_APIS.admissionDownloadApplication(id); // Post request to send body data
    const { status, response: data } = response || {};
    if (status) {
      const { url } = data || {};
      openExternalLink(url);
    }
  };

  const addmisiionUploadChallan = async (
    body: FormData,
    app_id: any,
    setOpenModal: Function
  ) => {
    const response = await USER_APIS.addmisiionUploadChallan(app_id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
    }
  };
  const getCertificateLevelsByUserForApplyProgram = async (
    setData: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.getCertificateLevelsByUserForApplyProgram(
      queryParams
    );
    if (response?.status) {
      setData(response?.response?.data);
    }
  };

  const downloadEntryTestDocument = async (id: number) => {
    const response = await USER_APIS.downloadEntryTestDocument(id);
    const { url = "" } = response || {};
    downloadFileWithUrl(url);
  };

  const getMyProfile = async () => {
    const response = await USER_APIS.getMyProfile();
    const { user = {} } = response?.response || {};
    if (user) {
      setUser(user, true);
    }
  };

  const deleteUserAdmissionApplication = async (
    appId: number,
    setApplications: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await USER_APIS.deleteUserAdmissionApplication(appId);
    const { status = false, message = "" } = response || {};
    if (status) {
      successToaster(message);
      getStudentApplications(setApplications, queryParams);
    }
  };

  const downloadStudentIDPDFById = async () => {
    const response = await USER_APIS.downloadStudentIDPDFById();
    const { status, response: data } = response || {};
    const { url } = data || {};

    if (status && url) {
      openExternalLink(url);
    } else {
      errorToaster("File Not Download");
    }
  };
  const downloadStudentProfileImgById = async () => {
    const response = await USER_APIS.downloadStudentProfileImgById();
    const { status, response: data } = response || {};
    const { url } = data || {};

    if (status && url) {
      openExternalLink(url);
    } else {
      errorToaster("File Not Download");
    }
  };

  return {
    downloadStudentIDPDFById,
    downloadStudentProfileImgById,
    getCertificateLevelsByUserForApplyProgram,
    deleteUserAdmissionApplication,
    getMyProfile,
    getProfile,
    getJobsToApply,
    getJobTypesByUser,
    getJobDesignations,
    changePasswordByUser,
    checkJobEligibility,
    getCitiesByUser,
    getStudentApplications,
    submitAdmissionApplication,
    updateUserPersonalInfo,
    updateUserGuradianInfo,
    updateUserAdditionalInfo,
    createQualification,
    updateQualification,
    createEntryTest,
    updateEntryTest,
    deleteEntryTestByUser,
    getBoardsByUser,
    getCertificateLevelsByUser,
    getDegreeCertificatesByUser,
    getEntryTestsByUser,
    getQualificationsByUser,
    downloadEntryTestDocument,
    getResultTypesByUser,
    deleteQualificationByUser,
    downloadQualificationDocumentByUser,
    getEntryTestTypesByUser,
    getMyVouchers,
    getSubjectsByUser,
    getAdmissionPrograms,
    createEmployementInfo,
    updateEmployementInfo,
    getEmployementInfoById,
    getEmployementInfos,
    deleteEmployementInfo,
    createPublicationInfo,
    updatePublicationInfo,
    getPublicationInfoById,
    getPublicationInfos,
    deletePublicationInfo,
    createReferenceInfo,
    updateReferenceInfo,
    getReferenceInfoById,
    getLevelsDegreesAndBoards,
    getReferenceInfos,
    getStudentPersonalInfo,
    deleteReferenceInfo,
    createJobApplication,
    updateJobApplication,
    getJobApplicationById,
    getJobApplications,
    deleteJobApplication,
    getAppliedTests,
    getTestSchedules,
    checkTestEligibility,
    generateFeeChallan,
    downloadResultCard,
    downloadTestSlip,
    createTestApplication,
    uploadChallan,
    admissionGenerateFeeChallan,
    admissionDownloadApplication,
    addmisiionUploadChallan,
  };
};
export default useEportal;
