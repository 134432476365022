import { FC, Fragment, useEffect, useState } from "react";
import {
    DeleteTableSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
    TestCenterListingMain,
    TestCenterListingSection,
    TestCenterListingTop,
    FilterSection,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

import { useSelector, useStore } from "react-redux";
import { TestingCenterRoomDTO } from "utils/helpers/models/testing-service/testing-center-room.dto";
import useUtils from "hooks/useUtils";
import DataNotFound from "components/particles/table/data-not-found";
import { TestingCenterDTO } from "utils/helpers/models/testing-service/testing-center.dto";
import useAcademics from "../../useHooks";

const AcademicAwardList: FC = () => {
    const breadcrumbLinks = [

        {
            title: "Academics /",
            path: "",
        },
        {
            title: "Award List ",
            path: siteRoutes.academicsAwardList,
        },
    ]
    const columns: string[] = [
        "Sr #",
        "Reg #",
        "Student Name",
        "Sessional",
        "Mid Term",
        "End Term",
        "Total Marks",
        "Percentage",
        "Grade",
        "Grade Points",
        "Q.P",
    ];
    const columns2: string[] = [
        "Remarks",
        "Remarks By",
        "Remarks At",

    ];
     const { getQueryParams } = useUtils();
        const params = getQueryParams();
    const navigate = useNavigate();
    const [data, setData] = useState<{ records: any[] ,gradebook_remarks: any[] }>({ records: [] , gradebook_remarks: [] });
    const [center, setCenter] = useState<TestingCenterDTO>(new TestingCenterDTO());
    const { isLoading } = useSelector((state: any) => state.sharedReducer);
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
    const [search, setSearch] = useState<string>("");

    const onPageChange = (pageInfo: { selected: number, per_page: number }) => {
        const { selected: page, per_page } = pageInfo;
        setPagination({ ...pagination, page: page + 1, per_page });

    };
const { getAwardList } = useAcademics();
console.log(data);

    useEffect(() => {
        const payload ={
            gradebook_id : params?.gradebook_id,
        }
        getAwardList(payload, setData)
    }, []);






    return (
        <TestCenterListingMain>
            <TestCenterListingTop>
                <div className="left">
                    <span className="page-heading">Award List</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">

                </div>
            </TestCenterListingTop>

            <FilterSection className="content-radius-shadow">
                <div className="stats">
                    <div className="sats-item">
                        <div className="stats-title">Campus</div>
                        <div className="stats-value">
                         {data?.records[0]?.campus}
                        </div>
                    </div>
                    <div className="sats-item">
                        <div className="stats-title">Department</div>
                        <div className="stats-value">     {data?.records[0]?.department}</div>
                    </div>
                    <div className="sats-item">
                        <div className="stats-title">Course</div>
                        <div className="stats-value">     {data?.records[0]?.c_title}</div>
                    </div>
                    <div className="sats-item">
                        <div className="stats-title">Teacher</div>
                        <div className="stats-value">     {data?.records[0]?.teacher_name}</div>
                    </div>
                    <div className="">
                        <div className="stats-title">Status</div>
                        <div className="status-value">
                        {data?.records[0]?.verified_status == 1 ? "Verified" : "Not Verified"}
                        </div>
                    </div>
                </div>
            </FilterSection>
            <TestCenterListingSection
                isTableOverflowing={false}
                className="content-radius-shadow"
            >
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>

                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                            value={search}

                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.records?.map((item:any, index:number) => {
                                return (
                                    <tr>

                                        <td>{index+1}</td>
                                        <td>{item?.reg_number}</td>
                                        <td>{item?.name}</td>
                                        <td>{item?.sessional_marks}</td>
                                        <td>{item?.mid_term_marks}</td>
                                        <td>{item?.final_term_marks}</td>
                                        <td>{item?.total_marks}</td>
                                        <td>{item?.marks_percentage}</td>
                                        <td>--</td>
                                        <td>{item?.grade_point}</td>
                            
                                        <td>--</td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>

                    <Pagination
                        onPageChange={onPageChange}
                        {...pagination}
                    />
                </Fragment>
            </TestCenterListingSection>
            <TestCenterListingSection
                isTableOverflowing={false}
                className="content-radius-shadow"
            >
                <div className="list-header">
                    <div className="top-stats">Submitted By : <span>{data?.gradebook_remarks[0]?.submitted_by}</span></div>
                    <div className="top-stats"> Submitted At : <span>{data?.gradebook_remarks[0]?.created_at}</span></div>
                    <div className="top-stats">Gradebook Version : <span>{data?.records[0]?.gradebook_version}</span></div>

                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns2.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.gradebook_remarks.map((item:any, index:number) => {
                                return (
                                    <tr>

                                        <td>{item?.remarks}</td>
                                        <td>{item?.remarks_status}</td>
                                        <td>{item?.created_at}</td>



                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

            </TestCenterListingSection>
            <div className="description-box">
                <div className="common-fields">
                    <div className="input-field">
                        <label>Description</label>
                        <div className="field-wrap">
                           
                                <textarea
                                    placeholder="Description"
                                    value=""
                                ></textarea>
                         
                        </div>
                    </div>
                </div>
            </div>
            <div className="submit-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                              
                                >
                                    Rejected and Submitted
                                </button>
                                </div>
                                <div className="buttons">
                                <button className="lg-rounded-btn">
                                    Approved and Submitted
                                </button>
                                </div>
                           
                        </div>
        </TestCenterListingMain>
    );
};

export default AcademicAwardList;
