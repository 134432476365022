import { FC, Fragment, useEffect, useState } from "react";
import {
  TeacherLisitngSection,
  TeacherLisitngTop,
  TeacherListingMain,
} from "./style";
import {
  CallTableSvg,
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  MinusRedSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
  VoucherSvg,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useHRManagement from "../../useHook";

import SingleDetailDialog from "components/particles/dialogs/single-detail-dialog";
import { EmployeesDTO } from "utils/helpers/models/hr-management/employees.dto";
import SingleImageDialog from "components/particles/dialogs/show-single-img-dialog";

interface TeahersProps {}

const EmployeesListing: FC<TeahersProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Hr management / ", path: "" },
    { title: "Employees Listing", path: siteRoutes.employeesListing },
  ];

  const columns: string[] = [
    "",
    "Avatar",
    "CNIC",
    "First Name",
    "Father Name",
    "Email",
    "Campus",
    "Country",
  ];
  const navigate = useNavigate();
  const { hasAccess } = useStore();

  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getEmployeesLisiting, deleteEmployeeById, updateTeacherById } =
    useHRManagement();

  const [data, setData] = useState<EmployeesDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const [singleDetail, setSingleDetail] = useState({ value: "", label: "" });
  const [singleImageShow, setSingleImageShow] = useState({
    value: "",
    label: "",
  });
  const [showSingleDetailDialog, setShowSingleDetailDialog] =
    useState<boolean>(false);
  const [showSingleImageDialog, setShowSingleImageDialog] =
    useState<boolean>(false);
  const [isTableOpened, setIsTableOpened] = useState(false);
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deleteEmployeeById(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllEmployeesList(page + 1, search, per_page);
  };
  const getAllEmployeesList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      page,
      per_page,
      search,
    };
    getEmployeesLisiting(setData, queryParams, setPagination);
  };

  const toggleRowExpand = (index: number) => {
    (data as any)[index].isExpanded = !(data as any)[index].isExpanded;
    setData([...data]);
  };

  const goToEdit = (id: number) => {
    navigate(`${siteRoutes.employeesCreate}?id=${id}`);
  };

  const goToCreateEmployee = () => {
    navigate(siteRoutes.employeesCreate);
  };

  const handleShowSingleDetailDialog = (label: string, value: string) => {
    setSingleDetail({ label, value });
    setShowSingleDetailDialog(true);
  };

  const handleShowCnicImageDialog = (label: string, value: string) => {
    setSingleImageShow({ label, value });
    setShowSingleImageDialog(true);
  };

  // const updateTeacherIndexById = async (
  //   teacher: EmployeesDTO,
  //   index: number
  // ) => {
  //   const response = await confirmationPopup();
  //   if (response.isConfirmed) {
  //     const { id } = teacher;
  //     data[index] = teacher;
  //     setData([...data]);
  //     // updateTeacherById(id, teacher);
  //   }
  // };

  useEffect(() => {
    getAllEmployeesList(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <TeacherListingMain>
      <TeacherLisitngTop>
        <div className="left">
          <span className="page-heading">Employees Listing</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.employeesCreate) && (
            <div className="create-org-btn" onClick={goToCreateEmployee}>
              <button className="lg-rounded-btn">+ Add Employee</button>
            </div>
          )}
        </div>
      </TeacherLisitngTop>
      <TeacherLisitngSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllEmployeesList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllEmployeesList)
              }
            />
          </div>
        </div>
        <div className={`data-table ${isTableOpened ? "menu-opened" : ""}`}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th
                      className={
                        index > 8 ? `extra-head extra-head-${index - 8}` : ""
                      }
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((employee, index) => {
                return (
                  <Fragment key={index}>
                    <tr
                      className={`expandable ${
                        (employee as any).isExpanded && "opened"
                      }`}
                    >
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>
                            {(employee as any).isExpanded ? "-" : "+"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="user-img">
                          <img
                            src={employee?.user?.profile_image}
                            alt="employee img"
                          />
                        </div>
                      </td>
                      <td>{employee?.user?.cnic ?? "-"}</td>
                      <td>
                        {employee?.title?.title} {employee?.name ?? "-"}
                      </td>
                      <td>
                        {employee?.user?.users_meta[0].father_name ?? "-"}
                      </td>
                      <td>{employee?.email ?? "-"}</td>
                      <td>{employee?.campus?.title ?? "-"}</td>
                      <td>{employee?.nationality?.name ?? "-"}</td>
                    </tr>

                    {(employee as any).isExpanded && (
                      <tr>
                        <td colSpan={12}>
                          <div className="expanded-content">
                            <div className="particular-info particular-info-1">
                              <span className="title">User Name</span>
                              <span className="info">
                                {employee?.user_name !== ""
                                  ? employee?.user_name
                                  : "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-2">
                              <span className="title">Gender</span>
                              <span className="info">
                                {employee?.user?.gender ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-3">
                              <span className="title">Salary</span>
                              <span className="info">
                                {employee?.salary ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-4">
                              <span className="title">Religion</span>
                              <span className="info">
                                {employee?.user?.users_meta[0].religion ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-5">
                              <span className="title">Domicile</span>
                              <span className="info">
                                {employee?.domicile?.title ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-6">
                              <span className="title">Address</span>
                              <span className="info">
                                {employee?.user?.address ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-7">
                              <span className="title">Present Address</span>
                              <span className="info">
                                {employee?.present_address ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-8">
                              <span className="title">Permanent Address</span>
                              <span className="info">
                                {employee?.permanent_address ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-9">
                              <span className="title">Account Number</span>
                              <span className="info">
                                {employee?.acc_number ?? "-"}
                              </span>
                            </div>
                            <div className="particular-info particular-info-10">
                              <span className="title">Cnic Image</span>
                              <span
                                className="info"
                                onClick={() =>
                                  handleShowCnicImageDialog(
                                    "Cnic",
                                    employee?.user?.cnic_image
                                  )
                                }
                              >
                                <VoucherSvg />{" "}
                                <span className="status-tile green">
                                  click to show img
                                </span>
                              </span>
                            </div>

                            <div className="particular-info particular-info-11">
                              <span className="title">Actions</span>
                              <span className="info">
                                <div className="table-action-buttons">
                                  <div
                                    className="action-icon"
                                    onClick={() =>
                                      handleShowSingleDetailDialog(
                                        "Phone",
                                        employee?.user?.phone_no
                                      )
                                    }
                                  >
                                    <CallTableSvg />
                                  </div>

                                  {hasAccess(
                                    sitePermissions.employeesUpdate
                                  ) && (
                                    <div
                                      className="action-icon cp"
                                      onClick={() => goToEdit(employee.id)}
                                    >
                                      <EditTableSvg />
                                    </div>
                                  )}
                                  {hasAccess(
                                    sitePermissions.employeesDelete
                                  ) && (
                                    <div
                                      className="action-icon cp"
                                      onClick={() => handleDelete(employee.id)}
                                    >
                                      <DeleteTableSvg />
                                    </div>
                                  )}
                                </div>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {showSingleDetailDialog && (
            <SingleDetailDialog
              {...singleDetail}
              setOpen={setShowSingleDetailDialog}
            />
          )}
          {showSingleImageDialog && (
            <SingleImageDialog
              {...singleImageShow}
              setOpen={setShowSingleImageDialog}
            />
          )}
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination {...pagination} onPageChange={onPageChange} />
        </Fragment>
      </TeacherLisitngSection>
    </TeacherListingMain>
  );
};

export default EmployeesListing;
