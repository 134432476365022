import { FINANCE_APIS } from "libs/apis/finance.api";
import { HR_MANAGEMENT_APIS } from "libs/apis/hr-management.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AccSlotInstallmentParticular } from "utils/helpers/models/finance/acc-slot-installment-particular.dto";
import { BankDiscountAdjustmentDTO } from "utils/helpers/models/finance/bank-transaction.dto";
import { EmployeesDTO } from "utils/helpers/models/hr-management/employees.dto";
import { TeacherDTO } from "utils/helpers/models/hr-management/teacher.dto";

const useHRManagement = () => {
  const navigate = useNavigate();

  const createTeachers = async (
    body: TeacherDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.createTeachers(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.teachersListing);
      }
      successToaster(message);
    }
  };

  const updateTeacherById = async (id: number, body: TeacherDTO) => {
    const response = await HR_MANAGEMENT_APIS.updateTeacherById(body, id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.teachersListing);
    }
  };
  const updateIsEmployeeStatusById = async (id: number, body: TeacherDTO) => {
    const response = await HR_MANAGEMENT_APIS.updateTeacherById(body, id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.teachersListing);
    }
  };
  const updateTeacherIsOnLeaveById = async (id: number, body: TeacherDTO) => {
    const response = await HR_MANAGEMENT_APIS.updateTeacherById(body, id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.teachersListing);
    }
  };
  const updateTeacherIsVisitingById = async (id: number, body: TeacherDTO) => {
    const response = await HR_MANAGEMENT_APIS.updateTeacherById(body, id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.teachersListing);
    }
  };
  const getTeacherById = async (
    id: number,
    formData: TeacherDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.getTeacherById(id);
    const { status, message } = response || {};
    const data = response?.response;
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };

  const getTeachersTitles = async (
    setTeachersTitles: Function,
    queryParams: any = { per_page: "All" }
  ) => {
    const response = await HR_MANAGEMENT_APIS.getTeachersTitles(queryParams);
    const data = response?.response;
    setTeachersTitles(data);
  };

  const getTeachersLisiting = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.getTeachersLisiting(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(Array.isArray(data) ? data?.map((i) => new TeacherDTO(i)) : []);
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new TeacherDTO(i))
            : []
        );
      }
    }
  };

  const deleteTeacherById = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.deleteTeacherById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getTeachersLisiting(setData, queryParams, setPagination);
    }
  };

  const getEmployeesLisiting = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.getEmployeesLisiting(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new EmployeesDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map((i) => new EmployeesDTO(i))
            : []
        );
      }
    }
  };

  const deleteEmployeeById = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.deleteEmployeeById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getEmployeesLisiting(setData, queryParams, setPagination);
    }
  };
  const updateEmployeesById = async (id: number, body: EmployeesDTO) => {
    const response = await HR_MANAGEMENT_APIS.updateEmployeesById(body, id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.employeesListing);
    }
  };

  const createEmployees = async (
    body: EmployeesDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.createEmployees(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.employeesListing);
      }
      successToaster(message);
    }
  };

  const getEmployeeById = async (
    id: number,
    formData: EmployeesDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await HR_MANAGEMENT_APIS.getEmployeeById(id);
    const { status, message } = response || {};
    const data = response?.response;
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }
    setFormData({ ...formData });
  };

  return {
    createTeachers,
    updateTeacherById,
    updateIsEmployeeStatusById,
    updateTeacherIsOnLeaveById,
    updateTeacherIsVisitingById,
    getTeachersTitles,
    getTeacherById,
    getTeachersLisiting,
    getEmployeesLisiting,
    deleteTeacherById,
    deleteEmployeeById,
    updateEmployeesById,
    createEmployees,
    getEmployeeById,
  };
};

export default useHRManagement;
