import { FC, Fragment, useEffect, useState } from "react";
import {
  DownArrowLightgrayMediumSvg,
  DownloadApplicationSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  PlannerSection,
  PlannerDashboard,
  PlannerLisitngSection,
  PlannerLisitngTop,
} from "./style";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useAcademics from "containers/private/academics/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useMultiClickOutside from "hooks/multi-click-outside";
import { DownArrowSvg } from "assets/images/e-portal/svgs";
import useTimeTable from "containers/private/time-table/UseHooks";
import { TimeTablePlannerDashboardDTO } from "utils/helpers/models/time-table/time-table-planner-dashboard.dto";

interface TimeTablePlannerDashboardProps {}

const TimeTablePlannerDashboard: FC<TimeTablePlannerDashboardProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Time Table / ", path: siteRoutes.timeTablePlannerListing },

    {
      title: "Time Table Planner Dashboard",
      path: siteRoutes.timeTablePlannerDashboard,
    },
  ];
  const columns: string[] = [
    "Planner Title",
    "Academic Session",
    "Campus",
    "Buildings",
    "Faculties",
    "Dep",
    "Programs",
    "Sections",
  ];
  const navigate = useNavigate();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const { getTimeTablePlannerDashboardById } = useTimeTable();

  const { getPlanOfStudies, deletePlanOfStudy } = useAcademics();
  const { currentIndex, refs } = useMultiClickOutside();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { hasAccess } = useStore();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<any[]>([]);
  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deletePlanOfStudy(id, setData, queryParams, setPagination);
    }
  };
  const toggleApplicant = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    // getAllPlanOfStudies(page + 1, search, per_page);
  };

  const goToManageDefaultCreditHours = () => {
    navigate(`${siteRoutes.defaultCreditHoursListing}?planner_id=4`);
  };
  const goToPreferdToRoomCourseList = (id: number) => {
    navigate(`${siteRoutes.prefferedRoomCourseListing}?planner_id=${id}`);
  };
  const goTOSectionList = (id: number) => {
    navigate(`${siteRoutes.roomToSectionListing}?planner_id=${id}`);
  };

  const getPlannerDashboard = () => {
    const filterOptions = { ...filters };
    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }
    const queryParams = {
      per_page: pagination.per_page,
      page: pagination.page,
      ...(search && { search }),
      ...filterOptions,
      planner_id: params?.planner_id,
    };

    getTimeTablePlannerDashboardById(setData, queryParams, setPagination);
  };

  useEffect(() => {
    if (params) {
      getPlannerDashboard();
    }
  }, []);
  const plan = data?.planner || {};
  const plannerID = params?.planner_id || "";
  return (
    <PlannerDashboard>
      <PlannerLisitngTop>
        <div className="left">
          <span className="page-heading">Timetable Planner Dashboard</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </PlannerLisitngTop>

      <PlannerSection $isDropdownOpen={isDropdownOpen}>
        <div className="link-main">
          <div className="link-heading">Dashboard</div>
          <span className="dropdown-arrow cp" onClick={toggleApplicant}>
            <DownArrowLightgrayMediumSvg />
          </span>
        </div>

        {isDropdownOpen && (
          <div className="link-card-container">
            {[
              {
                title: "Planner Courses",
                value: data?.planner_courses_count ?? "-",
                color: "#128BAB26",
              },
              {
                title: "Planner Credit Hours",
                value: data?.planner_credit_hours_count ?? "-",
                color: "#ffbb3826",
              },
              {
                title: "Planner Rooms",
                value: data?.planner_rooms_count ?? "-",
                color: "#128BAB26",
              },
            ].map((card, index) => (
              <div
                className="link-card"
                style={{ backgroundColor: card.color }}
                key={index}
              >
                <div className="link-card-text">
                  <div className="card-heading">
                    <div className="link-title">{card.title ?? "-"}</div>
                    <div className="total-amount">{card.value ?? "-"}</div>
                  </div>
                  {/* <div className="card-text"> // backend remove this
                    <div>{card.text ?? "-"}</div>
                    <div>{card.availble_numbers ?? "-"}</div>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </PlannerSection>

      <PlannerLisitngSection className="content-radius-shadow">
        <div className={`data-table `}>
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="">{plan?.title ?? "-"}</div>
                </td>
                <td>
                  <div>
                    {plan?.academic_session?.type ?? "-"} -{" "}
                    {plan?.academic_session?.year ?? "-"}
                  </div>
                </td>
                <td>{plan?.campus?.title ?? "-"}</td>
                <td>
                  <div className="status-tile">
                    {plan?.building_id?.length ?? "-"}
                  </div>
                </td>
                <td>
                  <div className="status-tile">
                    {" "}
                    {plan?.faculty_id?.length ?? "-"}
                  </div>
                </td>
                <td>
                  <div className="status-tile">
                    {" "}
                    {plan?.department_id?.length ?? "-"}
                  </div>
                </td>
                <td>
                  <div className="status-tile">
                    {" "}
                    {plan?.program_id?.length ?? "-"}
                  </div>
                </td>
                <td>
                  <div className="status-tile">
                    {plan?.section_id?.length ?? "-"}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {Object.keys(plan).length != 0 && (
            <div className="menu-dropdown">
              <div
                className="action-button"
                onClick={() => goToPreferdToRoomCourseList(plannerID)}
              >
                Preferred Room to Course List
              </div>

              <div
                className="action-button"
                onClick={() => goTOSectionList(plannerID)}
              >
                Room To Section List
              </div>

              <div className="action-button">Break Time Management</div>
              <div className="action-button">Constrains Management</div>
              <div
                className="action-button"
                onClick={goToManageDefaultCreditHours}
              >
                Default Credit Hours Management
              </div>
              <div className="action-button">Rooms</div>
              <div className="action-button">View Sections</div>
              <div className="action-button">Assign Section</div>
              <div className="action-button-icon">
                <span>
                  <DownloadApplicationSvg />
                </span>
                Generate FET File
              </div>
              <div className="action-button-icon">
                <span>
                  <DownloadApplicationSvg />
                </span>
                Execute FET File
              </div>
              <div className="action-button-icon">
                <span>
                  <DownArrowSvg />
                </span>
                Download Course File (CSV)
              </div>
            </div>
          )}
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data?.planner} />
        </Fragment>
      </PlannerLisitngSection>
    </PlannerDashboard>
  );
};

export default TimeTablePlannerDashboard;
