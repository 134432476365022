import { FC, Fragment, useEffect, useState } from "react";
import {
  AdmissionCampaignListingMain,
  AdmissionCampaignListingSection,
  AdmissionCampaignListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  ProgramActionSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAdmissions from "../../useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { AdmissionCampaignDTO } from "utils/helpers/models/admissions/admission-campaign.dto";

const AdmissionCampaignsListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Admission /", path: "" },
    {
      title: "Admission Campaigns ",
      path: siteRoutes.admissionCampaignListing,
    },
  ];
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Code",
    "Title",
    "Type",
    "Campus",
    "Start Date",
    "End Date",
    "Program Level",
    "Status",
    "Action",
  ];
  const { hasAccess } = useStore();

  const { getAdmissionCampaigns, deleteAdmissionCampaign } = useAdmissions();
  const { handleSearchChange, handleTableSearch } = useUtils();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const navigate = useNavigate();
  const [data, setData] = useState<AdmissionCampaignDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const { getDateFromDateTime, createQuery } = useUtils();

  const goToCreateAdmissionCampaign = () => {
    navigate(siteRoutes.createAdmissionCampaign);
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
      };
      deleteAdmissionCampaign(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllAdmissionCampaigns(page + 1, search, per_page);
  };
  const getAllAdmissionCampaigns = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const queryParams = {
      per_page,
      page,
      search,
    };
    getAdmissionCampaigns(setData, queryParams, setPagination);
  };

  const goToAdmissionProgram = (program: any) => {
    const { id: admission_session_header_id, certificate_level_id: level_id } =
      program;
    const query = createQuery({ admission_session_header_id, level_id });
    navigate(siteRoutes.campaignProgramsListing + query);
  };

  const goToEdit = (campaignId: number) => {
    navigate(`${siteRoutes.createAdmissionCampaign}?id=${campaignId}`);
  };
  useEffect(() => {
    getAllAdmissionCampaigns(pagination.page, search, pagination.per_page);
  }, []);

  return (
    <AdmissionCampaignListingMain>
      <AdmissionCampaignListingTop>
        <div className="left">
          <span className="page-heading">Admission Campaigns</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.createAdmissionCampaign) && (
            <div className="create-org-btn">
              <button
                className="lg-rounded-btn"
                onClick={goToCreateAdmissionCampaign}
              >
                + Add New
              </button>
            </div>
          )}
        </div>
      </AdmissionCampaignListingTop>

      <AdmissionCampaignListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadAdmissionCampaignPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadAdmissionCampaignExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllAdmissionCampaigns
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAllAdmissionCampaigns
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((campaign, index) => {
                return (
                  <tr key={index}>
                    <td>{campaign?.code}</td>
                    <td>{campaign?.title}</td>
                    <td>{campaign?.session?.title}</td>
                    <td>{campaign?.category?.title}</td>
                    <td>{getDateFromDateTime(campaign?.start_date)}</td>
                    <td>{getDateFromDateTime(campaign?.end_date)}</td>
                    <td>{campaign?.certificate?.title}</td>
                    <td>
                      {campaign?.campaign_status ? (
                        <span className="status-tile">Active</span>
                      ) : (
                        <span className="status-tile red">Inactive</span>
                      )}
                    </td>
                    <td>
                      <div className="table-action-icons">
                        {hasAccess(
                          sitePermissions.admissionCampaignLinkProgram
                        ) && (
                          <div className="action-icon" title="Programs">
                            <ProgramActionSvg
                              onClick={() => goToAdmissionProgram(campaign)}
                            />
                          </div>
                        )}

                        {hasAccess(sitePermissions.EditAdmissionCampaign) && (
                          <div
                            className="action-icon"
                            onClick={() => goToEdit(campaign?.id)}
                          >
                            <EditTableSvg />
                          </div>
                        )}

                        {hasAccess(sitePermissions.deleteAdmissionCampaign) && (
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(campaign?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </AdmissionCampaignListingSection>
    </AdmissionCampaignListingMain>
  );
};

export default AdmissionCampaignsListing;
