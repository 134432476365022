import { FC, Fragment, useEffect, useState } from "react";
import {
  DownArrowLightgrayMediumSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import {
  Main,
  ListingSection,
  ListingTop,
  Filters,
  FilterSection,
  FilterHeader,
} from "./style";

import useStore from "hooks/useStore";
import useUtils from "hooks/useUtils";
import useAcademics from "../useHooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import DataNotFound from "components/particles/table/data-not-found";
import useOrganization from "containers/private/organization/useHooks";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import KalaAvatar from "../../../../assets/images/common/others/avatar-9.jpg";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { CardManagementListDTO } from "utils/helpers/models/academics/card-managements-list.dto";
import UpdateProfileImageModal from "./components/update-profile-image-modal";

interface dataToSendToImageUploadModal {
  student_id: number;
  avatar: string;
}

const StudentCardManagementListing: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: "" },
    {
      title: "Students Card Management / ",
      path: siteRoutes.studentCardManagementListing,
    },
  ];
  const columns: string[] = [
    "Reg Num",
    "Name",
    "Father Name",
    "CNIC",
    "Cell Number",
    "Avatar",
    "Action",
  ];

  const navigate = useNavigate();

  const { hasAccess } = useStore();
  const { getStudnetsListForCardManagement, downloadStudentsCard } =
    useAcademics();
  const { getPrograms, getSpecificStructure } = useOrganization();
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();

  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [data, setData] = useState<CardManagementListDTO[]>([]);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<any>({
    program_id: "",
    department_id: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [openImageUpdateModal, setOpenImageUpdateModal] =
    useState<boolean>(false);
  const [dataToSend, studentDataToSend] =
    useState<dataToSendToImageUploadModal>({
      student_id: 0,
      avatar: "",
    });

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const handleFilterChange = (event: any) => {
    const { value, name } = event.target;
    filters[name] = value;
    setFilters({ ...filters });
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
    }
    setFilters({ ...filters });
    getAllStudentsList(pagination?.page, search, pagination?.per_page);
  };

  const handleCourseSelection = (courseId: number) => {
    setSelectedStudents((prevSelectedCourses) => {
      if (prevSelectedCourses.includes(courseId)) {
        return prevSelectedCourses.filter((id) => id !== courseId);
      } else {
        return [...prevSelectedCourses, courseId];
      }
    });
  };

  const handleSelectAll = () => {
    setIsSelectedAll((prevState) => {
      const newIsSelectedAll = !prevState;
      const newSelectedCourses = newIsSelectedAll
        ? data?.map((item) => item?.id)
        : [];
      setSelectedStudents(newSelectedCourses);
      return newIsSelectedAll;
    });
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllStudentsList(page + 1, search, per_page);
  };

  const onApplyFilters = () => {
    getAllStudentsList(pagination?.page, search, pagination?.per_page);
  };

  const handleDownloadMultipleStudentsCards = async () => {
    const response = await confirmationPopup(
      "Are You Want To Download Selected Students Cards"
    );
    if (response.isConfirmed) {
      const student_ids: string = selectedStudents?.join(", ");

      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );
      downloadStudentsCard(student_ids);
    }
  };

  const hanldeOpenAvatarModal = (student_id: number, avatar: string) => {
    studentDataToSend({ student_id, avatar });
    setOpenImageUpdateModal(true);
  };

  const getAllStudentsList = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );
    const queryParams = {
      per_page,
      page,
      search,
      ...filteredFilters,
    };
    getStudnetsListForCardManagement(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getPrograms(setPrograms);
    getSpecificStructure(setDepartments, { type: "department " });
    if (!openImageUpdateModal && data) {
      getAllStudentsList(pagination.page, search, pagination?.per_page);
    }
  }, [openImageUpdateModal]);

  return (
    <Main>
      <ListingTop>
        <div className="left">
          <span className="page-heading">Student Card Management</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.studentCardManagementDownload) && (
            <Fragment>
              {selectedStudents?.length > 0 && (
                <button
                  className="lg-rounded-btn"
                  onClick={handleDownloadMultipleStudentsCards}
                >
                  Download Selected ( {selectedStudents?.length} ) Students Card
                </button>
              )}
            </Fragment>
          )}
        </div>
      </ListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader $showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Select Program</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.program_id}
                      onChange={handleFilterChange}
                      name="program_id"
                    >
                      <option value="">-- Select --</option>
                      {programs?.map((level, index) => {
                        return (
                          <option value={level.id} key={index}>
                            {level.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-field">
                <label>Select Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      value={filters.department_id}
                      onChange={handleFilterChange}
                      name="department_id"
                    >
                      <option value="">-- Select --</option>
                      {departments?.map((item: any, index: number) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" onClick={resetFilters}>
                  Reset
                </button>
                <button className="lg-rounded-btn" onClick={onApplyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <ListingSection
        isTableOverflowing={false}
        className="content-radius-shadow"
      >
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAllStudentsList
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(e, pagination.per_page, getAllStudentsList)
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                <td>
                  {data?.length > 0 && (
                    <div className="row-action-cell">
                      <div className="table-checkbox-field">
                        <input
                          type="checkbox"
                          checked={isSelectedAll}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>
                  )}
                </td>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((student, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="row-action-cell">
                        <div className="table-checkbox-field">
                          <input
                            type="checkbox"
                            checked={selectedStudents?.includes(student?.id)}
                            onChange={() => handleCourseSelection(student?.id)}
                          />
                        </div>
                      </div>
                    </td>
                    <td>{student?.reg_number ?? "-"}</td>
                    <td>{student?.user?.name ?? "-"}</td>
                    <td>{student?.user?.users_meta[0]?.father_name ?? "-"}</td>
                    <td>{student?.user?.cnic ?? "-"}</td>
                    <td>{student?.user?.phone_no ?? "-"}</td>
                    <td>
                      <img
                        src={
                          student?.user?.profile_image &&
                          student?.user?.profile_image !== ""
                            ? student?.user?.profile_image
                            : KalaAvatar
                        }
                        alt="Profile"
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </td>
                    <td>
                      <div className="action-button">
                        <button
                          className="black-btn"
                          onClick={() =>
                            hanldeOpenAvatarModal(
                              student?.user_id,
                              student?.user?.profile_image
                            )
                          }
                        >
                          Update Avatar
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {openImageUpdateModal && (
            <UpdateProfileImageModal
              setOpen={setOpenImageUpdateModal}
              studentData={dataToSend}
            />
          )}
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </ListingSection>
    </Main>
  );
};

export default StudentCardManagementListing;
