import { FC, useState, useEffect, Fragment } from "react";
import {
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import {
  StudyPlanCourseListingMain,
  StudyPlanCourseListingSection,
  StudyPlanCourseListingTop,
} from "./style";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import Pagination from "components/particles/table/pagination";

const HolidayList: FC = () => {
  const breadcrumbLinks = [
    { title: "Student Dashboard / ", path: "" },
    {
      title: " Holiday List",
      path: siteRoutes.studentHolidayList,
    },
  ];
  const columns1: string[] = ["Holiday Title", "Date ", "Description"];

  const [openAddCourseModal, setopenAddCourseModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [planOfStudy, setPlanOfStudy] = useState<PlanOfStudyDTO>(
    new PlanOfStudyDTO()
  );

  const [data, setData] = useState<PlanOfStudyCourseDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        search,
        pos_id: params?.pos_id,
      };
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAllPlanOfStudyCourses(page + 1, search, per_page);
  };
  const getAllPlanOfStudyCourses = (
    page: number,
    search: string,
    per_page: number
  ) => {
    const { pos_id = null } = params;
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      pos_id,
    };
  };

  const goToCreateCourse = () => {
    setopenAddCourseModal(true);
  };

  useEffect(() => {
    if (!openAddCourseModal) {
      getAllPlanOfStudyCourses(pagination.page, search, pagination.per_page);
    }
  }, [openAddCourseModal]);

  const handleSequenceChange = (event: any, index: number) => {
    const { value } = event.target;
    data[index].semester_seq_number = value;
    setData([...data]);
  };

  const handleTranscriptChange = (
    course: PlanOfStudyCourseDTO,
    index: number
  ) => {
    data[index] = course;
    setData([...data]);
  };

  return (
    <StudyPlanCourseListingMain>
      <StudyPlanCourseListingTop>
        <div className="left">
          <span className="page-heading">Holiday List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </StudyPlanCourseListingTop>

      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns1.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[1, 1, 1, 1].map((course, index) => {
                return (
                  <tr key={index}>
                    <td>Labour Day</td>
                    <td>23-08-2026</td>
                    <td>
                      <div className="mw-150">Here</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </StudyPlanCourseListingSection>
    </StudyPlanCourseListingMain>
  );
};

export default HolidayList;
