import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: var(--zoom-view-height);
  background: var(--modal-backshadow);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  align-items: flex-end;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: var(--white-color);
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .total-fee {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: var(--font-24);
    gap: 0.5em;
    margin-top: 1em;
    .amount-span {
      font-weight: bold;
      color: var(--medium-red);
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em 1em 2em;
    border-bottom: 1px solid var(--gray-light);

    @media screen and (max-width: 520px) {
      padding: 0 1em 1em 1em;
    }

    .heading {
      display: flex;
      justify-content: center;
      width: 100%;
      span {
        font-size: var(--font-32);
        color: var(--black-text);
        font-weight: 500;

        @media screen and (max-width: 520px) {
          font-size: var(--font-24);
        }
      }
    }

    .close-icon {
      .icon {
        width: 20px;
        height: 19px;

        @media screen and (max-width: 520px) {
          width: 18px;
          height: 17px;
        }
        path {
          fill: var(--black-text);
        }
      }
    }
  }

  .table-top {
    display: flex;
    gap: 1em;
    align-items: center;
    margin: 1em 0 0.5em 0;
    .total-fee-title {
      font-size: var(--font-24);
      text-align: center;
    }

    .table-modal-close-button {
      display: flex;
      justify-content: end;
      margin-top: 1em;
    }
    .buttons {
      padding: 0.5em 1.5em;
      border-radius: 0.5em;
      color: var(--white-color);
      background-color: var(--green-dark);
    }

    .button-primary {
      background-color: var(--primary) !important;
    }

    @media screen and (max-width: 500px) {
      flex-direction: row;
    }
  }
  form {
    width: 990px;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding-top: 3em;

    @media screen and (max-width: 1080px) {
      width: 100%;
      padding: 3em 2em 0 2em;
    }
    @media screen and (max-width: 880px) {
      padding: 1em;
    }

    table td .mw-150 {
      max-width: 150px;
    }

    .table-action-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.2em;

      .buttons {
        display: flex;
        align-items: center;
        @media screen and (max-width: 600px) {
          width: 100%;
          flex-direction: column;

          button {
            width: 100%;
          }
        }
      }
    }

    .common-fields {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 25px;

      .input-field {
        width: 50%;
      }
    }

    .action-buttons {
      width: 100%;
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: end;

      .buttons {
        display: flex;
        align-items: center;
        gap: 1em;

        @media screen and (max-width: 600px) {
          width: 100%;
          flex-direction: column;

          button {
            width: 100%;
          }
        }
      }
    }
  }
`;
