import useUtils from "hooks/useUtils";
import { LEGAL_MATTERS_API } from "libs/apis/legal-matters-api";
import { setDatasets } from "react-chartjs-2/dist/utils";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AdviserRatesDTO } from "utils/helpers/models/legal-matters/adviser-rates.dto";
import { LegalFrameWorksDocumentDTO } from "utils/helpers/models/legal-matters/framework-documents.dto";
import {
  LegalAdviserDTO,
  LegalAdviserTypeDTO,
} from "utils/helpers/models/legal-matters/legal-adviser.dto";
import { LegalCaseNatureDTO } from "utils/helpers/models/legal-matters/legal-case-nature.dto";
import { LegalCasesDTO } from "utils/helpers/models/legal-matters/legal-cases.dto";
import { LegalCourtsDTO } from "utils/helpers/models/legal-matters/legal-courts-dto";
import { LegalDocsTypeDTO } from "utils/helpers/models/legal-matters/legal-frameworks-document-types.dto";
import { LegalOrganizationDTO } from "utils/helpers/models/legal-matters/legal-orgainizations.dto";
import {
  LegalStatusesCategoryDTO,
  LegalStatusesDTO,
} from "utils/helpers/models/legal-matters/legal-statuses.dto";
import { MatterTypeDTO } from "utils/helpers/models/legal-matters/matter-type.dto";

const useLegalMatters = () => {
  const navigate = useNavigate();
  const { downloadFileWithUrl } = useUtils();
  const createLegalMatterFrameworkDocument = async (
    body: any,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalMatterFrameworkDocument(
      body
    );
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalFrameworkList);
      }
      successToaster(message);
    }
  };
  const updateLegalMatterFrameworkDocument = async (
    id: number,
    body: LegalFrameWorksDocumentDTO
  ) => {
    const response = await LEGAL_MATTERS_API.updateLegalMatterFrameworkDocument(
      id,
      body
    );
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalFrameworkList);
    }
  };
  const getLegalMatterFrameworkDocumentById = async (
    id: number,
    formData: LegalFrameWorksDocumentDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response =
      await LEGAL_MATTERS_API.getLegalMatterFrameworkDocumentById(id);
    const { status, message } = response || {};
    if (response?.status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
    }

    setFormData({ ...formData });
  };

  const getLegalFrameworkDocumentsList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalFrameworkDocumentsList(
      queryParams
    );
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data?.map((i) => new LegalFrameWorksDocumentDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalFrameWorksDocumentDTO) =>
                  new LegalFrameWorksDocumentDTO(i)
              )
            : []
        );
      }
    }
  };

  const DowloadLegalMatterFile = async (file_path: string) => {
    // const response = await ACADEMICS_APIS.downloadBlankGradeBook({ file_path });
    // const { status = false, response: url = "" } = response || {};
    if (file_path) {
      downloadFileWithUrl(file_path);
    }
  };
  const deleteLegalFrameworkDocumentsById = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalFrameworkDocumentsById(
      id
    );
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalFrameworkDocumentsList(setData, queryParams, setPagination);
    }
  };

  const getLegalMatterTypesList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalMatterTypesList(
      queryParams
    );
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new MatterTypeDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: MatterTypeDTO) => new MatterTypeDTO(i)
              )
            : []
        );
      }
    }
  };
  const getLegalmatterTypeById = async (
    id: number,
    formData: MatterTypeDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalmatterTypeById(id);
    const { status, message } = response || {};
    const data = response?.response;
    for (let key in formData) {
      formData[key] = data[key];
      setValue(key, formData[key]);
    }

    setFormData({ ...formData });
  };
  const updateLegalmatterTypeById = async (id: number, body: MatterTypeDTO) => {
    const response = await LEGAL_MATTERS_API.updateLegalmatterTypeById(
      id,
      body
    );
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalMatterTypeListing);
    }
  };

  const createLegalMatterType = async (
    body: MatterTypeDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalMatterType(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalMatterTypeListing);
      }
      successToaster(message);
    }
  };
  const deleteLegalmatterTypeById = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalmatterTypeById(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalMatterTypesList(setData, queryParams, setPagination);
    }
  };

  const getLegalDocTypeList = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalDocTypeList(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new LegalDocsTypeDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalDocsTypeDTO) => new LegalDocsTypeDTO(i)
              )
            : []
        );
      }
    }
  };

  const deleteLegalDocTypeByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalDocTypeByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalDocTypeList(setData, queryParams, setPagination);
    }
  };

  const createLegalMatterFrameworkDocumentType = async (
    body: LegalDocsTypeDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response =
      await LEGAL_MATTERS_API.createLegalMatterFrameworkDocumentType(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.frameworkDocumentTypeList);
      }
      successToaster(message);
    }
  };

  const updateLegalMatterFrameworkDocumentType = async (
    id: number,
    body: LegalDocsTypeDTO
  ) => {
    const response =
      await LEGAL_MATTERS_API.updateLegalMatterFrameworkDocumentType(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.frameworkDocumentTypeList);
    }
  };

  const getLegalMatterFrameworkDocumentTypeByID = async (
    id: number,
    formData: LegalDocsTypeDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response =
      await LEGAL_MATTERS_API.getLegalMatterFrameworkDocumentTypeByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }

      setFormData({ ...formData });
    }
  };
  const getLegalOrganization = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalOrganization(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data?.map((i) => new LegalOrganizationDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalOrganizationDTO) => new LegalOrganizationDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalOrganization = async (
    body: LegalOrganizationDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalOrganization(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalOrganizationList);
      }
      successToaster(message);
    }
  };

  const updateLegalOrganization = async (
    id: number,
    body: LegalOrganizationDTO
  ) => {
    const response = await LEGAL_MATTERS_API.updateLegalOrganization(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalOrganizationList);
    }
  };

  const deleteLegalOrganizationByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalOrganizationByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalOrganization(setData, queryParams, setPagination);
    }
  };
  const getLegalOrganizationByID = async (
    id: number,
    formData: LegalOrganizationDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalOrganizationByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };

  const getLegalStatuses = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalStatuses(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new LegalStatusesDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalStatusesDTO) => new LegalStatusesDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalStatuses = async (
    body: LegalStatusesDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalStatuses(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalStatusList);
      }
      successToaster(message);
    }
  };

  const updateLegalStatuses = async (id: number, body: LegalStatusesDTO) => {
    const response = await LEGAL_MATTERS_API.updateLegalStatuses(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalStatusList);
    }
  };

  const deleteLegalStatusesByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalStatusesByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalStatuses(setData, queryParams, setPagination);
    }
  };
  const getLegalStatusesByID = async (
    id: number,
    formData: LegalStatusesDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalStatusesByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };

  const getLegalCourts = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalCourts(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new LegalCourtsDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalCourtsDTO) => new LegalCourtsDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalCourts = async (
    body: LegalCourtsDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalCourts(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalCourtList);
      }
      successToaster(message);
    }
  };

  const updateLegalCourts = async (id: number, body: LegalCourtsDTO) => {
    const response = await LEGAL_MATTERS_API.updateLegalCourts(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalCourtList);
    }
  };

  const deleteLegalCourtsByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalCourtsByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalCourts(setData, queryParams, setPagination);
    }
  };
  const getLegalCourtsByID = async (
    id: number,
    formData: LegalCourtsDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalCourtsByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };
  const getLegalAdviser = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalAdviser(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new LegalAdviserDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalAdviserDTO) => new LegalAdviserDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalAdvisor = async (
    body: LegalAdviserDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalAdvisor(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalAdvisorListing);
      }
      successToaster(message);
    }
  };

  const updateLegalAdviserByID = async (id: number, body: LegalAdviserDTO) => {
    const response = await LEGAL_MATTERS_API.updateLegalAdviserByID(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalAdvisorListing);
    }
  };

  const deleteLegalAdviserByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalAdviserByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalAdviser(setData, queryParams, setPagination);
    }
  };
  const getLegalAdviserByID = async (
    id: number,
    formData: LegalAdviserDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalAdviserByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };
  const getLegalAdviserType = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalAdviserType(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data?.map((i) => new LegalAdviserTypeDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalAdviserTypeDTO) => new LegalAdviserTypeDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalAdvisorType = async (
    body: LegalAdviserDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.createLegalAdvisor(body);
    // const { status, message } = response || {};
    // if (status) {
    //   if (addMore) {
    //     resetForm();
    //   } else {
    //     navigate(siteRoutes.legalCourtList);
    //   }
    //   successToaster(message);
    // }
  };

  const updateLegalAdviserTypeByID = async (
    id: number,
    body: LegalAdviserDTO
  ) => {
    // const response = await LEGAL_MATTERS_API.updateLegalAdviserByID(id, body);
    // const { status, message } = response || {};
    // if (status) {
    //   successToaster(message);
    //   navigate(siteRoutes.legalCourtList);
    // }
  };

  const deleteLegalAdviserTypeByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.deleteLegalAdviserByID(id);
    // const { status, message } = response || {};
    // if (status) {
    //   successToaster(message);
    //   getLegalAdviser(setData, queryParams, setPagination);
    // }
  };
  const getLegalAdviserTypeByID = async (
    id: number,
    formData: LegalAdviserDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.getLegalAdviserByID(id);
    // const { status } = response || {};
    // if (status) {
    //   const data = response?.response;
    //   for (let key in formData) {
    //     formData[key] = data[key];
    //     setValue(key, formData[key]);
    //   }
    //   setFormData({ ...formData });
    // }
  };

  const getLegalCase = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalCase(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new LegalCasesDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalCasesDTO) => new LegalCasesDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalCase = async (
    body: LegalCasesDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createLegalCase(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.legalCasesList);
      }
      successToaster(message);
    }
  };

  const updateLegalCaseByID = async (id: number, body: LegalCasesDTO) => {
    const response = await LEGAL_MATTERS_API.updateLegalCaseByID(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.legalCourtList);
    }
  };

  const deleteLegalCaseByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteLegalCaseByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getLegalCase(setData, queryParams, setPagination);
    }
  };
  const getLegalCaseByID = async (
    id: number,
    formData: LegalCasesDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalCaseByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };
  const getLegalCaseNature = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalCaseNature(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new LegalCaseNatureDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalCaseNatureDTO) => new LegalCaseNatureDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalCaseNature = async (
    body: LegalCasesDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.createLegalCase(body);
    // const { status, message } = response || {};
    // if (status) {
    //   if (addMore) {
    //     resetForm();
    //   } else {
    //     navigate(siteRoutes.legalCasesList);
    //   }
    //   successToaster(message);
    // }
  };

  const updateLegalCaseNatureByID = async (id: number, body: LegalCasesDTO) => {
    // const response = await LEGAL_MATTERS_API.updateLegalCaseByID(id, body);
    // const { status, message } = response || {};
    // if (status) {
    //   successToaster(message);
    //   navigate(siteRoutes.legalCourtList);
    // }
  };

  const deleteLegalCaseNatureByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.deleteLegalCaseByID(id);
    // const { status, message } = response || {};
    // if (status) {
    //   successToaster(message);
    //   getLegalCase(setData, queryParams, setPagination);
    // }
  };
  const getLegalCaseNatureByID = async (
    id: number,
    formData: LegalCasesDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.getLegalCaseByID(id);
    // const { status } = response || {};
    // if (status) {
    //   const data = response?.response;
    //   for (let key in formData) {
    //     formData[key] = data[key];
    //     setValue(key, formData[key]);
    //   }
    //   setFormData({ ...formData });
    // }
  };
  const getLegalStatusCategory = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getLegalStatusCategory(
      queryParams
    );
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data)
            ? data?.map((i) => new LegalStatusesCategoryDTO(i))
            : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: LegalStatusesCategoryDTO) => new LegalStatusesCategoryDTO(i)
              )
            : []
        );
      }
    }
  };
  const createLegalStatusCategory = async (
    body: LegalCasesDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.createLegalStatusCategory(body);
    // const { status, message } = response || {};
    // if (status) {
    //   if (addMore) {
    //     resetForm();
    //   } else {
    //     navigate(siteRoutes.legalCasesList);
    //   }
    //   successToaster(message);
    // }
  };

  const updateLegalStatusCategoryByID = async (
    id: number,
    body: LegalCasesDTO
  ) => {
    // const response = await LEGAL_MATTERS_API.updateLegalStatusCategoryByID(id, body);
    // const { status, message } = response || {};
    // if (status) {
    //   successToaster(message);
    //   navigate(siteRoutes.legalCourtList);
    // }
  };

  const deleteLegalStatusCategoryByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.deleteLegalStatusCategoryByID(id);
    // const { status, message } = response || {};
    // if (status) {
    //   successToaster(message);
    //   getLegalCase(setData, queryParams, setPagination);
    // }
  };
  const getLegalStatusCategoryById = async (
    id: number,
    formData: LegalCasesDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    // const response = await LEGAL_MATTERS_API.getLegalStatusCategoryById(id);
    // const { status } = response || {};
    // if (status) {
    //   const data = response?.response;
    //   for (let key in formData) {
    //     formData[key] = data[key];
    //     setValue(key, formData[key]);
    //   }
    //   setFormData({ ...formData });
    // }
  };
  const getAdviserRates = async (
    setData: Function,
    queryParams: any = { per_page: "All" },
    setPagination?: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getAdviserRates(queryParams);
    const { status } = response || {};
    if (status) {
      const data = response?.response?.data;
      if (data) {
        const {
          total: totalRecords,
          current_page: page,
          per_page,
        } = response?.response;
        if (setPagination) {
          setPagination({ per_page, totalRecords, page });
        }
        setData(
          Array.isArray(data) ? data?.map((i) => new AdviserRatesDTO(i)) : []
        );
      } else {
        setData(
          Array.isArray(response?.response)
            ? response?.response?.map(
                (i: AdviserRatesDTO) => new AdviserRatesDTO(i)
              )
            : []
        );
      }
    }
  };
  const createAdviserRates = async (
    body: AdviserRatesDTO,
    addMore: Boolean = false,
    resetForm: Function
  ) => {
    const response = await LEGAL_MATTERS_API.createAdviserRates(body);
    const { status, message } = response || {};
    if (status) {
      if (addMore) {
        resetForm();
      } else {
        navigate(siteRoutes.advisorRatesListing);
      }
      successToaster(message);
    }
  };

  const updateAdviserRatesByID = async (id: number, body: AdviserRatesDTO) => {
    const response = await LEGAL_MATTERS_API.updateAdviserRatesByID(id, body);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      navigate(siteRoutes.advisorRatesListing);
    }
  };

  const deleteAdviserRatesByID = async (
    id: number,
    setData: Function,
    queryParams: any = { per_page: 10 },
    setPagination: Function
  ) => {
    const response = await LEGAL_MATTERS_API.deleteAdviserRatesByID(id);
    const { status, message } = response || {};
    if (status) {
      successToaster(message);
      getAdviserRates(setData, queryParams, setPagination);
    }
  };
  const getAdviserRatesByID = async (
    id: number,
    formData: AdviserRatesDTO,
    setValue: Function,
    setFormData: Function
  ) => {
    const response = await LEGAL_MATTERS_API.getAdviserRatesByID(id);
    const { status } = response || {};
    if (status) {
      const data = response?.response;
      for (let key in formData) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };

  return {
    getLegalFrameworkDocumentsList,
    DowloadLegalMatterFile,

    createLegalMatterFrameworkDocument,
    updateLegalMatterFrameworkDocument,
    getLegalMatterFrameworkDocumentById,
    deleteLegalFrameworkDocumentsById,

    getLegalMatterTypesList,
    getLegalmatterTypeById,
    updateLegalmatterTypeById,
    createLegalMatterType,
    deleteLegalmatterTypeById,

    getLegalDocTypeList,
    deleteLegalDocTypeByID,

    createLegalMatterFrameworkDocumentType,
    updateLegalMatterFrameworkDocumentType,
    getLegalMatterFrameworkDocumentTypeByID,

    updateLegalOrganization,
    createLegalOrganization,
    getLegalOrganizationByID,
    getLegalOrganization,
    deleteLegalOrganizationByID,

    updateLegalStatuses,
    createLegalStatuses,
    getLegalStatusesByID,
    getLegalStatuses,
    deleteLegalStatusesByID,

    updateLegalCourts,
    createLegalCourts,
    getLegalCourtsByID,
    getLegalCourts,
    deleteLegalCourtsByID,

    getLegalAdviser,
    deleteLegalAdviserByID,
    updateLegalAdviserByID,
    createLegalAdvisor,
    getLegalAdviserByID,

    getLegalAdviserType,
    deleteLegalAdviserTypeByID,
    updateLegalAdviserTypeByID,
    createLegalAdvisorType,
    getLegalAdviserTypeByID,

    createLegalCase,
    getLegalCaseByID,
    getLegalCase,
    updateLegalCaseByID,
    deleteLegalCaseByID,

    createLegalCaseNature,
    getLegalCaseNatureByID,
    getLegalCaseNature,
    updateLegalCaseNatureByID,
    deleteLegalCaseNatureByID,

    getLegalStatusCategory,
    createLegalStatusCategory,
    getLegalStatusCategoryById,
    updateLegalStatusCategoryByID,
    deleteLegalStatusCategoryByID,

    getAdviserRates,
    createAdviserRates,
    getAdviserRatesByID,
    updateAdviserRatesByID,
    deleteAdviserRatesByID,
  };
};

export default useLegalMatters;
