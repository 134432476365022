import { FC, Fragment, useEffect, useState } from "react";
import { Main, STListingSection, EmailTempTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";

import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  // ExcelSvg,
  // PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";

import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useTimeTable from "containers/private/time-table/UseHooks";
import { TimeTableDTO } from "utils/helpers/models/time-table/time-table.dto";
import { PreferedRoomToSectionListDTO } from "utils/helpers/models/time-table/add-room-to-section-list.dto";

interface Props {}

const RoomToSectionListing: FC<Props> = () => {
  const breadcrumbLinks = [
    {
      title: "Time Table / ",
      path: "",
    },
    {
      title: "Planner Dashboard / ",
      path: siteRoutes.timeTablePlannerDashboard,
    },
    {
      title: "Room To Section List",
      path: siteRoutes.roomToSectionListing,
    },
  ];
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const columns = ["Section Title", "Weightage", "Room", "Action"];

  const { deleteRoomToSectionListById, getRoomToPlannerSectionList } =
    useTimeTable();

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { handleSearchChange, handleTableSearch } = useUtils();

  const [data, setData] = useState<PreferedRoomToSectionListDTO[]>([]);

  const { hasAccess } = useStore();
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      const queryParams = {
        per_page: pagination.per_page,
        page: pagination.page,
        search,
      };
      deleteRoomToSectionListById(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number; per_page: number }) => {
    const { selected: page, per_page } = pageInfo;
    setPagination({ ...pagination, page: page + 1, per_page });
    getAddRoomToSectionListAll(page + 1);
  };

  const getAddRoomToSectionListAll = (page: number) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      planner_id: params?.planner_id,
    };
    getRoomToPlannerSectionList(setData, queryParams, setPagination);
  };

  const goToAddRoomToSectionList = (id: number) => {
    navigate(`${siteRoutes.roomToSectionCreate}?planner_id=${id}`);
  };

  useEffect(() => {
    getAddRoomToSectionListAll(pagination.page);
  }, []);

  return (
    <Main>
      <EmailTempTop>
        <div className="left">
          <span className="page-heading">Add Room To Section List</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.prefferedRoomCourseListCreate) && (
            <div className="create-org-btn">
              <button
                className="lg-rounded-btn"
                onClick={() => goToAddRoomToSectionList(params?.planner_id)}
              >
                + Add Room To Section
              </button>
            </div>
          )}
        </div>
      </EmailTempTop>

      <STListingSection className="content-radius-shadow">
        <div className="list-detail">
          <div className="page-sub-heading">
            <span>Add Room To Preffered Section Lisitng</span>
          </div>
        </div>
        <div className="list-header">
          <div className="table-data-export-buttons">
            {/* {hasAccess(sitePermissions.downloadStructureTypesListPdf) && ( */}
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            {/* )} */}
            {/* {hasAccess(sitePermissions.downloadStructureTypesListExcel) && ( */}
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
            {/* )} */}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(
                  e,
                  setSearch,
                  pagination.per_page,
                  getAddRoomToSectionListAll
                )
              }
              onKeyUp={(e) =>
                handleTableSearch(
                  e,
                  pagination.per_page,
                  getAddRoomToSectionListAll
                )
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((items, index: number) => {
                const { section } = items;
                return (
                  <tr key={index}>
                    <td>{section?.title ?? "-"}</td>
                    <td>
                      <div className="status-tile">
                        {items?.weight_percentage ?? "-"}
                      </div>
                    </td>
                    <td>{items?.room?.room_name ?? "-"}</td>
                    <td>
                      <div className="table-action-icons">
                        <Fragment>
                          {hasAccess(sitePermissions.timeTableDelete) && (
                            <div
                              className="action-icon"
                              onClick={() => handleDelete(items?.id)}
                            >
                              <DeleteTableSvg />
                            </div>
                          )}
                        </Fragment>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <DataNotFound show={!isLoading && !data?.length} />
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </STListingSection>
    </Main>
  );
};

export default RoomToSectionListing;
