import CreateAdmissionCampaign from "containers/private/admissions/admission-campaigns/create";
import AdmissionCampaignsListing from "containers/private/admissions/admission-campaigns/listing";
import AdmissionApplicantsListing from "containers/private/admissions/applicants-listing";
import EditAdmissionBoard from "containers/private/admissions/boards/edit";
import EditAdmissionCertificate from "containers/private/admissions/certificate/edit";
import EditAdmissionDocument from "containers/private/admissions/documents-management/edit";
import AdmissionDocumentsListing from "containers/private/admissions/documents-management/listing";
import CreateAdmissionETB from "containers/private/admissions/eligibility-template-bodies/create";
import AdmissionETBListing from "containers/private/admissions/eligibility-template-bodies/listing";
import CreateAdmissionETH from "containers/private/admissions/eligibility-template-headers/create";
import AdmissionETHListing from "containers/private/admissions/eligibility-template-headers/listing";
import AdmissionLockedMeritListing from "containers/private/admissions/locked-merit-list";
import CreateAdmissionBoardManagement from "containers/private/admissions/manage-boards/create";
import AdmissionBoardManagementListing from "containers/private/admissions/manage-boards/listing";
import CreateAdmissionCertificateManagement from "containers/private/admissions/manage-certificates/create";
import AdmissionCertificateManagementListing from "containers/private/admissions/manage-certificates/listing";
import AdmissionDocumentsMasterListing from "containers/private/admissions/manage-documents-master/listing";
import CreateManageMeritList from "containers/private/admissions/manage-merit-list/create";
import ManageMeritList from "containers/private/admissions/manage-merit-list/listing";
import CreateAdmissionQuota from "containers/private/admissions/manage-quotas/create";
import AdmissionManageQuotasListing from "containers/private/admissions/manage-quotas/listing";
import CreateAdmissionSubjectManagement from "containers/private/admissions/manage-subjects/create";
import SubjectManagementAdmissionListing from "containers/private/admissions/manage-subjects/listing";
import CreateMeritKeys from "containers/private/admissions/merit-keys/create";
import MeritKeysListing from "containers/private/admissions/merit-keys/listing";
import CreateMeritListFormula from "containers/private/admissions/merit-list-formula/create";
import MeritListFormulaListing from "containers/private/admissions/merit-list-formula/listing";
import AdmissionMeritListing from "containers/private/admissions/merit-list/listing";
import CreateAdmissionOfferLetter from "containers/private/admissions/offer-letter/create";
import AdmissionOfferLetterListing from "containers/private/admissions/offer-letter/listing";
import CreateQuotaList from "containers/private/admissions/quotas-list/create";
import QoutasListing from "containers/private/admissions/quotas-list/listing";
import QoutasProgramList from "containers/private/admissions/quotas-list/listing/programs/listing";
import StudentRegListing from "containers/private/admissions/student-registration/listing";
import AdmissionStudentListing from "containers/private/admissions/students-listing";
import CreateAdmissionTestTypes from "containers/private/admissions/test-types/create";
import TestTypesListing from "containers/private/admissions/test-types/listing";
import ProgramsListing from "containers/private/organization/programs/listing";
import EligibilityTemplateCreate from "containers/private/organization/programs/listing/components/eligibility-templates/create";
import EligibilityTemplatesListing from "containers/private/organization/programs/listing/components/eligibility-templates/listing";
import CreateAddLinkMeritList from "containers/private/organization/programs/listing/components/link-merit/create";
import LinkMeritList from "containers/private/organization/programs/listing/components/link-merit/listing";
import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const AdmissionRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();

  console.log(getPathToSetRoute(
    siteRoutes.admissionCertificateManagementListing,
    true
  ))

  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.campaignProgramsListing, true)}
        Component={ProgramsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionCampaignListing, true)}
        Component={AdmissionCampaignsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createAdmissionCampaign, true)}
        Component={CreateAdmissionCampaign}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionStudentListing)}
        Component={AdmissionStudentListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionApplicantsListing)}
        Component={AdmissionApplicantsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.editAdmissionDocument, true)}
        Component={EditAdmissionDocument}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionDocumentListing, true)}
        Component={AdmissionDocumentsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionTestTypesListing, true)}
        Component={TestTypesListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createAdmissionTestType, true)}
        Component={CreateAdmissionTestTypes}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.studentRegListing)}
        Component={StudentRegListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionMeritListing)}
        Component={AdmissionMeritListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionOfferLetterListing, true)}
        Component={AdmissionOfferLetterListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createAdmissionOfferLetter, true)}
        Component={CreateAdmissionOfferLetter}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.editAdmissionBoard)}
        Component={EditAdmissionBoard}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.editAdmissionCertificate)}
        Component={EditAdmissionCertificate}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.admissionManageDocumentsMasterListing
        )}
        Component={AdmissionDocumentsMasterListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAdmissioneligibilityTemplateHeaders,
          true
        )}
        Component={CreateAdmissionETH}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAdmissioneligibilityTemplateBody,
          true
        )}
        Component={CreateAdmissionETB}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.admissioneligibilityTemplateHeadersListing,
          true
        )}
        Component={AdmissionETHListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.admissioneligibilityTemplateBodiesListing,
          true
        )}
        Component={AdmissionETBListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionQuotasListing, true)}
        Component={AdmissionManageQuotasListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createAdmissionQuotas, true)}
        Component={CreateAdmissionQuota}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.admissionSubjectManagementListing,
          true
        )}
        Component={SubjectManagementAdmissionListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAdmissionSubjectManagement,
          true
        )}
        Component={CreateAdmissionSubjectManagement}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.admissionBoardManagementListing,
          true
        )}
        Component={AdmissionBoardManagementListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAdmissionBoardManagement,
          true
        )}
        Component={CreateAdmissionBoardManagement}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.admissionCertificateManagementListing,
          true
        )}
        Component={AdmissionCertificateManagementListing}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAdmissionCertificateManagement,
          true
        )}
        Component={CreateAdmissionCertificateManagement}
      />
      {/* <Route path="/linked-program-admission/linked-program-admission-listing" Component={LinkedProgramAdmissionListing} /> */}
      <Route
        path={getPathToSetRoute(siteRoutes.meritKeysListing, true)}
        Component={MeritKeysListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createMeritKeys, true)}
        Component={CreateMeritKeys}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.meritListFormulaListing, true)}
        Component={MeritListFormulaListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createMeritListFormula, true)}
        Component={CreateMeritListFormula}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.quotasAdmissionListing, true)}
        Component={QoutasListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createQuotasAdmissionList, true)}
        Component={CreateQuotaList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.manageMeritListListing, true)}
        Component={ManageMeritList}
      />
      <Route
        path={getPathToSetRoute(
          siteRoutes.createAdmissionManageMeritList,
          true
        )}
        Component={CreateManageMeritList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.qoutasListProgramListing, true)}
        Component={QoutasProgramList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.eligibilityTemplatesListing, true)}
        Component={EligibilityTemplatesListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.eligibilityTemplatesCreate, true)}
        Component={EligibilityTemplateCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.programLinkMeritList, true)}
        Component={LinkMeritList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createProgramLinkMeritList, true)}
        Component={CreateAddLinkMeritList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.admissionLockedMeritListing, true)}
        Component={AdmissionLockedMeritListing}
      />
    </Routes>
  );
};

export default AdmissionRoutes;
