export class LegalDocsTypeDTO {
  id!: number;
  name: string = "";
  legal_organizations_id: number;
  legal_organization: {
    id: number;
    name: string;
    organizations_id: number;
  } = {
    id: null,
    name: "",
    organizations_id: null,
  };
  organizations_id: number;
  constructor(data = {}) {
    Object.assign(this, data);
  }
}
