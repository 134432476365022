import styled from "styled-components";

export const SectionCoursesListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;
export const SectionCoursesListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;

export const SectionCoursesListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .action-menu {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    .table-action-buttons {
      button {
        white-space: nowrap;
      }
    }

    .table-action-icons {
      display: flex;
      align-items: center;
    }
  }
  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  .table-info-header {
    width: 100%;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--lightgray-extralight);
    .heading span {
      font-size: 28px;
      color: var(--black-text);
    }
  }

  table td {
    .action-icon {
      .view {
        rect {
          fill: var(--primary);
        }
      }
    }
  }
`;
