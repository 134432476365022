import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";

import { ReportsForm, Main, Top } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { ReportsDTO } from "utils/helpers/models/ds-system/reports.dto";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import useDSSystem from "../../useHook";

interface ReportsProps {}

const ReportsCreate: FC<ReportsProps> = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    {
      title: "DS System /",
      path: "",
    },
    {
      title: `Reports Listing / `,
      path: siteRoutes.resportsListing,
    },
    {
      title: `Reports ${params?.id ? "Update" : "Create"}`,
      path: siteRoutes.resportsCreate,
    },
  ];
  const { hasAccess } = useStore();
  const { createReports, updateReports, getReportById } = useDSSystem();
  const [formData, setFormData] = useState<ReportsDTO>(new ReportsDTO());

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<ReportsDTO>({ defaultValues: formData });

  const handleChange = (event: any): void => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    for (let key in formData) {
      if (key !== "header_id") {
        setValue(key as keyof ReportsDTO, "");
      }
    }
    setFormData({
      ...new ReportsDTO(),
    });
  };

  const onSubmit = (data: ReportsDTO, addMore: boolean = false) => {
    if (params?.id) {
      updateReports(params?.id, data);
    } else {
      createReports(data, addMore, resetForm);
    }
  };

  useEffect(() => {
    if (params.id) {
      getReportById(params?.id, setFormData);
    }
  }, []);

  return (
    <Main>
      <Top>
        <div className="left">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Reports
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </Top>

      <ReportsForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field ">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("title", { required: true })}
                  value={formData?.title}
                  onChange={handleChange}
                  placeholder="title"
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>

          <div className="input-field">
            <label>Button Text</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("button_text", { required: true })}
                  value={formData.button_text}
                  onChange={handleChange}
                  placeholder="button title"
                />
              </div>
              <FormErrorMessage error={errors.button_text} />
            </div>
          </div>
          <div className="input-field">
            <label>Button Url</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("url_path", { required: true })}
                  value={formData?.url_path}
                  onChange={handleChange}
                  placeholder="button url"
                />
              </div>
              <FormErrorMessage error={errors.url_path} />
            </div>
          </div>

          <div className="radio-field">
            <label htmlFor="no">Session Required</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="is_session_required_yes"
                  {...register("sess_req", { required: true })}
                  value={1}
                  onChange={handleChange}
                  checked={formData?.sess_req == 1}
                />
                <label htmlFor="is_session_required">YES</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="is_session_required_no"
                  {...register("sess_req", { required: true })}
                  value={0}
                  onChange={handleChange}
                  checked={formData.sess_req == 0}
                />
                <label htmlFor="is_session_required_no">NO</label>
              </div>
            </div>
            <FormErrorMessage error={errors.sess_req} />
          </div>
        </div>

        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            {!params?.id && (
              <>
                {hasAccess(sitePermissions.resportsCreate) && (
                  <button
                    className="lg-rounded-btn black"
                    onClick={handleSubmit((data) => onSubmit(data, true))}
                  >
                    Save & Add more
                  </button>
                )}
              </>
            )}

            {hasAccess(sitePermissions.resportsCreate) && (
              <button
                className="lg-rounded-btn"
                onClick={handleSubmit((data) => onSubmit(data))}
              >
                {params?.id ? "Update" : "Save"} & Exit
              </button>
            )}
          </div>
        </div>
      </ReportsForm>
    </Main>
  );
};

export default ReportsCreate;
